import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clientActions, userActions } from '../../../_actions';
import CssBaseline from '@mui/material/CssBaseline';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
//import { CustomAlert } from '../../components';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { getClientList, getCountryList, getStateList } from '../../../_helpers';
//import { alertActions } from '../../_actions';
import { PrivateLayout } from "../../../components/PrivateLayout";
import FormBox from '../../../components/formBox';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getValue } from '@testing-library/user-event/dist/utils';

const TableCellHeader = styled(TableCell)({
    fontWeight: 'bold',
    padding: 15,
    backgroundColor: '#e8e4e4'
});
const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('First Name is required'),
  lastName: Yup.string()
    .required('Last Name is required'),
  phoneNumber: Yup.string()
      .required('Phone Number is required')
});

const FullWidthTextField = styled(TextField)({
  width: '100%'
})

const SaveButton = styled(Button)({
  marginTop: 15,
  marginRight: 15
});

const ChangePasswordButton = styled(Button)({
  marginTop: 15,
  marginRight: 15
});

function UserProfilePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
 
  const userProfile = useSelector(state => state.userProfile.userProfile);
  const loading = useSelector(state => state.userProfile.loadingUserProfile);
  const { control, handleSubmit, register, formState: { errors, submitCount }, getValues, setValue, trigger, reset} = useForm({
    resolver: yupResolver(validationSchema)
  });


  useEffect(() => { 
    dispatch(userActions.getProfile())
  }, []);

  useEffect(() => {
    if (userProfile !== null && userProfile !== undefined){
      reset(userProfile);
    }
  }, [userProfile])

  const onSubmit = (data) => {
    let updateProfile = {...data, id: userProfile.id};
    dispatch(userActions.saveProfile(updateProfile));
  }

  return (
      <PrivateLayout>
        <Container maxWidth='xl'>
        <CssBaseline />
            {userProfile === null || loading ? <CircularProgress/> : 
            <form onSubmit={handleSubmit(onSubmit)}>
            <FormBox title={`User Profile: ${userProfile?.userId}`}  >
              <Box>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: "15px",
                    gridTemplateRows: 'auto',
                    p:2
                  }}
                  >
                  <Box>
                    <Controller
                        name="firstName"
                        control={control}
                        inputRef={register()}
                        defaultValue={userProfile?.firstName}
                        render={({ field }) =>
                            <FullWidthTextField label="First Name*" variant="outlined" margin="normal" {...field} error={errors.firstName ? true : undefined} 
                              helperText={errors.firstName?.message}/>
                        }
                    />
                  </Box>
                  <Box >
                  <Controller
                        name="lastName"
                        control={control}
                        inputRef={register()}
                        defaultValue={userProfile?.lastName}
                        render={({ field }) =>
                            <FullWidthTextField label="Last Name*" variant="outlined" margin="normal" {...field} error={errors.lastName ? true : undefined}
                              helperText={errors.lastName?.message}/>
                        }
                    />
                  </Box>
                  <Box >
                    <Controller
                        name="phoneNumber"
                        control={control}
                        inputRef={register()}
                        defaultValue={userProfile?.phoneNumber}
                        render={({ field }) =>
                            <FullWidthTextField label="Phone Number*" variant="outlined" margin="normal" {...field} error={errors.phoneNumber ? true : undefined}
                              helperText={errors.phoneNumber?.message}/>
                        }
                    />
                  </Box>
                  <Box >
                    
                  </Box>
                  
                  <Box>
                    <SaveButton                                  
                                color="primary"
                                type="submit"
                                variant="contained">Save</SaveButton>

                  </Box>
                  <Box>
                    <ChangePasswordButton      
                                onClick={() => navigate("/profile/changepassword")}                            
                                color="secondary"
                                type="button"
                                variant="contained">Change Password</ChangePasswordButton>   
                  </Box>
                </Box>
              </Box>
            </FormBox>
            </form>
            }
            
       
    </Container>
  </PrivateLayout>
    );
  }
  
export { UserProfilePage };