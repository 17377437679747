import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import {Button, IconButton, Typography} from '@mui/material';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { PrivateLayout } from "../../../components/PrivateLayout";
import CircularProgress from '@mui/material/CircularProgress';
import ReactDataGrid from "@inovua/reactdatagrid-community";
import { useSelector, useDispatch } from 'react-redux';
import { seedManagementActions } from "../../../_actions";
import { industryManagementActions } from "../../../_actions";
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import moment from "moment";
import ConfirmDialog from "../../../components/ConfirmDialog";

const gridStyle = { minHeight: 600, width: '100%' }

function SeedListPage () {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const seedList = useSelector( state => state.seedManagement.seedList ? state.seedManagement.seedList : null);
    const industryList = useSelector( state => state.industryManagement.industryList ? state.industryManagement.industryList : []);
    const [defaultFirstSeeds, setDefaultFirstSeeds] = useState([])
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [seedIdToDel, setSeedIdToDel] = useState(-1);
    let loadingSeedList = useSelector((state) => state.seedManagement.loadingSeedList);
    const [seedIndustries, setSeedIndustries] = useState([])

    useEffect(() => { 
        dispatch(seedManagementActions.getSeedList());
        dispatch(industryManagementActions.getIndustryList());
    }, []);

    useEffect(() => { 
        setDefaultFirst(seedList)
    }, [seedList]);

    useEffect(() => { 
        setSeedIndustries(getIndustriesBySeed(seedIdToDel))
    }, [seedIdToDel]);

    const seedColumns = [
        { name: 'seedName', header: 'Seed Name', defaultFlex: 2, enableColumnFilterContextMenu: true},
        { name: 'deploymentId', header: 'Deployment ID', defaultFlex: 2, enableColumnFilterContextMenu: true},
        { name: 'seedCreatedDate', header: 'Seed Created Date' , defaultFlex: 2, dateFormat: 'll LT',
            filterEditor: DateFilter,            
            filterEditorProps: (props, { index }) => {
                // for range and notinrange operators, the index is 1 for the after field
                return {
                    dateFormat: 'll LT',
                }
            },
            render: ({ value, cellProps: { dateFormat } }) =>
                moment(value).format(dateFormat)
        },
        { name: "hostName", header: 'Host Name', defaultFlex: 2, enableColumnFilterContextMenu: true},
        { name: ' updates', header: '', 
            render: (row) => {
                let navRoute="/seedmanagement/" + row.data.id
                let seedId = row.data.id
                return (
                    <>
                    <span><IconButton onClick={()=>navigate(navRoute)}><EditIcon color='action'/></IconButton></span>
                    {row.data.isDefaultSeed && row.data.seedName.toLowerCase() === "default seed" ? "" : <span><IconButton 
                        onClick={()=>onDelete(seedId)}><DeleteIcon color='action' /></IconButton></span>}
                    </>
                )
            }
        }
    ]

    const setDefaultFirst = (seedList)=>{
        if(!seedList) {
            return false
        }
        let hasDefault=false
        let newSeeds = [...seedList]
        let defaultIdx = -1;
        for(let i=0; i<newSeeds.length; i++){
            if(newSeeds[i].isDefaultSeed && newSeeds[i].seedName === "Default Seed"){
                defaultIdx=i;
                break;
            }
        }
        if(defaultIdx != -1){
            let defaultSeed = newSeeds.splice(defaultIdx, 1)
            newSeeds = [...defaultSeed, ...newSeeds]
            hasDefault = true
        } else {
            hasDefault = false
        }
        setDefaultFirstSeeds(newSeeds)
        dispatch(seedManagementActions.storeDefaultFirstSeeds(newSeeds))
        return hasDefault
    }

    const rowStyle = ({data, props}) => {
        if (data.seedName.toLowerCase() === "default seed") {
          // Return custom styles for the first row
          return {
            backgroundColor: '#5F9EA0'
          };
        }
        return {};
    }
    
    const filterValue = [
        { name: 'seedName', operator: 'contains', type: 'string', value: '' },
        { name: 'deploymentId', operator: 'eq', type: 'string', value: null },
        { name: 'seedCreatedDate', operator: 'after', type: 'date', value: '' },
        { name: 'hostName', operator: 'contains', type: 'string', value: '' }
    ];

    const onDelete = (seedId) => {
        setSeedIdToDel(seedId)
        setDeleteConfirmOpen(true);
    }
    const cancelDelete = () => {
        setDeleteConfirmOpen(false);
    }

    const deleteConfirmed = async () => {
        setDeleteConfirmOpen(false);
        if(seedIdToDel != -1){
            await dispatch(seedManagementActions.deleteSeed(seedIdToDel));
            setSeedIdToDel(-1)
            navigate("/seedmanagement");
        }
    }

    const getIndustriesBySeed=(seedId)=>{
        let industries=[]
        if(seedId && industryList){
            industries = industryList.filter(ind=>ind.deploymentSeedId == seedId)
        }
        return industries
    }

    const getSeedIndustryNames = ()=>{
        return seedIndustries.map(i=>i.industryName)
    }
    const seedIndustryPromptMsg = seedIndustries.length>0 ? `The seed is used by industries ${getSeedIndustryNames().join(", ")}. This will also remove the seed from the industries. ` : ""

    return (
        <PrivateLayout>
            <Container maxWidth="xl">
            <ConfirmDialog open={deleteConfirmOpen} cancelCallback={cancelDelete} confirmCallback={deleteConfirmed} title={"Confirm Delete"}
                    description={`${seedIndustryPromptMsg}Are you sure that you want to delete this seed?`} />
            <CssBaseline />
                <Typography variant="h3">Deployment Seed Management</Typography>
                <br/>
                {loadingSeedList ? (
                    <CircularProgress/>
                ): (
                    <Grid container>
                        <Grid item xs={10}>
                            <Typography variant="h5">Seed List</Typography>
                        </Grid>
                        <Grid item xs={2} style={{textAlign:'right'}}>
                            <Button variant="contained" color="primary" onClick={() => navigate(`/seedmanagement/new`)} >New Seed</Button>
                        </Grid>

                        <br/><br/>
                        <Grid xs={12}>
                            <ReactDataGrid
                                enableFiltering={true}
                                defaultFilterValue={filterValue}
                                idProperty="Id"
                                columns={seedColumns}
                                dataSource={defaultFirstSeeds}
                                style={gridStyle}
                                rowStyle={rowStyle}
                            />
                        </Grid>
                    </Grid>
                )}
            </Container>
        </PrivateLayout>
    )
}

export {SeedListPage};