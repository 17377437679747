import axios from "./axiosInstance";
import { alertActions, userActions } from "../_actions";

const API_URL = process.env.REACT_APP_API_ENDPOINT;

const setupInterceptors = (store) => {

  /**
   * This interceptor's job is to attach the bearer token to every request that requires it so that we don't have
   * to do that explicitly for every service call.
   */
   axios.interceptors.request.use(
    (config) => {     
      config.headers["Content-Type"] = 'application/json';
      return config;
    },
    (error) => {
      console.error(error);
      return Promise.reject(error);
    }
  );

  const { dispatch } = store;

  /**
   * This interceptor's responsibility is to detect an expired JWT token and to request a new one. This will be
   * completely transparent to the application. The failed call will then be retried with the new token 
   */
   axios.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      console.error(err);
      const originalConfig = err.config;
        if (err.response.status == 400 || err.response.status == 500){
          var message = "An error occurred. Please try again."
          if (err.reponse?.data?.Error?.ErrorDescription !== null ){
            message = err.response.data.Error.ErrorDescription
          } else if (err.message !== null){
            message = err.message;
          } else {
            message ="An error has occurred while trying to sign up for a trial: " + JSON.stringify(err);
        }
          dispatch(alertActions.error(message))
        }
        if (originalConfig.url !== "/user/authenticate" && originalConfig.url !== "/user/refresh" 
            && err.response) {
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;

                try {
                    const rs = await axios.post("/user/refresh", {
                        JwtToken: JSON.parse(localStorage.getItem('jwtBearer')),
                        RefreshToken: JSON.parse(localStorage.getItem('refreshToken')),
                    });

                    console.log('refresh response: ' , rs);
                    const respJwtToken  = rs.data.JwtToken;
                    const respRefreshToken = rs.data.RefreshToken;

                    localStorage.setItem('jwtBearer', JSON.stringify(respJwtToken));
                    localStorage.setItem('refreshToken', JSON.stringify(respRefreshToken));

                    return axios(originalConfig);
                } catch (_error) {
                    return Promise.reject(_error);
                    
                }
            }
        }
        if (err.response && originalConfig.url === "/user/refresh"){
            dispatch(userActions.logout());
        }
        return Promise.reject({err,message:err.response?.data?.Error?.ErrorDescription});
    }
  );
};

export default setupInterceptors;