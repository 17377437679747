import { licenseTemplateConstants } from '../_constants';
import { licenseTemplateService } from '../_services';
//import { alertActions } from './';
import { history } from '../_helpers';
import { alertActions } from './alert.actions';

export const licenseTemplateActions = {
    getList,
    getById,
    save,
    deleteLicenseTemplate, 
    addLicenseTemplate,
    duplicateLicense
};

function getList() {
    return dispatch => {
        dispatch(request());
        licenseTemplateService.getLicenseTemplates().then((licenseTemplates) => {
            dispatch(success(licenseTemplates));
        })
        
    };

    function request() { return { type: licenseTemplateConstants.GET_LICENSE_TEMPLATE_LIST_REQUEST } }
    function success(list) { return { type: licenseTemplateConstants.GET_LICENSE_TEMPLATE_LIST_SUCCESS, list } }
    function failure(error) { return { type: licenseTemplateConstants.GET_LICENSE_TEMPLATE_LIST_FAILURE, error } }
}

function getById(licenseTemplateId) {
    return dispatch => {
        dispatch(request({ licenseTemplateId }));
        return licenseTemplateService.getLicenseTemplate(licenseTemplateId).then((licenseTemplate) => {
            dispatch(success(licenseTemplate));
        })
        
    };

    function request() { return { type: licenseTemplateConstants.GET_LICENSE_TEMPLATE_REQUEST } }
    function success(licenseTemplate) { return { type: licenseTemplateConstants.GET_LICENSE_TEMPLATE_SUCCESS, licenseTemplate } }
    function failure(error) { return { type: licenseTemplateConstants.GET_LICENSE_TEMPLATE_FAILURE, error } }
}

function save(licenseTemplate) {
    return async dispatch => {
        dispatch(request());
        await licenseTemplateService.saveLicenseTemplate(licenseTemplate);
        dispatch(success());
        dispatch(alertActions.success("License template has been saved"));
        
    };

    function request() { return { type: licenseTemplateConstants.SAVE_LICENSE_TEMPLATE_REQUEST } }
    function success() { return { type: licenseTemplateConstants.SAVE_LICENSE_TEMPLATE_SUCCESS } }
    function failure(error) { return { type: licenseTemplateConstants.SAVE_LICENSE_TEMPLATE_FAILURE, error } }
}

function deleteLicenseTemplate(licenseTemplateId) {
    return async dispatch => {
        dispatch(request());
        await licenseTemplateService.deleteLicenseTemplate(licenseTemplateId);
        dispatch(success());
        dispatch(alertActions.success("License template has been deleted"));
    };

    function request() { return { type: licenseTemplateConstants.SAVE_LICENSE_TEMPLATE_REQUEST } }
    function success() { return { type: licenseTemplateConstants.SAVE_LICENSE_TEMPLATE_SUCCESS } }
    function failure(error) { return { type: licenseTemplateConstants.SAVE_LICENSE_TEMPLATE_FAILURE, error } }
}

function addLicenseTemplate(newLicenseTemplate) {
    return async dispatch => {
        dispatch(request());
        await licenseTemplateService.addLicenseTemplate(newLicenseTemplate);
        dispatch(success());
        dispatch(alertActions.success("The license template was added successfully"));
    };

    function request() { return { type: licenseTemplateConstants.ADD_LICENSE_TEMPLATE_REQUEST } }
    function success() { return { type: licenseTemplateConstants.ADD_LICENSE_TEMPLATE_SUCCESS } }
    function failure(error) { return { type: licenseTemplateConstants.ADD_LICENSE_TEMPLATE_FAILURE, error } }
}

function duplicateLicense(licenseId) {
    return async (dispatch,getState) => {
        const state = getState();
        dispatch(request());
        try {
            var licenseToDuplicate = await licenseTemplateService.getLicenseTemplate(licenseId);
            delete licenseToDuplicate.id;
            let newName = `${licenseToDuplicate.licenseName} (Copy)`;
            let nameExists = state.licenseTemplates.templateList.findIndex(t => t.licenseName === newName) >= 0;
            
            while (nameExists){
                newName += ' (Copy)';
                nameExists = state.licenseTemplates.templateList.findIndex(t => t.licenseName === newName) >= 0;
            }
            
            licenseToDuplicate.licenseName = newName;
            await licenseTemplateService.addLicenseTemplate(licenseToDuplicate);
            let newLicenseList = await licenseTemplateService.getLicenseTemplates();
            dispatch(success(newLicenseList));
            dispatch(alertActions.success("License template has been duplicated"));
        } catch (e){
            dispatch(alertActions.error("Could not duplicate the license template"))
            dispatch(failure("An error occurred while attempting to duplicate the licese template"));
        }
        
    };

    function request() { return { type: licenseTemplateConstants.DUPLICATE_LICENSE_TEMPLATE_REQUEST } }
    function success(newLicenseList) { return { type: licenseTemplateConstants.DUPLICATE_LICENSE_TEMPLATE_SUCCESS, newLicenseList } }
    function failure(error) { return { type: licenseTemplateConstants.DUPLICATE_LICENSE_TEMPLATE_FAILURE, error } }
}

