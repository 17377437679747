import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LicenseBox from "../../../components/licenseBox";
import { PrivateLayout } from "../../../components/PrivateLayout";
import { alertActions } from "../../../_actions";
import { chargebeeService } from "../../../_services/chargebee.service";
import { customerSubscriptionService } from "../../../_services/customerSubscription.service";
import Container from "@mui/material/Container";

function CustomerLicenses() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [licenseList, setLicenseList] = useState([]);
    const dispatch = useDispatch();

    const formatDate = (dateString) => {
        var inputDate = new Date(dateString);
        return inputDate.toLocaleDateString(undefined, { year: "numeric", month: "long", day: "numeric" });
    };
    useEffect(() => {
        setLoading(true);
        customerSubscriptionService.getLicenses()
        .then(licenses => {
          setLicenseList(licenses);
          setLoading(false);
        })
        .catch(e => {
          console.error("Could not get subscriptions",e);
          dispatch(alertActions.error("Could not get list of customer subscriptions."))
        });
    },[])

    return (
      <PrivateLayout>
          <Container maxWidth={'xl'}>
            {loading ? <CircularProgress/>: (
              licenseList.map((license) => (
                <LicenseBox productName={license.productName} productCode={license.productCode}>
                    <Stack direction="row" spacing={2} style={{ marginTop: 10, padding: 10 }}>
                        <Typography style={{ fontWeight:"bold" }}>License Name:</Typography>
                        <Typography>{license.licenseName} {license.trial? "(Trial)": ""}</Typography>
                        <Typography style={{ fontWeight:"bold" }}>Status:</Typography>
                        <Typography>{license.active ? "Active" : "Not active"}</Typography>
                    </Stack>
                    {license.trial ?
                    <Stack direction="row" spacing={2} style={{ marginTop: 10, padding: 10 }}>
                        <Typography style={{ fontWeight:"bold" }}>Trial End:</Typography>
                        <Typography>{formatDate(license.trialEndDate)}</Typography>
                    </Stack>
                    : null}
                    <Stack direction="row" spacing={2} style={{ marginTop: 10, padding: 10 }}>
                        <Typography style={{ fontWeight:"bold" }}>URL:</Typography>
                        <Typography>{license.url}</Typography>
                    </Stack>
                    <Stack direction="row" spacing={2} style={{ marginTop: 10, padding: 10 }}>
                        <Typography style={{ fontWeight:"bold" }}>Plan:</Typography>
                        <Typography>{license.subPlanId}</Typography>
                        <Typography style={{ fontWeight:"bold" }}>Total Users:</Typography>
                        <Typography>{license.quantity}</Typography>
                    </Stack>

                    <Button
                                type="button"
                                variant="contained"
                                onClick={()=>navigate(`/license/${license.companyLicenseId}`)}
                                style={{ backgroundColor: '#f0ecec', color: '#000000', marginTop: 20, marginBottom: 20 }}
                            >
                                Modify
                    </Button>
                </LicenseBox>
                ))
            )}
          </Container>
      </PrivateLayout>
    );
  }
  
export { CustomerLicenses };