import styled from '@emotion/styled';
import { ListItem } from '@mui/material';
import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';

const DraggingListItem = styled(ListItem)({
    background: 'rgb(235,235,235)'
});

const DraggableListItem = ({ item, index, renderItem, disabled }) => {

  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <>
        
        {snapshot.isDragging ?         
            <DraggingListItem
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}  
            >
                {renderItem(item)}
            </DraggingListItem>
            :
            <ListItem
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}            
            >
              
                {renderItem(item, disabled)}
            </ListItem>
        }        
        </>
       
      )}
    </Draggable>
  );
};

export default DraggableListItem;
