import React, {useEffect, useState} from "react";
import ModuleGrid from "./ModuleGrid";
import {Grid, TextField} from '@mui/material';
import {isNumber} from "@mui/x-data-grid/internals";


const moduleNamesMap = new Map([
    ['ADMIN', 'Administration'],
    ['AzzierMobileApp', 'Azzier Mobile App'],
    ['iOSWo', 'IOS Mobile App'],
    ['AndroidWo', 'Android Mobile App'],
    ['Codes', 'Codes'],
    ['Customer', 'Customer'],
    ['Document', 'Document'],
    ['Equipment', 'Equipment'],
    ['Help', 'Help'],
    ['Interface', 'Interface'],
    ['Inventory', 'Inventory'],
    ['KPI', 'Key Performance Indicator'],
    ['Labour', 'Labour'],
    ['Location', 'Location'],
    ['Notification', 'Notification'],
    ['PDM', 'Predictive Maintenance'],
    ['PM', 'Preventive Maintenance'],
    ['PrintFormDesigner', 'Print Form Designer'],
    ['Procedure', 'Procedure'],
    ['Project', 'Project'],
    ['Purchase', 'Purchase'],
    ['QueryBuilder', 'Query Builder'],
    ['Receiving', 'Receiving'],
    ['ReportBuilder', 'Report Builder'],
    ['Requisition', 'Requisition'],
    ['Route', 'Route'],
    ['Scheduling', 'Scheduling'],
    ['ScreenDesigner', 'Screen Designer'],
    ['Storeroom', 'Storeroom'],
    ['Timecards', 'Timecards'],
    ['Vendor', 'Vendor'],
    ['Workorder', 'Work Order'],
    ['WorkRequest', 'Work Request']
]);


function LicenseModulesEditor({licenseOptions, editable, onChange}){


    const [moduleDataArray, setModuleDataArray] = useState([]);
    const [formData, setFormData] = useState({
        maxEquipment: '', maxLocation: '', maxInventory: ''
    })
    const [licOptions, setLicOptions] = useState([]);
    const initModuleDataArray = () => {
        const arr = [];
        moduleNamesMap.forEach((displayName, moduleName) => {
            const moduleObject = {
                moduleKey: moduleName,
                moduleName: displayName,
                rwEnabled: false,
                rwUsers: null,
                roEnabled: false,
                roUsers: null
            };
            arr.push(moduleObject);
        });
        return arr;
    }

    const updateLicenseOptions = () => {
        let newLicenseOptions = [...licOptions];
        moduleDataArray.forEach(o => {
            let rwLicenseOption = newLicenseOptions.find(lo => lo.optionName.toLowerCase() === o.moduleKey.toLowerCase());
            let roLicenseOption = newLicenseOptions.find(lo => lo.optionName.toLowerCase() === `ro_${o.moduleKey.toLowerCase()}`);
            if (rwLicenseOption){
                rwLicenseOption.optionValue = o.rwUsers ? `!${o.rwUsers}` : (o.rwEnabled ? '1' : '0');
            } else {
                newLicenseOptions.push({
                    id: null,
                    optionName: o.moduleKey,
                    optionValue: o.rwUsers ? `!${o.rwUsers}` : (o.rwEnabled ? '1' : '0')
                });
            }

            if (roLicenseOption){
                roLicenseOption.optionValue = o.roUsers ? `!${o.roUsers}` : (o.roEnabled ? '!1' : '0');
            } else {
                newLicenseOptions.push({
                    id: null,
                    optionName: "ro_" + o.moduleKey,
                    optionValue: o.rwUsers ? `!${o.roUsers}` : (o.roEnabled ? '1' : '0')
                });
            }
        });
        newLicenseOptions = updateOption(newLicenseOptions, 'max_Location', formData.maxLocation);
        newLicenseOptions = updateOption(newLicenseOptions, 'max_Equipment', formData.maxEquipment);
        newLicenseOptions = updateOption(newLicenseOptions, 'max_Inventory', formData.maxInventory);

        setLicOptions(newLicenseOptions);
        onChange(newLicenseOptions);
    }

    const updateOption = (licenseOptions, optionName, optionValue) =>{

        if (optionValue !== null && optionValue !== ''){
            //there is a value set so update or add the option
            let optionToUpdate = licenseOptions.find(lo => lo.optionName.toLowerCase() === optionName);
            if (optionToUpdate) {
                optionToUpdate.optionValue = optionValue;
            } else {
                licenseOptions.push({
                    id: null, optionName, optionValue
                });
            }
            return [...licenseOptions];
        } else {
            //value is null or ''...  that means get rid of that option
            return licenseOptions.filter( o => o.optionName !== optionName);
        }
    }

    const convertLicenseOptionsToModuleOptions = (licenseOptions) => {
        const newModuleDataArray = initModuleDataArray();
        if (licenseOptions === null) return newModuleDataArray;
        const newFormValues = {maxEquipment : '', maxLocation: '', maxInventory: ''};
        licenseOptions.forEach(l => {
            let isRo = isReadOnlyOption(l.optionName)
            let moduleKey = l.optionName;
            if (isRo){
                moduleKey = l.optionName.replace("ro_", "");
            }
            var moduleEntry = newModuleDataArray.find( m => m.moduleKey.toLowerCase() === moduleKey.toLowerCase());
            if (moduleEntry){
                if (isRo){
                    moduleEntry.roEnabled = isModuleEnabled(l.optionValue);
                    moduleEntry.roUsers =  getFixedUserCount(l.optionValue);
                } else {
                    moduleEntry.rwEnabled = isModuleEnabled(l.optionValue);
                    moduleEntry.rwUsers = getFixedUserCount(l.optionValue);
                }
            }

            if (l.optionName.toLowerCase() === 'max_equipment') newFormValues.maxEquipment = l.optionValue;
            if (l.optionName.toLowerCase() === 'max_location') newFormValues.maxLocation = l.optionValue;
            if (l.optionName.toLowerCase() === 'max_inventory') newFormValues.maxInventory = l.optionValue;
        });
        setFormData(newFormValues);
        console.log("CONVERTED MODULE DATA");
        console.log(newModuleDataArray);
        return newModuleDataArray;
    }

    const isModuleEnabled = (optionValue) => {
        return optionValue !== '0' && optionValue !== '!0';
    }

    const getFixedUserCount = (optionValue) => {
        if (optionValue.startsWith('!')){
            let option = optionValue.replace('!', '');
            if (isValidUserCount(option)){
                return parseInt(option);
            }
        }
        return null;
    }

    const isValidUserCount = (str) => {
        // Use unary plus operator (+) to convert string to number
        // and then check if it's a finite number
        return !Number.isNaN(+str) && Number.isFinite(+str) && +str;
    };
    const isReadOnlyOption = (optionName) => {
        if (optionName !== null){
            return optionName.startsWith("ro_");
        }
    }

    const maxEquipmentChanged = (e) => {
        setFormData(prevState => {
            return {...prevState, maxEquipment: e.target.value};
        });
    }
    const maxLocationChanged = (e) => {
        setFormData(prevState => {
            return {...prevState, maxLocation: e.target.value};
        });
    }
    const maxInventoryChanged = (e) => {
        setFormData(prevState => {
            return {...prevState, maxInventory: e.target.value};
        });
    }



    useEffect(() => {
        updateLicenseOptions();
    }, [formData]);

    useEffect(() => {
        var moduleDataArray = convertLicenseOptionsToModuleOptions(licenseOptions)
        setModuleDataArray(moduleDataArray);
        setLicOptions([...licenseOptions]);
    }, [licenseOptions]);

    const onDataUpdate = (data) => {
        console.log(data);
        updateLicenseOptions()
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ModuleGrid data={moduleDataArray} editable={editable} onUpdate={onDataUpdate}  />
            </Grid>
            <Grid item sm={4}>
                <TextField variant="outlined" value={formData.maxEquipment} fullWidth type={"number"} label="Max Equipment" margin="normal" onChange={maxEquipmentChanged} disabled={!editable} />
            </Grid>
            <Grid item sm={4}>
                <TextField variant="outlined" value={formData.maxLocation} fullWidth type={"number"} label="Max Location" margin="normal" onChange={maxLocationChanged} disabled={!editable}/>
            </Grid>
            <Grid item sm={4}>
                <TextField variant="outlined" value={formData.maxInventory} fullWidth type={"number"} label="Max Inventory" margin="normal" onChange={maxInventoryChanged} disabled={!editable}/>
            </Grid>
        </Grid>

    );


}

export default LicenseModulesEditor;