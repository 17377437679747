import { List, ListItem, ListItemButton, ListItemText, ListSubheader, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Link as RouterLink,
    Route,
    Routes,
    MemoryRouter,
    useLocation,
    useParams,
    useNavigate,
  } from 'react-router-dom';
import { clientActions, companyLicenseActions } from '../_actions';


function AdminSidebar(){
    // get params
    let params = useParams();
    const navigate = useNavigate();
    console.log("Params:", params);
    let location = useLocation();
    let currentLicense = useSelector(state => state.licenseTemplates.currentLicenseTemplate);
    let currentCompany = useSelector(state => state.companyLicenses.selectedCompany);
    let companyLicenseState = useSelector(state => state.companyLicenses);
    let deploymentId = null;
    if (companyLicenseState.companyLicenses !== null && companyLicenseState.companyLicenses.length){
        deploymentId = companyLicenseState.companyLicenses[0].liveDeploymentId
    }

    let user = useSelector(state => state.authentication.user);
    const dispatch = useDispatch();

    useEffect(() =>{
        if (params.companyId !== null && params.companyId !== undefined && currentCompany === null){
            dispatch(companyLicenseActions.getCompanyLicenses(params.companyId));
        }
    },[params.companyId])


    function editingLicense() {
        const regex = new RegExp(/^\/licenses\/[0-9]{1,10}$/g);
        //console.log( location.pathname + ": editingLicense: ", regex.test(location.pathname));
        return regex.test(location.pathname);
    }

    function clientSelected() {
        const regex = new RegExp(/^\/clients\/[0-9]{1,10}/g);
        return regex.test(location.pathname);
    }

    function clientListSelected() {
        const regex = new RegExp(/^\/clients\/[0-9]{1,10}$/g);
        return regex.test(location.pathname) ;
    }

    function seedManagementSelected() {
        const regex = new RegExp(/^\/seedmanagement/g);
        return regex.test(location.pathname) ;
    }

    function industryManagementSelected() {
        const regex = new RegExp(/^\/industrymanagement/g);
        return regex.test(location.pathname) ;
    }

    function newClientSelected() {
        const regex = new RegExp(/^\/clients\/new$/g);
        return regex.test(location.pathname) ;
    }

    function clientLicensesSelected() {
        const regex = new RegExp(/^\/clients\/[0-9]{1,10}\/licenses\/[0-9]{1,10}$/g);
        return regex.test(location.pathname);
    }

    function clientCompanyInfoSelected() {
        const regex = new RegExp(/^\/clients\/[0-9]{1,10}\/details$/g);
        return regex.test(location.pathname);
    }

    function snapshotsSelected() {
        const regex = new RegExp(/^\/clients\/[0-9]{1,10}\/deployments\/[0-9]{1,10}\/snapshots$/g);
        return regex.test(location.pathname);
    }

    function changingPassword() {
        const regex = new RegExp(/^\/profile\/changepassword$/g);
        return regex.test(location.pathname);
    }

    function editingUser() {
        const regex = new RegExp(/^\/usermanagement\/edituser\//g);
        return regex.test(location.pathname);
    }

    return (
        <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            >
            <ListItemButton onClick={()=>{navigate(`/clients`)}} selected={location.pathname === '/clients' || location.pathname === '/'}>
                <ListItemText primary="Clients" />
            </ListItemButton>
            {newClientSelected() &&
                <List component="div" subheader={
                    <ListItemButton selected={true} sx={{ pl: 4 }}>
                        <ListItemText primary="New Client" />
                    </ListItemButton>
                }>
                </List>
            }
            {clientSelected() &&
            <List component="div" subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    <ListItemText disableTypography style={{color: 'black', fontWeight:"bold"}} fontWeight="bold" primary={
                        <Typography  style={{ color: 'black', fontWeight:"bold" }}>{currentCompany}</Typography>
                    } />
                </ListSubheader>
                }>

                <ListItemButton onClick={()=>{navigate(`/clients/${params.companyId}`)}} selected={clientListSelected()} sx={{ pl: 4 }}>
                    <ListItemText primary="Licenses" />
                </ListItemButton>
                {clientLicensesSelected() && (
                    <List component="div" >
                        <ListItem selected={true} sx={{ pl: 6 }}>
                            Edit License
                        </ListItem>
                    </List>
                )}
                <ListItemButton onClick={()=>{navigate(`/clients/${params.companyId}/details`)}} selected={clientCompanyInfoSelected()} sx={{ pl: 4 }}>
                    <ListItemText  primary="Company Info" />
                </ListItemButton>
                <ListItemButton onClick={()=>{navigate(`/clients/${params.companyId}/deployments/${deploymentId}/snapshots`)}} selected={snapshotsSelected()} sx={{ pl: 4 }}>
                    <ListItemText  primary="Manage Snapshots" />
                </ListItemButton>
            </List>
            }
            <ListItemButton  onClick={()=>{navigate(`/licenses`)}} selected={location.pathname === '/licenses'}>
                <ListItemText primary="License Admin" />
            </ListItemButton>
            {editingLicense() && (
                <List component="div" >
                    <ListItem selected={true} sx={{ pl: 4 }}>
                        <ListItemText primary={currentLicense?.licenseName} />
                    </ListItem>
                </List>
            )}
            {location.pathname === '/licenses/new' && (
                <List component="div" >
                    <ListItem selected={true} sx={{ pl: 4 }}>
                        <ListItemText primary="New License" />
                    </ListItem>
                </List>
            )}
            <ListItemButton onClick={()=>{navigate(`/profile`)}} selected={location.pathname === '/profile'}>
                <ListItemText primary="User Profile" />
            </ListItemButton>
            {changingPassword() && (
                <List component="div" >
                    <ListItem selected={true} sx={{ pl: 6 }}>
                        Change Password
                    </ListItem>
                </List>
            )}
            <ListItemButton onClick={()=>{navigate(`/usermanagement`)}} selected={location.pathname === '/usermanagement'}>
                <ListItemText primary="User Management" />
            </ListItemButton>
            {editingUser() && (
                <List component="div" >
                    <ListItem selected={true} sx={{ pl: 6 }}>
                        Edit User
                    </ListItem>
                </List>
            )}
            <ListItemButton onClick={()=>{navigate(`/releases`)}} selected={location.pathname === '/releases'}>
                <ListItemText primary="Releases" />
            </ListItemButton>
            <ListItemButton onClick={()=>{navigate(`/hosts`)}} selected={location.pathname === '/hosts'}>
                <ListItemText primary="Hosts" />
            </ListItemButton>
            <ListItemButton onClick={()=>{navigate(`/seedmanagement`)}} selected={location.pathname === '/seedmanagement'}>
                <ListItemText primary="Seed Management" />
            </ListItemButton>
            {(seedManagementSelected() || industryManagementSelected() ) && <List component="div" subheader={
                <ListItemButton onClick={()=>navigate(`/industrymanagement`)} 
                    selected={location.pathname === '/industrymanagement'} sx={{ pl: 4 }}>
                    <ListItemText primary="Industry Management" />
                </ListItemButton>
                }>
            </List>}
            <ListItemButton onClick={()=>{navigate(`/login`)}}  selected={location.pathname === '/login'}>
                <ListItemText primary="Log Out" />
            </ListItemButton>
        </List>
    );
}

export default AdminSidebar;
