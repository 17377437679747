import { userConstants } from '../_constants';

let user = JSON.parse(localStorage.getItem('user'));

const initialState = { 
    userProfile: null,
    loadingUserProfile: false,
    savingUserProfile: false,
    changingPassword: false
};

export function userProfile(state = initialState, action) {
    switch (action.type) {
        case userConstants.GET_USER_PROFILE_REQUEST:
            return {
                ...state,
                loadingUserProfile:true
            };
        case userConstants.GET_USER_PROFILE_SUCCESS:
            return {
                ...state,
                loadingUserProfile:false,
                userProfile: action.userProfile
            };
        case userConstants.GET_USER_PROFILE_FAILURE:
            return {
                ...state,
                loadingUserProfile:false
            };
        case userConstants.SAVE_USER_PROFILE_REQUEST:
            return {
                ...state,
                savingUserProfile:true
            };

        case userConstants.SAVE_USER_PROFILE_SUCCESS:
            return {
                ...state,
                savingUserProfile:false
            };
        case userConstants.SAVE_USER_PROFILE_FAILURE:
            return {
                ...state,
                savingUserProfile:false
            };
        case userConstants.CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                changingPassword:true
            };

        case userConstants.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changingPassword:false
            };
        case userConstants.CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                changingPassword:false
            };
        
        default:
            return state;
    }
}