import {
    Alert,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    ListSubheader,
    MenuItem,
    Modal,
    Select,
    Stack,
    styled,
    TextareaAutosize,
    TextField,
    Typography
} from "@mui/material";
import {Box} from "@mui/system";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import LicenseBox from "../../../components/licenseBox";
import {PrivateLayout} from "../../../components/PrivateLayout";
import {alertActions} from "../../../_actions";
import {chargebeeService} from "../../../_services/chargebee.service";
import {customerSubscriptionService} from "../../../_services/customerSubscription.service";
import Moment from 'react-moment';
import {Tune} from "@mui/icons-material";
import moment from 'moment';
import Container from '@mui/material/Container';

const FullWidthTextField = styled(TextField)({
    width: '100%'
})

const HeadingBox = styled(Box)({
    fontWeight: "bold"
});

const SaveButton = styled(Button)({
    marginTop: 15,
    marginRight: 15
});

const CancelButton = styled(Button)({
    marginTop: 15,
    marginRight: 15
});

const ClearDbButton = styled(Button)({
    marginTop: 15,
    marginRight: 15
});

const SelectFormControl = styled(FormControl)({
    width: "100%"
});

const ModalContent = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    padding: 50,
    transform: 'translate(-50%, -50%)',
    width: "70%",
    bgcolor: 'background.paper',
    backgroundColor: "#FFFFFF",
    margin: 40,
    boxShadow: 24,
    maxWidth: 750,
    pt: 2,
    px: 4,
    pb: 3,
});

function CustomerLicenseDetail() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [license, setLicense] = useState(null);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmClearDbOpen, setConfirmClearDbOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [formState, setFormState] = useState({
        priceId: '',
        licenseType: '',
        totalUsers: 0,
        paymentMethodSetUp: false,
        trial: false,
        trialEndDate: null,
        itemPrices: [],
        selectedItemPrice: null,
        changed: false
    });
    const dispatch = useDispatch();
    let {companyLicenseId} = useParams();

    const loadLicenseDetails = () => {
        setLoading(true);
        customerSubscriptionService.getLicense(companyLicenseId)
            .then(l => {
                console.log("license:", l);
                setLicense(l);
                setFormState({
                    priceId: l.subPriceId,
                    selectedItemPrice: l.itemPrices?.find(p => p.id === l.subPriceId),
                    totalUsers: l.quantity,
                    paymentMethodSetUp: l.paymentMethodSetUp,
                    trial: l.trial,
                    trialEndDate: l.trialEndDate,
                    itemPrices: l.itemPrices,
                    changed: false,
                    subscriptionId: l.subscriptionId,
                })
                setLoading(false);
            })
            .catch(e => {
                console.error("Could not get subscription detail", e);
                dispatch(alertActions.error("NCould not get subscription detail."))
            });
    }

    useEffect(() => {
        loadLicenseDetails();
    }, [])

    const handlePriceIdChange = (evt) => {
        var newFormState = {...formState};
        var selectedItemPrice = formState.itemPrices.find(p => p.id === evt.target.value);
        newFormState.priceId = evt.target.value;
        newFormState.selectedItemPrice = selectedItemPrice;
        if (formState.totalUsers > selectedItemPrice.metadata.quantity_meta.max) {
            newFormState.totalUsers = selectedItemPrice.metadata.quantity_meta.max
        }
        newFormState.changed = detectChange(license, newFormState);
        setFormState(newFormState);

    }

    const handleTotalUserChange = (evt) => {
        var newFormState = {...formState};

        newFormState.totalUsers = parseInt(evt.target.value);
        if (newFormState.totalUsers > formState.selectedItemPrice.metadata.quantity_meta.max) {
            newFormState.totalUsers = formState.selectedItemPrice.metadata.quantity_meta.max
        }
        if (newFormState.totalUsers < formState.selectedItemPrice.metadata.quantity_meta.min) {
            newFormState.totalUsers = formState.selectedItemPrice.metadata.quantity_meta.min
        }
        newFormState.changed = detectChange(license, newFormState);
        setFormState(newFormState);
    }

    const detectChange = (lic, form) => {
        if (form.priceId !== lic.subPriceId) return true;
        if (form.totalUsers !== lic.quantity) return true;
        return false;
    }

    const onApplyChange = () => {
        setSubmitting(true);
        customerSubscriptionService.updateSubscription(companyLicenseId, formState.priceId, formState.totalUsers)
            .then(() => {
                setSubmitting(false);
                navigate("/");
            })
            .catch((error) => {
                console.error(error);
                dispatch(alertActions.error(`An error occurred while updating subscription. ${error.message}`));
                setSubmitting(false);
            });
    }

    const showClearDatabase = () => {

        if (license.dbClearedDate != null) return false;
        if (license.trialEndDate == null) return false;
        const now = moment();
        const trialEnd = moment(license.trialEndDate);
        if (now.diff(trialEnd, 'months') >= 1) return false;

        return true;
    }

    const onSave = () => {
        setConfirmOpen(true);
    }

    const onCancelChange = () => {
        setConfirmOpen(false);
    }

    const getPeriodUnit = (periodUnitId) => {
        if (periodUnitId == 3) return "month";
        if (periodUnitId == 4) return "year";
        return "";
    }

    const onClearDb = () => {
        setConfirmClearDbOpen(true);
    }
    const onCancelClearDb = () => {
        setConfirmClearDbOpen(false);
    }

    const onClearDbConfirmed = async () => {
        setSubmitting(true);
        await customerSubscriptionService.clearDeploymentDatabase(license.deploymentId);
        dispatch(alertActions.success("Database Cleared"));
        setConfirmClearDbOpen(false);
        setSubmitting(false);
        loadLicenseDetails();

    }

    return (
        <PrivateLayout>
            <Container maxWidth='xl'>
                <Modal open={confirmOpen} onClose={onCancelChange}>
                    <ModalContent>
                        <Typography variant='h5'>Update Subscription</Typography><br/>
                        <Grid container>
                            <Grid item sm={12}>
                                Subscription update summary:<br/>
                                <span
                                    style={{fontWeight: "bold"}}>New Subscription Plan:</span> {formState.priceId}<br/>
                                <span style={{fontWeight: "bold"}}>Number of Users:</span> {formState.totalUsers}<br/>
                                <span
                                    style={{fontWeight: "bold"}}>Price:</span> ${formState.selectedItemPrice?.price / 100.00 * formState.totalUsers}/{getPeriodUnit(formState.selectedItemPrice?.periodUnit)}<br/>
                                {formState.trial ?
                                    <Box sx={{gridColumnStart: 1, gridColumnEnd: 3}}>
                                        <Alert severity="info">Your trial ends on <Moment
                                            format="MMM-DD-YYYY">{formState.trialEndDate}</Moment>. Any changes you make
                                            to your license now will be reflected when your trial activates at the end
                                            of the trial period. </Alert>
                                    </Box>
                                    :
                                    <Box sx={{gridColumnStart: 1, gridColumnEnd: 3}}>
                                        <Alert severity="info">If you are increasing your subscription fees your payment
                                            method will be charged immediately. If you have any outstanding credits on
                                            your account they will be applied to this change.</Alert>
                                    </Box>
                                }

                            </Grid>

                        </Grid>
                        <Grid container justifyContent="center" style={{marginTop: 20}}>
                            <Grid item>
                                <Button variant="outlined" disabled={submitting} onClick={onApplyChange}
                                        sx={{m: 2}}>Apply {submitting && <CircularProgress size={10}/>}</Button>
                                <Button variant="outlined" disabled={submitting}
                                        onClick={onCancelChange}>Cancel</Button>
                            </Grid>
                        </Grid>
                    </ModalContent>

                </Modal>
                <Modal open={confirmClearDbOpen} onClose={onCancelClearDb}>
                    <ModalContent>
                        <Typography variant='h5'>Clear Database</Typography><br/>
                        <Grid container>
                            <Grid item sm={12}>
                                Are you sure you want to clear your database?<br/>
                                If you clear your database only user accounts will remain. All work you have done up to
                                this point including the original test data will be deleted.
                                This is typically only done once you have finished your trial and want a fresh start.
                            </Grid>

                        </Grid>
                        <Grid container justifyContent="center" style={{marginTop: 20}}>
                            <Grid item>
                                <Button variant="outlined" disabled={submitting} onClick={onClearDbConfirmed}
                                        sx={{m: 2}}>Yes, clear my whole database {submitting &&
                                    <CircularProgress size={10}/>}</Button>
                                <Button variant="outlined" disabled={submitting}
                                        onClick={onCancelClearDb}>Cancel</Button>
                            </Grid>
                        </Grid>
                    </ModalContent>

                </Modal>
                {loading ? <CircularProgress/> : (

                    <LicenseBox productName={license.productName} productCode={license.productCode}>
                        <Grid container spacing={2}>
                            <Grid item xl={12}>
                                {formState.trial &&
                                    <Alert severity="info">Your trial ends on <Moment
                                        format="MMM-DD-YYYY">{formState.trialEndDate}</Moment>. Any changes you make to
                                        your license now will be reflected when your trial activates at the end of the
                                        trial period. </Alert>
                                }
                            </Grid>
                            {formState.subscriptionId === '' || formState.subscriptionId === null ? (
                                <>
                                    <Grid item xl={12}>
                                        Your cannot change your license yourself. To change licence information please
                                        contact Tero sales.
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <Grid item sm={6}>
                                        <SelectFormControl style={{marginTop: 16}} variant="outlined">
                                            <InputLabel htmlFor="priceId">License Type </InputLabel>
                                            <Select label="License Type" disabled={!formState.paymentMethodSetUp}
                                                    id="priceId" value={formState.priceId}
                                                    onChange={handlePriceIdChange}>
                                                {
                                                    formState.itemPrices?.map(p => (
                                                        <MenuItem key={p.id} value={p.id}>
                                                            {p.name} - ${p.price / 100.0}/{getPeriodUnit(p.periodUnit)}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </SelectFormControl>
                                    </Grid>
                                    <Grid item sm={6}>
                                        <FormControl>
                                            <FullWidthTextField label="Total Users"
                                                                disabled={!formState.paymentMethodSetUp} id="totalUsers"
                                                                type="number" variant="outlined" margin="normal"
                                                                value={formState.totalUsers}
                                                                onChange={handleTotalUserChange}
                                                                helperText={"This plan supports up to " + formState.selectedItemPrice?.metadata.quantity_meta.max + " users"}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {!formState.paymentMethodSetUp &&
                                        <Grid item xs={12}>
                                            <Alert severity="error">Cannot make changes until a payment method is added
                                                in your billing section.</Alert>
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <SaveButton
                                            color="primary"
                                            type="button"
                                            variant="contained"
                                            onClick={onSave}
                                            disabled={!formState.paymentMethodSetUp || !formState.changed}
                                        >
                                            Save
                                        </SaveButton>
                                        <CancelButton
                                            color="error"
                                            type="button"
                                            variant="contained"
                                            onClick={() => navigate(`/`)}

                                        >
                                            Cancel
                                        </CancelButton>
                                    </Grid>
                                </>
                            )}

                            {showClearDatabase() &&
                                <Grid item xs={12}>
                                    <ClearDbButton
                                        color="error"
                                        type="button"
                                        variant="outlined"
                                        onClick={onClearDb}>
                                        Reset / Clear Database
                                    </ClearDbButton>
                                </Grid>
                            }


                        </Grid>

                    </LicenseBox>

                )}
            </Container>
        </PrivateLayout>
    );
}

export {CustomerLicenseDetail};