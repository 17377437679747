import axios from '../_helpers/axiosInstance';
import { authHeader } from '../_helpers/authHeader';

export const userManagementService = {
    getUserList,
    getUserInfo,
    inviteUser,
    usernameTaken,
    inviteKeyValid,
    createUserFromInvite,
    saveUser,
    deleteUser,
    getInviteList,
    deleteInvite,
    getInviteDetails
};

async function getUserList(){
    var response = await axios.get('/usermanagement', {headers: authHeader()});
    return response.data.result;
}

async function getUserInfo(userId){
    var response = await axios.get(`/usermanagement/${userId}`, {headers: authHeader()});
    return response.data.result;
}

async function getInviteList(){
    var response = await axios.get('/usermanagement/invite', {headers: authHeader()});
    return response.data.result;
}

async function deleteInvite(inviteId){
    await axios.delete(`/usermanagement/invite/${inviteId}`, {headers: authHeader()});
}

async function inviteUser(emailAddress, securityGroup ){
    await axios.post('/usermanagement/invite', {emailAddress, securityGroup}, {headers: authHeader()});
}

async function usernameTaken(userId){
    var response = await axios.get(`/usermanagement/usernametaken/${userId}`);
    return response.data.result;
}

async function inviteKeyValid(inviteKey){
    var response = await axios.get(`/usermanagement/invitekeyvalid/${inviteKey}`);
    return response.data.result;
}

async function getInviteDetails(inviteKey){
    var response = await axios.get(`/usermanagement/invite/${inviteKey}`);
    return response.data.result;
}

async function createUserFromInvite(firstName, lastName, phoneNumber, password, inviteKey){
    await axios.post('/usermanagement/newuserfrominvite', {firstName, lastName, phoneNumber, password, inviteKey}, {headers: authHeader()});
}

async function saveUser(userId, securityGroup){
    await axios.put('/usermanagement', {userId, securityGroup}, {headers: authHeader()});
}

async function deleteUser(userId){
    await axios.delete(`/userprofile/${userId}`, {headers: authHeader()});
}