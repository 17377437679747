import {Button, CircularProgress, Modal, TextareaAutosize, TextField} from "@mui/material";
import { borderColor, Box, Container, styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FormBox from "../../../components/formBox";
import { PrivateLayout } from "../../../components/PrivateLayout";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch } from "react-redux";
import { releaseActions } from "../../../_actions";


const BoldLabel = styled("span")({
    fontWeight: "bold"
});

const SaveButton = styled(Button)({
    marginTop: 15,
    marginRight: 15
});

const CancelButton = styled(Button)({
    marginTop: 15,
    marginRight: 15
});
const DeleteButton = styled(Button)({
    marginTop: 15,
    marginRight: 15
});


const ResizingTextArea = styled(TextField)({
    resize: "both"
});

const validationSchema = Yup.object().shape({
    fileName: Yup.string()
      .required('File Name is required'),
    contents: Yup.string()
      .required('Contents is required')
  });


const ModalContent = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    padding: 50,
    transform: 'translate(-50%, -50%)',
    width: '85%',   // Set width to 80%
    height: '85%',  // Set height to 80%
    bgcolor: 'background.paper',
    backgroundColor: "#FFFFFF",
    margin: 40,  // Consider removing or adjusting margin
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    overflowY: "auto" // Add the overflow for scrolling if needed
});

function NewReleaseScreenChange({open, onClose, releaseId}){
    const dispatch = useDispatch();
    const saving = useSelector(state => state.releases.savingReleaseScreen);

    const { control, setValue, handleSubmit, register, reset, formState: { errors }} = useForm({
        resolver: yupResolver(validationSchema),
        
    });
  
    const onSubmit = (data) => {
        dispatch(releaseActions.createNewReleaseScreen(releaseId, data.fileName, data.contents ))
            .then(async () =>{
                await dispatch(releaseActions.loadReleaseDetails(releaseId));
                reset();
                onClose();
            });
        
    }

    const close = () => {
        reset();
        onClose();
    }
    return (
        <Modal open={open} onClose={close} >
        <ModalContent>
            <h2>New Screen Change</h2>
            <form onSubmit={handleSubmit(onSubmit)} style={{width: '100%'}}>
             {saving ? <CircularProgress/> :
             <Box
             sx={{
                 display: 'grid',
                 gridTemplateColumns: 'repeat(2, 1fr)',
                 gap: "15px",
                 gridTemplateRows: 'auto',
                 p:2
             }}
             >
                 <Box >
                    <Controller
                            name="fileName"
                            control={control}
                            inputRef={register()}
                            render={({ field }) => 
                                <TextField variant="outlined" fullWidth label="File Name*" margin="normal" {...field} error={errors.fileName ? true : false}
                                    helperText={errors.fileName?.message} inputProps={{maxLength: 100}}/>
                                
                            }
                    />
                 </Box>
                 <Box >
                   
                 </Box>
                 <Box sx={{gridColumnStart:1, gridColumnEnd:-1}} >
                    <Controller
                            name="contents"
                            control={control}
                            inputRef={register()}
                            render={({ field }) => 
                            <ResizingTextArea variant="outlined" fullWidth label="Contents*" multiline minRows={5} maxRows={20} margin="normal" {...field} error={errors.contents ? true : false}
                                helperText={errors.contents?.sqlScript} />
                                
                            }
                    />
                 </Box>
                 
                 
                 <Box sx={{gridColumnStart:1, gridColumnEnd:-1}}>
                    <SaveButton variant="contained" color='primary' type="submit" disabled={saving}>{saving && <CircularProgress/>} Create Screen Change</SaveButton>
                    <CancelButton variant="contained" color="secondary" type='button' disabled={saving} onClick={close}>Cancel</CancelButton>
                 </Box>
                 
                 
                 
                
             </Box>
             }
            </form>
        </ModalContent>
        </Modal>
    );
}

export {NewReleaseScreenChange};