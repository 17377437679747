import { Box, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Modal, Select, styled, TextField, Typography } from "@mui/material";
import React, {useEffect} from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {releaseService} from "../../../_services";
import {Controller} from "react-hook-form";


const ModalContent = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    padding:50,
    transform: 'translate(-50%, -50%)',
    width: "70%",
    bgcolor: 'background.paper',
    backgroundColor:"#FFFFFF",
    margin:40,
    boxShadow: 24,
    maxWidth:750,
    pt: 2,
    px: 4,
    pb: 3,
  });

function ExportReleasePackageModal ({releaseId, open, onClose, releaseVersion, releases}){
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [exporting, setExporting] = useState(false);
    const [exportConfirm, setExportConfirm] = useState("");
    const [fromReleaseId, setFromReleaseId] = useState();
    const [releaseRange, setReleaseRange] = useState([]);

    useEffect(() => {
        setFromReleaseId(releaseId)
    }, [releaseId]);

    useEffect(() => {
        if (releases && releaseId){
            setReleaseRange(releases.filter(r => r.id <= releaseId));
        }
    }, [releases, releaseId]);

    const close = () => {
        if (!exporting){
            onClose();
        }
    }

    const submit = async () => {
        try {
            setExporting(true);
            await releaseService.exportReleasePackage(releaseId, fromReleaseId, releaseVersion)
            setExporting(false);
        } catch(e) {
            setExporting(false);
        } finally {
            onClose();
        }
    }
    const handleFromReleaseChange = (e) => {
        setFromReleaseId(e.target.value);
    }
    return (
        <Modal open={open} onClose={close}>
        <ModalContent>
            <Typography variant='h5'>Export Release</Typography><br/>
            <Grid container >
                <Grid item sm={12} mb={2}>
                    <p>Export this release so that you can import it on another environment and promote the exact same code and scripts.
                    To continue generating the release package press the "Confirm Export" button.</p>

                </Grid>
            </Grid>
            <Grid container>
                <Grid item xl={12}>
                    If this package needs to include scripts and screen changes from a range of releases please select the release to
                    begin aggregating from.  Scripts / Screen Changes will retain the order across releases.
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <FormControl fullWidth style={{marginTop:16, width: '100%'}}  variant="outlined">
                        <InputLabel id='fromRelease'>Starting from:*</InputLabel>
                        <Select  labelId="fromRelease" style={{width:'100%'}} value={fromReleaseId}
                                 onChange={handleFromReleaseChange}
                                 label="Starting from:*">
                            {releaseRange?.map(r => <MenuItem key={r.id} value={r.id}>{r.version}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" style={{marginTop:20}}>
                <Grid item >
                    {exporting ?
                    <>
                        <CircularProgress/> Exporting release ... please wait.
                    </>
                    :
                    <>
                        <Button sx={{mr:2}} variant="contained" color="primary" onClick={submit} >Confirm Export</Button>
                        <Button variant="contained" color="error" onClick={onClose} >Cancel</Button>
                   </>
                    }
                   
                </Grid>
            </Grid>
        </ModalContent>
          
      </Modal>
    )
}

export {ExportReleasePackageModal};