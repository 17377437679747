import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { licenseTemplates } from './licenseTemplate.reducer';
import { activateTrial } from './activateTrial.reducer';
import { resetPassword } from './resetPassword.reducer';
import { client } from './client.reducer';
import { companyLicenses } from './companyLicense.reducer';
import { alert } from './alert.reducer';
import { userProfile } from './userProfile.reducer';
import { userManagement } from './userManagement.reducer';
import { releases } from './release.reducer';
import { seedManagement } from './seedManagement.reducer';
import { industryManagement } from './industryManagement.reducer';

const appReducer = combineReducers({
    authentication,
    licenseTemplates,
    activateTrial,
    resetPassword,
    client,
    companyLicenses, 
    alert, 
    userProfile, 
    userManagement, 
    releases,
    seedManagement,
    industryManagement
});

const rootReducer = (state, action) => {
    // when a logout action is dispatched it will reset redux state
    if (action.type === 'USERS_LOGOUT') {
      state = undefined;
    }
  
    return appReducer(state, action);
};

export default rootReducer;