import { seedManagementConstants } from '../_constants';

const initialState = { 
    seedList: null,
    loadingSeedList: false,
    error: null,
    standardDeployments: null,
    loadingStandardDeployments: false
};

export function seedManagement(state = initialState, action) {
    switch (action.type) {
        case seedManagementConstants.GET_SEED_LIST_REQUEST:
            return {
                ...state,
                loadingSeedList: true
            };
        case seedManagementConstants.GET_SEED_LIST_SUCCESS:
            return {
                ...state,
                loadingSeedList: false,
                seedList: action.seedList
            };
        case seedManagementConstants.GET_SEED_LIST_FAILURE:
            return {
                ...state,
                loadingSeedList: false,
            };
        case seedManagementConstants.STORE_DEFAULT_FIRST_SEEDS:
            return {
                ...state,
                defaultFirstSeeds: action.defaultFirstSeeds
            };
        case seedManagementConstants.SAVE_SEED_DETAILS_REQUEST:
            return {
                ...state,
                savingSeedDetails: true
            };
        case seedManagementConstants.SAVE_SEED_DETAILS_SUCCESS:
            return {
                ...state,
                savingSeedDetails: false
            };
        case seedManagementConstants.SAVE_SEED_DETAILS_FAILURE:
            return {
                ...state,
                savingSeedDetails: false,
                errorMessage: action.error
            };
        case seedManagementConstants.ADD_SEED_REQUEST:
            return {
                ...state,
                addingSeed: true
            };
        case seedManagementConstants.ADD_SEED_SUCCESS:
            return {
                ...state,
                //We should better update store state here too although the seed list page will be reloaded
                //In order to update store here, action should have the new seed
                addingSeed: false
            };
        case seedManagementConstants.ADD_SEED_FAILURE:
            return {
                ...state,
                addingSeed: false,
                errorMessage: action.error
            };
        case seedManagementConstants.DELETE_SEED_REQUEST:
            return {
                ...state,
                deletingSeed: true
            };
        case seedManagementConstants.DELETE_SEED_SUCCESS:
            return {
                ...state,
                seedList: removeSeed(state, action.seedId),
                defaultFirstSeeds: rmSeedInDefaultFirst(state, action.seedId),
                deletingSeed: false
            };
        case seedManagementConstants.DELETE_SEED_FAILURE:
            return {
                ...state,
                deletingSeed: false,
                errorMessage: action.error
            };
        case seedManagementConstants.GET_STANDARD_DEPLOYMENTS_REQUEST:
            return {
                ...state,
                loadingStandardDeployments: true
            };
        case seedManagementConstants.GET_STANDARD_DEPLOYMENTS_SUCCESS:
            return {
                ...state,
                loadingStandardDeployments: false,
                standardDeployments: action.standardDeployments
            };
        case seedManagementConstants.GET_STANDARD_DEPLOYMENTS_FAILURE:
            return {
                ...state,
                loadingStandardDeployments: false,
            };
        default:
            return state
    }
}

function removeSeed(state, seedId){
    let newSeedList = [...state.seedList]
    return newSeedList.filter(seed=>seed.id != seedId)
}

function rmSeedInDefaultFirst(state, seedId){
    let newSeedList = [...state.defaultFirstSeeds]
    return newSeedList.filter(seed=>seed.id != seedId)
}