import { clientConstants } from '../_constants';

const initialState = { 
    clientList: null,
    loadingClientList: false,
    selectedClientDetails: null,
    loadingClientDetails: false,
    savingClientDetails: false,
    error: null
};

export function client(state = initialState, action) {
    switch (action.type) {
        case clientConstants.GET_CLIENT_LIST_REQUEST:
            return {
                ...state,
                loadingClientList: true
            };
        case clientConstants.GET_CLIENT_LIST_SUCCESS:
            return {
                ...state,
                loadingClientList: false,
                clientList: action.clientList
            };
        case clientConstants.GET_CLIENT_LIST_FAILURE:
            return {
                ...state,
                loadingClientList: false,
            };
        case clientConstants.GET_CLIENT_DETAILS_REQUEST: 
            return {
                ...state,
                loadingClientDetails: true
            }
        case clientConstants.GET_CLIENT_DETAILS_SUCCESS:
            return {
                ...state,
                loadingClientDetails: false,
                selectedClientDetails: action.clientDetails
            }
        case clientConstants.GET_CLIENT_DETAILS_FAILURE:
            return {
                ...state,
                loadingClientDetails: false,
                selectedClientDetails: null,
                error: action.error
            }
        default:
            return state
    }
}