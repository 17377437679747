import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PrivateLayout } from "../../../components/PrivateLayout";
import { chargebeeService } from "../../../_services/chargebee.service";

function SubscriptionPage() {
    const navigate = useNavigate();
    const [chargebeeSession, setChargebeeSession] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true);
        chargebeeService.getSession()
            .then(session => {
                setChargebeeSession(session);
                setLoading(false);
            })  
    },[])

    return (
      <PrivateLayout>
        {loading ? <CircularProgress/> : 
        <iframe width="100%" height="100%" style={{border:0, width:"100%", height:"100%", overflow:"hidden"}} scr src={chargebeeSession.accessUrl}></iframe>
        }
        
      </PrivateLayout>
    );
  }
  
export { SubscriptionPage };