import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function ConfirmDialog({open, title, description, confirmButtonText = "OK", cancelButtonText = "Cancel", confirmCallback, cancelCallback}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
    return (

        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={cancelCallback}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={cancelCallback}>
              {cancelButtonText}
            </Button>
            <Button onClick={confirmCallback} autoFocus>
              {confirmButtonText}
            </Button>
          </DialogActions>
        </Dialog>
    );
  }