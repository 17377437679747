import axios from '../_helpers/axiosInstance';
import { authHeader } from '../_helpers/authHeader';

export const companyLicenseService = {
    getCompanyLicenses,
    getCompanyLicenseDetails,
    saveCompanyLicense,
    deactivateCompanyLicense,
    activateCompanyLicense,
    getRoPassword,
    getRwPassword
};



async function getCompanyLicenses(companyId) {
    console.log('About to get company licenses for company with Id: ' + companyId);

    var response = await axios.get(`/CompanyLicense/LicenseList/${companyId}`, { headers: authHeader() });

    return response.data.result;
}

async function getCompanyLicenseDetails(companyLicenseId) {
    console.log('About to get company license detailes for id ' + companyLicenseId);
    var response = await axios.get(`/companylicense/${companyLicenseId}`, { headers: authHeader() });

    return response.data.result;
}

async function saveCompanyLicense(companyLicense) {
    console.log('About to save company license');
    await axios.put(`/companyLicense/${companyLicense.id}`,companyLicense, {headers: authHeader()});
}

async function deactivateCompanyLicense(companyLicenseId) {
    console.log('About to deactivate company license');
    await axios.put(`/companyLicense/${companyLicenseId}/deactivate`,null, {headers: authHeader()});
}

async function activateCompanyLicense(companyLicenseId) {
    console.log('About to deactivate company license');
    await axios.put(`/companyLicense/${companyLicenseId}/activate`,null, {headers: authHeader()});
}

async function getRoPassword(deploymentId) {
    console.log('About to get azzier ro pw');
    var response = await axios.get(`/companyLicense/deployment/${deploymentId}/ropw`, {headers: authHeader()});
    return response.data.result;
}

async function getRwPassword(deploymentId) {
    console.log('About to get azzier rw pw');
    var response = await axios.get(`/companyLicense/deployment/${deploymentId}/rwpw`, {headers: authHeader()});
    return response.data.result;
}
