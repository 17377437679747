import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { activateTrialActions } from '../../../_actions';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';
import { PublicLayout } from '../../../components/PublicLayout';

function TrialActivationPage() {
    // SET UP ALERT
    // const alert = useSelector(state => state.alert);
    const errorCode = useSelector(state => state.activateTrial.errorCode);
    let { key } = useParams();
    console.log('Activation key: ' + key);
    const dispatch = useDispatch();
    
    useEffect(() => { 
        dispatch(activateTrialActions.activateTrial(key));
    }, []);


    return (
    <PublicLayout>
        <Container maxWidth='md'>
            <CssBaseline />
            <Grid 
                container
                spacing={2}
                alignItems="center"
                justifyContent="center"
                style={{ marginTop: 30 }}
            >
                
                <Grid container>
                    <Typography variant="h3" gutterBottom>
                            Azzier Trial - Activation
                    </Typography>
                </Grid>
                {!errorCode ? 
                    <Grid container>
                        <Typography mt={5}>
                                Thank you for verifying your email address!
                        </Typography>
                        <Typography mt={3}>
                                Our system has started to prepare your Azzier trial. Once your Azzier trial is ready an email will be sent to you and you can log in with the username and password you provided.
                        </Typography>
                        <Typography mt={3}>
                                If you have any questions please feel free to contact on our contact page (<a href="https://azzier.com/contact/">https://azzier.com/contact/</a>) and one of our sales specialists will be happy to set up a time to walk through the product with you.
                        </Typography>
                    </Grid>
                :
                    <Grid container>
                        <Typography mt={5}>
                            {errorCode}
                        </Typography>
                    </Grid>
                }
            </Grid>
        </Container>
    
    </PublicLayout>
    );
  }
  
export { TrialActivationPage };