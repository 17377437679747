import { Box, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Modal, Select, styled, TextField, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { releaseActions } from "../../../_actions";
import {releaseService} from "../../../_services";

const ModalContent = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    padding:50,
    transform: 'translate(-50%, -50%)',
    width: "70%",
    bgcolor: 'background.paper',
    backgroundColor:"#FFFFFF",
    margin:40,
    boxShadow: 24,
    maxWidth:750,
    pt: 2,
    px: 4,
    pb: 3,
  });

function ImportReleaseModal ({open, onClose, nonStandardDeploymentId}){

    const dispatch = useDispatch();
    const [file, setFile] = useState(null);
    const [importing, setImporting] = useState(false);
    const [errors, setErrors] = useState({});

    const close = () => {
        if (!importing){
            onClose();
        }
    }

    const fileSelected = (event) => {
        setFile(event.target.files[0]);
    }

    const isFormValid = () => {
        let newErrors = {};
        if (file === null) newErrors.file = "Please select a release package to import";
        console.log(newErrors);
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }
    const submit = async () => {
        if (isFormValid()){
            try {
                setImporting(true);
                if (nonStandardDeploymentId){
                    await releaseService.importNonStandardReleasePackage(file, nonStandardDeploymentId);
                } else {
                    await releaseService.importReleasePackage(file);
                }

                await dispatch(releaseActions.getReleases());
                setImporting(false);
            } catch(e) {
                console.error(e);
                setImporting(false);
            } finally {
                onClose();
            }
        }
    }
    return (
        <Modal open={open} onClose={close}>
        <ModalContent>
            <Typography variant='h5'>Import Release Package</Typography><br/>
            <Grid container >
                <Grid item sm={12} mb={2}>
                    <p>Select the ".azzierpkg" release export that you wish to import then press the import button.</p>
                    <FormControl fullWidth error={errors.file ? true : undefined}>
                        <TextField error={errors.file ? true : undefined} type={"file"} onChange={fileSelected}/>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" style={{marginTop:20}}>
                <Grid item >
                    {importing ?
                    <>
                        <CircularProgress/> Importing release ... please wait.
                    </>
                    :
                    <>
                        <Button sx={{mr:2}} variant="contained" color="primary" onClick={submit} >Import</Button>
                        <Button variant="contained" color="error" onClick={onClose} >Cancel</Button>
                   </>
                    }
                   
                </Grid>
            </Grid>
        </ModalContent>
          
      </Modal>
    )
}

export {ImportReleaseModal};