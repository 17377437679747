import jwt_decode from "jwt-decode";
import axios from '../_helpers/axiosInstance';

import { authHeader } from '../_helpers/authHeader';

export const licenseTemplateService = {
    getLicenseTemplates,
    getLicenseTemplate,
    saveLicenseTemplate,
    deleteLicenseTemplate, 
    addLicenseTemplate,
    getProducts
};



const API_URL = process.env.REACT_APP_API_ENDPOINT;

async function getLicenseTemplates() {
  console.log('About to get license templates');

  var response = await axios.get('/license', {headers: authHeader()});
  return response.data.result;
    
}

async function getLicenseTemplate(licenseTemplateId) {
  console.log('About to get license template with id ' + licenseTemplateId);

  var response = await axios.get(`/license/${licenseTemplateId}`, {headers: authHeader()});
  return response.data.result;
 
}

async function saveLicenseTemplate(licenseTemplate) {
  await axios.put(`/license/${licenseTemplate.id}`,licenseTemplate, {headers: authHeader()});
}

async function addLicenseTemplate(licenseTemplate) {
  await axios.post(`/license`,licenseTemplate, {headers: authHeader()});
}

async function deleteLicenseTemplate(id) {
  await axios.delete(`/license/${id}`, {headers: authHeader()});
}

async function getProducts() {
  var response = await axios.get('/product', {headers: authHeader()});
  return response.data.result;
}