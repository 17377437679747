import { CircularProgress, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import DraggableList from "../../../components/DraggableList";
import EditIcon from '@mui/icons-material/Edit';
import Moment from 'react-moment';
import { Link } from "react-router-dom";





export default function ReleaseList({releases}) {

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Version</TableCell>
                    <TableCell>Revision</TableCell>
                    <TableCell>Branch</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Release Date</TableCell>
                    <TableCell></TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {releases && releases.map((release) => (
                    <TableRow
                        selected={release.currentReleasedVersion}
                        key={release.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                        >
                        <TableCell>
                            {release.releaseName}
                        </TableCell>
                        <TableCell >{release.version}</TableCell>
                        <TableCell >{release.revision}</TableCell>
                        <TableCell >{release.branch}</TableCell>
                        <TableCell >{release.releaseStatus}{release.releaseInProgress?<CircularProgress/>:null}</TableCell>
                        <TableCell >{release.dateReleased !== null?<Moment>{release.dateReleased}</Moment>:null}</TableCell>
                        <TableCell >
                            <Link to={`/releases/${release.id}`}><EditIcon color='action'/></Link>
                        </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
