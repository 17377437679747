import {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PrivateLayout } from "../../../components/PrivateLayout";
import { Box, Container, Stack } from "@mui/system";
import ReleaseList from "./ReleaseList";
import { releaseActions } from "../../../_actions";
import {ImportReleaseModal} from "./ImportReleaseModal";
import {deploymentService} from "../../../_services/deployment.service";

function ReleasesPage() {
  let dispatch = useDispatch();
  var releases = useSelector((state) => state.releases.releaseList);

  var loading = useSelector((state) => state.releases.gettingReleases);
  const [importModalOpen, setImportModalOpen] = useState(false);
  var nonStandardDeployment = useSelector( state => state.releases.nonStandardDeployment);
  const [selectedDeploymentId, setSelectedDeploymentId] = useState('default');
  let navigate = useNavigate();
  const [nonStandardDeployments, setNonStandardDeployments] = useState([]);
  useEffect(() =>{
    const loadNonStandardDeployments = async () => {
      var deployments = await deploymentService.getNonStandardDeployments();
      setNonStandardDeployments(deployments);
    }
    loadNonStandardDeployments();
    dispatch(releaseActions.getReleases());

  },[])

  useEffect(() => {
    if (nonStandardDeployment !== null)
      setSelectedDeploymentId(nonStandardDeployment.deploymentId.toString());
    else
      setSelectedDeploymentId("default");
    dispatch(releaseActions.getReleases());
  }, [nonStandardDeployment]);

  const onReleaseTargetChange = (e) => {
    if (!e.target.value || e.target.value === 'default')
      dispatch(releaseActions.setNonStandardDeployment(null));
    var deployment = nonStandardDeployments.find(d => d.deploymentId.toString() === e.target.value)
    if (!deployment) dispatch(releaseActions.setNonStandardDeployment(null));
    else dispatch(releaseActions.setNonStandardDeployment(deployment));
  }
  const openImportRelease = () => {
    setImportModalOpen(true);
  }
  const closeImportRelease = () => {
    setImportModalOpen(false);
  }
  return (
      <PrivateLayout>
        <ImportReleaseModal open={importModalOpen} onClose={closeImportRelease} nonStandardDeploymentId={nonStandardDeployment?.deploymentId} />
        <Container maxWidth="xl">
          <Stack spacing={2}>
            <Typography variant='h3'>Releases</Typography>
              <Box>
                <FormControl fullWidth>
                  <InputLabel id="releaseTargetLabel">Release Target</InputLabel>
                  <Select
                      labelId="releaseTargetLabel"
                      id="releaseTarget"
                      value={selectedDeploymentId}
                      label="Release Target"
                      onChange={onReleaseTargetChange}
                  >
                    <MenuItem key="default" value="default">Azzier Standard</MenuItem>
                    { nonStandardDeployments?.map(d => <MenuItem key={d.deploymentId.toString()} value={d.deploymentId.toString()}>{d.customerName} - {d.domain}</MenuItem>)}

                  </Select>
                </FormControl>
              </Box>
              {
                loading ? <CircularProgress/>:
                <>

                  <Box>
                    <Button variant={"outlined"} onClick={() => navigate('/releases/new')}>New Release</Button>
                    <Button variant={"outlined"} onClick={openImportRelease} style={{marginLeft: 10}}>Import Release</Button>
                  </Box>
                  <ReleaseList releases={releases??[]} />
                </>
              }
          </Stack>

        </Container>
      </PrivateLayout>
    );
  }
  
export { ReleasesPage };