import { releaseConstants } from '../_constants';


const initialState = { 
    gettingReleases: false,
    gettingReleaseDetails: false,
    gettingReleaseMigrationDetails: false,
    gettingReleaseScreenDetails: false,
    savingRelease: false,
    savingReleaseMigration: false,
    savingReleaseScreen: false,
    releaseList: [],
    currentReleaseMigration: null,
    currentReleaseScreenChange: null,
    currentRelease: null, 
    errorMsg: null,
    nonStandardDeployment: null
};

export function releases(state = initialState, action) {
    switch (action.type) {
        case releaseConstants.GET_RELEASE_LIST_REQUEST:
            return {
                ...state,
                gettingReleases: true
            };
        case releaseConstants.GET_RELEASE_LIST_SUCCESS:
            return {
                ...state,
                gettingReleases: false,
                releaseList: action.releaseList
            };
        case releaseConstants.GET_RELEASE_LIST_FAILURE:
            return {
                ...state,
                gettingReleases: false, 
                errorMsg: action.error
            };
        case releaseConstants.GET_RELEASE_DETAILS_REQUEST:
            return {
                ...state,
                gettingReleaseDetails: false,
                errorMsg: null                
            };

        case releaseConstants.GET_RELEASE_DETAILS_SUCCESS:
            return {
                ...state,
                gettingReleaseDetails: false,
                currentRelease: action.releaseDetails
            };
        case releaseConstants.GET_RELEASE_DETAILS_FAILURE:
            return {
                ...state,
                gettingReleaseDetails: false,
                currentRelease: null,
                errorMsg: action.error
            };
        case releaseConstants.UPDATE_RELEASE_DETAILS_REQUEST:
            return {
                ...state,
                savingRelease:true
            };
        case releaseConstants.UPDATE_RELEASE_DETAILS_SUCCESS:
            return {
                ...state,
                savingRelease:false
            };
        case releaseConstants.UPDATE_RELEASE_DETAILS_FAILURE:
            return {
                ...state,
                savingRelease:false,
                errorMsg: action.error
            };
        case releaseConstants.NEW_RELEASE_REQUEST:
            return {
                ...state,
                savingRelease:true
            };
        case releaseConstants.NEW_RELEASE_SUCCESS:
            return {
                ...state,
                savingRelease:false
            };
        case releaseConstants.NEW_RELEASE_FAILURE:
            return {
                ...state,
                savingRelease:false,
                errorMsg: action.error
            };
        case releaseConstants.DELETE_RELEASE_REQUEST:
            return {
                ...state
            };
        case releaseConstants.DELETE_RELEASE_SUCCESS:
            return {
                ...state
            };
        case releaseConstants.DELETE_RELEASE_FAILURE:
            return {
                ...state,
                errorMsg: action.error
            };
        case releaseConstants.GET_RELEASE_MIGRATION_DETAILS_REQUEST:
            return {
                ...state,
                gettingReleaseMigrationDetails: true
            };
        case releaseConstants.GET_RELEASE_MIGRATION_DETAILS_SUCCESS:
            return {
                ...state, 
                gettingReleaseMigrationDetails: false,
                currentReleaseMigration: action.migrationDetails

            };
        case releaseConstants.GET_RELEASE_MIGRATION_DETAILS_FAILURE:
            return {
                ...state, 
                gettingReleaseMigrationDetails: false,
                errorMsg: action.error
            };
        case releaseConstants.GET_RELEASE_SCREEN_DETAILS_REQUEST:
            return {
                ...state,
                gettingReleaseScreenDetails: true
            };
        case releaseConstants.GET_RELEASE_SCREEN_DETAILS_SUCCESS:
            return {
                ...state, 
                gettingReleaseScreenDetails: false,
                currentReleaseScreenChange: action.screenChangeDetails

            };
        case releaseConstants.GET_RELEASE_SCREEN_DETAILS_FAILURE:
            return {
                ...state, 
                gettingReleaseScreenDetails: false,
                errorMsg: action.error
            };
        case releaseConstants.UPDATE_RELEASE_MIGRATION_DETAILS_REQUEST:
            return {
                ...state,
                savingReleaseMigration: true
            };
        case releaseConstants.UPDATE_RELEASE_MIGRATION_DETAILS_SUCCESS:
            return {
                ...state, 
                savingReleaseMigration: false
            };
        case releaseConstants.UPDATE_RELEASE_MIGRATION_DETAILS_FAILURE:
            return {
                ...state, 
                savingReleaseMigration: false,
                errorMsg: action.error
            };
        case releaseConstants.UPDATE_RELEASE_SCREEN_DETAILS_REQUEST:
            return {
                ...state,
                savingReleaseScreen: true
            };
        case releaseConstants.UPDATE_RELEASE_SCREEN_DETAILS_SUCCESS:
            return {
                ...state, 
                savingReleaseScreen: false
            };
        case releaseConstants.UPDATE_RELEASE_SCREEN_DETAILS_FAILURE:
            return {
                ...state, 
                savingReleaseScreen: false,
                errorMsg: action.error
            };
        case releaseConstants.DELETE_RELEASE_MIGRATION_REQUEST:
            return {
                ...state
            };
        case releaseConstants.DELETE_RELEASE_MIGRATION_SUCCESS:
            return {
                ...state
            };
        case releaseConstants.DELETE_RELEASE_MIGRATION_FAILURE:
            return {
                ...state, 
                errorMsg: action.error
            };
        case releaseConstants.DELETE_RELEASE_SCREEN_REQUEST:
            return {
                ...state
            };
        case releaseConstants.DELETE_RELEASE_SCREEN_SUCCESS:
            return {
                ...state
            };
        case releaseConstants.DELETE_RELEASE_SCREEN_FAILURE:
            return {
                ...state, 
                errorMsg: action.error
            };
        case releaseConstants.REORDER_RELEASE_MIGRATIONS_REQUEST:
            return {
                ...state
            };
        case releaseConstants.REORDER_RELEASE_MIGRATIONS_SUCCESS:
            return {
                ...state
            };
        case releaseConstants.REORDER_RELEASE_MIGRATIONS_FAILURE:
            return {
                ...state, 
                errorMsg: action.error
            };
        case releaseConstants.REORDER_RELEASE_SCREENS_REQUEST:
            return {
                ...state
            };
        case releaseConstants.REORDER_RELEASE_SCREENS_SUCCESS:
            return {
                ...state
            };
        case releaseConstants.REORDER_RELEASE_SCREENS_FAILURE:
            return {
                ...state, 
                errorMsg: action.error
            };
        case releaseConstants.NEW_RELEASE_MIGRATION_REQUEST:
            return {
                ...state, 
                savingReleaseMigration: true
            };
        case releaseConstants.NEW_RELEASE_MIGRATION_SUCCESS:
            return {
                ...state, 
                savingReleaseMigration: false
            };
        case releaseConstants.NEW_RELEASE_MIGRATION_FAILURE:
            return {
                ...state, 
                savingReleaseMigration:false,
                errorMsg: action.error
            };
        case releaseConstants.NEW_RELEASE_SCREEN_REQUEST:
            return {
                ...state, 
                savingReleaseScreen: true
            };
        case releaseConstants.NEW_RELEASE_SCREEN_SUCCESS:
            return {
                ...state, 
                savingReleaseScreen: false
            };
        case releaseConstants.NEW_RELEASE_SCREEN_FAILURE:
            return {
                ...state, 
                savingReleaseScreen:false,
                errorMsg: action.error
            };
        case releaseConstants.SET_NON_STANDARD_DEPLOYMENT_REQUEST:
            return {
                ...state,
                nonStandardDeployment: action.nonStandardDeployment
            }
        default:
            return state
    }
}