import axios from '../_helpers/axiosInstance';
import { authHeader } from '../_helpers/authHeader';

export const clientService = {
    getClientList,
    getDetails,
    saveDetails,
    deleteClient,
    onboardNewClient,
    onboardNonStandardClient
};

async function getClientList() {
    console.log('About to get list of clients');
  
    var response = await axios.get('/Client/ClientList', { headers: authHeader() });

    return response.data.result;
}


async function getDetails(companyId) {
    console.log("about to get company details");
    var response = await axios.get(`/Client/${companyId}`, {headers: authHeader()});
    return response.data.result;
}

async function saveDetails(companyDetails){
    companyDetails.industry = '';
    await axios.put(`/Client`,companyDetails, {headers: authHeader()});
}

async function deleteClient(companyId){
    var response = await axios.delete(`/client/${companyId}`, {headers: authHeader()});
    return response.data.result;
}

async function onboardNewClient(companyName, companyEmailContact, companyPhoneContact, companyAddress, companyCountry,
                          companyStateProv, companyCity, companyPostalCode, firstName, lastName, domain, username,
                          password, licenseId, trial, trialEndDate, numberOfUsers, industryId){
    var data = {companyName, companyEmailContact, companyPhoneContact, companyAddress, companyCountry,
        companyStateProv, companyCity, companyPostalCode, firstName, lastName, domain, username,
        password, licenseId, trial, trialEndDate, numberOfUsers, industryId};
    var response = await axios.post(`/client`,data, {headers: authHeader()});
    return response.data.result;
}
async function onboardNonStandardClient(companyName, companyEmailContact, companyPhoneContact, companyAddress, companyCountry,
                        companyStateProv, companyCity, companyPostalCode, firstName, lastName, domain, licenseId, 
                        numberOfUsers, provisionHostId, rwDbUsername, rwDbPassword, roDbUsername, roDbPassword, dbServer,
                        dbName, svnRoot, sitePath, iisSiteName, dbTimezone){
    var data = {companyName, companyEmailContact, companyPhoneContact, companyAddress, companyCountry,
    companyStateProv, companyCity, companyPostalCode, firstName, lastName, domain,  licenseId, sitePath,
    numberOfUsers, provisionHostId, rwDbUsername, rwDbPassword, roDbUsername, roDbPassword, dbServer, dbName, svnRoot, iisSiteName, dbTimezone };
    var response = await axios.post(`/client/nonstandard`,data, {headers: authHeader()});
    return response.data.result;
}
