import { Button, CircularProgress, TextareaAutosize, TextField, Grid } from "@mui/material";
import { borderColor, Box, Container, styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {Link, useNavigate, useParams} from "react-router-dom";
import FormBox from "../../../components/formBox";
import { PrivateLayout } from "../../../components/PrivateLayout";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch } from "react-redux";
import { releaseActions } from "../../../_actions";
import DraggableList from "../../../components/DraggableList";
import Moment from 'react-moment';
import moment from "moment";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { reorder } from "../../../_helpers/dragHelpers";
import { releaseService } from "../../../_services";
import { useRef } from "react";
import * as semver from 'semver';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { RollbackReleaseModal } from "./RollbackReleaseModal";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import EditIcon from "@mui/icons-material/Edit";
import CopyIcon from "@mui/icons-material/ContentCopy";
import TableContainer from "@mui/material/TableContainer";
import {ExportReleasePackageModal} from "./ExportReleasePackageModal";
import {NewReleaseMigration} from "./NewReleaseMigration";
import {ReleaseMigrationDetails} from "./ReleaseMigrationDetails";
import {NewReleaseScreenChange} from "./NewReleaseScreenChange";
import {ReleaseScreenChangeDetails} from "./ReleaseScreenChangeDetails";


const BoldLabel = styled("span")({
    fontWeight: "bold"
});

const SaveButton = styled(Button)({
    marginTop: 15,
    marginRight: 15
});

const CancelButton = styled(Button)({
    marginTop: 15,
    marginRight: 15
});
const DeleteButton = styled(Button)({
    marginTop: 15,
    marginRight: 15
});


const BetterTextArea = styled(TextField)({
    resize: "both"
});



const outputLogs = (logs) => {
    var logText = "";
    if (logs != null && logs.length > 0){
        logs.forEach(l => {
            logText += `${moment(l.eventTime).format()} - ${l.eventType} - ${l.message}\n`;
        });
    }
    return logText;

}

function HostReleaseList({hostReleases}) {

    return (
        <TableContainer component={Paper}>
            <Table  aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Release Host</TableCell>
                        <TableCell>Releasing ?</TableCell>
                        <TableCell>Progress</TableCell>
                        <TableCell>Start Time</TableCell>
                        <TableCell>Completed Time</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {hostReleases && hostReleases.map((hostRelease) => (
                        <TableRow
                            key={hostRelease.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell>{hostRelease.provisionHostName}</TableCell>
                            <TableCell>{hostRelease.releasing ? "Yes" : "No"}</TableCell>
                            <TableCell>{hostRelease.progressStatus}</TableCell>
                            <TableCell>{hostRelease.dateReleaseStarted !== null ? moment(hostRelease.dateReleaseStarted).format('LLL') : ''}</TableCell>
                            <TableCell>{hostRelease.dateReleased !== null ? moment(hostRelease.dateReleased).format('LLL') : ''}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

let logCheckIntervalId = null;

function ReleaseDetails(){
    const {releaseId} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const releaseDetails = useSelector(state => state.releases.currentRelease);
    const releases = useSelector(state => state.releases.releaseList);
    const loading = useSelector(state => state.releases.gettingReleaseDetails);
    const saving = useSelector(state => state.releases.savingRelease)
    const [migrationItems, setMigrationItems] = useState([]);
    const [screenItems, setScreenItems] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [releaseInProgress, setReleaseInProgress] = useState(false);
    const [showLogs, setShowLogs] = useState(false)
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [startReleaseConfirmOpen, setStartReleaseConfirmOpen] = useState(false);
    const [rollbackModalOpen, setRollbackModalOpen] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [logs, setLogs] = useState([]);
    const [hostReleases, setHostReleases] = useState([]);
    const [migrationLogs, setMigrationLogs] = useState([]);
    const [exportModalOpen, setExportModalOpen] = useState(false);
    const [newMigrationModalOpen, setNewMigrationModalOpen] = useState(false);
    const [migrationEditModalOpen, setMigrationEditModalOpen] = useState(false);
    const [newScreenModalOpen, setNewScreenModalOpen] = useState(false);
    const [screenEditModalOpen, setScreenEditModalOpen] = useState(false);
    const [editDbMigrationId, setEditDbMigrationId] = useState(null);
    const [editScreenChangeId, setEditScreenChangeId] = useState(null);
	const fileSelected = (event) => {
		setSelectedFile(event.target.files[0]);
	};

    const logTextArea = useRef(null);
    const migrationLogTextArea = useRef(null);
    const validationSchema = Yup.object().shape({
        releaseName: Yup.string()
          .required('Release Name is required'),
        releaseNotes: Yup.string()
          .required('Release notes required'),
        version: Yup.string()
            .required('Version is required')
            .test('test-semantic-validation', 'That is not a valid sematic version (e.g. x.y.z)',
                (value) => { return semver.valid(value) !== null })
            .test('test-release-num-gt-last', "The semantic release version must be greater than the last release version. ",
                (value) => {
                    if (releases && releases.length > 1 && semver.valid(value) !== null && semver.gt(value, releases[1].version ) ) return true;
                    if (!releases || releases.length == 1) return true;
                    return false;
                })
      });
    const { control, setValue, handleSubmit, register, reset, formState: { errors }} = useForm({
        resolver: yupResolver(validationSchema),

    });
    useEffect(()=> {
        dispatch(releaseActions.loadReleaseDetails(releaseId));
        dispatch(releaseActions.getReleases());
    },[])


    const renderMigration = (item, disabled = false) => {
        return (
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: !disabled? '50px repeat(3, 1fr)': 'repeat(3, 1fr)',
                gap: "5px",
                gridTemplateRows: 'auto',
                padding:1,
                border:1,
                width:"100%"
            }}>
                {!disabled && <Box>
                    <DragIndicatorIcon/>
                </Box>}
                <Box >
                    {item.value.scriptName}
                </Box>
                <Box>
                    {item.value.dateApplied == null ? "Not applied" : <Moment>{item.value.dateApplied}</Moment>}
                </Box>
                <Box>
                    <Button onClick={() => openMigrationEdit(item.value.id)} variant="contained">Details</Button>
                </Box>
            </Box>
        );
    }

    const renderScreenChange = (item, disabled = false) => {
        return (
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: !disabled? '50px repeat(3, 1fr)': 'repeat(3, 1fr)',
                gap: "5px",
                gridTemplateRows: 'auto',
                padding:1,
                border:1,
                width:"100%"
            }}>
                {!disabled && <Box>
                    <DragIndicatorIcon/>
                </Box>}
                <Box >
                    {item.value.fileName}
                </Box>
                <Box>
                    {item.value.dateApplied == null ? "Not applied" : <Moment>{item.value.dateApplied}</Moment>}
                </Box>
                <Box>
                    <Button onClick={() => openEditScreenChange(item.value.id)} variant="contained">Details</Button>
                </Box>
            </Box>
        );
    }

    const deleteRelase = () => {
        setDeleteConfirmOpen(true);
    }
    const cancelDelete = () => {
        setDeleteConfirmOpen(false);
    }
    const deleteConfirmed = async () => {
        setDeleteConfirmOpen(false);
        await dispatch(releaseActions.deleteRelease(releaseDetails.id));
        navigate('/releases');
    }

    const startRelease = () => {
        setStartReleaseConfirmOpen(true);
    }

    const cancelStartRelease = () => {
        setStartReleaseConfirmOpen(false);
    }

    const releaseStartConfirmed = () => {
        releaseService.startRelease(releaseId)
            .then(() => {
                setTimeout(() => {dispatch(releaseActions.loadReleaseDetails(releaseId))}, 5000);

            });
        setStartReleaseConfirmOpen(false);
    }

    const onMigrationDragEnd = ({ destination, source }) => {
        // dropped outside the list
        if (!destination) return;

        const newItems = reorder(migrationItems, source.index, destination.index);
        const newIdOrder = newItems.map(i => i.value.id);
        let oldMigrationOrder = [...migrationItems];
        dispatch(releaseActions.reorderReleaseMigrations(newIdOrder))
        .catch(e => setMigrationItems(oldMigrationOrder));

        setMigrationItems(newItems);
      };

    const onScreenDragEnd = ({ destination, source }) => {
        // dropped outside the list
        if (!destination) return;

        const newItems = reorder(screenItems, source.index, destination.index);
        const newIdOrder = newItems.map(i => i.value.id);
        let oldScreenOrder = [...screenItems];
        dispatch(releaseActions.reorderReleaseScreens(newIdOrder))
        .catch(e => setScreenItems(oldScreenOrder));

        setScreenItems(newItems);
    };

    const getReleaseLogs = () => {
        releaseService.getReleaseLogs(releaseId)
                    .then(logs => {
                        setLogs(logs);
                    });
    }

    const getHostReleases = () => {
        releaseService.getHostReleases(releaseId)
            .then(hostReleases => {
                setHostReleases(hostReleases);
            });
    }

    const getReleaseMigrationLogs = () => {
        releaseService.getReleaseMigrationLogs(releaseId)
                    .then(logs => {
                        setMigrationLogs(logs);
                    });
    }

    const getAllLogs = () => {
        getReleaseLogs();
        getReleaseMigrationLogs();
        getHostReleases();
    }

    const reloadReleaseDetails = () => {
        dispatch(releaseActions.loadReleaseDetails(releaseId))
    }

    useEffect(() => {
        if (releaseInProgress && logCheckIntervalId == null){
            //getAllLogs();
            logCheckIntervalId = setInterval(reloadReleaseDetails,3000);
        } else {
            clearInterval(logCheckIntervalId);
            logCheckIntervalId = null;
        }
    }, [releaseInProgress]);

    useEffect(() => {
        if (releaseDetails !== null){
            reset({...releaseDetails});
            setReadOnly(releaseDetails.dateReleased !== null || releaseDetails.releaseInProgress);
            setReleaseInProgress(releaseDetails.releaseInProgress);
            setShowLogs(releaseDetails.dateReleased !== null || releaseDetails.releaseInProgress || releaseDetails.releaseError );
            getReleaseLogs();
            getReleaseMigrationLogs();
            getHostReleases();
            if (releaseDetails.dbMigrations && releaseDetails.dbMigrations.length > 0){
                setMigrationItems(releaseDetails.dbMigrations.map(m =>{
                    return {
                        id: "" + m.id,
                        value: m
                    }
                }));
            } else {
                setMigrationItems([]);
            }

            if (releaseDetails.screenChanges && releaseDetails.screenChanges.length > 0){
                setScreenItems(releaseDetails.screenChanges.map(s =>{
                    return {
                        id: "" + s.id,
                        value: s
                    }
                }));
            } else {
                setScreenItems([]);
            }
        }
    },[releaseDetails]);

    useEffect(() =>{
        const ta = logTextArea.current;
        if (ta)
            ta.scrollTop = ta.scrollHeight;
    }, [logs]);

    useEffect(() =>{
        const ta = migrationLogTextArea.current;
        if (ta)
            ta.scrollTop = ta.scrollHeight;
    }, [migrationLogs]);

    const onSubmit = async (data) => {
        await dispatch(releaseActions.updateRelease(releaseDetails.id, data.releaseName, data.releaseNotes, data.version, selectedFile ));
        navigate(`/releases`);
    }

    const onCloseRollbackModal = () =>
    {
        setRollbackModalOpen(false);
    }
    const openRollbackModal = () => {
        setRollbackModalOpen(true);
    }

    const onExportRelease = () => {
        setExportModalOpen(true);
    }
    const onCloseExportModal = () => {
        setExportModalOpen(false);
    }
    const openNewMigration = () => {
        setNewMigrationModalOpen(true);
    }
    const onCloseNewRelMigrationModal = () => {
        setNewMigrationModalOpen(false);
    }
    const openMigrationEdit = (editDbMigrationId) => {
        setEditDbMigrationId(editDbMigrationId);
        setMigrationEditModalOpen(true);
    }
    const onCloseMigrationModal = () => {
        setMigrationEditModalOpen(false);
    }

    const openNewScreenChange = () => {
        setNewScreenModalOpen(true);
    }
    const closeNewScreenChange = () => {
        setNewScreenModalOpen(false);
    }
    const openEditScreenChange = (screenChangeId) => {
        setEditScreenChangeId(screenChangeId);
        setScreenEditModalOpen(true);
    }
    const closeEditScreenChange = () => {
        setScreenEditModalOpen(false);
    }

    return (
        <PrivateLayout>
        <RollbackReleaseModal open={rollbackModalOpen} onClose={onCloseRollbackModal} />
        <NewReleaseMigration releaseId={releaseId} open={newMigrationModalOpen} onClose={onCloseNewRelMigrationModal} />
        <ReleaseMigrationDetails releaseId={releaseId} open={migrationEditModalOpen} dbMigrationId={editDbMigrationId} onClose={onCloseMigrationModal}/>
        <NewReleaseScreenChange open={newScreenModalOpen} onClose={closeNewScreenChange} releaseId={releaseId} />
        <ReleaseScreenChangeDetails open={screenEditModalOpen} onClose={closeEditScreenChange} releaseId={releaseId} screenChangeId={editScreenChangeId}/>
        <ExportReleasePackageModal open={exportModalOpen} onClose={onCloseExportModal} releaseId={releaseId} releaseVersion={releaseDetails?.version} releases={releases} />
        <Container maxWidth="xl">
            <ConfirmDialog open={startReleaseConfirmOpen} cancelCallback={cancelStartRelease} confirmCallback={releaseStartConfirmed} title={"Start Release"}
                    description={"Are you sure that you want to start this release?  This will impact customers that are currently on the site."} />
            <ConfirmDialog open={deleteConfirmOpen} cancelCallback={cancelDelete} confirmCallback={deleteConfirmed} title={"Confirmt Delete"}
                    description={"Are you sure that you want to delete this release?"} />
            <form onSubmit={handleSubmit(onSubmit)} style={{width: '100%'}}>
            <FormBox title={"Release Details"}>
             {loading || releaseDetails === null || releaseDetails === undefined ? <CircularProgress/> :
             <Grid container spacing={2}>
                 {releaseDetails.isExternal && <Grid item sm={12}>
                     <TextField disabled={true} variant="outlined" fullWidth label="Client Target" margin="normal" value={releaseDetails.externalClientName} />
                 </Grid>}
                 {!releaseDetails.isExternal && <Grid item sm={12}>
                     <TextField disabled={true} variant="outlined" fullWidth label="Client Target" margin="normal" value={"Azzier Standard"} />
                 </Grid>}
                 <Grid item sm={4} >
                    <Controller

                            name="releaseName"
                            control={control}
                            inputRef={register()}
                            defaultValue={releaseDetails.releaseName}
                            render={({ field }) =>
                                <TextField disabled={readOnly} variant="outlined" fullWidth label="Release Name*" margin="normal" {...field} error={errors.releaseName ? true : false}
                                    helperText={errors.releaseName?.message} inputProps={{maxLength: 100}}/>

                            }
                    />
                 </Grid>
                 <Grid item sm={4} >
                    <Controller
                            name="version"
                            control={control}
                            inputRef={register()}
                            defaultValue={releaseDetails.version}
                            render={({ field }) =>
                                <TextField disabled={readOnly} variant="outlined" fullWidth label="Version*" margin="normal" {...field} error={errors.version ? true : false}
                                    helperText={errors.version?.message} inputProps={{maxLength: 100}}/>

                            }
                    />
                 </Grid>
                 <Grid item sm={4} >
                    <TextField disabled={true} variant="outlined" fullWidth label="Revision*" margin="normal" value={releaseDetails.revision}/>
                 </Grid>
                 <Grid item sm={4} >
                     <TextField disabled={true} variant="outlined" fullWidth label="Branch" margin="normal" value={releaseDetails.branch}/>
                 </Grid>
                 <Grid item sm={12}>
                    <Controller
                            name="releaseNotes"
                            control={control}
                            inputRef={register()}
                            defaultValue={releaseDetails.releaseNotes}
                            render={({ field }) =>
                            <BetterTextArea disabled={readOnly} variant="outlined" fullWidth label="Release Notes*" multiline margin="normal" {...field} error={errors.releaseNotes ? true : false}
                                helperText={errors.version?.releaseNotes    } inputProps={{maxLength: 100}}/>

                            }
                    />
                 </Grid>

                 <Grid item sm={4}>
                    <BoldLabel>Release Package Zip file</BoldLabel>
                 </Grid>
                 <Grid item sm={4} >
                    {readOnly?
                    <span>
                    {releaseDetails.releasePackageFileName != null ? <>Current file: {releaseDetails.releasePackageFileName}</>:null}
                    </span>
                    :
                    <>
                    <input type="file" name="releasePackage" onChange={fileSelected}/>
                    {releaseDetails.releasePackageFileName != null ? <><br/>Current file: {releaseDetails.releasePackageFileName}</>:null}
                    </>
                    }

                 </Grid>
                 {readOnly?
                     <Grid item sm={4}>
                        <CancelButton variant="contained" color="primary" type='button' onClick={() => navigate("/releases")}>Go Back</CancelButton>
                        {releaseDetails.currentReleasedVersion &&
                        <CancelButton variant="contained" color="error" type='button' onClick={openRollbackModal}>Rollback Release</CancelButton>
                        }
                         <SaveButton variant="contained" color="secondary" onClick={onExportRelease}>Export Release</SaveButton>
                    </Grid>
                :
                <>
                    <Grid item sm={4} >
                        <SaveButton disabled={releaseInProgress || saving} variant="contained" color='primary' type="submit">{saving && <CircularProgress size={"1rem"}/>}Save</SaveButton>
                        <CancelButton disabled={releaseInProgress || saving} variant="contained" color="secondary" type='button' onClick={() => navigate("/releases")}>Cancel</CancelButton>
                        <DeleteButton disabled={releaseInProgress || saving}  variant="contained" color="error" type='button' onClick={deleteRelase}>Delete</DeleteButton>
                    </Grid>
                    <Grid item sm={6} >
                        {releaseDetails?.releasePackageFileName !== null || migrationItems?.length > 0 ?
                            <>
                                <DeleteButton disabled={releaseInProgress || saving} variant="contained" color="error" type='button' onClick={startRelease}>Start Release</DeleteButton>
                                <SaveButton variant="contained" color="secondary" onClick={onExportRelease}>Export Release</SaveButton>
                            </>

                            : null
                        }
                    </Grid>
                </>
                 }
                 {hostReleases != null && hostReleases.length ?
                     <Grid item xs={12}>
                         <h4>Hosts</h4>
                         <HostReleaseList hostReleases={hostReleases} />
                     </Grid> : <></>
                 }
                 {(showLogs) &&
                 <Grid item sm={12}>
                    <TextareaAutosize
                        ref={logTextArea}
                        style={{width:"100%"}}
                        minRows={10}
                        maxRows={20}
                        value={outputLogs(logs)}
                    />
                 </Grid>
                 }
                 <Grid item sm={12}>
                    <h2>Release Database Migrations</h2>
                    {!readOnly && <Button variant="contained" onClick={openNewMigration}>New Migration</Button>}
                 </Grid>
                 {(showLogs) &&
                 <Grid item sm={12}>
                    <TextareaAutosize
                        ref={migrationLogTextArea}
                        style={{width:"100%"}}
                        minRows={10}
                        maxRows={20}
                        value={outputLogs(migrationLogs)}
                    />
                 </Grid>
                 }
                 <Grid item sm={12}>
                    <DraggableList disabled={readOnly} items={migrationItems} onDragEnd={onMigrationDragEnd}  renderItem={renderMigration}/>
                 </Grid>

                 <Grid item sm={12}>
                    <h2>Release Screen Changes</h2>
                    {!readOnly && <Button variant="contained" onClick={openNewScreenChange}>New Screen Change</Button>}
                 </Grid>
                 <Grid item sm={12}>
                    <DraggableList disabled={readOnly} items={screenItems} onDragEnd={onScreenDragEnd}  renderItem={renderScreenChange}/>
                 </Grid>
             </Grid>
             }
            </FormBox>
            </form>
        </Container>
        </PrivateLayout>
    );
}

export {ReleaseDetails};
