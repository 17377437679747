import React, { useState, useEffect } from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import {FormControl, FormControlLabel, FormHelperText, styled, Switch, TextareaAutosize} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Typography } from '@mui/material';
import { getCountryList, getStateList } from '../../../_helpers';
import {activateTrialService, clientService} from '../../../_services';
import { PrivateLayout } from '../../../components/PrivateLayout';
import { licenseTemplateActions, industryManagementActions } from '../../../_actions';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { hostService } from '../../../_services/host.service';



const FullWidthTextField = styled(TextField)({
    width: '100%'
})

const SaveButton = styled(Button)({
    width: '10%',
    marginRight:10
});

const CancelButton = styled(Button)({
    width: '10%'
});

const SelectFormControl = styled(FormControl)({
    marginTop: 16,
    width:"100%"
});

const caPostCodeRegex = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i
const usZipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;
const standardValidationSchema = Yup.object().shape({
  licenseId: Yup.string()
    .required('License is required'),
  adminPhoneNumber: Yup.string()
    .required('Admin phone number is required'),
  adminEmail: Yup.string()
    .required('Admin email is required')
    .matches(/.+@.+\..+/, 'Please enter a valid email'),
  companyName: Yup.string()
      .required('Company name is required'),
  companyAddress: Yup.string()
      .required('Company address is required'),
  companyCountry: Yup.string()
      .required('Country is required'),
  companyStateProv: Yup.string()
      .test("reqForCAorUS", "Please choose state/prov",
        (value, {createError, parent}) => {
            var country = parent.companyCountry;
            console.log("Country: " + parent.companyCountry)
            if ((country == 'CA' || country === 'US' ) && value == ''){
                return createError( {message: 'Please choose state / prov', type: 'companyStateProv'});
            } 
            return true;
        }
      ),
      
  companyCity: Yup.string()
      .required('City is required'),
  companyPostalCode: Yup.string()
      .required('Postal Code is required')
      .when('companyCountry',{
        is: 'CA',
        then: (schema) => schema.matches(caPostCodeRegex, "Invalid Postal Code Format")
    
        })
      .when('companyCountry',{
            is: 'US',
            then: (schema) => schema.matches(usZipCodeRegex, "Invalid Zip Code")
          }),
        //companyIndustry: Yup.string()
  //    .required('Company industry is required'),
  companyDomain: Yup.string()
      .required('Company domain is required')
      .matches(/^[a-zA-Z0-9]+$/,"Domain names can only contain letters and numbers.")
      .test('hostnameAvailable', 'Sorry this host name is taken already.',
        async (value, {createError}) => {
            var hostAvailable = await activateTrialService.hostAvailable(`${value}.azzier.com`);
            if (hostAvailable.isAvailable) return true;
            console.log (hostAvailable);
            return createError({
                message: "Sorry this host name is already in use.",
                type: 'companyDomain'
            });
        }),
  firstName: Yup.string()
      .required('First name is required'),
  lastName: Yup.string()
      .required('Last name is required'),
  password: Yup.string()
      .required('Password is required')
      .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, "Password must be at least 8 characters and must contain an upper case letter, lower case letter and a number")
      .max(100, 'Password must not exceed 100 characters'),
  confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
      trialEnd: Yup.string().nullable()
      .test('trial-end-required', 'Trial End is required when trial is selected', function(value) {
          return !this.parent.trial || !!value; // Use 'this.parent' to access other fields
      }),
    numberOfUsers: Yup.number()
      .required('Number of Users is required')
      .typeError('Number of Users must be a number') // Ensure it's a number
      .integer('Number of Users must be an integer')
      .positive('Number of Users must be positive')
      .min(1, "Minimum number of users is 1")
        
});

const nonStandardValidationSchema = Yup.object().shape({
    licenseId: Yup.string()
        .required('License is required'),
    adminPhoneNumber: Yup.string()
        .required('Admin phone number is required'),
    adminEmail: Yup.string()
        .required('Admin email is required')
        .matches(/.+@.+\..+/, 'Please enter a valid email'),
    companyName: Yup.string()
        .required('Company name is required'),
    companyAddress: Yup.string()
        .required('Company address is required'),
    dbTimezone: Yup.string()
        .required('Db Timezone is required'),
    companyCountry: Yup.string()
        .required('Country is required'),
    companyStateProv: Yup.string()
        .test("reqForCAorUS", "Please choose state/prov",
            (value, {createError, parent}) => {
                var country = parent.companyCountry;
                console.log("Country: " + parent.companyCountry)
                if ((country == 'CA' || country === 'US' ) && value == ''){
                    return createError( {message: 'Please choose state / prov', type: 'companyStateProv'});
                }
                return true;
            }
        ),

    companyCity: Yup.string()
        .required('City is required'),
    companyPostalCode: Yup.string()
        .required('Postal Code is required')
        .when('companyCountry',{
            is: 'CA',
            then: (schema) => schema.matches(caPostCodeRegex, "Invalid Postal Code Format")

        })
        .when('companyCountry',{
            is: 'US',
            then: (schema) => schema.matches(usZipCodeRegex, "Invalid Zip Code")
        }),

    companyDomain: Yup.string()
        .required('Company domain is required')
        .matches(/^[a-zA-Z0-9]+$/,"Domain names can only contain letters and numbers."),
    firstName: Yup.string()
        .required('First name is required'),
    lastName: Yup.string()
        .required('Last name is required'),
    rwDbUsername: Yup.string()
        .required('Read/Write db username is required'),
    rwDbPassword: Yup.string()
        .required('Read/Write db password is required'),
    roDbUsername: Yup.string()
        .required('Read-Only db username is required'),
    roDbPassword: Yup.string()
        .required('Read-Only db password is required'),
    numberOfUsers: Yup.number()
        .required('Number of Users is required')
        .typeError('Number of Users must be a number') // Ensure it's a number
        .integer('Number of Users must be an integer')
        .positive('Number of Users must be positive')
        .min(1, "Minimum number of users is 1"), 
    provisionHostId: Yup.number()
        .required('Provision Host is required'),
    dbServer: Yup.string()
        .required('DB Server is required'),
    dbName: Yup.string()
        .required('DB Name is required'),
    svnRoot: Yup.string()
        .required('SVN Root is required'),
    sitePath: Yup.string()
        .required("Site Path is required"),
    iisSiteName: Yup.string()
        .required("IIS Site Name is required")
});

function NewClientOnboardPage() {
    const [searchParams, setSearchParams] = useSearchParams();
    const registering = useSelector(state => state.activateTrial.registering);
    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [companyStateList, setCompanyStateList] = useState([]);
    const [nonStandardClient, setNonStandardClient] = useState(false);
    const [provisionHosts, setProvisionHosts] = useState([]);
    const dispatch = useDispatch();
    const licenseTemplates = useSelector(state => state.licenseTemplates.templateList);
    const industryList = useSelector(state => state.industryManagement.industryList);

    const { control, handleSubmit, register, formState: { errors, submitCount }, getValues, setValue, trigger} = useForm({
        resolver: yupResolver(nonStandardClient ? nonStandardValidationSchema : standardValidationSchema), reValidateMode: 'onBlur'
    });

    useEffect(() => { 
        initializeCountryList();
        initProvisionHosts();
        dispatch(licenseTemplateActions.getList());
        dispatch(industryManagementActions.getIndustryList())
    }, []);
    useEffect(() => {
        if(selectedCountry){
            initializeStateList(selectedCountry);
        }
        
    }, [selectedCountry]);

    const navigate = useNavigate();

    const initProvisionHosts = async () => {
        setProvisionHosts(await hostService.getHostList());
    }
    const initializeCountryList = async () => {
        setCountryList(await getCountryList());
        console.log('test' , countryList);
    };

    const renderCountryList = () => {
        return countryList.map((country, keyIndex) => {
            return (<MenuItem key={keyIndex} value={ country.countryCode }>{ country.countryName }</MenuItem>);
        });
    };

    const initializeStateList = async (countryCode) => {
        setCompanyStateList(await getStateList(countryCode));
    };

    const renderStateList = () => {
        return companyStateList.map((state, keyIndex) => {
            return (<MenuItem key={keyIndex} value={ state.stateCode }>{ state.stateName }</MenuItem>);
        });
    }

    const toggleNonStandard = () => {
        setNonStandardClient(!nonStandardClient);
    }

    const onSubmit = async data => {
        console.log('Data from form:' , data);
        let newClientId = 0;
        if (nonStandardClient){
            newClientId = await clientService.onboardNonStandardClient(data.companyName, data.adminEmail, data.adminPhoneNumber,
                data.companyAddress, data.companyCountry, data.companyStateProv, data.companyCity, data.companyPostalCode,
                data.firstName, data.lastName, data.companyDomain, data.licenseId, data.numberOfUsers, data.provisionHostId, 
                data.rwDbUsername, data.rwDbPassword, data.roDbUsername, data.roDbPassword, data.dbServer, data.dbName,
                data.svnRoot, data.sitePath, data.iisSiteName, data.dbTimezone);
        } else {
            let trial = data.trial;
            if (!data.trial || data.trial == '') trial= false;

            newClientId = await clientService.onboardNewClient(data.companyName, data.adminEmail, data.adminPhoneNumber,
                data.companyAddress, data.companyCountry, data.companyStateProv, data.companyCountry, data.companyPostalCode,
                data.firstName, data.lastName, data.companyDomain, data.adminEmail, data.password, data.licenseId,
                trial, data.trialEnd, data.numberOfUsers, data.industryId);
        }
        
        navigate(`/clients/${newClientId}`);

    };

    const renderTimezoneList = () => {
        return usCanadaTimezones.map((timzone, keyIndex) => {
            return (<MenuItem key={keyIndex} value={ timzone.tz }>{ timzone.tz }</MenuItem>);
        });
    };

    console.log(errors);
    return (
    <PrivateLayout>
        <LocalizationProvider  dateAdapter={AdapterDayjs}>
            <Container maxWidth="xl">           
                <h1 style={{marginTop:20}}>Onboard Client
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography>Azzier Standard</Typography>
                        <Switch checked={nonStandardClient} inputProps={{ 'aria-label': 'ant design' }} onClick={toggleNonStandard} />
                        <Typography>Non-Standard Azzier</Typography>
                    </Stack>
                </h1>
                <p>
                    Fill in the details below to create a new Azzier client and instance.
                </p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <h4>License Info</h4>
                    <hr/>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <Grid item>
                                <SelectFormControl style={{marginTop:16}}  variant="outlined">
                                    <InputLabel id='licenseIdLabel'>License*</InputLabel>
                                    <Controller
                                        name="licenseId"
                                        control={control}
                                        inputRef={register()}
                                        defaultValue=""
                                        render={({ field }) =>
                                            <Select  labelId="licenseIdLabel" id="select" style={{width:'100%'}} {...field}                                            
                                                    error={errors.licenseId ? true : undefined} label="License*">
                                                {licenseTemplates && licenseTemplates.map(l =>
                                                    <MenuItem key={l.id} value={ l.id }>{ l.licenseName }</MenuItem>
                                                )}
                                            </Select>
                                        }
                                    />
                                </SelectFormControl>
                            </Grid>
                            <Grid item>
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors.licenseId?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                        {!nonStandardClient && 
                        <>
                        <Grid xs={12} sm={4} item>
                        
                            <Controller
                                name="trial"
                                control={control}
                                inputRef={register()}
                                defaultValue=""
                                render={({ field }) => 
                                    <FormControlLabel sx={{mt:2}} control={<Checkbox {...field} error={errors.trial ? true : undefined} />} label="Trial" />
                                }
                            />
                        </Grid> 
                        <Grid item sm={4} xs={12}>
                            <Grid item>
                                <Controller
                                    name="trialEnd"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue={dayjs().add(1, 'month')}
                                    render={({ field }) =>
                                        <DateTimePicker label="Trial End"  variant="outlined" {...field} sx={{mt:2}} error={false}/>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="inherit" color="error" style={{textAlign:'left', marginRight: '2rem'}}>
                                    {errors.trialEnd?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                        </>}
                        
                        <Grid item sm={4} xs={12}>
                            <Grid item>
                                <Controller
                                    name="numberOfUsers"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue=""
                                    render={({ field }) =>
                                        <FullWidthTextField label="Number of Users*"  variant="outlined" type='number' margin="normal" {...field} error={errors.numberOfUsers ? true : undefined}/>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="inherit" color="error" style={{textAlign:'left', marginRight: '2rem'}}>
                                    {errors.numberOfUsers?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                    <h4>Account Information</h4>
                    <hr/> 
                    <Grid spacing={3} container>
                        <Grid item sm={4} xs={12}>
                            <Grid item>
                                <Controller
                                    name="firstName"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue=""
                                    render={({ field }) =>
                                        <FullWidthTextField label="First Name*" variant="outlined" margin="normal" {...field} error={errors.firstName ? true : undefined}/>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="inherit" color="error" style={{textAlign:'left', marginRight: '2rem'}}>
                                    {errors.firstName?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <Grid item>
                                <Controller
                                    name="lastName"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue=""
                                    render={({ field }) =>
                                        <FullWidthTextField label="Last Name*"  variant="outlined" margin="normal" {...field} error={errors.lastName ? true : undefined}/>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="inherit" color="error" style={{textAlign:'left', marginRight: '2rem'}}>
                                    {errors.lastName?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <Grid item>
                                <Controller
                                    name="adminPhoneNumber"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue=""
                                    render={({ field }) =>
                                            <FullWidthTextField label="Admin Phone Number*" variant="outlined" margin="normal" {...field} error={errors.adminPhoneNumber ? true : undefined}/>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors.adminPhoneNumber?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                        {nonStandardClient && 
                        <Grid item sm={4} xs={12}>
                            <Grid item>
                                <Controller
                                    name="adminEmail"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue=""
                                    render={({ field }) =>
                                        <FullWidthTextField label="Admin Email Address*" variant="outlined" margin="normal" {...field} error={errors.adminEmail ? true : undefined}/>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="inherit" color="error" style={{textAlign:'left', marginRight: '2rem'}}>
                                    {errors.adminEmail?.message}
                                </Typography>
                            </Grid>
                        </Grid>}
                    </Grid>
                    <h4>Company Information</h4>
                    <hr/> 
                    <Grid container spacing={3}>               
                        <Grid item  xs={12} sm={8}>
                            <Grid item>
                                <Controller
                                    name="companyName"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue=""
                                    render={({ field }) => 
                                        <FullWidthTextField  label="Company Name*" variant="outlined" margin="normal"  {...field} error={errors.companyName ? true : undefined}/>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors.companyName?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                        {!nonStandardClient && <Grid item xs={4} sm={4}>
                        <Grid item>
                            <FormControl style={{marginTop:16, width: '100%'}}  variant="outlined">
                                <InputLabel id='industryLabel'>Company Industry</InputLabel>
                                <Controller
                                    name="industryId"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue=""
                                    render={({ field }) =>
                                        <Select  labelId="industryLabel" id="industry" {...field}                                            
                                            label="Company Industry*">
                                            {industryList && industryList.map(i =>
                                                <MenuItem key={i.id} value={ i.id }>{ i.industryName }</MenuItem>
                                            )}
                                        </Select>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        </Grid>}
                    </Grid>
                    
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={8}>
                            <Grid item>
                                <Controller
                                    name="companyAddress"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue=""
                                    render={({ field }) =>
                                        <TextField label="Address*" variant="outlined" margin="normal" style = {{width: '100%'}} {...field} error={errors.companyAddress ? true : undefined}/>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors.companyAddress?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Grid item>
                                <Controller
                                    name="companyCity"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue=""
                                    render={({ field }) =>
                                        <FullWidthTextField label="City*" variant="outlined" margin="normal" {...field} error={errors.companyCity ? true : undefined}/>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors.companyCity?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <Grid item>
                                <SelectFormControl style={{marginTop:16}}  variant="outlined">
                                    <InputLabel id='companyCountryLabel'>Country*</InputLabel>
                                    <Controller
                                        name="companyCountry"
                                        control={control}
                                        inputRef={register()}
                                        defaultValue=""
                                        render={({ field }) =>
                                            <Select  labelId="companyCountryLabel" id="select" style={{width:'100%'}} {...field}
                                                
                                                    onChange={(e) => {
                                                        field.onChange(e); 
                                                        setSelectedCountry(e.target.value); 
                                                        setValue('companyStateProv', '');
                                                        if (submitCount > 0)
                                                            trigger('companyStateProv');
                                                    }}
                                                    error={errors.companyCountry ? true : undefined} label="Country*">
                                                {renderCountryList()}
                                            </Select>
                                        }
                                    />
                                </SelectFormControl>
                            </Grid>
                            <Grid item>
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors.companyCountry?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Grid item >
                                {(getValues("companyCountry") === 'CA' || getValues("companyCountry") === 'US') ? 
                                <SelectFormControl disabled={!getValues("companyCountry")} style={{marginTop:16}}  variant="outlined">
                                    <InputLabel id='companyStateProv'>State / Prov*</InputLabel>

                                    <Controller
                                        name="companyStateProv"
                                        control={control}
                                        inputRef={register()}
                                        defaultValue=""
                                        render={({ field }) =>

                                        <Select label="State / Prov*" labelId="companyStateProv" id="select" style={{width:'100%'}} {...field} error={errors.companyStateProv ? true : undefined}>
                                            {renderStateList()}
                                        </Select>
                                            
                                        }
                                    />
                                </SelectFormControl>
                                :
                                <Controller
                                    name="companyStateProv"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue=""
                                    render={({ field }) =>
                                        <TextField label="State / Prov" variant="outlined" margin="normal" style = {{width: '100%'}} {...field} error={errors.companyStateProv ? true : undefined}/>
                                    }
                                />
                                }
                                
                            </Grid>
                            <Grid item>
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors.companyStateProv?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item  xs={12} sm={4}>
                            <Grid item>
                                <Controller
                                    name="companyPostalCode"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue=""
                                    render={({ field }) =>
                                        <FullWidthTextField label="Zip / Postal Code*" variant="outlined"  margin="normal" {...field} error={errors.companyPostalCode ? true : undefined}/>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors.companyPostalCode?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {!nonStandardClient && <>
                    <h4>Azzier Login Information</h4>
                    <hr/> 
                    <Grid container spacing={3}>
                        <Grid item sm={4} xs={12}>
                            <Grid item>
                                <Controller
                                    name="adminEmail"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue=""
                                    render={({ field }) =>
                                        <FullWidthTextField label="Email Address*" variant="outlined" margin="normal" {...field} error={errors.adminEmail ? true : undefined}/>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="inherit" color="error" style={{textAlign:'left', marginRight: '2rem'}}>
                                    {errors.adminEmail?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <Grid item>
                                <Controller
                                    name="password"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue=""
                                    render={({ field }) =>
                                        <FullWidthTextField label="Password*" type="password" variant="outlined" margin="normal" {...field} error={errors.password ? true : undefined}/>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="inherit" color="error" style={{textAlign:'left', marginRight: '2rem'}}>
                                    {errors.password?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <Grid item>
                                <Controller
                                    name="confirmPassword"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue=""
                                    render={({ field }) =>
                                        <FullWidthTextField label="Confirm Password*" type="password" variant="outlined" margin="normal" {...field} error={errors.confirmPassword ? true : undefined}/>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors.confirmPassword?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item sm={8} xs={12}>
                            <Grid item width={'100%'}>
                                <Controller
                                    name="companyDomain"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue=""
                                    render={({ field }) => 
                                        <Grid container>
                                            <Grid item sm={2} xs={12}>
                                                <FullWidthTextField disabled={true} label="https://" style={{backgroundColor: '#d6d6d6'}} variant="outlined" margin="normal" />
                                            </Grid>
                                            <Grid item sm={3} xs={12}>
                                                <FullWidthTextField label="Site Name*" variant="outlined" margin="normal" {...field} error={errors.companyDomain ? true : undefined}/>
                                            </Grid>
                                            <Grid item sm={2} xs={12}>
                                                <FullWidthTextField disabled={true} label=".azzier.com" style={{backgroundColor: '#d6d6d6'}} variant="outlined" margin="normal" />
                                            </Grid>
                                        </Grid>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors.companyDomain?.message && errors.companyDomain?.message !== 'PendingEmailConfirmation' && (
                                        <>
                                            {errors.companyDomain?.message}
                                        </>
                                    )}
                                    
                                </Typography>
                                
                            </Grid>
                        </Grid> 
                    </Grid>
                    
                    </>}
                    
                    {nonStandardClient && <>
                    <h4>Deployment Information</h4>
                    <hr/> 
                    
                    <Grid container spacing={3}>
                        <Grid item sm={4} xs={12}>
                            <Grid item>
                                <SelectFormControl style={{marginTop:16}}  variant="outlined">
                                        <InputLabel id='provisionHostLabel'>Provision Host*</InputLabel>
                                        <Controller
                                            name="provisionHostId"
                                            control={control}
                                            inputRef={register()}
                                            defaultValue=""
                                            render={({ field }) =>
                                                <Select  labelId="provisionHostLabel" id="select" style={{width:'100%'}} {...field}                                            
                                                        error={errors.provisionHostId ? true : undefined} label="Provision Host*">
                                                    {provisionHosts && provisionHosts.map(l =>
                                                        <MenuItem key={l.id} value={ l.id }>{ l.name }</MenuItem>
                                                    )}
                                                </Select>
                                            }
                                        />
                                </SelectFormControl>
                            </Grid>
                            <Grid item>
                                <Typography variant="inherit" color="error" style={{textAlign:'left', marginRight: '2rem'}}>
                                    {errors.provisionHostId?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item sm={4} >
                            <SelectFormControl style={{marginTop:16}}  variant="outlined">
                                <InputLabel id='dbTimezoneLabel'>DB Timezone*</InputLabel>
                                <Controller
                                    name="dbTimezone"
                                    control={control}
                                    inputRef={register()}
                                    render={({ field }) =>
                                        <Select label="DB Timezone*" labelId="dbTimezoneLabel" id="select" style={{width:'100%'}} {...field} error={errors.dbTimezone ? true : undefined}>
                                            {renderTimezoneList()}
                                        </Select>
                                    }
                                />
                                <FormHelperText error={errors.dbTimezone ? true : undefined}>{errors.dbTimezone?.message}</FormHelperText>
                            </SelectFormControl>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <Grid item width={'100%'}>
                                <Controller
                                    name="companyDomain"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue=""
                                    render={({ field }) => 
                                        <Grid container>
                                            <Grid item sm={2} xs={12}>
                                                <FullWidthTextField disabled={true} label="https://" style={{backgroundColor: '#d6d6d6'}} variant="outlined" margin="normal" />
                                            </Grid>
                                            <Grid item sm={4} xs={12}>
                                                <FullWidthTextField label="Site Name*" variant="outlined" margin="normal" {...field} error={errors.companyDomain ? true : undefined}/>
                                            </Grid>
                                            <Grid item sm={2} xs={12}>
                                                <FullWidthTextField disabled={true} label=".azzier.com" style={{backgroundColor: '#d6d6d6'}} variant="outlined" margin="normal" />
                                            </Grid>
                                        </Grid>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors.companyDomain?.message && errors.companyDomain?.message !== 'PendingEmailConfirmation' && (
                                        <>
                                            {errors.companyDomain?.message}
                                        </>
                                    )}
                                    
                                </Typography>
                                
                            </Grid>
                        </Grid>
                        <Grid item sm={8} xs={12}>
                            <Grid item>
                                <Controller
                                    name="sitePath"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue=""
                                    render={({ field }) =>
                                        <FullWidthTextField label="Site Path*"  variant="outlined" margin="normal" {...field} error={errors.sitePath ? true : undefined}/>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="inherit" color="error" style={{textAlign:'left', marginRight: '2rem'}}>
                                    {errors.sitePath?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item sm={8} xs={12}>
                            <Grid item>
                                <Controller
                                    name="iisSiteName"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue=""
                                    render={({ field }) =>
                                        <FullWidthTextField label="IIS Site Name*"  variant="outlined" margin="normal" {...field} error={errors.iisSiteName ? true : undefined}/>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="inherit" color="error" style={{textAlign:'left', marginRight: '2rem'}}>
                                    {errors.iisSiteName?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Grid item>
                                <Controller
                                    name="rwDbUsername"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue=""
                                    render={({ field }) =>
                                        <FullWidthTextField label="R/W DB Username*"  variant="outlined" margin="normal" {...field} error={errors.rwDbUsername ? true : undefined}/>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="inherit" color="error" style={{textAlign:'left', marginRight: '2rem'}}>
                                    {errors.rwDbUsername?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                        
                        <Grid item sm={6} xs={12}>
                            <Grid item>
                                <Controller
                                    name="rwDbPassword"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue=""
                                    render={({ field }) =>
                                        <FullWidthTextField label="R/W DB Password*"  variant="outlined" margin="normal" {...field} error={errors.rwDbPassword ? true : undefined}/>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="inherit" color="error" style={{textAlign:'left', marginRight: '2rem'}}>
                                    {errors.rwDbPassword?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Grid item>
                                <Controller
                                    name="roDbUsername"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue=""
                                    render={({ field }) =>
                                        <FullWidthTextField label="RO DB Username*"  variant="outlined" margin="normal" {...field} error={errors.roDbUsername ? true : undefined}/>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="inherit" color="error" style={{textAlign:'left', marginRight: '2rem'}}>
                                    {errors.roDbUsername?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                        
                        <Grid item sm={6} xs={12}>
                            <Grid item>
                                <Controller
                                    name="roDbPassword"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue=""
                                    render={({ field }) =>
                                        <FullWidthTextField label="RO DB Password*"  variant="outlined" margin="normal" {...field} error={errors.roDbPassword ? true : undefined}/>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="inherit" color="error" style={{textAlign:'left', marginRight: '2rem'}}>
                                    {errors.roDbPassword?.message}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item sm={4} xs={12}>
                            <Grid item>
                                <Controller
                                    name="dbServer"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue=""
                                    render={({ field }) =>
                                        <FullWidthTextField label="DB Server*"  variant="outlined" margin="normal" {...field} error={errors.dbServer ? true : undefined}/>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="inherit" color="error" style={{textAlign:'left', marginRight: '2rem'}}>
                                    {errors.dbServer?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <Grid item>
                                <Controller
                                    name="dbName"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue=""
                                    render={({ field }) =>
                                        <FullWidthTextField label="DB Name*"  variant="outlined" margin="normal" {...field} error={errors.dbName ? true : undefined}/>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="inherit" color="error" style={{textAlign:'left', marginRight: '2rem'}}>
                                    {errors.dbName?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Grid item width={'100%'}>
                                <Controller
                                    name="svnRoot"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue=""
                                    render={({ field }) => 
                                        <Grid container>
                                            <Grid item sm={4} xs={4}>
                                                <FullWidthTextField disabled={true} label="/svn/AzzierSVN/" style={{backgroundColor: '#d6d6d6'}} variant="outlined" margin="normal" />
                                            </Grid>
                                            <Grid item sm={8} xs={8}>
                                                <FullWidthTextField label="SVN Root Dir*" variant="outlined" margin="normal" {...field} error={errors.svnRoot ? true : undefined}/>
                                            </Grid>
                                        </Grid>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="inherit" color="error" style={{textAlign:'left', marginRight: '2rem'}}>
                                    {errors.svnRoot?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                        
                    </Grid> 
                    </>}
                                     
                    <div style={{marginTop:20}}>
                        {!registering &&             
                            <SaveButton
                                type="submit"
                                variant="contained"
                                color="primary"
                            > 
                                Save 
                            </SaveButton>
                        }
                        {registering && <CircularProgress />}
                        <Link to="/clients" style={{ textDecoration: 'none' }}>
                            <CancelButton
                                    color="secondary"
                                    type="button"
                                    variant="contained"                                
                                > 
                                    Cancel
                            </CancelButton>
                        </Link>
                    </div>
                </form>
                <br /><br />
            </Container>
        </LocalizationProvider>
    </PrivateLayout>
    );
  }
  
export { NewClientOnboardPage };

const usCanadaTimezones = [
    { description: 'Halifax', tz: 'America/Halifax' },
    { description: 'Glace_Bay', tz: 'America/Glace_Bay' },
    { description: 'Moncton', tz: 'America/Moncton' },
    { description: 'Goose_Bay', tz: 'America/Goose_Bay' },
    { description: 'Blanc-Sablon', tz: 'America/Blanc-Sablon' },
    { description: 'Toronto', tz: 'America/Toronto' },
    { description: 'Nipigon', tz: 'America/Nipigon' },
    { description: 'Thunder_Bay', tz: 'America/Thunder_Bay' },
    { description: 'Iqaluit', tz: 'America/Iqaluit' },
    { description: 'Pangnirtung', tz: 'America/Pangnirtung' },
    { description: 'Atikokan', tz: 'America/Atikokan' },
    { description: 'Winnipeg', tz: 'America/Winnipeg' },
    { description: 'Rainy_River', tz: 'America/Rainy_River' },
    { description: 'Resolute', tz: 'America/Resolute' },
    { description: 'Rankin_Inlet', tz: 'America/Rankin_Inlet' },
    { description: 'Regina', tz: 'America/Regina' },
    { description: 'Swift_Current', tz: 'America/Swift_Current' },
    { description: 'Edmonton', tz: 'America/Edmonton' },
    { description: 'Cambridge_Bay', tz: 'America/Cambridge_Bay' },
    { description: 'Yellowknife', tz: 'America/Yellowknife' },
    { description: 'Inuvik', tz: 'America/Inuvik' },
    { description: 'Creston', tz: 'America/Creston' },
    { description: 'Dawson_Creek', tz: 'America/Dawson_Creek' },
    { description: 'Fort_Nelson', tz: 'America/Fort_Nelson' },
    { description: 'Vancouver', tz: 'America/Vancouver' },
    { description: 'Whitehorse', tz: 'America/Whitehorse' },
    { description: 'Dawson', tz: 'America/Dawson' },
    { description: 'St_Johns', tz: 'America/St_Johns' },
    { description: 'New_York', tz: 'America/New_York' },
    { description: 'Detroit', tz: 'America/Detroit' },
    { description: 'Louisville', tz: 'America/Kentucky/Louisville' },
    { description: 'Monticello', tz: 'America/Kentucky/Monticello' },
    { description: 'Indianapolis', tz: 'America/Indiana/Indianapolis' },
    { description: 'Vincennes', tz: 'America/Indiana/Vincennes' },
    { description: 'Winamac', tz: 'America/Indiana/Winamac' },
    { description: 'Marengo', tz: 'America/Indiana/Marengo' },
    { description: 'Petersburg', tz: 'America/Indiana/Petersburg' },
    { description: 'Vevay', tz: 'America/Indiana/Vevay' },
    { description: 'Tell_City', tz: 'America/Indiana/Tell_City' },
    { description: 'Knox', tz: 'America/Indiana/Knox' },
    { description: 'Chicago', tz: 'America/Chicago' },
    { description: 'Menominee', tz: 'America/Menominee' },
    { description: 'Denver', tz: 'America/Denver' },
    { description: 'Boise', tz: 'America/Boise' },
    { description: 'Phoenix', tz: 'America/Phoenix' },
    { description: 'Los_Angeles', tz: 'America/Los_Angeles' },
    { description: 'Anchorage', tz: 'America/Anchorage' },
    { description: 'Juneau', tz: 'America/Juneau' },
    { description: 'Sitka', tz: 'America/Sitka' },
    { description: 'Metlakatla', tz: 'America/Metlakatla' },
    { description: 'Yakutat', tz: 'America/Yakutat' },
    { description: 'Nome', tz: 'America/Nome' },
    { description: 'Adak', tz: 'America/Adak' }
];