export const seedManagementConstants = {
    GET_SEED_LIST_REQUEST: 'GET_SEED_LIST_REQUEST',
    GET_SEED_LIST_SUCCESS: 'GET_SEED_LIST_SUCCESS',
    GET_SEED_LIST_FAILURE: 'GET_SEED_LIST_FAILURE',

    STORE_DEFAULT_FIRST_SEEDS: 'STORE_DEFAULT_FIRST_SEEDS',

    GET_SEED_DETAILS_REQUEST: 'GET_SEED_DETAILS_REQUEST',
    GET_SEED_DETAILS_SUCCESS: 'GET_SEED_DETAILS_SUCCESS',
    GET_SEED_DETAILS_FAILURE: 'GET_SEED_DETAILS_FAILURE',

    SAVE_SEED_DETAILS_REQUEST: 'SAVE_SEED_DETAILS_REQUEST',
    SAVE_SEED_DETAILS_SUCCESS: 'SAVE_SEED_DETAILS_SUCCESS',
    SAVE_SEED_DETAILS_FAILURE: 'SAVE_SEED_DETAILS_FAILURE',

    ADD_SEED_REQUEST: 'ADD_SEED_REQUEST',
    ADD_SEED_SUCCESS: 'ADD_SEED_SUCCESS',
    ADD_SEED_FAILURE: 'ADD_SEED_FAILURE',

    DELETE_SEED_REQUEST: 'DELETE_SEED_REQUEST',
    DELETE_SEED_SUCCESS: 'DELETE_SEED_SUCCESS',
    DELETE_SEED_FAILURE: 'DELETE_SEED_FAILURE',

    GET_STANDARD_DEPLOYMENTS_REQUEST: 'GET_STANDARD_DEPLOYMENTS_REQUEST',
    GET_STANDARD_DEPLOYMENTS_SUCCESS: 'GET_STANDARD_DEPLOYMENTS_SUCCESS',
    GET_STANDARD_DEPLOYMENTS_FAILURE: 'GET_STANDARD_DEPLOYMENTS_FAILURE',
};