import * as React from 'react';
import DraggableListItem from './DraggableListItem';
import {
  DragDropContext,
  Droppable
} from 'react-beautiful-dnd';
import { List, ListItem } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const DraggableList = React.memo(({ items, onDragEnd, renderItem, disabled = false }) => {
    if (disabled){
        return (
            <List>
                {items.map(item => (
                    <ListItem key={item.id}>
                        {renderItem(item, disabled)}
                    </ListItem>
                ))}       
            </List>
        )
    } else {
        return (
            <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-list">
                {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                    
                    {items.map((item, index) => (
                    <DraggableListItem disabled={disabled} item={item} index={index} key={item.id} renderItem={renderItem} />
                    ))}
                    {provided.placeholder}
                </div>
                )}
            </Droppable>
            </DragDropContext>
        );
    }
    
});

export default DraggableList;