import axios from '../_helpers/axiosInstance';
import { authHeader } from '../_helpers/authHeader';

export const industryManagementService = {
    getIndustryList,
    saveIndustryDetails,
    saveIndustries,
    addIndustry,
    deleteIndustry
};

async function getIndustryList() {
    console.log('About to get list of industries');
    var response = await axios.get('/industrymanagement/industrylist', { headers: authHeader() });
    return response.data.result;
}

async function saveIndustryDetails(industry){
    await axios.put('/industrymanagement/industry', industry, {headers: authHeader()});
}

async function saveIndustries(industries){
    await axios.put('/industrymanagement/industries', industries, {headers: authHeader()});
}

async function addIndustry(industry) {
    console.log('About to add a new Industry');
    var response = await axios.post('/industrymanagement/industry', industry, { headers: authHeader() });
    return response.data.result;
}

async function deleteIndustry(industryId) {
    console.log('About to delete a industry');
    await axios.delete('/industrymanagement/industry/'+ industryId, { headers: authHeader() });
}

