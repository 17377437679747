
//import { alertActions } from './';
import {alertConstants, releaseConstants} from '../_constants';
import { history } from '../_helpers';
import { releaseService } from '../_services';
import { alertActions } from './alert.actions';

export const releaseActions = {
    getReleases,
    loadReleaseDetails,
    updateRelease,
    deleteRelease,
    createNewRelease,
    loadReleaseMigrationDetails,
    loadReleaseScreenChangeDetails,
    saveReleaseMigraion,
    saveReleaseScreen,
    createNewReleaseMigration,
    createNewReleaseScreen,
    reorderReleaseMigrations,
    reorderReleaseScreens,
    deleteReleaseMigration,
    deleteReleaseScreen, 
    rollbackRelease,
    setNonStandardDeployment
};


function getReleases(){
    return async (dispatch, getState) => {
        try {
            const state = getState();
            var nonStandardDeployment = state.releases.nonStandardDeployment;
            dispatch(request());
            var releases = await releaseService.getReleases(nonStandardDeployment?.deploymentId || null);
            dispatch(success(releases));
        } catch(e){
            console.error(e);
            dispatch(alertActions.error("Could not retrieve the list of releases.") )
            dispatch(failure(e.message))
            
        }
        
    }

    function request() { return { type: releaseConstants.GET_RELEASE_LIST_REQUEST } }
    function success(releaseList) { return { type: releaseConstants.GET_RELEASE_LIST_SUCCESS, releaseList } }
    function failure(error) { return { type: releaseConstants.GET_RELEASE_LIST_FAILURE, error } }
}

function setNonStandardDeployment(deployment){
    return { type: releaseConstants.SET_NON_STANDARD_DEPLOYMENT_REQUEST, nonStandardDeployment : deployment };
}

function updateRelease(releaseId, releaseName, releaseNotes, version, releasePackage){
    return async dispatch => {
        try {
            dispatch(request());
            await releaseService.updateRelease(releaseId, releaseName, releaseNotes, version, releasePackage);
            dispatch(success());
        } catch(e){
            console.error(e);
            dispatch(alertActions.error("Could not update the release.") )
            dispatch(failure(e.message))
            
        }
        
    }

    function request() { return { type: releaseConstants.UPDATE_RELEASE_DETAILS_REQUEST } }
    function success() { return { type: releaseConstants.UPDATE_RELEASE_DETAILS_SUCCESS } }
    function failure(error) { return { type: releaseConstants.UPDATE_RELEASE_DETAILS_FAILURE, error } }
}

function createNewRelease(releaseName, releaseNotes, version, revision, releasePackage, nonStandardDeploymentId){
    return async dispatch => {
        try {
            dispatch(request());
            await releaseService.createNewRelease(releaseName, releaseNotes, version, revision, releasePackage, nonStandardDeploymentId);
            dispatch(success());
        } catch(e){
            console.error(e);
            dispatch(alertActions.error("Could not update the release.") );
            dispatch(failure(e.message));            
        }
        
    }

    function request() { return { type: releaseConstants.NEW_RELEASE_REQUEST } }
    function success() { return { type: releaseConstants.NEW_RELEASE_SUCCESS } }
    function failure(error) { return { type: releaseConstants.NEW_RELEASE_FAILURE, error } }
}

function loadReleaseDetails(releaseId){
    return async dispatch => {
        try {
            dispatch(request());
            var releaseDetails = await releaseService.getReleaseDetails(releaseId);
            dispatch(success(releaseDetails));
        } catch(e){
            console.error(e);
            dispatch(alertActions.error("Could not retrieve the release details.") )
            dispatch(failure(e.message))
            
        }        
    }

    function request() { return { type: releaseConstants.GET_RELEASE_DETAILS_REQUEST } }
    function success(releaseDetails) { return { type: releaseConstants.GET_RELEASE_DETAILS_SUCCESS, releaseDetails } }
    function failure(error) { return { type: releaseConstants.GET_RELEASE_DETAILS_FAILURE, error } }
}

function deleteRelease(releaseId){
    return async dispatch => {
        try {
            dispatch(request());
            await releaseService.deleteRelease(releaseId);
            dispatch(success());
        } catch(e){
            console.error(e);
            dispatch(alertActions.error("Could not delete this release.") )
            dispatch(failure(e.message));            
        }        
    }

    function request() { return { type: releaseConstants.DELETE_RELEASE_REQUEST } }
    function success() { return { type: releaseConstants.DELETE_RELEASE_SUCCESS } }
    function failure(error) { return { type: releaseConstants.DELETE_RELEASE_FAILURE, error } }
}




function saveReleaseMigraion(id, releaseId, scriptName, sqlScript, rollbackScript){
    return async dispatch => {
        try {
            dispatch(request());
            await releaseService.updateReleaseMigration(id, releaseId, scriptName, sqlScript, rollbackScript);
            dispatch(success());
        } catch(e){
            console.error(e);
            dispatch(alertActions.error("Could not update the release migration.") )
            dispatch(failure(e.message))
            
        }
        
    }

    function request() { return { type: releaseConstants.UPDATE_RELEASE_MIGRATION_DETAILS_REQUEST } }
    function success() { return { type: releaseConstants.UPDATE_RELEASE_MIGRATION_DETAILS_SUCCESS } }
    function failure(error) { return { type: releaseConstants.UPDATE_RELEASE_MIGRATION_DETAILS_FAILURE, error } }
}

function saveReleaseScreen(id, releaseId, fileName, contents){
    return async dispatch => {
        try {
            dispatch(request());
            await releaseService.updateScreenChange(id, releaseId, fileName, contents);
            dispatch(success());
        } catch(e){
            console.error(e);
            dispatch(alertActions.error("Could not update the release screen.") )
            dispatch(failure(e.message))
            
        }
        
    }

    function request() { return { type: releaseConstants.UPDATE_RELEASE_SCREEN_DETAILS_REQUEST } }
    function success() { return { type: releaseConstants.UPDATE_RELEASE_SCREEN_DETAILS_SUCCESS } }
    function failure(error) { return { type: releaseConstants.UPDATE_RELEASE_SCREEN_DETAILS_FAILURE, error } }
}

function loadReleaseMigrationDetails(dbMigrationId){
    return async dispatch => {
        try {
            dispatch(request());
            let migrationDetails = await releaseService.getReleaseMigrationDetails(dbMigrationId);
            dispatch(success(migrationDetails));
        } catch(e){
            console.error(e);
            dispatch(alertActions.error("Could not load the release migration.") );
            dispatch(failure(e.message));            
        }        
    }

    function request() { return { type: releaseConstants.GET_RELEASE_MIGRATION_DETAILS_REQUEST } }
    function success(migrationDetails) { return { type: releaseConstants.GET_RELEASE_MIGRATION_DETAILS_SUCCESS, migrationDetails } }
    function failure(error) { return { type: releaseConstants.GET_RELEASE_MIGRATION_DETAILS_FAILURE, error } }
}

function loadReleaseScreenChangeDetails(screenChangeId){
    return async dispatch => {
        try {
            dispatch(request());
            let screenChangeDetails = await releaseService.getScreenChangeDetail(screenChangeId);
            dispatch(success(screenChangeDetails));
        } catch(e){
            console.error(e);
            dispatch(alertActions.error("Could not load the release migration.") );
            dispatch(failure(e.message));            
        }        
    }

    function request() { return { type: releaseConstants.GET_RELEASE_SCREEN_DETAILS_REQUEST } }
    function success(screenChangeDetails) { return { type: releaseConstants.GET_RELEASE_SCREEN_DETAILS_SUCCESS, screenChangeDetails } }
    function failure(error) { return { type: releaseConstants.GET_RELEASE_SCREEN_DETAILS_FAILURE, error } }
}


function createNewReleaseMigration(releaseId, scriptName, sqlScript, rollbackScript){
    return async dispatch => {
        try {
            dispatch(request());
            await releaseService.createNewReleaseMigration(releaseId, scriptName, sqlScript, rollbackScript);
            dispatch(success());
        } catch(e){
            console.error(e);
            dispatch(alertActions.error("Could create the new release migration.") )
            dispatch(failure(e.message))
            
        }
        
    }

    function request() { return { type: releaseConstants.NEW_RELEASE_MIGRATION_REQUEST } }
    function success() { return { type: releaseConstants.NEW_RELEASE_MIGRATION_SUCCESS } }
    function failure(error) { return { type: releaseConstants.NEW_RELEASE_MIGRATION_FAILURE, error } }
}

function createNewReleaseScreen(releaseId, fileName, contents){
    return async dispatch => {
        try {
            dispatch(request());
            await releaseService.createNewScreenChange(releaseId, fileName, contents);
            dispatch(success());
        } catch(e){
            console.error(e);
            dispatch(alertActions.error("Could create the new release migration.") )
            dispatch(failure(e.message))
            
        }
        
    }

    function request() { return { type: releaseConstants.NEW_RELEASE_SCREEN_REQUEST } }
    function success() { return { type: releaseConstants.NEW_RELEASE_SCREEN_SUCCESS } }
    function failure(error) { return { type: releaseConstants.NEW_RELEASE_SCREEN_FAILURE, error } }
}

function deleteReleaseMigration(dbMigrationId){
    return async dispatch => {
        try {
            dispatch(request());
            await releaseService.deleteReleaseMigration(dbMigrationId);
            dispatch(success());
        } catch(e){
            console.error(e);
            dispatch(alertActions.error("Could not delete this release migration.") )
            dispatch(failure(e.message))
            
        }
        
    }

    function request() { return { type: releaseConstants.DELETE_RELEASE_MIGRATION_REQUEST } }
    function success() { return { type: releaseConstants.DELETE_RELEASE_MIGRATION_SUCCESS } }
    function failure(error) { return { type: releaseConstants.DELETE_RELEASE_MIGRATION_FAILURE, error } }
}

function deleteReleaseScreen(screenChangeId){
    return async dispatch => {
        try {
            dispatch(request());
            await releaseService.deleteScreenChange(screenChangeId);
            dispatch(success());
        } catch(e){
            console.error(e);
            dispatch(alertActions.error("Could not delete this release migration.") )
            dispatch(failure(e.message))
            
        }
        
    }

    function request() { return { type: releaseConstants.DELETE_RELEASE_SCREEN_REQUEST } }
    function success() { return { type: releaseConstants.DELETE_RELEASE_SCREEN_SUCCESS } }
    function failure(error) { return { type: releaseConstants.DELETE_RELEASE_SCREEN_FAILURE, error } }
}


function reorderReleaseMigrations(reorderedMigrations){
    return async dispatch => {
        try {
            dispatch(request());
            await releaseService.reorderReleaseMigrations(reorderedMigrations);
            dispatch(success());
        } catch(e){
            console.error(e);
            dispatch(alertActions.error("Could not reorder these release migrations.") )
            dispatch(failure(e.message))
            
        }
        
    }

    function request() { return { type: releaseConstants.REORDER_RELEASE_MIGRATIONS_REQUEST } }
    function success() { return { type: releaseConstants.REORDER_RELEASE_MIGRATIONS_SUCCESS } }
    function failure(error) { return { type: releaseConstants.REORDER_RELEASE_MIGRATIONS_FAILURE, error } }
}

function reorderReleaseScreens(reordedScreens){
    return async dispatch => {
        try {
            dispatch(request());
            await releaseService.reorderScreenChanges(reordedScreens);
            dispatch(success());
        } catch(e){
            console.error(e);
            dispatch(alertActions.error("Could not reorder these release screens.") )
            dispatch(failure(e.message))
            
        }
        
    }

    function request() { return { type: releaseConstants.REORDER_RELEASE_SCREENS_REQUEST } }
    function success() { return { type: releaseConstants.REORDER_RELEASE_SCREENS_SUCCESS } }
    function failure(error) { return { type: releaseConstants.REORDER_RELEASE_SCREENS_FAILURE, error } }
}

function rollbackRelease(releaseId, rollbackType){
    return async dispatch => {
        try {
            dispatch(request());
            await releaseService.rollbackRelease(releaseId, rollbackType)
            dispatch(success());
        } catch(e){
            console.error(e);
            dispatch(failure(e.message))
            
        }
        
    }

    function request() { return { type: releaseConstants.ROLLBACK_RELEASE_REQUEST } }
    function success() { return { type: releaseConstants.ROLLBACK_RELEASE_SUCCESS } }
    function failure(error) { return { type: releaseConstants.ROLLBACK_RELEASE_FAILURE, error } }
}