import { resetPasswordConstants } from '../_constants';

const initialState = {
    validatingKey: false, 
    requestingReset: false, 
    keyValid: false, 
    errorCode: null, 
    passwordResetSuccess: false
}

export function resetPassword(state = initialState, action) {
    let newState = {...state};
    switch (action.type) {
        case resetPasswordConstants.VALIDATE_RESET_KEY_REQUEST:
            newState.validatingKey = true;
            newState.passwordResetSuccess = false;
            newState.keyValid = false;
            newState.errorCode = null;
            return newState;
        case resetPasswordConstants.VALIDATE_RESET_KEY_SUCCESS:
            newState.validatingKey = false;
            newState.keyValid = true;
            return newState;
        case resetPasswordConstants.VALIDATE_RESET_KEY_FAILURE:
            newState.validatingKey = false;
            newState.keyValid = false;
            newState.errorCode = action.error;
            return newState;
        case resetPasswordConstants.RESET_PASSWORD_REQUEST:
            newState.requestingReset = true;
            return newState;
        case resetPasswordConstants.RESET_PASSWORD_SUCCESS:
            newState.requestingReset = false;
            newState.passwordResetSuccess = true;
            return newState;
        case resetPasswordConstants.RESET_PASSWORD_FAILURE:
            newState.requestingReset = false;
            newState.passwordResetSuccess = false;
            newState.errorCode = action.error;
            return newState;
        default:
            return state
    }
}