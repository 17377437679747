import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clientActions } from '../../../_actions';
import CssBaseline from '@mui/material/CssBaseline';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
//import { CustomAlert } from '../../components';
import {Alert, Button, Typography} from '@mui/material';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { getClientList } from '../../../_helpers';
//import { alertActions } from '../../_actions';
import { PrivateLayout } from "../../../components/PrivateLayout";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import '@inovua/reactdatagrid-community/index.css'
import moment from "moment";
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter'

window.moment = moment;

const TableCellHeader = styled(TableCell)({
    fontWeight: 'bold',
    padding: 15,
    backgroundColor: '#e8e4e4'
});

const gridStyle = { minHeight: 600, width: '100%' }

function ClientListPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isClientTrial, setIsClientTrial] = useState('false');
    const [loading, setLoading] = useState(true);
    const [activeClientList, setActiveClientList] = useState([]);
    const [trialClientList, setTrialClientList] = useState([]);

    useEffect(() => { 
        getAndSetClientLists();
    }, []);

    const activeClientColumns = [
        { name: 'companyName', header: 'Company Name', defaultFlex: 3, enableColumnFilterContextMenu: true},
        {
            name: 'nonStandard',
            header: 'Non Standard',
            defaultFlex: 2,
            //enableColumnFilterContextMenu: true,
            render: ({ value }) => (
                <input type="checkbox" checked={value} disabled />
            ),
            filterEditor: SelectFilter,
            filterEditorProps: {
                multiple: false,
                wrapMultiple: false,
                dataSource: [{id: null, label: "All"},{id: true, label: "Y"},{id: false, label: "N"}].map(c => {
                    return { id: c.id, label: c.label}
                }),
            }
        },
        { name: 'azzierHost', header: 'Site' , defaultFlex: 3, enableColumnFilterContextMenu: true},
        { name: "subscriptionSource", header: 'Sub', defaultFlex: 1},
        { name: "seed", header: "Seed?", defaultFlex: 0},
        { name: 'startDate', header: 'Start Date', defaultFlex: 3,
            dateFormat: 'll LT',
            filterEditor: DateFilter,
            filterEditorProps: (props, { index }) => {
                // for range and notinrange operators, the index is 1 for the after field
                return {
                    dateFormat: 'll LT',
                }
            },
            render: ({ value, cellProps: { dateFormat } }) =>
                moment(value).format(dateFormat)
        },
        { name: 'companyId', header: '',
            render: ({data: {companyId}}) => {
                return <span><Button variant="contained" color="primary" onClick={() => navigate(`/clients/${companyId}`)}>Details</Button></span>
            }
        }
    ]

    const filterValue = [
        { name: 'companyName', operator: 'contains', type: 'string', value: '' },
        { name: 'nonStandard', operator: 'eq', type: 'select', value: null },
        { name: 'azzierHost', operator: 'contains', type: 'string', value: '' },
        { name: 'subscriptionSource', operator: 'contains', type: 'string', value: '' },
        { name: 'startDate', operator: 'after', type: 'date', value: '' },
        { name: 'endDate', operator: 'after', type: 'date', value: '' }
    ];

    const trialClientColumns = [
        { name: 'companyName', header: 'Company Name', defaultFlex: 3, enableColumnFilterContextMenu: true },
        { name: 'azzierHost', header: 'Site' , defaultFlex: 3, enableColumnFilterContextMenu: true },
        { name: "subscriptionSource", header: 'Sub', defaultFlex: 1},
        { name: 'startDate', header: 'Start Date', defaultFlex: 3,
            dateFormat: 'll LT',
            filterEditor: DateFilter,
            filterEditorProps: (props, { index }) => {
                // for range and notinrange operators, the index is 1 for the after field
                return {
                    dateFormat: 'll LT',

                }
            },
            render: ({ value, cellProps: { dateFormat } }) =>
                moment(value).format(dateFormat)
        },
        { name: 'endDate', header: 'Trial End Date', defaultFlex: 3,
            dateFormat: 'll LT',
            filterEditor: DateFilter,
            filterEditorProps: (props, { index }) => {
                // for range and notinrange operators, the index is 1 for the after field
                return {
                    dateFormat: 'll LT',
                }
            },
            render: ({ value, cellProps: { dateFormat } }) =>
                moment(value).format(dateFormat)
        },
        { name: 'companyId', header: '',
            render: ({data: {companyId}}) => {
                return <span><Button variant="contained" color="primary" onClick={() => navigate(`/clients/${companyId}`)}>Details</Button></span>
            }}
    ]
    const getAndSetClientLists = async () => {
        setLoading(false);
        const clientList = await getClientList();
        console.log('Client list front end: ' + JSON.stringify(clientList))
        if (clientList != null){
            setActiveClientList(clientList.filter(c => !c.trial));
            setTrialClientList(clientList.filter(c => c.trial));
        }
        setLoading(false);
    };

    const handleChange = (event, newValue) => {
        console.log(newValue);
        setIsClientTrial(newValue);
    };

    return (
      <PrivateLayout>
        <Container maxWidth="xl">
        <CssBaseline />
            <Typography variant="h3">Tero Client List</Typography>

            {loading ? (
                <CircularProgress/>
            ): (
                <Grid container>
                    <Grid item xs={12}>
                        <Alert severity={"info"}>Sub: T = Tero Managed, C = Chargebee Managed</Alert>
                    </Grid>
                    <Grid item xs={8} sx={{mt:2}}>
                        <TabContext value={isClientTrial}>
                            <Grid sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label='Active Clients' value='false' />
                                    <Tab label='Trials' value='true' />
                                </TabList>
                            </Grid>
                        </TabContext>
                    </Grid>
                    <Grid item xs={4} sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', padding:4 }}>
                        <Button variant="contained" color="primary" onClick={() => navigate(`/clients/new`)}>New Client</Button>
                    </Grid>
                    <Grid xs={12}>
                        <ReactDataGrid
                            enableFiltering={true}
                            defaultFilterValue={filterValue}
                            idProperty="companyId"
                            columns={isClientTrial === 'true' ? trialClientColumns : activeClientColumns}
                            dataSource={isClientTrial === 'true' ? trialClientList : activeClientList}
                            style={gridStyle}
                        />
                    </Grid>
                </Grid>
            )}
        </Container>
  </PrivateLayout>
    );
  }
  
export { ClientListPage };