import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clientActions, userActions } from '../../../_actions';
import CssBaseline from '@mui/material/CssBaseline';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
//import { CustomAlert } from '../../components';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { getClientList, getCountryList, getStateList } from '../../../_helpers';
//import { alertActions } from '../../_actions';
import { PrivateLayout } from "../../../components/PrivateLayout";
import FormBox from '../../../components/formBox';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getValue } from '@testing-library/user-event/dist/utils';

const TableCellHeader = styled(TableCell)({
    fontWeight: 'bold',
    padding: 15,
    backgroundColor: '#e8e4e4'
});
const validationSchema = Yup.object().shape({
  newPassword: Yup.string()
      .required('New Password is required')
      .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, "Password must be at least 8 characters and must contain an upper case letter, lower case letter and a number")
      .max(100, 'Password must not exceed 100 characters'),
  confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('newPassword'), null], 'Confirm Password does not match'),
});

const FullWidthTextField = styled(TextField)({
  width: '100%'
})

const SaveButton = styled(Button)({
  marginTop: 15,
  marginRight: 15
});


function ChangePasswordPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
 
  const { control, handleSubmit, register, formState: { errors, submitCount }, getValues, setValue, trigger, reset} = useForm({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = (data) => {
    dispatch(userActions.changePassword(data.newPassword));
    navigate('/profile');
  }

  return (
      <PrivateLayout>
        <Container maxWidth='md'>
        <CssBaseline />
           
          <form onSubmit={handleSubmit(onSubmit)}>
          <FormBox title={`Change Password`}  >
            <Box>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, 1fr)',
                  gap: "15px",
                  gridTemplateRows: 'auto',
                  p:2
                }}
                >
                <Box sx={{ gridColumnStart:1, gridColumnEnd: 4}}>
                  Please enter your new password below and submit the form to change your password.
                </Box>
                <Box>
                  <Controller
                      name="newPassword"
                      control={control}
                      inputRef={register()}
                      render={({ field }) =>
                          <FullWidthTextField label="New Password*" type="password" variant="outlined" margin="normal" {...field} error={errors.newPassword ? true : undefined} 
                            helperText={errors.newPassword?.message}/>
                      }
                  />
                </Box>
                <Box>
                  <Controller
                      name="confirmPassword"
                      control={control}
                      inputRef={register()}
                      render={({ field }) =>
                          <FullWidthTextField label="Confirm Password*" type="password" variant="outlined" margin="normal" {...field} error={errors.confirmPassword ? true : undefined} 
                            helperText={errors.confirmPassword?.message}/>
                      }
                  />
                </Box>
                
                
                <Box>
                  <SaveButton                                  
                              color="primary"
                              type="submit"
                              variant="contained">Update Password</SaveButton>

                </Box>
              </Box>
            </Box>
          </FormBox>
          </form>
          
            
       
    </Container>
  </PrivateLayout>
    );
  }
  
export { ChangePasswordPage };