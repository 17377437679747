import { useEffect } from "react";
import { licenseTemplateActions } from "../../../_actions/licenseTemplate.actions";
import { useDispatch, useSelector } from "react-redux";
import LicenseTemplateList from "./LicenseTemplateList";
import { Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PrivateLayout } from "../../../components/PrivateLayout";
import { Box, Container, Stack } from "@mui/system";

function LicenseTemplatesPage() {
  let dispatch = useDispatch();
  var licTemplList = useSelector((state) => state.licenseTemplates.templateList)
  let navigate = useNavigate();
  useEffect(() =>{
    dispatch(licenseTemplateActions.getList());
  },[])

  const onDuplicateLicense = (licenseTemplateId) => {
    dispatch(licenseTemplateActions.duplicateLicense(licenseTemplateId));
  }
  return (
      <PrivateLayout>
        <Container maxWidth="xl">
        
          <Stack spacing={2}>
            <Typography variant='h3'>License Templates</Typography>
            <Box><Button onClick={() => navigate('/licenses/new')}>New License Template</Button></Box>
            <LicenseTemplateList licenseTemplates={licTemplList} onDuplicateLicense={onDuplicateLicense}/>
          
          </Stack>

        </Container>
      </PrivateLayout>
    );
  }
  
export { LicenseTemplatesPage };