import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetPasswordActions } from '../../../_actions';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { useForm, Controller } from "react-hook-form";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Typography } from '@mui/material';
import { PublicLayout } from '../../../components/PublicLayout';


const validationSchema = Yup.object().shape({
   
    newPassword: Yup.string()
        .required('Password is required')
        .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, "Password must be at least 8 characters and must contain an upper case letter, lower case letter and a number")
        .max(100, 'Password must not exceed 100 characters'),
    newPasswordConfirm: Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('newPassword'), null], 'Confirm Password does not match')
});

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function ResetPasswordPage() {
    let { key } = useParams();
    const navigate = useNavigate();
    let query = useQuery();
    const { control, handleSubmit, register, formState: { errors }} = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = data => {
        dispatch(resetPasswordActions.resetPassword(data.newPassword, data.newPasswordConfirm, key));
    };

    const dispatch = useDispatch();
    const resetPasswordState = useSelector( state => state.resetPassword);
    if (resetPasswordState.passwordResetSuccess) {
            navigate("/");
    }

    useEffect(() => { 
        dispatch(resetPasswordActions.validateResetKey(key));
    }, []);

    return (
        <PublicLayout>
            <Container maxWidth='md'>
                <CssBaseline />
                {resetPasswordState.validatingKey || resetPasswordState.requestingReset ? <CircularProgress/>:
                    <Grid 
                        container
                        spacing={10}
                        alignItems="left"
                        justifyContent="left"
                        style={{ minHeight: '50vh' }}
                    >
                        <Grid item direction='column'>
                            <h1>Reset Password</h1>
                            
                            {!resetPasswordState.keyValid? 
                                <Alert severity="error">Sorry the password reset link you have used is invalid.  Please request another password reset link.</Alert> :
                                <>
                                    <p>Please enter your new password below.</p>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                    <Grid container spacing={2} direction="column">
                                        <Grid item lg={12}>
                                            <Controller
                                            name="newPassword"
                                            control={control}
                                            inputRef={register()}
                                            defaultValue=""
                                            render={({ field }) => 
                                                <TextField fullWidth label="New Password*" type="password" {...field} error={errors.newPassword ? true : false}/>}
                                            />
                                            <Grid item lg={12}>
                                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                        {errors.newPassword?.message}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        
                                        <Grid item lg={12}>
                                            <Controller
                                                name="newPasswordConfirm"
                                                control={control}
                                                defaultValue=""
                                                inputRef={register()}
                                                render={({ field }) => 
                                                    <TextField fullWidth type="password" label="Confirm  Password*" {...field} error={errors.newPasswordConfirm ? true : false} />
                                                }
                                            />
                                            <Grid item lg={12}>
                                                <Typography variant="inherit" color="error">
                                                    {errors.newPasswordConfirm?.message}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        
                                    </Grid>
                                    
                                    <Grid container xs={12} sm={12} direction='row'>
                                        <Button variant="contained" style={{marginTop:20, marginRight:10}} color="primary" type="submit">
                                            Update Password
                                        </Button>
                                        <Button color="secondary" href="/" variant="contained" style={{marginTop:20}}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                        
                                    </form>
                                </>
                            }
                        </Grid>  
                    </Grid>
                }
            </Container>
        </PublicLayout>
    );
}

export { ResetPasswordPage };