import { userConstants } from '../_constants';
import { userService } from '../_services';
//import { alertActions } from './';
import { history } from '../_helpers';
import { alertActions } from './alert.actions';

export const userActions = {
    login,
    logout,
    getProfile,
    saveProfile,
    changePassword
};

function login(username, password, from) {
    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then(
                user => { 
                        let decodedUserJWT = user[0];
                        let encodedUserJWT = user[1];
                        let refreshToken = user[2];
                        localStorage.setItem('user', JSON.stringify(decodedUserJWT));
                        localStorage.setItem('jwtBearer', JSON.stringify(encodedUserJWT));
                        localStorage.setItem('refreshToken', JSON.stringify(refreshToken));
                        dispatch(success(decodedUserJWT));
                        //history.push(from);
                        document.location.href = '/';
                },
                error => {
                    dispatch(failure("Incorrect email/password"));
                    //dispatch(alertActions.error("Incorrect email/password"));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function getProfile(){
    return async dispatch => {
        try {
            dispatch(request());
            let profile = await userService.getUserProfile();
            dispatch(success(profile));
        } catch(e){
            console.error(e);
            dispatch(alertActions.error("Could not get profile details"))
            dispatch(error(e));
        }
        
    }

    function request() { return { type: userConstants.GET_USER_PROFILE_REQUEST } }
    function success(userProfile) { return { type: userConstants.GET_USER_PROFILE_SUCCESS, userProfile } }
    function error(error) { return { type: userConstants.GET_USER_PROFILE_FAILURE, error } }
}

function saveProfile(userProfile) {
    return async dispatch => {
        try {
            dispatch(request());
            await userService.saveUserProfile(userProfile);
            dispatch(success());
            dispatch(alertActions.success("User profile saved."));
        } catch(e){
            console.error(e);
            dispatch(alertActions.error("Could not save profile details"))
            dispatch(error(e));
        }
        
    }

    function request() { return { type: userConstants.SAVE_USER_PROFILE_REQUEST } }
    function success() { return { type: userConstants.SAVE_USER_PROFILE_SUCCESS } }
    function error(error) { return { type: userConstants.SAVE_USER_PROFILE_FAILURE, error } }
}


function changePassword( newPassword ) {
    return async dispatch => {
        dispatch(request());
        try {
            await userService.changePassword(newPassword);
            dispatch(success());
            dispatch(alertActions.success("Password changed!"));
        } catch (e){
            dispatch(failure(e));
            dispatch(alertActions.error("Error: Could not change password."));
            return Promise.reject();
        }
        
        
    };

    function request() { return { type: userConstants.CHANGE_PASSWORD_REQUEST } }
    function success() { return { type: userConstants.CHANGE_PASSWORD_SUCCESS } }
    function failure(error) { return { type: userConstants.CHANGE_PASSWORD_FAILURE, error } }
}