import React, { useState, useEffect } from 'react';
import { Controller, useForm } from "react-hook-form";
import {Link, useNavigate, useParams} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import {Button, TextField, Typography, FormControl, InputLabel, Select, MenuItem, FormHelperText, TextareaAutosize} from '@mui/material';
import { styled } from '@mui/material/styles';
import { PrivateLayout } from "../../../components/PrivateLayout";
import CircularProgress from '@mui/material/CircularProgress';
import ReactDataGrid from "@inovua/reactdatagrid-community";
import { useSelector, useDispatch } from 'react-redux';
import { seedManagementActions } from "../../../_actions";
import { industryManagementActions } from "../../../_actions";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const SaveButton = styled(Button)({
    marginTop: 15,
    marginRight: 15
});

const CancelButton = styled(Button)({
    marginTop: 15,
    marginRight: 15
});

const SelectFormControl = styled(FormControl)({
    marginTop: 16,
    width:"100%"
  });

const ResizingTextArea = styled(TextField)({
    resize: "both"
});

function IndustryDetailPage () {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { industryId } = useParams()
    const seedList = useSelector(state=>state.seedManagement.seedList ? state.seedManagement.seedList : null)
    const industryList = useSelector(state=>state.industryManagement.industryList ? state.industryManagement.industryList : null)
    const industry = useSelector(state=> {
        let selectedIndustry = {}
        if(state.industryManagement.industryList){
            state.industryManagement.industryList.forEach(i=>{
                if(i.id == industryId){
                    selectedIndustry = i
                }
            })
        }
        return selectedIndustry
    })
    let saving = useSelector((state) => state.industryManagement.savingIndustryDetails);
    const [loadingSeedList, setLoadingSeedList] = useState(false);

    useEffect(() => { 
        dispatch(seedManagementActions.getSeedList());
    }, []);

    const validationSchema = Yup.object().shape({
        industryName: Yup.string()
            .required('You must enter a industry name')
            .min(1, 'The industry name can not be empty')
            .max(100, 'The industry name must not exceed 100 characters')
            .test("non-duplicate", "The duplicate industry name(case insensitive) is not allowed", (industryName)=>{
                return !isIndustryNameDuplicate(industryName)
            })
    });

    const handleIndustryChange = (event, field) => {
        field.onChange(event)
    }

    const isIndustryNameDuplicate = (industryName)=>{
        let filtered = industryList.filter(i=>i.industryName.toLowerCase() !== (industry.id ? industry.industryName.toLowerCase() : null))
        filtered = filtered.filter(i=>i.industryName.toLowerCase() === industryName.toLowerCase())
        return filtered.length > 0
    }

    const { control, handleSubmit, register, formState: { errors }} = useForm({
        mode: "onChange",
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = async data => {
        let updatedIndustry = industry.id ? {...industry} : {}
        updatedIndustry.industryName = data.industryName
        updatedIndustry.description = data.description
        updatedIndustry.deploymentSeedId = data.deploymentSeedId
        //deploymentSeedName is not useful for the backend operations here
        updatedIndustry.deploymentSeedName = ""
        if(industry.id){
            //Edit seed
            await dispatch(industryManagementActions.saveIndustryDetails(updatedIndustry))
            navigate("/industrymanagement");
        } else {
            //New seed
            await dispatch(industryManagementActions.addIndustry(updatedIndustry))
            navigate("/industrymanagement");
        }
    };

    return (
        <PrivateLayout>
            <Container style={{paddingBottom:20}}>
            <CssBaseline />
                <Typography variant="h3">{industry.id ? "Industry: "+industry.industryName : "New Industry" }</Typography>
                <br/>
                {loadingSeedList ? (
                    <CircularProgress/>
                ): (
                    <form onSubmit={handleSubmit(onSubmit)} style={{width: '100%'}}>
                    <Grid container style={{marginTop: 20}} spacing={2}>
                        <Grid item xs={12} sm={6}>
                                <Controller
                                    name="industryName"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue={industry.industryName}
                                    render={({ field }) => 
                                        <TextField variant="outlined" fullWidth label="Industry Name*" margin="normal" {...field} error={errors.industryName ? true : false}
                                            helperText={errors.industryName?.message} inputProps={{maxLength: 100}}/>
                                        
                                    }
                                />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SelectFormControl style={{marginTop: 16}} variant="outlined">
                                <InputLabel htmlFor="deploymentSeedId">Deployment Seed Name </InputLabel>
                                <Controller
                                    name="deploymentSeedId"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue={industry.id ? industry.deploymentSeedId : ""}
                                    render={({field})=><Select label="Deployment Seed Name" id="deploymentSeedId" labelId="lblDeploymentSeed" 
                                        defaultValue={industry.id ? industry.deploymentSeedId : ""} {...field}
                                        onChange={(e)=>handleIndustryChange(e, field)} error={errors.industryName ? true : undefined}>
                                            {
                                                seedList?.map(s => (
                                                    <MenuItem key={s.id} value={s.id}>
                                                        {s.seedName}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>}
                                />
                                <FormHelperText error={errors.seedName ? true : undefined}>{errors.seedName?.message}</FormHelperText>
                            </SelectFormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Controller
                                name="description"
                                control={control}
                                inputRef={register()}
                                defaultValue={industry.description}
                                render={({ field }) => 
                                    <ResizingTextArea variant="outlined" id="description" fullWidth label="Industry Description" multiline minRows={5} maxRows={10} margin="normal" {...field} 
                                    error={errors.description ? true : false}
                                    helperText={errors.description?.message} />}
                            />
                        </Grid>
                    </Grid>
                    <br /><br />
                    {!saving && <SaveButton
                        color="primary"
                        type="submit"
                        variant="contained"
                    > 
                        Save Changes
                    </SaveButton>}
                    {saving && <CircularProgress />}
                    <Link to="/industrymanagement" style={{ textDecoration: 'none' }}>
                        <CancelButton
                            color="secondary"
                            type="button"
                            variant="contained"
                        > 
                            Cancel
                        </CancelButton>
                    </Link>
                </form> 
                )}
            </Container>
        </PrivateLayout>
    )
}

export {IndustryDetailPage};