import { List, ListItem, ListItemButton, ListItemText, ListSubheader, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Link as RouterLink,
    Route,
    Routes,
    useLocation,
    useParams,
    useNavigate,
  } from 'react-router-dom';
import { clientActions, companyLicenseActions } from '../_actions';
import {customerSubscriptionService} from "../_services/customerSubscription.service";


function CustomerSideBar(){
    let params = useParams();
    const navigate = useNavigate();
    console.log("Params:", params);
    let location = useLocation();
    let currentLicense = useSelector(state => state.licenseTemplates.currentLicenseTemplate);
    const [hasSubscriptionId, setHasSubscriptionId] = useState(false);
    let currentCompany = useSelector(state => state.companyLicenses.selectedCompany);
    let user = useSelector(state => state.authentication.user);
    const dispatch = useDispatch();

    useEffect(() => {
        customerSubscriptionService.getLicenses().then((licenses) =>{
            if (licenses && licenses.length > 0){
                let subId = licenses[0].subscriptionId;
                setHasSubscriptionId(subId !== null && subId !== '');
                console.log("SubID: ", subId);
            }
            console.log("user.id=", user.IdNumber)
        });
    },[user.IdNumber]);
    useEffect(() =>{
        if (params.companyId !== null && params.companyId !== undefined && currentCompany === null){
            dispatch(companyLicenseActions.getCompanyLicenses(params.companyId));
        }
    },[params.companyId]);
    
    function changingPassword() {
        const regex = new RegExp(/^\/profile\/changepassword$/g);
        return regex.test(location.pathname);
    }

    
    return (
        <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            >
            <ListItemButton onClick={()=>{navigate(`/`)}} selected={ location.pathname === '/'}>
                <ListItemText primary="Home" />
            </ListItemButton>
            <ListItemButton onClick={()=>{navigate(`/snapshots`)}} selected={location.pathname === '/snapshots'}>
                <ListItemText primary="Snapshots" />
            </ListItemButton>
            {hasSubscriptionId && (
                <ListItemButton onClick={()=>{navigate(`/billing`)}} selected={location.pathname === '/billing'}>
                    <ListItemText primary="Billing" />
                </ListItemButton>)
            }

            
            <ListItemButton onClick={()=>{navigate(`/profile`)}} selected={location.pathname === '/profile'}>
                <ListItemText primary="User Profile" />
            </ListItemButton>
            {changingPassword() && (
                <List component="div" > 
                    <ListItem selected={true} sx={{ pl: 6 }}>
                        Change Password
                    </ListItem>
                </List>
            )}
            <ListItemButton onClick={()=>{navigate(`/login`)}}  selected={location.pathname === '/login'}>
                <ListItemText primary="Log Out" />
            </ListItemButton>
            
        </List>
    );
}

export default CustomerSideBar;