import axios from '../_helpers/axiosInstance';
import { authHeader } from '../_helpers/authHeader';

export const activateTrialService = {
    activateTrial,
    trialSignUp,
    hostAvailable,
    usernameAvailable,
    resendTrialActivation
};

const API_URL = process.env.REACT_APP_API_ENDPOINT;

async function trialSignUp(trialSignUpData) {
    console.log('Trial Sign Up Data: ' + JSON.stringify(trialSignUpData));
    let formData = new FormData();
    formData.append('CompanyName', trialSignUpData.companyName);
    formData.append('CompanyEmailContact', trialSignUpData.adminEmail);
    formData.append('CompanyPhoneContact', trialSignUpData.adminPhoneNumber);
    formData.append('CompanyAddress', trialSignUpData.companyAddress);
    formData.append('CompanyCountry', trialSignUpData.companyCountry);
    formData.append('CompanyStateProv', trialSignUpData.companyStateProv);
    formData.append('CompanyCity', trialSignUpData.companyCity);
    formData.append('CompanyPostalCode', trialSignUpData.companyPostalCode);
    formData.append('IndustryId', trialSignUpData.industryId);//trialSignUpData.companyIndustry);
    formData.append('FirstName', trialSignUpData.firstName);
    formData.append('LastName', trialSignUpData.lastName);
    formData.append('Domain', trialSignUpData.companyDomain);
    formData.append('Password', trialSignUpData.password);
    formData.append('SubscriptionPlanId', trialSignUpData.subscriptionPlanId ?? "");


    return await axios.post('/AzzierTrial/azziertrialsignup', formData, {headers: {'content-type': 'multipart/form-data', ...authHeader()}, });
    
}

function activateTrial(activationKey){
    console.log('About to activate trial with activation key: ' + activationKey);

    const requestOptions = {
        method: 'PUT',
        headers: {'Content-Type': 'application/json' },
        body: JSON.stringify({ActivationKey:activationKey})
    };

    return fetch(`${API_URL}/AzzierTrial/activateTrial`, requestOptions);
}

function resendTrialActivation(hostName){
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json' }
    };

    return fetch(`${API_URL}/AzzierTrial/resendTrialActivation/${hostName}`, requestOptions);
}

async function hostAvailable(hostName){
    console.log("About to check host :" + hostName);

    const requestOptions = {
        method: "GET"
    }
    var response = await fetch(`${API_URL}/AzzierTrial/available/${hostName}`);
    if (response.ok){
        var apiResponse = await response.json(); 
        return apiResponse.result;  
    } else {
        return false;
    }
    
}

async function usernameAvailable(username){
    console.log("About to check username :" + username);

    const requestOptions = {
        method: "GET"
    }
    var response = await fetch(`${API_URL}/AzzierTrial/user/available?username=${username}`);
    if (response.ok){
        var apiResponse = await response.json(); 
        return apiResponse.result;  
    } else {
        return false;
    }    
}