import axios from '../_helpers/axiosInstance';
import { authHeader } from '../_helpers/authHeader';

export const hostService = {
    getHostList,
    getDetails,
    saveDetails,
    deleteHost,
    createHost
};

async function getHostList() {
    console.log('About to get list of hosts');
  
    var response = await axios.get('/ProvisionHost', { headers: authHeader() });

    return response.data.result;
}

async function getDetails(id) {
    console.log("about to get Host details");
    var response = await axios.get(`/ProvisionHost/${id}`, {headers: authHeader()});
    return response.data.result;
}

async function saveDetails(hostDetails){
    await axios.put(`/ProvisionHost`,hostDetails, {headers: authHeader()});
}

async function createHost(hostDetails){
    await axios.post(`/ProvisionHost`,hostDetails, {headers: authHeader()});
}

async function deleteHost(id){
    var response = await axios.delete(`/ProvisionHost/${id}`, {headers: authHeader()});
    return response.data.result;
}
