import { industryManagementConstants } from '../_constants';

const initialState = { 
    industryList: null,
    loadingIndustryList: false,
    error: null
};

export function industryManagement(state = initialState, action) {
    switch (action.type) {
        case industryManagementConstants.GET_INDUSTRY_LIST_REQUEST:
            return {
                ...state,
                loadingIndustryList: true
            };
        case industryManagementConstants.GET_INDUSTRY_LIST_SUCCESS:
            return {
                ...state,
                loadingIndustryList: false,
                industryList: action.industryList
            };
        case industryManagementConstants.GET_INDUSTRY_LIST_FAILURE:
            return {
                ...state,
                loadingIndustryList: false,
            };
        case industryManagementConstants.SAVE_INDUSTRY_DETAILS_REQUEST:
            return {
                ...state,
                savingIndustryDetails: true
            };
        case industryManagementConstants.SAVE_INDUSTRY_DETAILS_SUCCESS:
            return {
                ...state,
                savingIndustryDetails: false
            };
        case industryManagementConstants.SAVE_INDUSTRY_DETAILS_FAILURE:
            return {
                ...state,
                savingIndustryDetails: false,
                errorMessage: action.error
            };
        case industryManagementConstants.SAVE_INDUSTRIES_REQUEST:
            return {
                ...state,
                savingIndustries: true
            };
        case industryManagementConstants.SAVE_INDUSTRIES_SUCCESS:
            return {
                ...state,
                savingIndustries: false
            };
        case industryManagementConstants.SAVE_INDUSTRIES_FAILURE:
            return {
                ...state,
                savingIndustries: false,
                errorMessage: action.error
            };
        case industryManagementConstants.ADD_INDUSTRY_REQUEST:
            return {
                ...state,
                addingIndustry: true
            };
        case industryManagementConstants.ADD_INDUSTRY_SUCCESS:
            return {
                ...state,
                //We'd better update store state here too although the industry list page will be reloaded
                //In order to update store here, action should have the new industry
                addingIndustry: false
            };
        case industryManagementConstants.ADD_INDUSTRY_FAILURE:
            return {
                ...state,
                addingIndustry: false,
                errorMessage: action.error
            };
        case industryManagementConstants.DELETE_INDUSTRY_REQUEST:
            return {
                ...state,
                deletingIndustry: true
            };
        case industryManagementConstants.DELETE_INDUSTRY_SUCCESS:
            return {
                ...state,
                industryList: removeIndustry(state, action.industryId),
                deletingIndustry: false
            };
        case industryManagementConstants.DELETE_INDUSTRY_FAILURE:
            return {
                ...state,
                deletingIndustry: false,
                errorMessage: action.error
            };
        default:
            return state
    }

    function removeIndustry(state, industryId){
        let newIndustryList = [...state.industryList]
        return newIndustryList.filter(industry=>industry.id != industryId)
    }
}