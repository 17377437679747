import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions, userActions } from '../../../_actions';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
//import { CustomAlert } from '../../components';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from "react-hook-form";
import { Typography } from '@mui/material';
import { userService } from '../../../_services';
import logo from '../../../_resources/Azzier_ops_logo_no_bg.png';
import { PublicLayout } from '../../../components/PublicLayout';

const validationSchema = Yup.object().shape({
   
emailAddress: Yup.string().email("Invalid email")
      .required('Email Address is required')
      .max(100, 'Email must not exceed 100 characters')
});

function ForgotPasswordPage() {
    const dispatch = useDispatch();
    const [sendingForgotPasswordEmail,setSendingForgotPasswordEmail] = useState(false);
    const { control, handleSubmit, register, formState: { errors }, reset} = useForm({
        resolver: yupResolver(validationSchema)
    });

    //const classes = useStyles();

    useEffect(() => { 

    }, []);

    function onSubmit(data, e){
        setSendingForgotPasswordEmail(true);
        userService.requestPasswordReset(data.emailAddress).then((result)=>{
            setSendingForgotPasswordEmail(false);
            dispatch(alertActions.success("Thank you, if this is a valid user id you will recieve an email shortly with a link that will allow you to reset your password."));
            reset();
            e.target.reset();
            //history.push("/");
        }).catch(function(err) {
            setSendingForgotPasswordEmail(false);
            dispatch(alertActions.error("Sorry there was a problem.  We cannot process your password reset request at this time.  Please try again later."));
            console.error(err);
            reset();
            e.target.reset();
        });
    }

    return (
    <PublicLayout>
        <Container maxWidth='md'>
            <CssBaseline />
            <Grid 
                container
                spacing={2}
                alignItems="center"
                justifyContent="center"
              
            >
                <Grid item xs={12} sm={12}>
                    
                    <Grid item style={{marginTop:20}}>
                    <Typography variant='h4'>Forgot Password</Typography>
                        <p>Please enter your email address and a link will be sent to you to reset you password</p>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Controller
                                name="emailAddress"
                                control={control}   
                                inputRef={register()}
                                defaultValue=""
                                render={({ field }) => 
                                    <Grid item lg={12}>
                                        <TextField style={{width:"50%"}} variant="outlined" margin="normal" autofocus={true} label="Email Address"  {...field} error={'emailAddress' in errors}/>
                                    </Grid>}
                                />
                                <Grid item lg={12}>
                                    <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                            {errors.emailAddress?.message}
                                    </Typography>
                                </Grid>
                            
                            {!sendingForgotPasswordEmail &&             
                                <>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    > 
                                        Request Password Reset 
                                    </Button>
                                    <Button
                                        color="secondary"
                                        href="/"
                                        variant="contained"
                                        style={{marginLeft:10}}
                                    > 
                                        Cancel
                                    </Button>
                                </>
                            }
                            {sendingForgotPasswordEmail && <CircularProgress />}
                        </form>
                    </Grid>
                    
                    
                </Grid>
                
            </Grid>
        </Container>
    </PublicLayout>
    );
  }
  
export { ForgotPasswordPage };