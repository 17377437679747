import { activateTrialConstants } from '../_constants';

const initialState = {
    errorCode: null,
}

export function activateTrial(state = initialState, action) {
    switch (action.type) {
        case activateTrialConstants.TRIAL_SIGN_UP_REQUEST:
            return { registering: true };
        case activateTrialConstants.TRIAL_SIGN_UP_SUCCESS:
            return { registering: false };
        case activateTrialConstants.TRIAL_SIGN_UP_FAILURE:
            return { };
        case activateTrialConstants.ACTIVATE_TRIAL_REQUEST:
            return { errorCode: null };
        case activateTrialConstants.ACTIVATE_TRIAL_SUCCESS:
            return { errorCode: null };
        case activateTrialConstants.ACTIVATE_TRIAL_FAILURE:
            return { errorCode: 'There was a problem activating the trial' };
        default:
            return state
    }
}