import axios from '../_helpers/axiosInstance';
import { authHeader } from '../_helpers/authHeader';

export const seedManagementService = {
    getSeedList,
    saveSeedDetails,
    addSeed,
    deleteSeed
};

async function getSeedList() {
    console.log('About to get list of seeds');
    var response = await axios.get('/seedmanagement/seedlist', { headers: authHeader() });
    return response.data.result;
}

async function saveSeedDetails(seed){
    await axios.put('/seedmanagement/seed', seed, {headers: authHeader()});
}

async function addSeed(seed) {
    console.log('About to add a new seed');
    var response = await axios.post('/seedmanagement/seed', seed, { headers: authHeader() });
    return response.data.result;
}

async function deleteSeed(seedId) {
    console.log('About to delete a seed');
    await axios.delete('/seedmanagement/seed/'+ seedId, { headers: authHeader() });
}

