export const userManagementConstants = {
    LIST_USERS_REQUEST: 'LIST_USERS_REQUEST',
    LIST_USERS_SUCCESS: 'LIST_USERS_SUCCESS',
    LIST_USERS_FAILURE: 'LIST_USERS_FAILURE',

    GET_USER_INFO_REQUEST: 'GET_USER_INFO_REQUEST',
    GET_USER_INFO_SUCCESS: 'GET_USER_INFO_SUCCESS',
    GET_USER_INFO_FAILURE: 'GET_USER_INFO_FAILURE',
    
    INVITE_USER_REQUEST: 'INVITE_USER_REQUEST',
    INVITE_USER_SUCCESS: 'INVITE_USER_SUCCESS',
    INVITE_USER_FAILURE: 'INVITE_USER_FAILURE',

    ADD_USER_FROM_INVITE_REQUEST: 'ADD_USER_FROM_INVITE_REQUEST',
    ADD_USER_FROM_INVITE_SUCCESS: 'ADD_USER_FROM_INVITE_SUCCESS',
    ADD_USER_FROM_INVITE_FAILURE: 'ADD_USER_FROM_INVITE_FAILURE',


    UPDATE_USER_INFO_REQUEST: 'UPDATE_USER_INFO_REQUEST',
    UPDATE_USER_INFO_SUCCESS: 'UPDATE_USER_INFO_SUCCESS',
    UPDATE_USER_INFO_FAILURE: 'UPDATE_USER_INFO_FAILURE',

    DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',

    LIST_INVITES_REQUEST: 'LIST_INVITES_REQUEST',
    LIST_INVITES_SUCCESS: 'LIST_INVITES_SUCCESS',
    LIST_INVITES_FAILURE: 'LIST_INVITES_FAILURE',

    DELETE_INVITE_REQUEST: 'DELETE_INVITE_REQUEST',
    DELETE_INVITE_SUCCESS: 'DELETE_INVITE_SUCCESS',
    DELETE_INVITE_FAILURE: 'DELETE_INVITE_FAILURE'
};