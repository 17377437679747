import { Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, styled } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import FormBox from "../../../components/formBox";
import { PrivateLayout } from "../../../components/PrivateLayout";
import {useDispatch, useSelector} from 'react-redux'
import { userManagementActions } from "../../../_actions";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";

const BoldLabel = styled("span")({
    fontWeight: "bold"
});

const SaveButton = styled(Button)({
    marginTop: 15,
    marginRight: 15
});

const CancelButton = styled(Button)({
    marginTop: 15,
    marginRight: 15
});

function EditUserPage(){
    const navigate = useNavigate();
    const {userId} = useParams();
    const dispatch = useDispatch();
    const userInfo = useSelector(state => state.userManagement.userInfo);
    const loggedInUserId = useSelector(state => state.authentication.user.UserID);
    const loading = useSelector(state => state.userManagement.loadingUserInfo);
    const [group, setGroup] = useState("");
    const [allowEdit, setAllowEdit] = useState(false);
    useEffect(() => {
        dispatch(userManagementActions.getUserInfo(userId));
    },[]);
    useEffect(() => {
        if (userInfo?.userId === loggedInUserId){
            setAllowEdit(false);
        } else {
            setAllowEdit(true);
        }
        if (userInfo !== null){
            setGroup(userInfo.securityGroup);
        }
    }, [userInfo, loggedInUserId])

    const saveUserInfo = async () =>{
        await dispatch(userManagementActions.updateUser(userId, group));
        navigate("/usermanagement");
    }
    console.log ("userInfo: ", userInfo)
    return (
        <PrivateLayout>
            <Container maxWidth='xl'>
                <FormBox title={"Edit User"}>
                    {loading || userInfo === null || userInfo === undefined ? <CircularProgress/> :                     
                    <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(4, 1fr)',
                        gap: "15px",
                        gridTemplateRows: 'auto',
                        p:2
                    }}
                    >
                        <Box >
                            <BoldLabel>First Name:</BoldLabel>
                        </Box>
                        <Box >
                            {userInfo?.firstName}
                        </Box>
                        <Box >
                            <BoldLabel>Last Name:</BoldLabel>
                        </Box>
                        <Box>
                            {userInfo?.lastName}
                        </Box>
                        <Box >
                            <BoldLabel>User Id:</BoldLabel>
                        </Box>
                        <Box >
                            {userInfo?.userId}
                        </Box>
                        <Box >
                            <BoldLabel>Phone Number:</BoldLabel>
                        </Box>
                        <Box>
                            {userInfo?.phoneNumber}
                        </Box>
                        {allowEdit ?
                        <>
                            <Box sx={{ gridColumnStart:1, gridColumnEnd: 3}}>
                                <FormControl fullWidth>
                                    <InputLabel id="securityGroupLabel">Security Group</InputLabel>
                                    <Select  label="Security Group" labelId="securityGroupLabel" id="group" style={{width:'100%'}} value={group} 
                                        onChange={(e) => {setGroup(e.target.value)}} >
                                        <MenuItem key={0} value="MANAGER">Manager</MenuItem>
                                        <MenuItem key={1} value="MANAGER_ADMIN">Manager Admin</MenuItem>                
                                </Select>
                                </FormControl>
                                
                            </Box>
                            <Box sx={{ gridColumnStart:3, gridColumnEnd: -1}}></Box>
                        </>
                        :
                        <>
                            <Box >
                                <BoldLabel>Security Group:</BoldLabel>
                            </Box>
                            <Box >
                                {userInfo?.securityGroup}
                            </Box>
                            <Box >
                               
                            </Box>
                            <Box>
                               
                            </Box>
                            <Box>
                            <CancelButton             
                                        onClick={() => navigate('/usermanagement')}                     
                                        color="secondary"
                                        type="button"
                                        variant="contained">Go Back</CancelButton>
                            </Box>
                        </>
                        }
                        {allowEdit && 
                        <Box sx={{ gridColumnStart:1, gridColumnEnd: 3}}>
                            <SaveButton             
                                        onClick={saveUserInfo}                     
                                        color="primary"
                                        type="button"
                                        variant="contained">Save</SaveButton>
                            <CancelButton             
                                        onClick={() => navigate('/usermanagement')}                     
                                        color="secondary"
                                        type="button"
                                        variant="contained">Cancel</CancelButton>
                        </Box>
                        }
                        
                       
                    </Box>
                    }
                    
                </FormBox>
            </Container>
        </PrivateLayout>

    )
}

export {EditUserPage};