import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { history } from './_helpers';
import { PrivateRoute } from './components';
import { HomePage } from './pages/Private/HomePage';
import { LoginPage } from './pages/Public/LogIn';
import { ForgotPasswordPage } from './pages/Public/Password';
import { TrialConfirmPage } from './pages/Public/TrialSetUp';
import { TrialSignUpPage } from './pages/Public/TrialSetUp';
import { LicenseTemplateDetailsPage, LicenseTemplatesPage, NewLicenseTemplatePage } from './pages/Private/LicenseTemplates';
import { TrialActivationPage } from './pages/Public/TrialSetUp';
import { ResetPasswordPage } from './pages/Public/Password';
import { ClientDetailsPage, ClientListPage, NewClientOnboardPage } from './pages/Private/Client';
import { LicenseDetailsPage } from './pages/Private/Client';

import { theme } from './theme';
import { ThemeProvider } from '@mui/material/styles';
import { ClientLicenseEditPage } from './pages/Private/ClientLicense';
import { StyledEngineProvider } from '@mui/material';
import SystemAlert from './components/SystemAlert';
import { UserProfilePage } from './pages/Private/User';
import { ChangePasswordPage } from './pages/Private/User/ChangePasswordPage';
import { UserListPage } from './pages/Private/UserManagement/UserListPage';
import { SeedListPage } from './pages/Private/SeedManagement/SeedListPage';
import { SeedDetailPage } from './pages/Private/SeedManagement/SeedDetailPage';
import { IndustryListPage } from './pages/Private/IndustryManagement/IndustryListPage';
import { IndustryDetailPage } from './pages/Private/IndustryManagement/IndustryDetailPage';
import { EditUserPage } from './pages/Private/UserManagement';
import { UserInvitePage } from './pages/Public/Invite';
import { NewReleaseMigration, ReleaseDetails, ReleaseMigrationDetails, ReleasesPage } from './pages/Private/Release';
import { NewRelease } from './pages/Private/Release/NewRelease';
import { useSelector } from 'react-redux';
import { CustomerHomePage, CustomerLicenseDetail, CustomerLicenses } from './pages/Private/Customer';
import { SubscriptionPage } from './pages/Private/Subscription';
import { ReleaseScreenChangeDetails } from './pages/Private/Release/ReleaseScreenChangeDetails';
import { NewReleaseScreenChange } from './pages/Private/Release/NewReleaseScreenChange';
import { SnapshotPage } from './pages/Private/Client/SnapshotPage';
import {EditProvisionHostPage, HostListPage, NewProvisionHostPage} from './pages/Private/Hosts';
import {CustomerSnapshotPage} from "./pages/Private/CustomerSnapshot/CustomerSnapshotPage";


function App() {
    var loggedInUser = useSelector(state => state.authentication.user);
    var loggedIn = useSelector(state => state.authentication.loggedIn);
    console.log("creating APP");
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <BrowserRouter location={history.location} navigator={history}>
                    <Routes>
                        {!loggedIn && <Route path="/" element={ <LoginPage /> } />}
                        <Route path="/login" element={ <LoginPage /> } />
                        <Route path="/forgotpassword" element={ <ForgotPasswordPage /> } />
                        <Route path="/trialsignup" element={ <TrialSignUpPage /> } />
                        <Route path="/trialconfirm" element={ <TrialConfirmPage /> } />
                        <Route path="/signupInvite/:inviteKey" element={<UserInvitePage/>}/>
                        <Route path="/trialactivation/:key" element={ <TrialActivationPage /> } />
                        <Route path="/ResetPassword/:key" element={ <ResetPasswordPage /> } />
                        {
                            loggedIn && (
                            <>
                                {["MANAGER","MANAGER_ADMIN","SUPER_USER"].includes(loggedInUser.SecurityGroup) && (
                                    <>
                                        <Route path="/" element={ <PrivateRoute><ClientListPage /></PrivateRoute> } />
                                        <Route path="/licenses" title="License Templates" element={ <PrivateRoute><LicenseTemplatesPage/></PrivateRoute>} />
                                        <Route path="/licenses/new" title="New License Template" element={ <PrivateRoute><NewLicenseTemplatePage/></PrivateRoute>} />
                                        <Route path="/licenses/:licenseId" title="Edit License Template" element={ <PrivateRoute><LicenseTemplateDetailsPage/></PrivateRoute>} />
                                        
                                        <Route path="/Clients" element={ <PrivateRoute><ClientListPage /></PrivateRoute> } />
                                        <Route path="/Clients/:companyId/licenses/:companyLicenseId" title="Edit Client License" element={ <PrivateRoute><ClientLicenseEditPage/></PrivateRoute>} />
                                        <Route path="/Clients/:companyId" element={ <PrivateRoute><LicenseDetailsPage /></PrivateRoute> } />
                                        <Route path="/Clients/:companyId/details" element={ <PrivateRoute><ClientDetailsPage /></PrivateRoute> } />
                                        <Route path="/Clients/new" element={ <PrivateRoute><NewClientOnboardPage     /></PrivateRoute> } />
                                        <Route path="/profile" element={ <PrivateRoute><UserProfilePage /></PrivateRoute> } />
                                        <Route path="/profile/changepassword" element={ <PrivateRoute><ChangePasswordPage /></PrivateRoute> } />

                                        <Route path="/hosts" element={ <PrivateRoute><HostListPage /></PrivateRoute> } />
                                        <Route path="/hosts/:id" element={ <PrivateRoute><EditProvisionHostPage /></PrivateRoute> } />
                                        <Route path="/hosts/new" element={ <PrivateRoute><NewProvisionHostPage /></PrivateRoute> } />
                                    </>
                                    )
                                }

                                {["MANAGER_ADMIN","SUPER_USER"].includes(loggedInUser.SecurityGroup) && (
                                    <>                                    
                                        <Route path="/userManagement" element={ <PrivateRoute><UserListPage/></PrivateRoute>}/>
                                        <Route path="/usermanagement/edituser/:userId" element={<PrivateRoute><EditUserPage/></PrivateRoute>} />
                                        <Route path="/releases/" element={<PrivateRoute><ReleasesPage/></PrivateRoute>} />
                                        <Route path="/releases/:releaseId" element={<PrivateRoute><ReleaseDetails/></PrivateRoute>} />
                                        <Route path="/releases/new" element={<PrivateRoute><NewRelease/></PrivateRoute>} />
                                        <Route path="/clients/:companyId/deployments/:deploymentId/snapshots" element={<PrivateRoute><SnapshotPage/></PrivateRoute>} />
                                        <Route path="/seedmanagement" element={ <PrivateRoute><SeedListPage/></PrivateRoute>}/>
                                        <Route path="/seedmanagement/:seedId" element={ <PrivateRoute><SeedDetailPage/></PrivateRoute>}/>
                                        <Route path="/seedmanagement/new" element={ <PrivateRoute><SeedDetailPage/></PrivateRoute>}/>
                                        <Route path="/industrymanagement" element={ <PrivateRoute><IndustryListPage/></PrivateRoute>}/>
                                        <Route path="/industrymanagement/:industryId" element={ <PrivateRoute><IndustryDetailPage/></PrivateRoute>}/>
                                        <Route path="/industrymanagement/new" element={ <PrivateRoute><IndustryDetailPage/></PrivateRoute>}/>
                                    </>
                                    )
                                }
                                
                                {["CUSTOMER"].includes(loggedInUser.SecurityGroup) && (
                                    <>                                    
                                        <Route path="/" element={ <PrivateRoute><CustomerLicenses/></PrivateRoute>}/>
                                        <Route path="/license/:companyLicenseId" element={ <PrivateRoute><CustomerLicenseDetail/></PrivateRoute>}/>
                                        <Route path="/billing" element={ <PrivateRoute><SubscriptionPage/></PrivateRoute>}/>
                                        <Route path="/profile" element={ <PrivateRoute><UserProfilePage /></PrivateRoute> } />
                                        <Route path="/profile/changepassword" element={ <PrivateRoute><ChangePasswordPage /></PrivateRoute> } />
                                        <Route path="/snapshots" element={ <PrivateRoute><CustomerSnapshotPage /></PrivateRoute> } />
                                    </>
                                    )
                                }
                                
                                
                                
                            </>
                            ) 
                        }
                        
                    </Routes>
                    <SystemAlert/>
                </BrowserRouter>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;