import { Box, Button, CircularProgress, Container, CssBaseline } from "@mui/material";
import React from "react";
import FormBox from "../../../components/formBox";
import { PrivateLayout } from "../../../components/PrivateLayout";
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from "react";
import { userManagementActions } from "../../../_actions";
import { DataGrid } from '@mui/x-data-grid';
import ReactDataGrid from "@inovua/reactdatagrid-community";
import InviteUserModal from "./InviteUserModal";
import { useState } from "react";
import ConfirmDialog from "../../../components/ConfirmDialog";
import Moment from 'react-moment';
import { useNavigate } from "react-router-dom";




const gridStyle = { minHeight: 400 }

function UserListPage(){
    const navigate = useNavigate();
    const loading = useSelector( state => state.userManagement.loadingUserList);
    const userList = useSelector( state => state.userManagement.userList);
    const inviteList = useSelector( state => state.userManagement.inviteList);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [inviteModalOpen, setInviteModalOpen] = useState(false);
    const [inviteToDelete, setInviteToDelete] = useState(null);
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(userManagementActions.getUserList());
        dispatch(userManagementActions.listInvites());
    }, []);

    const modalClosed = () =>{
        setInviteModalOpen(false);
    }

    const inviteSent = async (newInvite) => {
        await dispatch(userManagementActions.inviteUser(newInvite.email, newInvite.group));
        await dispatch(userManagementActions.listInvites());
    }

    const deleteInvite = async (inviteId) => {
        setInviteToDelete (inviteId)
        setDeleteConfirmOpen(true);
    }

    const cancelDelete = async () => {
        setDeleteConfirmOpen(false);
    }
    const deleteInviteConfimed = async () => {
        setDeleteConfirmOpen(false);
        await dispatch(userManagementActions.deleteInvite(inviteToDelete));
        await dispatch(userManagementActions.listInvites());
    }

    const inviteColumns = [
        { name: 'userId', header: 'User Id', defaultFlex: 1},
        { name: 'expiryDate', header: 'Invite Expires' , defaultFlex: 1,
            render: ({value}) => {
                return <Moment format="YYYY-MMM-DD h:mm A">{value}</Moment>
            }},
        { name: 'id', header: '', 
            render: ({value}) => {
                return <span><Button variant="contained" color="secondary" onClick={() => deleteInvite(value)}>Delete</Button></span>
            }}
    ]

    const userColumns = [
        { name: 'userId', header: 'User Id', defaultFlex: 1},
        { name: 'firstName', header: 'First Name' , defaultFlex: 1},
        { name: 'lastName', header: 'Last Name', defaultFlex: 1 },
        { name: 'id', header: '', 
            render: ({data: {userId}}) => {
                return <span><Button variant="contained" color="primary" onClick={() => navigate(`/usermanagement/edituser/${userId}`)}>Edit</Button></span>
            }}
    ]
    
    return (
        <PrivateLayout>
            <ConfirmDialog open={deleteConfirmOpen} cancelCallback={cancelDelete} confirmCallback={deleteInviteConfimed} title={"Confirm Delete"}
                    description={"Are you sure that you want to delete this invite?"} />
            <Container maxWidth='xl'>
                <CssBaseline />
                <InviteUserModal onCloseHandler={modalClosed} onSubmitHandler={inviteSent} open={inviteModalOpen}/>
                {userList === null || loading ? <CircularProgress/> : 
                <div>
                    
                    <FormBox title={`Users`}  >
                        
                        <ReactDataGrid                                    
                            idProperty="id"
                            columns={userColumns}
                            dataSource={userList}
                            style={gridStyle}
                            />
                        <h4>Invites</h4>
                        <Button onClick={()=>setInviteModalOpen(true)} variant='contained'>Invite New User</Button>
                        <ReactDataGrid                                    
                            idProperty="id"
                            columns={inviteColumns}
                            dataSource={inviteList}
                            style={gridStyle}
                            />
                    </FormBox>
                </div>
                

                }
            
       
            </Container>
        </PrivateLayout>
    )
}

export {UserListPage};