import { Button, CircularProgress, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Modal, OutlinedInput, Radio, RadioGroup, Select, TextareaAutosize, TextField, Typography } from "@mui/material";
import { borderColor, Box, Container, styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FormBox from "../../../components/formBox";
import { PrivateLayout } from "../../../components/PrivateLayout";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch } from "react-redux";
import { releaseActions } from "../../../_actions";
import DraggableList from "../../../components/DraggableList";
import Moment from 'react-moment';
import ConfirmDialog from "../../../components/ConfirmDialog";
import * as semver from 'semver';
import { releaseService } from "../../../_services";
import {deploymentService} from "../../../_services/deployment.service";

const BoldLabel = styled("span")({
    fontWeight: "bold"
});

const SaveButton = styled(Button)({
    marginTop: 15,
    marginRight: 15
});

const CancelButton = styled(Button)({
    marginTop: 15,
    marginRight: 15
});
const DeleteButton = styled(Button)({
    marginTop: 15,
    marginRight: 15
});


const BetterTextArea = styled(TextField)({
    resize: "both"
});

const ModalContent = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    padding:50,
    transform: 'translate(-50%, -50%)',
    width: "70%",
    bgcolor: 'background.paper',
    backgroundColor:"#FFFFFF",
    margin:40,
    boxShadow: 24,
    maxWidth:750,
    pt: 2,
    px: 4,
    pb: 3,
  });



function NewRelease(){
    const {releaseId} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const releases = useSelector(state => state.releases.releaseList);
    const saving = useSelector(state => state.releases.savingRelease);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadFile, setUploadFile] = useState(false);
    const [branches, setBranches] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState("Dev");
    const [buildingRelease, setBuildingRelease] = useState(false);
    var nonStandardDeployment = useSelector( state => state.releases.nonStandardDeployment);
    const [selectedDeploymentId, setSelectedDeploymentId] = useState('default');
    const [nonStandardDeployments, setNonStandardDeployments] = useState([]);
    useEffect(() =>{


    },[])

    useEffect(() => {
        if (nonStandardDeployment !== null)
            setSelectedDeploymentId(nonStandardDeployment.deploymentId.toString());
        else
            setSelectedDeploymentId("default");
        dispatch(releaseActions.getReleases());
        if (nonStandardDeployment === null){
            releaseService.getBranches().then((branches) =>{
                if (branches && branches.length)
                    setSelectedBranch(branches[0]);
                setBranches(branches);
            });
        } else {
            releaseService.getNonStandardBranches(nonStandardDeployment.deploymentId).then((branches) => {
                if (branches && branches.length)
                    setSelectedBranch(branches[0]);
                setBranches(branches);
            });
        }

    }, [nonStandardDeployment]);
    const validationSchema = Yup.object().shape({
        releaseName: Yup.string()
          .required('Release Name is required'),
        releaseNotes: Yup.string()
          .required('Release notes required'),
        revision: Yup.string()
            .test('test-required', 'Revision is required when uploading a release package',
            (value) => { return !uploadFile || (value !== '' && value !== undefined)}),
        scriptsFromRevision: Yup.number("Must be a number")
            .nullable()
            .typeError('Must be a number')
            .min(0, "Must be a positive number"),
        scriptsToRevision: Yup.number("Must be a number")
            .nullable()
            .typeError('Must be a number')
            .min(0, "Must be a positive number"),
        sourceCodeRevision: Yup.number("Must be a number")
            .nullable()
            .typeError('Must be a number')
            .min(0, "Must be a positive number"),
        version: Yup.string()
            .required('Version is required')
            .test('test-semantic-validation', 'That is not a valid sematic version (e.g. x.y.z)',
                (value) => { return semver.valid(value) !== null })
            .test('test-only-one-new-release', 'Sorry there is already a release waiting to be deployed.',
                (value) => {
                    if (releases && releases.length > 0 && releases[0].dateReleased === null) return false;
                    return true;
                })
            .test('test-release-num-gt-last', "The semantic release version must be greater than the last release version. ",
                (value) => {
                    if (releases && releases.length > 0 && semver.valid(value) !== null && semver.gt(value, releases[0].version ) ) return true;
                    if (!releases || releases.length == 0) return true;
                    return false;
                })
            
      });

    
	const fileSelected = (event) => {
		setSelectedFile(event.target.files[0]);
	};

    const onReleaseTargetChange = (e) => {
        if (!e.target.value || e.target.value === 'default')
            dispatch(releaseActions.setNonStandardDeployment(null));
        var deployment = nonStandardDeployments.find(d => d.deploymentId.toString() === e.target.value)
        if (!deployment) dispatch(releaseActions.setNonStandardDeployment(null));
        else dispatch(releaseActions.setNonStandardDeployment(deployment));
    }

    const { control, setValue, handleSubmit, register, reset, formState: { errors }} = useForm({
        resolver: yupResolver(validationSchema),
        
    });

    useEffect(() => {
        if (nonStandardDeployment === null){
            if (releases && releases.length > 0){
                let newVer = semver.inc(releases[0].version, "patch");

                reset({releaseName: `Release ${newVer}`, version: newVer});
            } else {
                reset({releaseName: `Release 1.0.0`, version: '1.0.0'});
            }
        } else {
            if (nonStandardDeployment.currentVersion){
                let newVer = semver.inc(nonStandardDeployment.currentVersion, "patch");

                reset({releaseName: `Release ${newVer}`, version: newVer});
            } else {
                reset({releaseName: `Release 1.0.0`, version: '1.0.0'});
            }
        }

        
    }, [releases])

    useEffect(() => {
        const loadNonStandardDeployments = async () => {
            var deployments = await deploymentService.getNonStandardDeployments();
            setNonStandardDeployments(deployments);
        }
        loadNonStandardDeployments();
        dispatch(releaseActions.getReleases()); //get all releases so that we can validate the version number

    },[])
    const onSubmit = async (data) => {
        if (uploadFile){
            await dispatch(releaseActions.createNewRelease(data.releaseName, data.releaseNotes, data.version, data.revision,  selectedFile, nonStandardDeployment?.deploymentId ));
        } else {
            setBuildingRelease(true);
            try {
                await releaseService.buildNewRelease(data.releaseName, data.releaseNotes, selectedBranch, data.version, data.source, data.scriptsFromRevision, data.scriptsToRevision, nonStandardDeployment?.deploymentId);
            } finally {
                setBuildingRelease(false);
            }
           
            
        }
        
        navigate(`/releases`);
    }

    return (
        <PrivateLayout>
        <Modal open={buildingRelease}>
          <ModalContent>
              <Typography variant='h5'>Build In Progress</Typography><br/>
              <Grid container >
                  <Grid item sm={12}>
                      <CircularProgress/> - Building release. Please wait. This may take a few minutes.
                  </Grid>
                  
              </Grid>              
          </ModalContent>
            
        </Modal>
        <Container>
            
            <form onSubmit={handleSubmit(onSubmit)} style={{width: '100%'}}>
            <FormBox title={"Release Details"}>
                <Grid container spacing={2} >
                    <Grid item sm={12}>
                        <FormControl fullWidth>
                            <InputLabel id="releaseTargetLabel">Release Target</InputLabel>
                            <Select
                                labelId="releaseTargetLabel"
                                id="releaseTarget"
                                value={selectedDeploymentId}
                                label="Release Target"
                                onChange={onReleaseTargetChange}
                            >
                                <MenuItem key="default" value="default">Azzier Standard</MenuItem>
                                { nonStandardDeployments?.map(d => <MenuItem key={d.deploymentId.toString()} value={d.deploymentId.toString()}>{d.customerName} - {d.domain}</MenuItem>)}

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                        <Controller
                            name="releaseName"
                            control={control}
                            inputRef={register()}
                            render={({field}) =>
                                <TextField variant="outlined" fullWidth label="Release Name*" margin="normal" {...field}
                                           error={errors.releaseName ? true : false}
                                           helperText={errors.releaseName?.message} inputProps={{maxLength: 100}}/>

                            }
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <Controller
                            name="version"
                            control={control}
                            inputRef={register()}
                            render={({field}) =>
                                <TextField variant="outlined" fullWidth label="Version*" margin="normal" {...field}
                                           error={errors.version ? true : false}
                                           helperText={errors.version?.message} inputProps={{maxLength: 100}}/>

                            }
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <Controller
                            name="releaseNotes"
                            control={control}
                            inputRef={register()}
                            render={({field}) =>
                                <BetterTextArea variant="outlined" fullWidth label="Release Notes*" multiline
                                                margin="normal" {...field} error={errors.releaseNotes ? true : false}
                                                helperText={errors.version?.releaseNotes}
                                                inputProps={{maxLength: 100}}/>

                            }
                        />

                    </Grid>
                    <Grid item sm={12}>
                        <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">I would like to: </FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={uploadFile}

                                onChange={(e) => {
                                    setUploadFile(e.target.value == 'true')
                                }}
                                name="radio-buttons-group"
                            >
                                <FormControlLabel value={false} control={<Radio/>} label="Build release from branch"/>
                                <FormControlLabel value={true} control={<Radio/>} label="Upload a release zip package"/>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {uploadFile ?
                        <Grid item sm={12}>
                            <Box>
                                <BoldLabel>Release Package Zip file</BoldLabel>
                                <input type="file" name="releasePackage" onChange={fileSelected}/>
                            </Box>
                            <Box>
                                <Controller
                                    name="revision"
                                    control={control}
                                    inputRef={register()}
                                    render={({field}) =>
                                        <TextField variant="outlined" fullWidth label="Revision*"
                                                   margin="normal" {...field} error={errors.revision ? true : false}
                                                   helperText={errors.revision?.message} inputProps={{maxLength: 100}}/>

                                    }
                                />
                            </Box>
                        </Grid>
                        :
                        <Grid item sm={12}>
                            <Box>
                                <BoldLabel>Choose Branch</BoldLabel>

                                <FormControl sx={{m: 1, width: 300}}>
                                    <InputLabel id="branchLabel">Select Branch</InputLabel>
                                    <Select
                                        labelId="branchLabel"
                                        id="branch"
                                        label="Select Branch"
                                        value={selectedBranch}
                                        onChange={(e) => setSelectedBranch(e.target.value)}


                                    >
                                        {branches.map((branchPath) => (
                                            <MenuItem
                                                key={branchPath}
                                                value={branchPath}
                                            >
                                                {branchPath}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                            </Box>

                        </Grid>
                    }
                    <Grid item sm={12}>
                        <BoldLabel>Choose Script Revision Range (optional)</BoldLabel>
                        <div>If you do not enter the from and to script revision the system will try to guess the best
                            range.</div>
                    </Grid>
                    <Grid item sm={6}>
                        <Controller
                            name="scriptsFromRevision"
                            control={control}
                            inputRef={register()}
                            render={({field}) =>
                                <TextField type={"number"} variant="outlined" fullWidth label="Scripts From Revision" margin="normal"
                                           inputMode={"numeric"} {...field}
                                           error={errors.scriptsFromRevision ? true : false}
                                           helperText={errors.scriptsFromRevision?.message} inputProps={{maxLength: 100}}/>

                            }
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <Controller
                            name="scriptsToRevision"
                            control={control}
                            inputRef={register()}
                            render={({field}) =>
                                <TextField type={"number"} variant="outlined" fullWidth label="Scripts To Revision" margin="normal"
                                           inputMode={"numeric"} {...field} error={errors.scriptsToRevision ? true : false}
                                           helperText={errors.scriptsToRevision?.message} inputProps={{maxLength: 100}}/>

                            }
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <BoldLabel sx={{gridColumnStart: 1, gridColumnEnd: -1}}>Source Code Revision (optional)</BoldLabel>
                        <div>If specified the system will use the source code at this revision in the build package. If it is
                            not specified the system will use the HEAD revision.</div>
                    </Grid>
                    <Grid item sm={4}>
                        <Controller
                            name="sourceCodeRevision"
                            control={control}
                            inputRef={register()}
                            render={({field}) =>
                                <TextField type={"number"} variant="outlined" fullWidth label="Source Code Revision" margin="normal"
                                           inputMode={"numeric"} {...field} error={errors.sourceCodeRevision ? true : false}
                                           helperText={errors.sourceCodeRevision?.message} inputProps={{maxLength: 100}}/>

                            }
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <Box >
                            <SaveButton disabled={saving} variant="contained" color='primary' type="submit">{saving &&
                                <CircularProgress size={"1rem"}/>}Create New Release</SaveButton>
                            <CancelButton disabled={saving} variant="contained" color="secondary" type='button'
                                          onClick={() => navigate("/releases")}>Cancel</CancelButton>
                        </Box>
                    </Grid>



                </Grid>

            </FormBox>
            </form>
        </Container>
        </PrivateLayout>
    );
}

export {NewRelease};