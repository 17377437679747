export const companyLicenseConstants = {
    GET_COMPANY_LICENSES_REQUEST: 'GET_COMPANY_LICENSES_REQUEST',
    GET_COMPANY_LICENSES_SUCCESS: 'GET_COMPANY_LICENSES_SUCCESS',
    GET_COMPANY_LICENSES_FAILURE: 'GET_COMPANY_LICENSES_FAILURE',

    GET_COMPANY_LICENSE_DETAILS_REQUEST: 'GET_COMPANY_LICENSE_DETAILS_REQUEST',
    GET_COMPANY_LICENSE_DETAILS_SUCCESS: 'GET_COMPANY_LICENSE_DETAILS_SUCCESS',
    GET_COMPANY_LICENSE_DETAILS_FAILURE: 'GET_COMPANY_LICENSE_DETAILS_FAILURE',

    SAVE_COMPANY_LICENSE_DETAILS_REQUEST: 'SAVE_COMPANY_LICENSE_DETAILS_REQUEST',
    SAVE_COMPANY_LICENSE_DETAILS_SUCCESS: 'SAVE_COMPANY_LICENSE_DETAILS_SUCCESS',
    SAVE_COMPANY_LICENSE_DETAILS_FAILURE: 'SAVE_COMPANY_LICENSE_DETAILS_FAILURE',

    DEACTIVATE_COMPANY_LICENSE_REQUEST: 'DEACTIVATE_COMPANY_LICENSE_REQUEST',
    DEACTIVATE_COMPANY_LICENSE_SUCCESS: 'DEACTIVATE_COMPANY_LICENSE_SUCCESS',
    DEACTIVATE_COMPANY_LICENSE_FAILURE: 'DEACTIVATE_COMPANY_LICENSE_FAILURE',

    ACTIVATE_COMPANY_LICENSE_REQUEST: 'ACTIVATE_COMPANY_LICENSE_REQUEST',
    ACTIVATE_COMPANY_LICENSE_SUCCESS: 'ACTIVATE_COMPANY_LICENSE_SUCCESS',
    ACTIVATE_COMPANY_LICENSE_FAILURE: 'ACTIVATE_COMPANY_LICENSE_FAILURE'

};