import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import {Button, IconButton, Typography} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { PrivateLayout } from "../../../components/PrivateLayout";
import CircularProgress from '@mui/material/CircularProgress';
import ReactDataGrid from "@inovua/reactdatagrid-community";
import { useSelector, useDispatch } from 'react-redux';
import { industryManagementActions } from "../../../_actions";
import ConfirmDialog from "../../../components/ConfirmDialog";

const gridStyle = { minHeight: 600, width: '100%' }

function IndustryListPage () {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const industryList = useSelector( state => state.industryManagement.industryList ? state.industryManagement.industryList : []);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [industryIdToDel, setIndustryIdToDel] = useState(-1);
    let loading = useSelector((state) => state.industryManagement.loadingIndustryList);

    useEffect(() => { 
        dispatch(industryManagementActions.getIndustryList());
    }, []);

    const industryColumns = [
        { name: 'industryName', header: 'Industry Name', defaultFlex: 2, enableColumnFilterContextMenu: true},
        { name: 'description', header: 'Description', defaultFlex: 2, enableColumnFilterContextMenu: true},
        { name: 'deploymentSeedName', header: 'Deployment Seed' , defaultFlex: 2, enableColumnFilterContextMenu: true},
        { name: ' updates', header: '', 
            render: (row) => {
                let navRoute="/industrymanagement/" + row.data.id
                let indId = row.data.id
                return (
                    <>
                    <span><IconButton onClick={()=>navigate(navRoute)}><EditIcon color='action'/></IconButton></span>
                    <span><IconButton onClick={(e)=>onDelete(indId)}><DeleteIcon color='action' /></IconButton></span>
                    </>
                )
            }
        }
    ]
 
    const filterValue = [
        { name: 'industryName', operator: 'contains', type: 'string', value: '' },
        { name: 'description', operator: 'contains', type: 'string', value: '' },
        { name: 'deploymentSeedName', operator: 'contains', type: 'string', value: '' }
    ];

    const onDelete = (industryId) => {
        //let indId = parseInt(e.currentTarget.id)
        setDeleteConfirmOpen(true);
        setIndustryIdToDel(industryId)
    }
    const cancelDelete = () => {
        setDeleteConfirmOpen(false);
    }

    const deleteConfirmed = async () => {
        setDeleteConfirmOpen(false);
        if(industryIdToDel != -1){
            await dispatch(industryManagementActions.deleteIndustry(industryIdToDel));
        }
        setIndustryIdToDel(-1)
        navigate("/industrymanagement");
    }

    return (
        <PrivateLayout>
            <Container maxWidth="xl">
            <ConfirmDialog open={deleteConfirmOpen} cancelCallback={cancelDelete} confirmCallback={deleteConfirmed} title={"Confirm Delete"}
                    description={"Are you sure that you want to delete this industry?"} />
            <CssBaseline />
                <Typography variant="h3">Industry Management</Typography>
                <br/>
                {loading ? (
                    <CircularProgress/>
                ): (
                    <Grid container>
                        <Grid item xs={10}>
                            <Typography variant="h5">Industry List</Typography>
                        </Grid>
                        <Grid item xs={2} style={{textAlign:'right'}}>
                            <Button variant="contained" color="primary" onClick={() => navigate(`/industrymanagement/new`)} >New Industry</Button>
                        </Grid>
                        <br/><br/>
                        <Grid xs={12}>
                            <ReactDataGrid
                                enableFiltering={true}
                                defaultFilterValue={filterValue}
                                idProperty="id"
                                columns={industryColumns}
                                dataSource={industryList}
                                style={gridStyle}
                            />
                        </Grid>
                    </Grid>
                )}
            </Container>
        </PrivateLayout>
    )
}

export {IndustryListPage};