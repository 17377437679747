import jwt_decode from "jwt-decode";
import axios from '../_helpers/axiosInstance';
import { authHeader } from '../_helpers/authHeader';

export const userService = {
    login,
    logout,
    requestPasswordReset,
    validateResetKey,
    resetPassword, 
    getUserProfile,
    saveUserProfile, 
    changePassword
};

const API_URL = process.env.REACT_APP_API_ENDPOINT;

async function login(userID, password) {
    console.log('About to fetch');
    let response = await fetch(`${API_URL}/User/authenticate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({UserId:userID, Password:password})
    });
    let jsonResponse = await response.json();
    console.log('Response: ' + JSON.stringify(jsonResponse));
    let jwt = jsonResponse.jwtToken;
    let refreshToken = jsonResponse.refreshToken;
    console.log('Encoded JWT: ' + jwt);
    console.log('Refresh Token: ' + refreshToken);
    var decoded = jwt_decode(jwt);
    decoded.token = jwt;
    console.log('Decoded JWT: ' + JSON.stringify(decoded));
    return [decoded, jwt, refreshToken];
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('jwtBearer');
    localStorage.removeItem('refreshToken');
}

function requestPasswordReset(userId) {
  const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({MobileUser:false,UserId: userId})
  };

  return fetch(`${API_URL}/user/password`, requestOptions).then(handleResponse);
}

function validateResetKey(resetKey) {
    console.log ("VALIDATING RESET KEY", resetKey);

    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ResetPasswordKey: resetKey })
    };

    return fetch(`${API_URL}/user/password/reset/validate`, requestOptions);
}

function resetPassword(password, passwordConfirm, resetKey) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ResetPasswordKey: resetKey, NewPassword: password, NewPasswordConfirm: passwordConfirm})
    };  

    return fetch(`${API_URL}/user/password/reset`, requestOptions);
}

async function getUserProfile(){
    var response = await axios.get('/userprofile', {headers: authHeader()});
    return response.data.result;
}

async function saveUserProfile(userProfile){
    await axios.put('/userprofile', userProfile, {headers: authHeader()});
}


function handleResponse(response) {
  return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
          if (response.status === 401) {
              // auto logout if 401 response returned from api
              logout();
              //location.reload(true);
          }
          if (data.Error && data.Error.ErrorCode){
              return Promise.reject(data.Error.ErrorCode)
          }
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
      }
      return data;
  });
}

async function changePassword(newPassword){
    await axios.put('/userprofile/password', {newPassword}, {headers: authHeader()});
}