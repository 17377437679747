import { Button, Container, CssBaseline, Grid, styled, TextField, Typography } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { PublicLayout } from "../../../components/PublicLayout";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect } from "react";
import { useDispatch } from 'react-redux';
import { userManagementActions } from "../../../_actions";
import { useState } from "react";
import { userManagementService } from "../../../_services";
import { useNavigate, useParams } from "react-router-dom";

const FullWidthTextField = styled(TextField)({
    width: '100%'
})

const SaveButton = styled(Button)({
    width: '10%',
    marginRight:10
});


const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .required('Phone number is required'),
    firstName: Yup.string()
        .required('First name is required'),
    lastName: Yup.string()
        .required('Last name is required'),
    password: Yup.string()
        .required('Password is required')
        .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, "Password must be at least 8 characters and must contain an upper case letter, lower case letter and a number")
        .max(100, 'Password must not exceed 100 characters'),
    confirmPassword: Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('password'), null], 'Confirm Password does not match')
  });

function UserInvitePage(){
    const navigate = useNavigate();
    const { control, handleSubmit, register, reset, formState: { errors, submitCount }, getValues, setValue, trigger} = useForm({
        resolver: yupResolver(validationSchema)
    });
    const {inviteKey} = useParams();
    const dispatch = useDispatch();
    const [inviteDetails,setInviteDetails] = useState(null);
    const [inviteValid,setInviteValid] = useState(true);
    useEffect(() => {
        userManagementService.inviteKeyValid(inviteKey)
            .then((valid) => setInviteValid(valid));

    },[])
    useEffect(() => {
        if (inviteValid){
            userManagementService.getInviteDetails(inviteKey)
                .then((details) => {
                    console.log(details);
                    reset(details);
                    setInviteDetails(details);
                });
        }
    },[inviteValid])

    


    const onSubmit = async data => {
        const newUser = {...data}
        await dispatch(userManagementActions.newUserFromInvite(data.firstName, data.lastName, data.phoneNumber, data.password, inviteDetails.inviteKey));
        navigate("/login?signupComplete=true");
    };

    return (
    <PublicLayout>
        <Container>
            
            <CssBaseline />
            {!inviteValid ? 
            <>
                <h1 style={{marginTop:20}}>Invite Invalid {inviteDetails?.userId}</h1>
                <p>
                    Sorry the invite link that you used either has an invalid or expired invitation. 
                </p>
            </>
            :
            <>
                <h1 style={{marginTop:20}}>User Invite {inviteDetails?.userId}</h1>
                <p>
                    Fill in your details below to complete the signup process.
                </p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <h4>Account Information</h4>
                    <hr/> 
                    <Grid spacing={3} container>
                        <Grid item sm={4} xs={12}>
                            <Grid item>
                                <Controller
                                    name="firstName"
                                    control={control}
                                    inputRef={register()}
                                    render={({ field }) =>
                                        <FullWidthTextField label="First Name*" variant="outlined" margin="normal" {...field} error={errors.firstName ? true : undefined}/>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="inherit" color="error" style={{textAlign:'left', marginRight: '2rem'}}>
                                    {errors.firstName?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <Grid item>
                                <Controller
                                    name="lastName"
                                    control={control}
                                    inputRef={register()}
                                    render={({ field }) =>
                                        <FullWidthTextField label="Last Name*"  variant="outlined" margin="normal" {...field} error={errors.lastName ? true : undefined}/>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="inherit" color="error" style={{textAlign:'left', marginRight: '2rem'}}>
                                    {errors.lastName?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <Grid item>
                                <Controller
                                    name="phoneNumber"
                                    control={control}
                                    inputRef={register()}
                                    render={({ field }) =>
                                            <FullWidthTextField label="Phone Number*" variant="outlined" margin="normal" {...field} error={errors.phoneNumber ? true : undefined}/>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors.phoneNumber?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item lg={12}>
                            <Grid item>
                                <span style={{fontWeight:'bold'}}>Username: </span>{inviteDetails?.userId}
                            </Grid>
                            
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <Grid item>
                                <Controller
                                    name="password"
                                    control={control}
                                    inputRef={register()}
                                    render={({ field }) =>
                                        <FullWidthTextField label="Password*" type="password" variant="outlined" margin="normal" {...field} error={errors.password ? true : undefined}/>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="inherit" color="error" style={{textAlign:'left', marginRight: '2rem'}}>
                                    {errors.password?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                        
                        <Grid item sm={4} xs={12}>
                            <Grid item>
                                <Controller
                                    name="confirmPassword"
                                    control={control}
                                    inputRef={register()}
                                    render={({ field }) =>
                                        <FullWidthTextField label="Confirm Password*" type="password" variant="outlined" margin="normal" {...field} error={errors.confirmPassword ? true : undefined}/>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors.confirmPassword?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    

                    <div style={{marginTop:20}}>
                        <SaveButton
                                type="submit"
                                variant="contained"
                                color="primary"
                            > 
                                Save 
                        </SaveButton>
                    </div>
                </form>
            </>
            }
            
            <br /><br />
        </Container>
    </PublicLayout>
    )
}

export {UserInvitePage};