import axios from '../_helpers/axiosInstance';
import { authHeader } from '../_helpers/authHeader';

export const releaseService = {
    getReleases,
    getReleaseDetails,
    createNewRelease,
    updateRelease,
    deleteRelease,
    getReleaseMigrationDetails,
    updateReleaseMigration,
    createNewReleaseMigration,
    
    deleteReleaseMigration,
    reorderReleaseMigrations,
    createNewScreenChange,
    updateScreenChange,
    getScreenChangeDetail,
    deleteScreenChange,
    reorderScreenChanges,
    startRelease,
    getReleaseLogs,
    getHostReleases,
    getReleaseMigrationLogs,
    getBranches,
    buildNewRelease,

    rollbackRelease,
    exportReleasePackage,
    importReleasePackage,
    importNonStandardReleasePackage,
    getNonStandardBranches

};

async function getBranches(){
    console.log("about to get branches");
    var response = await axios.get('/release/buildBranches', {headers: authHeader()});
    return response.data.result;
}
async function getNonStandardBranches(nonStandardDeploymentId){
    console.log("about to get branches");
    var response = await axios.get(`/release/buildBranches/nonStandardDeployment/${nonStandardDeploymentId}`,{headers: authHeader()});
    return response.data.result;
}

async function getReleases(externalDeploymentId = null){
    console.log('About to get releases');
    if (externalDeploymentId){
        var response = await axios.get(`/release/externaldeployment/${externalDeploymentId}`, {headers: authHeader()});
        return response.data.result;
    } else {
        var response = await axios.get('/release', {headers: authHeader()});
        return response.data.result;
    }

}

async function getReleaseDetails(releaseId){
    console.log('About to get release details');
    var response = await axios.get(`/release/${releaseId}`, {headers: authHeader()});
    return response.data.result;
}

async function updateRelease(releaseId, releaseName, releaseNotes, version, releasePackage){
    let formData = new FormData();
    formData.append("ReleaseId", releaseId);
    formData.append("ReleaseName", releaseName);
    formData.append("ReleaseNotes", releaseNotes);
    formData.append("Version", version);
    formData.append("ReleasePackage", releasePackage);
    await axios.put('/release', formData, {headers: {'content-type': 'multipart/form-data', ...authHeader()}, });
}

async function createNewRelease(releaseName, releaseNotes, version, revision, releasePackage, nonStandardDeploymentId = null){
    let formData = new FormData();
    formData.append("ReleaseName", releaseName);
    formData.append("ReleaseNotes", releaseNotes);
    formData.append("Version", version);
    formData.append("Revision", revision);
    formData.append("ReleasePackage", releasePackage);
    if (nonStandardDeploymentId)
        formData.append("NonStandardDeploymentId", nonStandardDeploymentId);
    await axios.post('/release', formData, {headers: {'content-type': 'multipart/form-data', ...authHeader()}, });
}

async function buildNewRelease(releaseName, releaseNotes, branch, version, sourceCodeRevision, scriptsFromRevision, scriptsToRevision, nonStandardDeploymentId = null){
    if (nonStandardDeploymentId){
        var buildRelease = {releaseName, releaseNotes, version, revision: sourceCodeRevision, branch, scriptsFromRevision, scriptsToRevision, nonStandardDeploymentId};
        await axios.post('/release/build/nonstandard', buildRelease, {headers: authHeader()});
    } else {
        var buildRelease = {releaseName, releaseNotes, version, revision: sourceCodeRevision, branch, scriptsFromRevision, scriptsToRevision};
        await axios.post('/release/build', buildRelease, {headers: authHeader()});
    }

}

async function deleteRelease(releaseId){
    await axios.delete(`/release/${releaseId}`, {headers:authHeader()});
}

async function getReleaseMigrationDetails(dbMigrationId){
    var response = await axios.get(`/release/migration/${dbMigrationId}`, {headers:authHeader()});
    return response.data.result;
}

async function updateReleaseMigration(id, releaseId, scriptName, sqlScript, rollbackScript){
    var updateData = {id, releaseId, scriptName, sqlScript, rollbackScript};
    await axios.put('/release/migration', updateData, {headers: authHeader()});
}

async function createNewReleaseMigration(releaseId, scriptName, sqlScript, rollbackScript){
    var updateData = {releaseId, scriptName, sqlScript, rollbackScript};
    await axios.post('/release/migration', updateData, {headers: authHeader()});
}

async function deleteReleaseMigration(dbMigrationId){
    await axios.delete(`/release/migration/${dbMigrationId}`,{headers: authHeader()});
}

async function reorderReleaseMigrations(newMigrationOrder){
    await axios.put('/release/migration/order', newMigrationOrder, {headers: authHeader()});
}

async function getScreenChangeDetail(screenChangeId){
    var response = await axios.get(`/release/screen/${screenChangeId}`, {headers:authHeader()});
    return response.data.result;
}

async function updateScreenChange(id, releaseId, fileName, contents){
    var updateData = {id, releaseId, fileName, contents};
    await axios.put('/release/screen', updateData, {headers: authHeader()});
}

async function createNewScreenChange(releaseId, fileName, contents){
    var updateData = {releaseId, fileName, contents};
    await axios.post('/release/screen', updateData, {headers: authHeader()});
}

async function deleteScreenChange(screenChnageId){
    await axios.delete(`/release/screen/${screenChnageId}`,{headers: authHeader()});
}

async function reorderScreenChanges(newScreenOrder){
    await axios.put('/release/screen/order', newScreenOrder, {headers: authHeader()});
}


async function startRelease(releaseId){
    await axios.put(`/release/${releaseId}/startrelease`,null, {headers: authHeader()});
}

async function getReleaseLogs(releaseId){
    var response = await axios.get(`/release/${releaseId}/logs`, {headers: authHeader()});
    return response.data.result;
}

async function getHostReleases(releaseId){
    var response = await axios.get(`/release/${releaseId}/hostRelease`, {headers: authHeader()})
    return response.data.result;
}

async function getReleaseMigrationLogs(releaseId){
    var response = await axios.get(`/release/${releaseId}/migration/logs`, {headers: authHeader()});
    return response.data.result;
}

async function rollbackRelease(releaseId, rollbackType){
    var rollbackData = {releaseId, rollbackType};
    var response = await axios.put(`/release/rollback`, rollbackData ,{headers: authHeader()});
    return response.data.result;
}

function parseContentDisposition(header) {
    if (!header) return null;

    const parts = header.split(';').map(part => part.trim());
    const namePart = parts.find(part => part.startsWith('filename='));

    if (!namePart) return null;

    let filename = namePart.split('=')[1].trim().replace(/"/g, '');

    // Handle UTF-8 decoding (if needed)
    const encodingPart = parts.find(part => part.startsWith('filename*='));
    if (encodingPart) {
        const [, encoding, encodedFilename] = encodingPart.split('*=');
        filename = decodeURIComponent(encodedFilename);
    }

    return filename;
}

async function exportReleasePackage(releaseId, fromReleaseId, releaseVersion) {
    var response = await axios.get(`/releasePackage/${releaseId}/from/${fromReleaseId}`, {responseType: 'blob', headers: authHeader()});
    const href = URL.createObjectURL(response.data);
    let filename = `Release_${releaseVersion}.azzierpkg`;
    const contentDisposition = response.headers['content-disposition'];
    if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        const filenameExtMatch = contentDisposition.match(/filename\*[^;=\n]*=([^;\n]*)/);

        if (filenameExtMatch && filenameExtMatch[1]) {
            // Decode URI component for extended filename
            filename = decodeURIComponent(filenameExtMatch[1].replace(/^UTF-8''/, ''));
        } else if (filenameMatch && filenameMatch[1]) {
            filename = filenameMatch[1].replace(/['"]/g, '');
        }
    } else {
        console.warn('Content-Disposition header not found');
    }
    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', filename); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
}

async function importReleasePackage(file) {
    let formData = new FormData();
    formData.append("file", file);
    let response = await axios.post('/releasePackage/import', formData, {headers: {'content-type': 'multipart/form-data', ...authHeader()}, });
    return response.data.result;
}

async function importNonStandardReleasePackage(file, deploymentId) {
    let formData = new FormData();
    formData.append("file", file);
    let response = await axios.post(`/releasePackage/import/nonstandard/${deploymentId}`, formData, {headers: {'content-type': 'multipart/form-data', ...authHeader()}, });
    return response.data.result;
}