import ReactDataGrid from '@inovua/reactdatagrid-community';
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControl, FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Tab,
    Tabs,
    TextField,
    Typography
} from '@mui/material';
import { Box, styled } from '@mui/system';
import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { PrivateLayout } from '../../../components/PrivateLayout';
import { companyLicenseActions } from '../../../_actions';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Moment from 'react-moment';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { companyLicenseService, licenseTemplateService } from '../../../_services';
import AlertDialog from '../../../components/AlertDialog';
import moment from 'moment';
import LicenseBox from '../../../components/licenseBox';
import Container from "@mui/material/Container";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from "dayjs";
import CustomerLicenseModulesEditor from "../../../components/CustomerLicenseModulesEditor";
import LicenseModulesEditor from "../../../components/LicenseModulesEditor";


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

const ModalContent = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    padding:50,
    transform: 'translate(-50%, -50%)',
    width: "70%",
    bgcolor: 'background.paper',
    backgroundColor:"#FFFFFF",
    margin:40,
    boxShadow: 24,
    maxWidth:750,
    pt: 2,
    px: 4,
    pb: 3,
});

const HeadingBox = styled(Box)({
    fontWeight:"bold"
});

const SaveButton = styled(Button)({
    marginTop: 15,
    marginRight: 15
});

const CancelButton = styled(Button)({
    marginTop: 15,
    marginRight: 15
});

const DeactivateButton = styled(Button)({
    marginTop: 15,
    marginRight: 15
});

const SelectFormControl = styled(FormControl)({
    width:"100%"
});


const gridStyle = { minHeight: 400 }

function getDeploymentSetting(deploymentSettings, key){
    if (deploymentSettings === null || deploymentSettings.length === 0) return null;
    var setting = deploymentSettings.find(d => d.settingName === key);
    if (setting !== null && setting !== undefined ) return setting.settingValue;
    return null;
}

function DeploymentSettings({companyLicense, isTest, revealReadOnlyPassword, revealReadWritePassword}) {
    var deploymentSettings = !isTest ? companyLicense.deploymentSettings : companyLicense.testDeploymentSettings;
    var deploymentStatus = !isTest ? companyLicense.deploymentStatus : companyLicense.testDeploymentStatus;
    return (
        <Grid container>
            <Grid item sm={4}>
                <Grid item xs={12}><HeadingBox sx={{  }}>Deploy Status:</HeadingBox></Grid>
                <Grid item xs={12}>{isTest? companyLicense.testDeploymentStatus : companyLicense.deploymentStatus}</Grid>
            </Grid>
            {
                deploymentStatus === 'Completed' && (
                    <>
                        <Grid item sm={4}>
                            <Grid item xs={12}><HeadingBox>URL:</HeadingBox></Grid>
                            <Grid item xs={12}>{getDeploymentSetting(deploymentSettings, "url")}</Grid>
                        </Grid>
                        <Grid item sm={4}>
                            <Grid item xs={12}><HeadingBox>Company Code (mobile app):</HeadingBox></Grid>
                            <Grid item xs={12}>{getDeploymentSetting(deploymentSettings, "companyCode")}</Grid>
                        </Grid>
                        <Grid item sm={4}>
                            <Grid item xs={12}><HeadingBox>Database Server:</HeadingBox></Grid>
                            <Grid item xs={12}>{getDeploymentSetting(deploymentSettings, "databaseServer")}</Grid>
                        </Grid>
                        <Grid item sm={4}>
                            <Grid item xs={12}><HeadingBox>Deployment Date:</HeadingBox></Grid>
                            <Grid item xs={12}>{ isTest ? companyLicense.testDeploymentDate : companyLicense.deploymentDate}</Grid>
                        </Grid>
                        <Grid item sm={4}>
                            <Grid item xs={12}><HeadingBox>Database Name:</HeadingBox></Grid>
                            <Grid item xs={12}>{getDeploymentSetting(deploymentSettings, "databaseName")}</Grid>
                        </Grid>
                        <Grid item sm={4}>
                            <Grid item xs={12}><HeadingBox>RO User/PW:</HeadingBox></Grid>
                            <Grid item xs={12}>
                                {getDeploymentSetting(deploymentSettings, "readOnlyUser")}/********<br/>
                                <a href="#" onClick={revealReadOnlyPassword}>Reveal password</a>
                            </Grid>
                        </Grid>
                        <Grid item sm={4}>
                            <Grid item xs={12}><HeadingBox>RW User/PW:</HeadingBox></Grid>
                            <Grid item xs={12}>
                                {getDeploymentSetting(deploymentSettings, "readWriteUser")}/********<br/>
                                <a href="#" onClick={revealReadWritePassword}>Reveal password</a>
                            </Grid>
                        </Grid>
                        <Grid item sm={4}>
                            <Grid item xs={12}><HeadingBox>Db Timezone:</HeadingBox></Grid>
                            <Grid item xs={12}>{getDeploymentSetting(deploymentSettings, "dbTimezone")}</Grid>
                        </Grid>
                        {companyLicense.external && <>
                            <Grid item sm={4}>
                                <Grid item xs={12}><HeadingBox>SVN Root:</HeadingBox></Grid>
                                <Grid item xs={12}>{getDeploymentSetting(deploymentSettings, "svnRoot")}</Grid>
                            </Grid>
                            <Grid item sm={4}>
                                <Grid item xs={12}><HeadingBox>Site Path:</HeadingBox></Grid>
                                <Grid item xs={12}>{getDeploymentSetting(deploymentSettings, "sitePath")}</Grid>
                            </Grid>
                            <Grid item sm={4}>
                                <Grid item xs={12}><HeadingBox>IIS Site Name:</HeadingBox></Grid>
                                <Grid item xs={12}>{getDeploymentSetting(deploymentSettings, "iisSiteName")}</Grid>
                            </Grid>
                        </>
                        }
                        
                    </>
                )
            }

        </Grid>
    )
}

function ClientLicenseEditPage() {
    let dispatch = useDispatch();
    let navigate = useNavigate();
    let { companyId, companyLicenseId } = useParams();
    const [gridRef, setGridRef] = useState(null);
    const [templateGridRef, setTemplateGridRef] = useState(null);
    const [licenseOptions, setLicenseOptions] = useState([]);
    const [updatedLicenseOptions, setUpdatedLicenseOptions] = useState([]);
    const [templateDataSource, setTemplateDataSource] = useState([]);
    const [licenseId, setLicenseId] = useState(null);
    const [trial, setTrial] = useState(false);
    const [licenseEndDate, setLicenseEndDate] = useState(null);
    const [newLicenseId, setNewLicenseId] = useState(null);
    const [showNewOptionModal, setShowNewOptionModal] = useState(false);
    const [newOptionName, setNewOptionName] = useState(null);
    const [newOptionValue, setNewOptionValue] = useState(null);
    const [openPasswordAlert, setOpenPasswordAlert] = useState(false);
    const [revealedPw, setRevealedPw] = useState(null);
    const [changeLicenseConfirmOpen, setChangeLicenseConfirmOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedDeploymentTab, setSelectedDeploymentTab] = useState(0);
    const [subscriptionId, setSubscriptionId] = useState("");

    const companyLicense = useSelector(state => state.companyLicenses.selectedCompanyLicense);
    
    const licenseTemplates = useSelector(state => state.licenseTemplates.templateList);
    const loading = useSelector(state => state.companyLicenses.loadingCompanyLicenseDetails);
    let inEdit;
    const columns = [
        { name: 'optionName', header: 'Option', minWidth: 50, defaultFlex: 2, editable: false},
        { name: 'optionValue', header: 'Value', maxWidth: 1000, defaultFlex: 2 },
        { name: 'id', header: 'Actions', maxWidth: 100, defaultFlex: 1,  editable: false, sortable: false,
        render: ({value}) => {
            //console.log(props.rowIndex);
            return <span><Button onClick={() => deleteOptionById(value)}>Delete</Button></span>
          } }
    ]

    const templateColumns = [
        { name: 'optionName', header: 'Option', minWidth: 50, defaultFlex: 2, editable: false},
        { name: 'optionValue', header: 'Value', maxWidth: 1000, defaultFlex: 2 }
    ]

    const deleteOptionById = (id) => {
        const newDataSource = [...licenseOptions];
        var index = newDataSource.findIndex((e) => e.id == id);
        if (index > -1){
            newDataSource.splice(index,1);
            setLicenseOptions(newDataSource);
        }        
    }

    useEffect(() => {
        if (companyLicense !== null){
            if (companyLicense.licenseOptions !== null){
                setLicenseOptions([...companyLicense.licenseOptions]);
                setTemplateDataSource([...companyLicense.licenseTemplateOptions]);
            }
            setLicenseId(companyLicense.licenseId);
            setLicenseEndDate(dayjs(companyLicense.licenseEndDate));
            setTrial(companyLicense.trial);
            setSubscriptionId(companyLicense.subscriptionId);
        }
        
        
            
    },[companyLicense]);
    useEffect( () => {
        dispatch(companyLicenseActions.getCompanyLicenseDetails(companyLicenseId));
    },[])

    
    const onEditStart = () => {
        inEdit = true
    }
    
    const onEditStop = () => {
        requestAnimationFrame(() => {
            inEdit = false
            gridRef.current.focus()
        })
    }
    
    const onKeyDown = (event) => {
        if (inEdit) {
          return
        }
        const grid = gridRef.current
        let [rowIndex, colIndex] = grid.computedActiveCell
    
        if (event.key === ' ' || event.key === 'Enter') {
          const column = grid.getColumnBy(colIndex)
          grid.startEdit({ columnId: column.name, rowIndex })
          event.preventDefault()
          return
        }
        if (event.key !== 'Tab') {
          return
        }
        event.preventDefault()
        event.stopPropagation()
    
        const direction = event.shiftKey ? -1 : 1
    
        const columns = grid.visibleColumns
        const rowCount = grid.count
    
        colIndex += direction
        if (colIndex === -1) {
          colIndex = columns.length - 1
          rowIndex -= 1
        }
        if (colIndex === columns.length) {
          rowIndex += 1
          colIndex = 0
        }
        if (rowIndex < 0 || rowIndex === rowCount) {
          return
        }
    
        grid.setActiveCell([rowIndex, colIndex])
    }
    
    const onEditComplete = ({ value, columnId, rowIndex }) => {
        const data = [...licenseOptions];
        data[rowIndex] = Object.assign({}, data[rowIndex], { [columnId]: value })

        setLicenseOptions(data);
    }


    const cancelChangeLicense = () => {
        setChangeLicenseConfirmOpen(false);
    }

    const confirmChangeLicense = async () => {
        setChangeLicenseConfirmOpen(false);
        var licenseTemplate = await licenseTemplateService.getLicenseTemplate(newLicenseId);
        
        if (licenseTemplate){
            let newOptions = licenseTemplate.licenseOptions.map(o => {o.id = null; return o;})
            setTemplateDataSource(newOptions)
            setLicenseId(newLicenseId);
            setTrial(licenseTemplate.trial);
        }
        
    }

    const handleLicenseChange = (event) => {
        setChangeLicenseConfirmOpen(true);
        setNewLicenseId(event.target.value);
    }

    const handleTrialChange = (event) => {
        setTrial(event.target.checked);
    }
    const handleLicenseEndDateChange = (newDate) => {
        setLicenseEndDate(newDate);
    }
    
    const handleSubIdChange = (event) => {
        setSubscriptionId(event.target.value);
    }

    const revealeadOnlyPassword = async (deploymentId) => {
        let pw = await companyLicenseService.getRoPassword(deploymentId);
        setRevealedPw(pw);
        setOpenPasswordAlert(true);
    }

    const revealReadWritePassword = async (deploymentId) => {
        let pw = await companyLicenseService.getRwPassword(deploymentId);
        setRevealedPw(pw);
        setOpenPasswordAlert(true);
    }

    const pwReavealAlertClosed = () => {
        setOpenPasswordAlert(false);
        setRevealedPw(null);
    }

    const onSave = async () => {
        console.log("licenseEndDate: ", licenseEndDate);
        let updatedOptions = [...updatedLicenseOptions];

        let updateCompanyLicense = {
            companyLicenseId: companyLicense.companyLicenseId,
            licenseId: licenseId,
            licenseOptions: updatedOptions,
            subscriptionId: subscriptionId,
            trial : trial,
            licenseEndDate: licenseEndDate?.toDate()
        }
        try {
            await dispatch(companyLicenseActions.saveCompanyLicense(updateCompanyLicense));
            navigate(`/clients/${companyId}`);
        } catch (e){
            console.error(e);
        }
        
    }

    const onDeactivate = async() => {
        try {
            await dispatch(companyLicenseActions.deactivateCompanyLicense(companyLicense.companyLicenseId));
            navigate(`/clients/${companyId}`);
        } catch (e){
            console.error(e);
        }
    }

    const onActivate = async() => {
        try {
            await dispatch(companyLicenseActions.activateCompanyLicense(companyLicense.companyLicenseId));
            navigate(`/clients/${companyId}`);
        } catch (e){
            console.error(e);
        }
    }

    const onAddNewOption = () => {
        if (newOptionName !== null && newOptionValue !== null){
            const newOptions = [...licenseOptions];
            var date = new Date();
            newOptions.push({id: `#NEW_${date.getMilliseconds()}`, optionName: newOptionName, optionValue: newOptionValue});
            setNewOptionName(null);
            setNewOptionValue(null);
            setLicenseOptions(newOptions);
            setShowNewOptionModal(false);
            gridRef.current.scrollToIndex(newOptions.length,{direction:"bottom", duration:200, force: true, offset: 40} )
        }
    }
    const closeNewOptionModal = () => {
        setNewOptionName(null);
        setNewOptionValue(null);
        setShowNewOptionModal(false);
    }

    const handleLicenseOptionChange = (licOpts) => {
        setUpdatedLicenseOptions(licOpts);
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ConfirmDialog open={changeLicenseConfirmOpen} 
                title="Change License?" 
                description="Are you sure you want to change the license?"
                cancelButtonText="No, Don't Change"
                confirmButtonText='Yes Change License'
                cancelCallback={cancelChangeLicense}
                confirmCallback={confirmChangeLicense}
            />
            <AlertDialog open={openPasswordAlert} description={revealedPw} title="Password reveal" okCallback={pwReavealAlertClosed}/>
            <Modal open={showNewOptionModal} onClose={closeNewOptionModal}>
                <ModalContent>
                    <Typography variant='h4'>New License Option</Typography>
                    <Grid container >
                        <Grid item sm={6}>
                            <TextField label="Option Name*" style={{padding:5, width:"100%"}} value={newOptionName} onChange={(e)=> {setNewOptionName(e.target.value)}}/>
                        </Grid>
                        <Grid item sm={6}>
                            <TextField label="Option Value*" style={{padding:5,  width:"100%"}} value={newOptionValue} onChange={(e)=> {setNewOptionValue(e.target.value)}}/>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" style={{marginTop:20}}>
                        <Grid item>
                            <Button onClick={onAddNewOption}>Add</Button>
                            <Button onClick={closeNewOptionModal}>Cancel</Button>
                        </Grid>
                    </Grid>
                </ModalContent>
                
            </Modal>
            <PrivateLayout>
                <Container maxWidth="xl">
                {loading || companyLicense === null || companyLicense === undefined? <CircularProgress/> : 
                <LicenseBox productName={companyLicense.productName} productCode={companyLicense.productCode}>
                    <Grid container spacing={2}>
                        <Grid item sm={4}>
                            <Grid item xs={12}><HeadingBox>License Key:</HeadingBox></Grid>
                            <Grid item xs={12}>{companyLicense?.licenseKey}</Grid>
                        </Grid>
                        <Grid item sm={4}>
                            <Grid item xs={12}><HeadingBox>License Type:</HeadingBox></Grid>
                            <Grid item xs={12}>
                                <SelectFormControl variant="outlined">

                                    <Select  id="select" value={licenseId} onChange={handleLicenseChange}>
                                        {licenseTemplates && licenseTemplates.map(l =>
                                            <MenuItem key={l.id} value={ l.id }>{ l.licenseName }</MenuItem>
                                        )}
                                    </Select>
                                </SelectFormControl>
                            </Grid>
                        </Grid>
                        <Grid item sm={4}>
                            <Grid item xs={12}><HeadingBox>Subscription Id:</HeadingBox></Grid>
                            <Grid item xs={12}>
                                <TextField variant="outlined" fullWidth  value={subscriptionId} onChange={handleSubIdChange}
                                           inputProps={{maxLength: 50}}/>
                            </Grid>
                        </Grid>
                        {trial &&
                            <>
                                <Grid item sm={4}>
                                    <Grid item xs={12}><HeadingBox>Trial End:</HeadingBox></Grid>
                                    <Grid item xs={12}>
                                        <DateTimePicker value={licenseEndDate}   onChange={handleLicenseEndDateChange}/>
                                    </Grid>
                                </Grid>

                            </>

                        }
                        <Grid item sm={4}>
                            <FormControlLabel control={ <Checkbox  checked={trial} onChange={handleTrialChange} /> } label="Trial"/>

                        </Grid>

                        <Grid item sm={4}>
                            <Grid item xs={12}><HeadingBox sx={{  }}>License Status:</HeadingBox></Grid>
                            <Grid item xs={12}>{companyLicense.licenseActive ? "Active" : "Inactive"}</Grid>
                        </Grid>
                    </Grid>
                    {companyLicense.hasTestSite ? (
                        <Grid container>
                            <Grid item xs={12}>
                                <Tabs value={selectedDeploymentTab} onChange={(event, newValue) => setSelectedDeploymentTab(newValue)} aria-label="basic tabs example">
                                    <Tab label="Live Deployment" id="deployment-tab-0" />
                                    <Tab label="Test Deployment" id="deployment-tab-1" />
                                </Tabs>
                            </Grid>
                            <Grid item xs={12}>
                                <TabPanel value={selectedDeploymentTab} index={0}>
                                    <DeploymentSettings
                                        companyLicense={companyLicense} isTest={false}
                                        revealReadOnlyPassword={() => revealeadOnlyPassword(companyLicense.deploymentId)}
                                        revealReadWritePassword={() => revealReadWritePassword(companyLicense.deploymentId)}
                                    />
                                </TabPanel>
                                <TabPanel value={selectedDeploymentTab} index={1}>
                                    <DeploymentSettings
                                        companyLicense={companyLicense} isTest={true}
                                        revealReadOnlyPassword={() => revealeadOnlyPassword(companyLicense.testDeploymentId)}
                                        revealReadWritePassword={() => revealReadWritePassword(companyLicense.testDeploymentId)}
                                    />
                                </TabPanel>
                            </Grid>
                        </Grid>
                        ): (
                        <DeploymentSettings
                            companyLicense={companyLicense} isTest={false}
                            revealReadOnlyPassword={() => revealeadOnlyPassword(companyLicense.deploymentId)}
                            revealReadWritePassword={() => revealReadWritePassword(companyLicense.deploymentId)}
                        />
                    )}

                    <Grid container>
                        <Grid item xs={12}>
                            <hr/>
                        </Grid>
                        <Grid item xs={12}>
                            <Tabs value={selectedTab} onChange={(event, newValue) => setSelectedTab(newValue)} aria-label="basic tabs example">
                                <Tab label="Customer License Options" id="tab-0" />
                                <Tab label="License Template Options" id="tab-1" />
                            </Tabs>
                        </Grid>
                        <Grid item xs={12}>
                            <TabPanel value={selectedTab} index={0}>
                                <CustomerLicenseModulesEditor companyLicenseId={companyLicenseId} customerLicenseOptions={licenseOptions} licenseOptions={templateDataSource} editable={true} onChange={(data) => {setUpdatedLicenseOptions(data)}}/>
                            </TabPanel>
                            <TabPanel value={selectedTab} index={1}>
                                <LicenseModulesEditor editable={false} licenseOptions={templateDataSource} onChange={() => {}} />
                            </TabPanel>
                        </Grid>
                        <Grid item sm={6} style={{textAlign: "left"}}>
                            <SaveButton
                                color="primary"
                                type="button"
                                variant="contained"
                                onClick={onSave}
                            >
                                Save
                            </SaveButton>
                            <CancelButton
                                color="error"
                                type="button"
                                variant="contained"
                                onClick={() => navigate(`/clients/${companyId}`)}

                            >
                                Cancel
                            </CancelButton>
                        </Grid>
                        <Grid item sm={6} style={{textAlign: "right"}}>
                            {companyLicense.licenseActive ?
                                <DeactivateButton
                                    color="secondary"
                                    type="submit"
                                    variant="contained"
                                    onClick={onDeactivate}
                                >
                                    Deactivate
                                </DeactivateButton>
                                :
                                <DeactivateButton
                                    color="secondary"
                                    type="submit"
                                    variant="contained"
                                    onClick={onActivate}
                                >
                                    Activate License
                                </DeactivateButton>
                            }
                        </Grid>
                    </Grid>

                </LicenseBox>
                }
                </Container>
            </PrivateLayout>
        </LocalizationProvider>
        
    )
}

export {ClientLicenseEditPage};