import axios from '../_helpers/axiosInstance';
import { authHeader } from '../_helpers/authHeader';

export const customerSnapshotService = {
    getSnapshots,
    restoreSnapshot,
    deleteSnapshot, 
    createSnapshot

};

async function getSnapshots(){
    console.log("about to get snapshots");
    var response = await axios.get(`/customerSnapshot`, {headers: authHeader()});
    return response.data.result;
}


async function deleteSnapshot(snapshotId){
    console.log('About to delete snapshot');
    await axios.delete(`/customerSnapshot/${snapshotId}`, {headers: authHeader()});
}

async function restoreSnapshot(snapshotId, deploymentId){
    console.log('About to restore snapshot');
    await axios.put(`/customerSnapshot/${snapshotId}/restore/deployment/${deploymentId}`,null, {headers: authHeader()});
}

async function createSnapshot(deploymentId, description){
    console.log('About to create snapshot');
    let snapshotData = {deploymentId,description};
    await axios.post(`/customerSnapshot`,snapshotData, {headers: authHeader()});
}