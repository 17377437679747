import React, { useState, useEffect } from 'react';
import { Controller, useForm } from "react-hook-form";
import {Link, useNavigate, useParams} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import {Button, TextField, Typography, FormControl, InputLabel, Select, MenuItem, FormHelperText, FormControlLabel, Checkbox} from '@mui/material';
import { styled } from '@mui/material/styles';
import { PrivateLayout } from "../../../components/PrivateLayout";
import CircularProgress from '@mui/material/CircularProgress';
import ReactDataGrid from "@inovua/reactdatagrid-community";
import { useSelector, useDispatch } from 'react-redux';
import { seedManagementActions } from "../../../_actions";
import { industryManagementActions } from "../../../_actions";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const SaveButton = styled(Button)({
    marginTop: 15,
    marginRight: 15
});

const CancelButton = styled(Button)({
    marginTop: 15,
    marginRight: 15
});

const SelectFormControl = styled(FormControl)({
    marginTop: 16,
    width:"100%"
  });

const gridStyle = { minHeight: 150 }

function SeedDetailPage () {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { seedId } = useParams()
    const defaultFirstSeeds = useSelector(state=>state.seedManagement.defaultFirstSeeds ? state.seedManagement.defaultFirstSeeds : [])
    const industryList = useSelector(state=>state.industryManagement.industryList ? state.industryManagement.industryList : null)
    const seed = useSelector(state=> {
        let selectedSeed = {}
        if(state.seedManagement.defaultFirstSeeds){
            state.seedManagement.defaultFirstSeeds.forEach(s=>{
                if(s.id == seedId){
                    selectedSeed = s
                }
            })
        }
        return selectedSeed
    })
    let saving = useSelector((state) => state.seedManagement.savingSeedDetails);
    const standardDeployments = useSelector( state => state.seedManagement.standardDeployments ? state.seedManagement.standardDeployments : []);
    const [loading, setLoading] = useState(false);
    const [disableSeedName, setDisableSeedName] = useState(seed.seedName && seed.isDefaultSeed && seed.seedName.toLowerCase() === "default seed")
    const [seedIndustries, setSeedIndustries] = useState([])

    useEffect(() => { 
        dispatch(seedManagementActions.getStandardDeploymentList());
        dispatch(industryManagementActions.getIndustryList());
    }, []);

    useEffect(() => { 
        setSeedIndustries(getIndustriesBySeed(seedId))
    }, [industryList]);

    const validationSchema = Yup.object().shape({
        seedName: Yup.string()
            .required('You must enter a seed name')
            .min(1, 'The seed name can not be empty')
            .max(100, 'The seed name must not exceed 100 characters')
            .test("non-duplicate", "The duplicate seed name(case insensitive) is not allowed", (seedName)=>{
                return !isSeedNameDuplicate(seedName)
            }),
        deploymentId: Yup.string()
            .required('You must choose a deploment ID')
            .min(1, 'You must choose a deploment ID')
    });

    const hasDefaultSeed=()=>{
        let defaultSeed = defaultFirstSeeds.filter(s => s.isDefaultSeed && s.seedName.toLowerCase() === "default seed")
        return defaultSeed.length > 0
    }

    const getIndustriesBySeed=(seedId)=>{
        let industries=[]
        if(seedId && industryList){
            industries = industryList.filter(ind=>ind.deploymentSeedId == seedId)
        }
        return industries
    }

    const handleDeploymentChange = (event, field) => {
        field.onChange(event)
    }

    const isSeedNameDuplicate = (seedName)=>{
        //Remove the current seed first so that the current seed name will not be treated as duplicate
        let filtered = defaultFirstSeeds.filter(s=>s.seedName.toLowerCase() !== (seed.id ? seed.seedName.toLowerCase() : null))
        filtered = filtered.filter(s=>s.seedName.toLowerCase() === seedName.toLowerCase())
        return filtered.length > 0
    }

    const { control, handleSubmit, register, setValue, setFocus, formState: { errors }} = useForm({
        mode: "onChange",
        resolver: yupResolver(validationSchema),
    });

    const setAsDefaultSeed = (e, field) => {
        if(field.value){
            setValue("seedName", "")
            setDisableSeedName(false)
            field.onChange(e)   
        }else{
            //The current value is false so turn it to true, i.e. default seed
            setValue("seedName", "Default Seed")
            setDisableSeedName(true)
            field.onChange(e)            
        }
    }

    const onSubmit = async data => {
        let updatedSeed = seed.id ? {...seed} : {}
        updatedSeed.deploymentId = data.deploymentId
        updatedSeed.seedName = data.seedName
        updatedSeed.isDefaultSeed = data.defaultSeed
        if(seed.id){
            //Edit seed
            await dispatch(seedManagementActions.saveSeedDetails(updatedSeed))
            navigate("/seedmanagement");
        } else {
            //New seed
            await dispatch(seedManagementActions.addSeed(updatedSeed))
            navigate("/seedmanagement");
        }
    };

    const industryColumns = [
        { name: 'industryName', header: 'Industry Name', defaultFlex: 3, enableColumnFilterContextMenu: true},
        { name: 'description', header: 'Description', defaultFlex: 9, enableColumnFilterContextMenu: true},
    ]

    return (
        <PrivateLayout>
            <Container style={{paddingBottom:20}}>
            <CssBaseline />
                <Typography variant="h3">{seed.id ? "Seed: "+seed.seedName : "New Seed" }</Typography>
                <br/>
                {loading ? (
                    <CircularProgress/>
                ): (
                    <form onSubmit={handleSubmit(onSubmit)} style={{width: '100%'}}>
                    <Grid container style={{marginTop: 20}} spacing={2}>
                        <Grid item xs={12} sm={6}>
                                <Controller
                                    name="seedName"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue={seed.seedName ? seed.seedName : ""}
                                    render={({field}) => 
                                        <TextField variant="outlined"
                                            fullWidth label="Seed Name*" margin="normal" {...field} error={errors.seedName ? true : false}
                                            helperText={errors.seedName?.message} inputProps={{maxLength: 100}} 
                                            disabled={disableSeedName}/>
                                        
                                    }
                                />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SelectFormControl style={{marginTop: 16}} variant="outlined">
                                <InputLabel htmlFor="deploymentId">Deployment ID* </InputLabel>
                                <Controller
                                    name="deploymentId"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue={seed.id ? seed.deploymentId : ""}
                                    render={({field})=><Select label="Deployment ID*" id="deploymentId" labelId="lblDeploymentId" 
                                        defaultValue={seed.id ? seed.deploymentId : ""} {...field}
                                        onChange={(e)=>handleDeploymentChange(e, field)} error={errors.deploymentId ? true : undefined}>
                                            {
                                                standardDeployments?.map(d => (
                                                    <MenuItem key={d.deploymentId} value={d.deploymentId}>
                                                        {d.deploymentId} - {d.customerName}({d.domain})
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>}
                                />
                                <FormHelperText error={errors.deploymentId ? true : undefined}>{errors.deploymentId?.message}</FormHelperText>
                            </SelectFormControl>
                        </Grid>
                    </Grid>
                    {!hasDefaultSeed() && <Grid item xs={12} sm={6}>
                        <Controller
                            name="defaultSeed"
                            control={control}
                            inputRef={register()}
                            defaultValue={false}
                            render={({ field }) => 
                                <FormControlLabel control={<Checkbox checked={field.value} onChange={(e)=>setAsDefaultSeed(e, field)} 
                                onBlur={field.onBlur} />} style={{marginTop:20}} label={`Default Seed?`} />
                            }
                        />
                    </Grid>}
                    <br/><br/>
                    <Grid>
                        <Grid item>
                            <Typography variant='h5'>Related Industries</Typography>
                        </Grid>
                    </Grid>
                    <br/>
                    <Grid container>
                        <Grid xs={12}>
                            <ReactDataGrid
                                idProperty="Id"
                                columns={industryColumns}
                                dataSource={seedIndustries}
                                style={gridStyle}
                            />
                        </Grid>
                    </Grid>
                    <br /><br />
                    {!saving && <SaveButton
                        color="primary"
                        type="submit"
                        variant="contained"
                    > 
                        Save Changes
                    </SaveButton>}
                    {saving && <CircularProgress />}
                    <Link to="/seedmanagement" style={{ textDecoration: 'none' }}>
                        <CancelButton
                            color="secondary"
                            type="button"
                            variant="contained"
                        > 
                            Cancel
                        </CancelButton>
                    </Link>
                </form> 
                )}
            </Container>
        </PrivateLayout>
    )
}

export {SeedDetailPage};