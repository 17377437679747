import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { licenseTemplateActions } from "../../../_actions/licenseTemplate.actions";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Checkbox, CircularProgress, Container, CssBaseline, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Modal, Paper, Select, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { licenseTemplateService } from '../../../_services/licenseTemplate.service';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { PrivateLayout } from "../../../components/PrivateLayout";
import LicenseModulesEditor from "../../../components/LicenseModulesEditor";

const gridStyle = { minHeight: 550 }

let inEdit;


const ModalContent = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    padding:50,
    transform: 'translate(-50%, -50%)',
    width: "70%",
    bgcolor: 'background.paper',
    backgroundColor:"#FFFFFF",
    margin:40,
    boxShadow: 24,
    maxWidth:750,
    pt: 2,
    px: 4,
    pb: 3,
})

const SaveButton = styled(Button)({
    marginTop: 15,
    marginRight: 15
});

const CancelButton = styled(Button)({
    marginTop: 15,
    marginRight: 15
});


const SelectFormControl = styled(FormControl)({
    width:"100%"
})

function NewLicenseTemplatePage(){
    let dispatch = useDispatch();
        
    let saving = useSelector((state) => state.licenseTemplates.savingLicenseTemplate);
    const [products, setProducts] = useState([]);
    const [initLicenseOptions, setInitLicenseOptions] = useState([]);
    const [newLicenseOptions, setNewLicenseOptions] = useState([]);
    let navigate = useNavigate();

    useEffect(() => {
        licenseTemplateService.getProducts().then(result => setProducts(result));
    },[]);

    
    const validationSchema = Yup.object().shape({
        licenseName: Yup.string()
            .required('You must enter a license name')
            .max(100, 'License name must not exceed 100 characters'),
        productId: Yup.number()
            .required('Required'),
        timeLimited: Yup.bool(),
        trial: Yup.bool(),
        defaultDurationInDays: Yup.number()
            .min(1).max(999999).nullable(true)
            .transform((_, val) => isNaN(parseInt(val))? null: parseInt(val) ) 
            .when('timeLimited', {
                is: true,
                then: Yup.number()
                .nullable(true)
                .transform((_, val) => isNaN(parseInt(val))? null: parseInt(val) )
                .required("Must enter default duration if time limited")
            })
    });
    
    const { control, handleSubmit, register, formState: { errors }} = useForm({
        resolver: yupResolver(validationSchema)
    });



    const onSubmit = async data => {
        let updatedOptions = [...newLicenseOptions];
        let newLicenseTemplate = {
            licenseName: data.licenseName,
            productId: data.productId,
            timeLimited: data.timeLimited, 
            trial: data.trial,
            defaultDurationInDays: data.defaultDurationInDays,
            licenseOptions: updatedOptions
        }
        await dispatch(licenseTemplateActions.addLicenseTemplate(newLicenseTemplate));
        navigate("/licenses");
    };

    return (
        <PrivateLayout>
            <Container>
                <CssBaseline />
                <Typography variant='h3'>New License Template</Typography>
                <form onSubmit={handleSubmit(onSubmit)} style={{width: '100%'}}>
                    <Grid container style={{marginTop: 20}} spacing={2}>
                        <Grid item xs={12} sm={6}>
                                <Controller
                                    name="licenseName"
                                    control={control}
                                    inputRef={register()}
                                    render={({ field }) => 
                                        <TextField variant="outlined" fullWidth label="License Name*" margin="normal" {...field} error={errors.licenseName ? true : false}
                                            helperText={errors.licenseName?.message} inputProps={{maxLength: 100}}/>
                                        
                                    }
                                />
                                
                        </Grid>
                        <Grid item xs={12} sm={3}>
                                <Controller
                                    name="timeLimited"
                                    control={control}
                                    inputRef={register()}
                                    render={({ field}) => 
                                        <FormControlLabel control={<Checkbox  checked={field.value} onChange={field.onChange} onBlur={field.onBlur}  />} style={{marginTop:20}} label="Time Limited?" />
                                    }
                                />
                                
                        </Grid>
                        <Grid item xs={12} sm={3}>
                                <Controller
                                        name="trial"
                                        control={control}
                                        inputRef={register()}
                                        render={({ field }) => 
                                            <FormControlLabel control={<Checkbox checked={field.value} onChange={field.onChange} onBlur={field.onBlur} />} style={{marginTop:20}} label={`Trial?`} />
                                        }
                                    />
                            </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Grid item style={{marginTop:16}}>
                                <SelectFormControl  variant="outlined" error={errors.productId}>
                                    <InputLabel id='productIdLabel' >Product*</InputLabel>
                                    <Controller
                                            name="productId"
                                            control={control}
                                            inputRef={register()}
                                            render={({ field }) => 
                                                <Select helperText={errors.productId?.message} label="Product*" style={{width:"100%"}} labelId="productIdLabel" id="productId"  {...field}>
                                                    {products.map(product => <MenuItem value={product.id}>{product.productName}</MenuItem>)}              
                                                </Select>
                                            }
                                        />
                                        <FormHelperText>
                                            {errors.productId?.message}
                                        </FormHelperText>
                                </SelectFormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                    name="defaultDurationInDays"
                                    control={control}
                                    inputRef={register()}
                                    render={({ field }) => 
                                        <TextField type="number" variant="outlined" fullWidth label="Default Duration in Days*" margin="normal" {...field} error={errors.defaultDurationInDays ? true : false}
                                            helperText={errors.defaultDurationInDays?.message} inputProps={{maxLength: 100}}/>
                                        
                                    }
                                />
                        </Grid>
                        
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <LicenseModulesEditor editable={true} licenseOptions={initLicenseOptions} onChange={(data) => {setNewLicenseOptions(data)}}/>
                        </Grid>
                    </Grid>
                    <br /><br />
                    {!saving &&             
                        <SaveButton
                            color="primary"
                            type="submit"
                            variant="contained"
                        > 
                            Save Changes
                        </SaveButton>
                    }
                    {saving && <CircularProgress />}
                    <Link to="/licenses" style={{ textDecoration: 'none' }}>
                        <CancelButton
                            color="secondary"
                            type="button"
                            variant="contained"
                        > 
                            Cancel
                        </CancelButton>
                    </Link>
                    
                </form> 
                
                
                
            </Container>
        </PrivateLayout>
    )
}

export {NewLicenseTemplatePage};

