import React, {useCallback, useEffect, useState} from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import {Button, Checkbox} from "@mui/material";
import _ from "lodash"
import NumericEditor from '@inovua/reactdatagrid-community/NumericEditor'

const gridStyle = { minHeight: 550 }
function ModuleGrid({data, onUpdate, editable, onModuleSelectionChanged, style = gridStyle}) {

    const [moduleData, setModuleData] = useState([]);

    useEffect(() => {
        setModuleData(data);
    }, [data]);
    const updateRwCheckbox = (value, moduleName) => {
        const data = [...moduleData];
        const toUpdate = data.find(x => x.moduleName === moduleName);
        toUpdate.rwEnabled = value;
        if (!value){
            toUpdate.rwUsers = null;
        }
        setModuleData(data);
        onUpdate(data);
    }
    const updateRoCheckbox = (value, moduleName) => {
        const data = [...moduleData];
        const toUpdate = data.find(x => x.moduleName === moduleName);
        toUpdate.roEnabled = value;
        if (!value){
            toUpdate.roUsers = null;
        } else {
            toUpdate.roUsers = 1;
        }
        setModuleData(data);
        onUpdate(data);
    }


    const onEditComplete = useCallback(({ value, columnId, rowId }) => {
        if (value && value < 0 ) return;
        const data = [...moduleData];
        const toUpdate = data.find(x => x.moduleName === rowId);
        toUpdate[columnId] = value !== 0 ? value : null;
        if (value){
            if (columnId.toLowerCase().startsWith("ro")){
                toUpdate.roEnabled = value > 0;
            } else {
                toUpdate.rwEnabled = value > 0;
            }
        } else {
            if (columnId.toLowerCase().startsWith("ro")){
                toUpdate.roEnabled = false;
            }
        }

        setModuleData(data);
        onUpdate(data);
    }, [moduleData])

    const handleSelectionChange = (e) => {
        console.log(e);
        if (!onModuleSelectionChanged){
            return;
        }
        if (e.selected === null){
            onModuleSelectionChanged(null);
        } else {
            onModuleSelectionChanged(e.data.moduleKey);
        }

    }

    const columns = [
        { name: 'moduleName', header: 'Module', defaultFlex: 1, editable: false },
        { name: 'rwEnabled', header: 'RW Enabled', defaultFlex: 1, editable: false, sortable: false,
            render: ({data: {moduleName, rwEnabled}, rowIndex, rowId }) => {
                //console.log(props.rowIndex);
                return <Checkbox checked={rwEnabled}  readOnly={!editable} disabled={!editable} onChange={e => {
                    updateRwCheckbox(e.target.checked, moduleName)
                }}/>
            } },
        { name: 'rwUsers', header: 'Fixed # Users', type:'number', defaultFlex: 1, editable: editable, editor: NumericEditor },
        { name: 'roEnabled', header: 'RO Enabled', defaultFlex: 1, editable: false, sortable: false,
            render: ({data: {moduleName, roEnabled}, rowIndex, rowId}) => {
                //console.log(props.rowIndex);
                return <Checkbox checked={roEnabled} readOnly={!editable} disabled={!editable} onChange={e => {
                    updateRoCheckbox(e.target.checked, moduleName)
                }}/>
            } },
        { name: 'roUsers', header: 'Fixed # RO Users', type:'number',defaultFlex: 1, editable: editable, editor: NumericEditor },
    ]

    return (
        <ReactDataGrid
            editable={editable}
            idProperty="moduleName"
            onEditComplete={onEditComplete}
            columns={columns}
            onSelectionChange={handleSelectionChange}
            dataSource={moduleData}
            enableSelection={true}
            toggleRowSelectOnClick={true}
            style={style}
        />
    );
}

export default ModuleGrid;