import React, {useEffect, useState} from "react";
import ModuleGrid from "./ModuleGrid";
import {
    Button,
    Grid,
    Modal,
    TextField,
    Typography,
    Container,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import {isNumber} from "@mui/x-data-grid/internals";
import {styled} from "@mui/material/styles";
import {Box} from "@mui/system";


const ModalContent = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    padding:50,
    transform: 'translate(-50%, -50%)',
    width: "70%",
    bgcolor: 'background.paper',
    backgroundColor:"#FFFFFF",
    margin:40,
    boxShadow: 24,
    maxWidth:750,
    pt: 2,
    px: 4,
    pb: 3,
});



const moduleNamesMap = [
    ['ADMIN', 'Administration'],
    ['AzzierMobileApp', 'Azzier Mobile App'],
    ['iOSWo', 'IOS Mobile App'],
    ['AndroidWo', 'Android Mobile App'],
    ['Codes', 'Codes'],
    ['Customer', 'Customer'],
    ['Document', 'Document'],
    ['Equipment', 'Equipment'],
    ['Help', 'Help'],
    ['Interface', 'Interface'],
    ['Inventory', 'Inventory'],
    ['KPI', 'Key Performance Indicator'],
    ['Labour', 'Labour'],
    ['Location', 'Location'],
    ['Notification', 'Notification'],
    ['PDM', 'Predictive Maintenance'],
    ['PM', 'Preventive Maintenance'],
    ['PrintFormDesigner', 'Print Form Designer'],
    ['Procedure', 'Procedure'],
    ['Project', 'Project'],
    ['Purchase', 'Purchase'],
    ['QueryBuilder', 'Query Builder'],
    ['Receiving', 'Receiving'],
    ['ReportBuilder', 'Report Builder'],
    ['Requisition', 'Requisition'],
    ['Route', 'Route'],
    ['Scheduling', 'Scheduling'],
    ['ScreenDesigner', 'Screen Designer'],
    ['Storeroom', 'Storeroom'],
    ['Timecards', 'Timecards'],
    ['Vendor', 'Vendor'],
    ['Workorder', 'Work Order'],
    ['WorkRequest', 'Work Request']
];


function CustomerLicenseModulesEditor({companyLicenseId, customerLicenseOptions, licenseOptions, editable, onChange}){

    const [moduleDataArray, setModuleDataArray] = useState([]);
    const [formData, setFormData] = useState({
        maxEquipment: '', maxLocation: '', maxInventory: '', totalUsers: ''
    })
    const [licOptions, setLicOptions] = useState([]);
    const [showNewOverride, setShowNewOverride] = useState(false);
    const [overrideModuleKey, setOverrideModuleKey] = useState(null);
    const [selectedModule, setSelectedModule] = useState(null);
    const [defaultMaxEquip, setDefaultMaxEquip] = useState(null);
    const [defaultMaxInventory, setDefaultMaxInventory] = useState(null);
    const [defaultMaxLocation, setDefaultMaxLocation] = useState(null);

    const updateLicenseOptions = () => {
        console.log("updating license options");
        let newLicenseOptions = [];
        moduleDataArray.forEach(o => {
            let rwLicenseOption = licOptions.find(lo => lo.optionName.toLowerCase() === o.moduleKey.toLowerCase());
            let roLicenseOption = licOptions.find(lo => lo.optionName.toLowerCase() === `ro_${o.moduleKey.toLowerCase()}`);
            if (rwLicenseOption){
                rwLicenseOption.optionValue = o.rwUsers ? `!${o.rwUsers}` : (o.rwEnabled ? '1' : '0');
                newLicenseOptions.push(rwLicenseOption);
            } else {
                newLicenseOptions.push({
                    id: null,
                    optionName: o.moduleKey,
                    optionValue: o.rwUsers ? `!${o.rwUsers}` : (o.rwEnabled ? '1' : '0')
                });
            }

            if (roLicenseOption){
                roLicenseOption.optionValue = o.roUsers ? `!${o.roUsers}` : (o.roEnabled ? '1' : '0');
                newLicenseOptions.push(roLicenseOption);
            } else {
                newLicenseOptions.push({
                    id: null,
                    optionName: 'ro_' + o.moduleKey,
                    optionValue: o.rwUsers ? `!${o.roUsers}` : (o.roEnabled ? '1' : '0')
                });
            }
        });
        updateOption(licOptions, newLicenseOptions, 'max_Location', formData.maxLocation);
        updateOption(licOptions, newLicenseOptions, 'max_Equipment', formData.maxEquipment);
        updateOption(licOptions, newLicenseOptions, 'max_Inventory', formData.maxInventory);
        updateOption(licOptions, newLicenseOptions, 'TotalUsers', formData.totalUsers);

        setLicOptions(newLicenseOptions);
        onChange(newLicenseOptions);
    }

    const updateOption = (existingLicenseOptions, newlicenseOptions, optionName, optionValue) => {
        if (optionValue !== null && optionValue !== ''){
            //there is a value set so update or add the option
            let optionToUpdate = existingLicenseOptions.find(lo => lo.optionName.toLowerCase() === optionName.toLowerCase());
            if (optionToUpdate) {
                optionToUpdate.optionValue = optionValue;
                newlicenseOptions.push(optionToUpdate);
            } else {
                newlicenseOptions.push({
                    id: null, optionName, optionValue
                });
            }
        }
    }

    const convertLicenseOptionsToModuleOptions = (licenseOptions) => {
        const newModuleDataArray = [];
        if (licenseOptions === null) return newModuleDataArray;
        const newFormValues = {maxEquipment : '', maxLocation: '', maxInventory: '', totalUsers: ''};
        licenseOptions.forEach(l => {
            let isRo = isReadOnlyOption(l.optionName)
            let moduleKey = l.optionName;
            if (isRo){
                moduleKey = l.optionName.replace("ro_", "");
            }
            var moduleEntry = newModuleDataArray.find( m => m.moduleKey.toLowerCase() === moduleKey.toLowerCase());
            if (!moduleEntry){
                var moduleNameEntry = moduleNamesMap.find(mnm => mnm[0].toLowerCase() === moduleKey.toLowerCase());
                if (moduleNameEntry){
                    moduleEntry = {
                        moduleKey: l.optionName,
                        moduleName: moduleNameEntry[1],
                        rwEnabled: false,
                        rwUsers: null,
                        roEnabled: false,
                        roUsers: null
                    };
                    newModuleDataArray.push(moduleEntry);
                }
            }
            if (moduleEntry){
                if (isRo) {
                    moduleEntry.roEnabled = isModuleEnabled(l.optionValue);
                    moduleEntry.roUsers =  getFixedUserCount(l.optionValue);
                } else {
                    moduleEntry.rwEnabled = isModuleEnabled(l.optionValue);
                    moduleEntry.rwUsers = getFixedUserCount(l.optionValue);
                }
            }

            if (l.optionName.toLowerCase() === 'max_equipment') newFormValues.maxEquipment = l.optionValue;
            if (l.optionName.toLowerCase() === 'max_location') newFormValues.maxLocation = l.optionValue;
            if (l.optionName.toLowerCase() === 'max_inventory') newFormValues.maxInventory = l.optionValue;
            if (l.optionName.toLowerCase() === 'totalusers') newFormValues.totalUsers = l.optionValue;
        });
        setFormData(newFormValues);
        console.log("CONVERTED MODULE DATA");
        console.log(newModuleDataArray);
        return newModuleDataArray;
    }

    const isModuleEnabled = (optionValue) => {
        return optionValue !== '0' && optionValue !== '!0';
    }

    const getFixedUserCount = (optionValue) => {
        if (optionValue.startsWith('!')){
            let option = optionValue.replace('!', '');
            if (isValidUserCount(option)){
                return parseInt(option);
            }
        }
        return null;
    }

    const isValidUserCount = (str) => {
        // Use unary plus operator (+) to convert string to number
        // and then check if it's a finite number
        return !Number.isNaN(+str) && Number.isFinite(+str) && +str;
    };
    const isReadOnlyOption = (optionName) => {
        if (optionName !== null){
            return optionName.startsWith("ro_");
        }
    }

    const maxEquipmentChanged = (e) => {
        setFormData(prevState => {
            return {...prevState, maxEquipment: e.target.value};
        });
    }
    const maxLocationChanged = (e) => {
        setFormData(prevState => {
            return {...prevState, maxLocation: e.target.value};
        });
    }
    const maxInventoryChanged = (e) => {
        setFormData(prevState => {
            return {...prevState, maxInventory: e.target.value};
        });
    }

    const totalUsersChanged = (e) => {
        setFormData(prevState => {
            return {...prevState, totalUsers: e.target.value};
        });
    }


    useEffect(() => {
        console.log("formData changed");
        updateLicenseOptions();
    }, [formData]);

    useEffect(() => {
        console.log("moduleDataArray changed");
        updateLicenseOptions();
    }, [moduleDataArray]);

    useEffect(() => {
        console.log("customerLicenseOptions changed");
        var moduleDataArray = convertLicenseOptionsToModuleOptions(customerLicenseOptions)
        setModuleDataArray(moduleDataArray);
        setLicOptions([...customerLicenseOptions]);
    }, [customerLicenseOptions]);

    useEffect(() => {
        console.log("licenseOptions changed");
        if (licenseOptions != null && licenseOptions.length){
            licenseOptions.forEach(o => {
                if (o.optionName === 'max_Inventory') {
                    setDefaultMaxInventory(o.optionValue);
                }
                if (o.optionName === 'max_Location') {
                    setDefaultMaxLocation(o.optionValue);
                }
                if (o.optionName === 'max_Equipment') {
                    setDefaultMaxEquip(o.optionValue);
                }

            });
        }
    }, [licenseOptions]);

    const onDataUpdate = (data) => {
        console.log(data);
        updateLicenseOptions()
    }

    const showAddNewOverrideModal = () => {
        setShowNewOverride(true);
    }
    const addNewOverride = () => {
        const newModuleDataArray = [...moduleDataArray];
        if (!overrideModuleKey) {
            alert("Please choose a module to set a license override");
            return ;
        }
        var existingOverride = newModuleDataArray.find(m => m.moduleKey == overrideModuleKey);
        if (existingOverride){
            alert("Override already exists");
            return;
        }
        var moduleData = moduleNamesMap.find(m => m[0] === overrideModuleKey);
        newModuleDataArray.push({
            moduleKey: moduleData[0],
            moduleName: moduleData[1],
            rwEnabled: false,
            rwUsers: null,
            roEnabled: false,
            roUsers: null
        })
        setModuleDataArray(newModuleDataArray);
        setShowNewOverride(false);
        setOverrideModuleKey(null);
    }

    const handleModuleSelection = (moduleId) => {
        setSelectedModule(moduleId);
    }
    const closeNewOverrideModal = () => {
        setShowNewOverride(false);
    }

    const handleDeleteOverride = () => {
        if (selectedModule === null) return;
        var newModuleDataArray = moduleDataArray.filter(m => m.moduleKey != selectedModule);
        setModuleDataArray(newModuleDataArray);
        setSelectedModule(null);
    }

    return (
        <>
            <Modal open={showNewOverride} onClose={closeNewOverrideModal}>
                <ModalContent>
                    <Typography variant='h4'>Add Override</Typography>
                    <Grid container >
                        <Grid item sm={12}>
                            <FormControl fullWidth>
                                <InputLabel id="ModuleName">Module</InputLabel>
                                <Select
                                    labelId="ModuleName"
                                    id="ModuleName"
                                    label="Module"
                                    value={overrideModuleKey}
                                    onChange={(e) => {setOverrideModuleKey(e.target.value)}}
                                >
                                    {
                                        moduleNamesMap.map(m =>
                                            <MenuItem value={m[0]}>{m[1]}</MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" style={{marginTop:20}} spacing={2}>
                        <Grid item>
                            <Button variant={"outlined"} onClick={addNewOverride}>Add</Button>
                        </Grid>
                        <Grid item>
                            <Button variant={"outlined"} onClick={closeNewOverrideModal}>Cancel</Button>
                        </Grid>
                    </Grid>
                </ModalContent>

            </Modal>
            <Grid container spacing={2}>
                <Grid item sm={6}><h2>Override License Module</h2></Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item sm={6}>
                    <Button variant={"contained"} onClick={showAddNewOverrideModal}>Add Override</Button>
                    <Button style={{marginLeft:5}} variant={"contained"} onClick={handleDeleteOverride} disabled={selectedModule === null}>Delete Override</Button>
                </Grid>
                <Grid item xs={12}>
                    <ModuleGrid data={moduleDataArray} editable={editable} onUpdate={onDataUpdate} onModuleSelectionChanged={handleModuleSelection} style={{minHeight:300}}/>
                </Grid>
                <Grid item sm={4}>
                    <TextField variant="outlined" value={formData.totalUsers} fullWidth type={"number"} label="Total Users" margin="normal" onChange={totalUsersChanged} disabled={!editable} />
                </Grid>

            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <h4>Override Limits</h4>
                </Grid>
            </Grid>
            <Grid container style={{paddingTop:0}} spacing={2}>
                <Grid item sm={4} >
                    <TextField variant="outlined" value={formData.maxEquipment} fullWidth type={"number"} label="Max Equipment" margin="normal" onChange={maxEquipmentChanged} helperText={defaultMaxEquip ? `Default: ${defaultMaxEquip}`: null}/>
                </Grid>
                <Grid item sm={4}>
                    <TextField variant="outlined" value={formData.maxLocation} fullWidth type={"number"} label="Max Location" margin="normal" onChange={maxLocationChanged} helperText={defaultMaxLocation ? `Default: ${defaultMaxLocation}`: null} />
                </Grid>
                <Grid item sm={4}>
                    <TextField variant="outlined" value={formData.maxInventory} fullWidth type={"number"} label="Max Inventory" margin="normal" onChange={maxInventoryChanged} helperText={defaultMaxInventory ? `Default: ${defaultMaxInventory}`: null} />
                </Grid>
            </Grid>
        </>


    );


}

export default CustomerLicenseModulesEditor;