import { userManagementConstants } from '../_constants';
import { userManagementService } from '../_services';
import { alertActions } from './alert.actions';

export const userManagementActions = {
    getUserList,
    getUserInfo,
    updateUser,
    inviteUser,
    updateUser,
    newUserFromInvite, 
    listInvites,
    deleteInvite
};



function getUserList(){
    return async dispatch => {
        try {
            dispatch(request());
            let userList = await userManagementService.getUserList();
            dispatch(success(userList));
        } catch(e){
            console.error(e);
            dispatch(alertActions.error("Could not get user list"))
            dispatch(error(e));
        }
        
    }

    function request() { return { type: userManagementConstants.LIST_USERS_REQUEST } }
    function success(userList) { return { type: userManagementConstants.LIST_USERS_SUCCESS, userList } }
    function error(error) { return { type: userManagementConstants.LIST_USERS_FAILURE, error } }
}


function getUserInfo(userId){
    return async dispatch => {
        try {
            dispatch(request());
            let userInfo = await userManagementService.getUserInfo(userId);
            dispatch(success(userInfo));
        } catch(e){
            console.error(e);
            dispatch(alertActions.error("Could not get user info"))
            dispatch(error(e));
        }
        
    }

    function request() { return { type: userManagementConstants.GET_USER_INFO_REQUEST } }
    function success(userInfo) { return { type: userManagementConstants.GET_USER_INFO_SUCCESS, userInfo } }
    function error(error) { return { type: userManagementConstants.GET_USER_INFO_FAILURE, error } }
}

function updateUser(userId, securityGroup){
    return async dispatch => {
        try {
            dispatch(request());
            await userManagementService.saveUser(userId, securityGroup);
            dispatch(alertActions.success("User saved."))
            dispatch(success());
        } catch(e){
            console.error(e);
            dispatch(alertActions.error("Could not update user"))
            dispatch(error(e));
        }
        
    }

    function request() { return { type: userManagementConstants.UPDATE_USER_INFO_REQUEST } }
    function success() { return { type: userManagementConstants.UPDATE_USER_INFO_SUCCESS } }
    function error(error) { return { type: userManagementConstants.UPDATE_USER_INFO_FAILURE, error } }
}

function inviteUser(emailAddress, securityGroup){
    return async dispatch => {
        try {
            dispatch(request());
            await userManagementService.inviteUser(emailAddress, securityGroup);
            dispatch(success());
        } catch(e){
            console.error(e);
            dispatch(alertActions.error("Could not invite user"))
            dispatch(error(e));
        }
        
    }

    function request() { return { type: userManagementConstants.INVITE_USER_REQUEST } }
    function success() { return { type: userManagementConstants.INVITE_USER_SUCCESS } }
    function error(error) { return { type: userManagementConstants.INVITE_USER_FAILURE, error } }
}

function listInvites(){
    return async dispatch => {
        try {
            dispatch(request());
            var inviteList = await userManagementService.getInviteList();
            dispatch(success(inviteList));
        } catch(e){
            console.error(e);
            dispatch(alertActions.error("Could not get invite list"))
            dispatch(error(e));
        }
        
    }

    function request() { return { type: userManagementConstants.LIST_INVITES_REQUEST } }
    function success(inviteList) { return { type: userManagementConstants.LIST_INVITES_SUCCESS, inviteList } }
    function error(error) { return { type: userManagementConstants.LIST_INVITES_FAILURE, error } }
}

function deleteInvite(inviteId){
    return async dispatch => {
        try {
            dispatch(request());
            await userManagementService.deleteInvite(inviteId);
            dispatch(success());
            dispatch(alertActions.success("Invited deleted"));
        } catch(e){
            console.error(e);
            dispatch(alertActions.error("Could not delete invite"))
            dispatch(error(e));
        }
        
    }

    function request() { return { type: userManagementConstants.DELETE_INVITE_REQUEST } }
    function success() { return { type: userManagementConstants.DELETE_INVITE_SUCCESS } }
    function error(error) { return { type: userManagementConstants.DELETE_INVITE_FAILURE, error } }
}

function newUserFromInvite(firstName,lastName, phoneNumber, password,inviteKey){
    return async dispatch => {
        try {
            dispatch(request());
            await userManagementService.createUserFromInvite(firstName,lastName, phoneNumber, password,inviteKey);
            dispatch(success());
            dispatch(alertActions("Account created!  You may now log in."));
        } catch(e){
            console.error(e);
            dispatch(alertActions.error("Could not create user"))
            dispatch(error(e));
        }
        
    }

    function request() { return { type: userManagementConstants.INVITE_USER_REQUEST } }
    function success() { return { type: userManagementConstants.INVITE_USER_SUCCESS } }
    function error(error) { return { type: userManagementConstants.INVITE_USER_FAILURE, error } }
}
