import { Box, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Modal, Select, styled, TextField, Typography } from "@mui/material";
import React, {useEffect} from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { releaseActions } from "../../../_actions";

const ModalContent = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    padding:50,
    transform: 'translate(-50%, -50%)',
    width: "70%",
    bgcolor: 'background.paper',
    backgroundColor:"#FFFFFF",
    margin:40,
    boxShadow: 24,
    maxWidth:750,
    pt: 2,
    px: 4,
    pb: 3,
  });

function NewCustSnapshotDialog ({open, onClose, onConfirm, liveDeploymentId, testDeploymentId}){
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const [description, setDescription] = useState(null);
    const [deploymentId, setDeploymentId] = useState(liveDeploymentId);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setDeploymentId(liveDeploymentId);
    }, [open]);
    const onDescriptionChanged = (e) => {
        setDescription(e.target.value);
    }
    const onInstanceChanged = (e) =>{
        console.log("new instance value", e.target.value)
        setDeploymentId(e.target.value);
    }
   
    const isFormValid = () => {
        let newErrors = {};
        if (description === null || description === '') newErrors.description = 'Please enter a description'
        
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }
    const submit = () => {
        if (isFormValid()){
            onConfirm(deploymentId, description);
        }
    }
    return (
        <Modal open={open} onClose={onClose}>
        <ModalContent>
            <Typography variant='h5'>Create New Snapshot</Typography><br/>
            <Grid container rowSpacing={2}>
                {testDeploymentId && (
                    <Grid item sm={12}>
                        <FormControl fullWidth>
                            <InputLabel id='instanceLabel'>Instance*</InputLabel>
                            <Select  value={deploymentId} onChange={onInstanceChanged} labelId={"instanceLabel"} label={"Instance*"}>
                                <MenuItem key={liveDeploymentId} value={ liveDeploymentId}>Live Instance</MenuItem>
                                <MenuItem key={testDeploymentId} value={ testDeploymentId}>Test Instance</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                )}

                <Grid item sm={12}>
                    <FormControl fullWidth >
                        <TextField error={errors.description ? true : undefined} id="description" label='Snapshot description' onChange={onDescriptionChanged}  variant="outlined" />
                    </FormControl>
                </Grid>
                
            </Grid>
            <Grid container justifyContent="center" style={{marginTop:20}}>
                <Grid item >
                    <Button sx={{mr:2}} variant="contained" color="primary" onClick={submit} >Create Snapshot</Button>
                    <Button variant="contained" color="error" onClick={onClose} >Cancel</Button>
                   
                   
                </Grid>
            </Grid>
        </ModalContent>
          
      </Modal>
    )
}

export {NewCustSnapshotDialog};