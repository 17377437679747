import React, { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { activateTrialActions, industryManagementActions } from '../../../_actions';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import { FormControl, styled, TextareaAutosize } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Typography } from '@mui/material';
import { getCountryList, getStateList } from '../../../_helpers';
import logo from '../../../_resources/Azzier_ops_logo_no_bg.png';
import debounce from 'lodash/debounce';
import { activateTrialService } from '../../../_services';
import { PublicLayout } from '../../../components/PublicLayout';
import AlertDialog from '../../../components/AlertDialog';
import ConfirmDialog from '../../../components/ConfirmDialog';



const FullWidthTextField = styled(TextField)({
    width: '100%'
})

const SaveButton = styled(Button)({
    width: '10%',
    marginRight:10
});

const CancelButton = styled(Button)({
    width: '10%'
});

const SelectFormControl = styled(FormControl)({
    marginTop: 16,
    width:"100%"
});

const caPostCodeRegex = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i
const usZipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;
const validationSchema = Yup.object().shape({
  adminPhoneNumber: Yup.string()
    .required('Admin phone number is required'),
  adminEmail: Yup.string()
    .required('Admin email is required')
    .matches(/.+@.+\..+/, 'Please enter a valid email')
    .test('usernameAvailable', 'Sorry a user is already registered to this email.',
        async (value) => {
            if (value)
                return activateTrialService.usernameAvailable(value);
        }),
  companyName: Yup.string()
      .required('Company name is required'),
  companyAddress: Yup.string()
      .required('Company address is required'),
  companyCountry: Yup.string()
      .required('Country is required'),
  companyStateProv: Yup.string()
      .test("reqForCAorUS", "Please choose state/prov",
        (value, {createError, parent}) => {
            var country = parent.companyCountry;
            console.log("Country: " + parent.companyCountry)
            if ((country == 'CA' || country === 'US' ) && value == ''){
                return createError( {message: 'Please choose state / prov', type: 'companyStateProv'});
            } 
            return true;
        }
      ),
      
  companyCity: Yup.string()
      .required('City is required'),
  companyPostalCode: Yup.string()
      .required('Postal Code is required')
      .when('companyCountry',{
        is: 'CA',
        then: (schema) => schema.matches(caPostCodeRegex, "Invalid Postal Code Format")
    
        })
      .when('companyCountry',{
            is: 'US',
            then: (schema) => schema.matches(usZipCodeRegex, "Invalid Zip Code")
          }),
        //companyIndustry: Yup.string()
  //    .required('Company industry is required'),
  companyDomain: Yup.string()
      .required('Company domain is required')
      .matches(/^[a-zA-Z0-9]+$/,"Domain names can only contain letters and numbers.")
      .test('hostnameAvailable', 'Sorry this host name is taken already.',
        async (value, {createError}) => {
            var hostAvailable = await activateTrialService.hostAvailable(`${value}.azzier.com`);
            if (hostAvailable.isAvailable) return true;
            console.log (hostAvailable);
            return createError({
                message: "Sorry this host name is already in use.",
                type: 'companyDomain'
            });
        }),
  firstName: Yup.string()
      .required('First name is required'),
  lastName: Yup.string()
      .required('Last name is required'),
  password: Yup.string()
      .required('Password is required')
      .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, "Password must be at least 8 characters and must contain an upper case letter, lower case letter and a number")
      .max(100, 'Password must not exceed 100 characters'),
  confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
  checkBox: Yup.bool().oneOf([true], 'Agreeing to the Terms of Services and Privacy Policy is required')
});

function TrialSignUpPage() {
    const [searchParams, setSearchParams] = useSearchParams();
    const registering = useSelector(state => state.activateTrial.registering);
    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [companyStateList, setCompanyStateList] = useState([]);
    const [alertOpen, setAlertOpen] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [industry, setIndustry] = useState([]);
    const dispatch = useDispatch();
    const industryList = useSelector(state => state.industryManagement.industryList);

    const { control, handleSubmit, register, formState: { errors, submitCount }, getValues, setValue, trigger} = useForm({
        resolver: yupResolver(validationSchema), reValidateMode: 'onBlur'
    });

    useEffect(() => { 
        initializeCountryList();
        dispatch(industryManagementActions.getIndustryList())
    }, []);
    useEffect(() => {
        if(selectedCountry){
            initializeStateList(selectedCountry);
        }
    }, [selectedCountry]);

    const initializeCountryList = async () => {
        setCountryList(await getCountryList());
        console.log('test' , countryList);
    };

    const renderCountryList = () => {
        return countryList.map((country, keyIndex) => {
            return (<MenuItem key={keyIndex} value={ country.countryCode }>{ country.countryName }</MenuItem>);
        });
    };

    const initializeStateList = async (countryCode) => {
        setCompanyStateList(await getStateList(countryCode));
    };

    const renderStateList = () => {
        return companyStateList.map((state, keyIndex) => {
            return (<MenuItem key={keyIndex} value={ state.stateCode }>{ state.stateName }</MenuItem>);
        });
    }

    const onSubmit = data => {
      console.log('Data from form:' , data);
      data.subscriptionPlanId = searchParams.get("subscriptionPlanId");
      dispatch(activateTrialActions.trialSignUp(data));
    };

    const resendActivationEmail = () =>{
        setConfirmOpen(true);        
    }
    const cancelResend =() =>{
        setConfirmOpen(false);
    }
    const confirmResend = () => {
        activateTrialService.resendTrialActivation(getValues("companyDomain") + ".azzier.com");
        setConfirmOpen(false);
        setAlertOpen(true);
    }
    

    console.log(errors);
    return (
    <PublicLayout>
        <Container>
            <ConfirmDialog cancelCallback={cancelResend} confirmCallback={confirmResend} 
                description="Are you sure you want to resend your activation link?  If it does not appear in your inbox check your junk folder"
                title="Resend trial activation link"
                open={confirmOpen}
            />
            <AlertDialog open={alertOpen} okCallback={() => setAlertOpen(false)} description={"Your activation email has been resent"} title="Resend email" />
            <CssBaseline />
           
            <h1 style={{marginTop:20}}>Azzier Trial - Sign up now</h1>
            <p>
                Fill in your details below and we'll get you set up with a trial installation of Azzier.
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h4>Account Information</h4>
                <hr/> 
                <Grid spacing={3} container>
                    <Grid item sm={4} xs={12}>
                        <Grid item>
                            <Controller
                                name="firstName"
                                control={control}
                                inputRef={register()}
                                defaultValue=""
                                render={({ field }) =>
                                    <FullWidthTextField label="First Name*" variant="outlined" margin="normal" {...field} error={errors.firstName ? true : undefined}/>
                                }
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant="inherit" color="error" style={{textAlign:'left', marginRight: '2rem'}}>
                                {errors.firstName?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <Grid item>
                            <Controller
                                name="lastName"
                                control={control}
                                inputRef={register()}
                                defaultValue=""
                                render={({ field }) =>
                                    <FullWidthTextField label="Last Name*"  variant="outlined" margin="normal" {...field} error={errors.lastName ? true : undefined}/>
                                }
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant="inherit" color="error" style={{textAlign:'left', marginRight: '2rem'}}>
                                {errors.lastName?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <Grid item>
                            <Controller
                                name="adminPhoneNumber"
                                control={control}
                                inputRef={register()}
                                defaultValue=""
                                render={({ field }) =>
                                        <FullWidthTextField label="Admin Phone Number*" variant="outlined" margin="normal" {...field} error={errors.adminPhoneNumber ? true : undefined}/>
                                }
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.adminPhoneNumber?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <h4>Company Information</h4>
                <hr/> 
                <Grid container spacing={3}>               
                    <Grid item  xs={12} sm={8}>
                        <Grid item>
                            <Controller
                                name="companyName"
                                control={control}
                                inputRef={register()}
                                defaultValue=""
                                render={({ field }) => 
                                    <FullWidthTextField  label="Company Name*" variant="outlined" margin="normal"  {...field} error={errors.companyName ? true : undefined}/>
                                }
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.companyName?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Grid item>
                            <FormControl style={{marginTop:16, width: '100%'}}  variant="outlined">
                                <InputLabel id='industryLabel'>Company Industry</InputLabel>
                                <Controller
                                    name="industryId"
                                    control={control}
                                    inputRef={register()}
                                    render={({ field }) =>
                                        <Select  labelId="industryLabel" id="industry" {...field}                                            
                                            label="Company Industry*">
                                            {industryList && industryList.map(i =>
                                                <MenuItem key={i.id} value={ i.id }>{ i.industryName }</MenuItem>
                                            )}
                                        </Select>
                                    }
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={8}>
                        <Grid item>
                            <Controller
                                name="companyAddress"
                                control={control}
                                inputRef={register()}
                                defaultValue=""
                                render={({ field }) =>
                                    <TextField label="Address*" variant="outlined" margin="normal" style = {{width: '100%'}} {...field} error={errors.companyAddress ? true : undefined}/>
                                }
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.companyAddress?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Grid item>
                            <Controller
                                name="companyCity"
                                control={control}
                                inputRef={register()}
                                defaultValue=""
                                render={({ field }) =>
                                    <FullWidthTextField label="City*" variant="outlined" margin="normal" {...field} error={errors.companyCity ? true : undefined}/>
                                }
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.companyCity?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                        <Grid item>
                            <SelectFormControl style={{marginTop:16}}  variant="outlined">
                                <InputLabel id='companyCountryLabel'>Country*</InputLabel>
                                <Controller
                                    name="companyCountry"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue=""
                                    render={({ field }) =>
                                        <Select  labelId="companyCountryLabel" id="select" style={{width:'100%'}} {...field}
                                            
                                                onChange={(e) => {
                                                    field.onChange(e); 
                                                    setSelectedCountry(e.target.value); 
                                                    setValue('companyStateProv', '');
                                                    if (submitCount > 0)
                                                        trigger('companyStateProv');
                                                }}
                                                error={errors.companyCountry ? true : undefined} label="Country*">
                                            {renderCountryList()}
                                        </Select>
                                    }
                                />
                            </SelectFormControl>
                        </Grid>
                        <Grid item>
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.companyCountry?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Grid item >
                            {(getValues("companyCountry") === 'CA' || getValues("companyCountry") === 'US') ? 
                            <SelectFormControl disabled={!getValues("companyCountry")} style={{marginTop:16}}  variant="outlined">
                                <InputLabel id='companyStateProv'>State / Prov*</InputLabel>

                                <Controller
                                    name="companyStateProv"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue=""
                                    render={({ field }) =>

                                    <Select label="State / Prov*" labelId="companyStateProv" id="select" style={{width:'100%'}} {...field} error={errors.companyStateProv ? true : undefined}>
                                        {renderStateList()}
                                    </Select>
                                        
                                    }
                                />
                            </SelectFormControl>
                            :
                            <Controller
                                name="companyStateProv"
                                control={control}
                                inputRef={register()}
                                defaultValue=""
                                render={({ field }) =>
                                    <TextField label="State / Prov" variant="outlined" margin="normal" style = {{width: '100%'}} {...field} error={errors.companyStateProv ? true : undefined}/>
                                }
                            />
                            }
                            
                        </Grid>
                        <Grid item>
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.companyStateProv?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item  xs={12} sm={4}>
                        <Grid item>
                            <Controller
                                name="companyPostalCode"
                                control={control}
                                inputRef={register()}
                                defaultValue=""
                                render={({ field }) =>
                                    <FullWidthTextField label="Zip / Postal Code*" variant="outlined"  margin="normal" {...field} error={errors.companyPostalCode ? true : undefined}/>
                                }
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.companyPostalCode?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <h4>Azzier Login Information</h4>
                <hr/> 
                <Grid container spacing={3}>
                    <Grid item sm={4} xs={12}>
                        <Grid item>
                            <Controller
                                name="adminEmail"
                                control={control}
                                inputRef={register()}
                                defaultValue=""
                                render={({ field }) =>
                                    <FullWidthTextField label="Email Address*" variant="outlined" margin="normal" {...field} error={errors.adminEmail ? true : undefined}/>
                                }
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant="inherit" color="error" style={{textAlign:'left', marginRight: '2rem'}}>
                                {errors.adminEmail?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <Grid item>
                            <Controller
                                name="password"
                                control={control}
                                inputRef={register()}
                                defaultValue=""
                                render={({ field }) =>
                                    <FullWidthTextField label="Password*" type="password" variant="outlined" margin="normal" {...field} error={errors.password ? true : undefined}/>
                                }
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant="inherit" color="error" style={{textAlign:'left', marginRight: '2rem'}}>
                                {errors.password?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <Grid item>
                            <Controller
                                name="confirmPassword"
                                control={control}
                                inputRef={register()}
                                defaultValue=""
                                render={({ field }) =>
                                    <FullWidthTextField label="Confirm Password*" type="password" variant="outlined" margin="normal" {...field} error={errors.confirmPassword ? true : undefined}/>
                                }
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.confirmPassword?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={4} xs={12}>
                        <Grid item>
                            <Controller
                                name="companyDomain"
                                control={control}
                                inputRef={register()}
                                defaultValue=""
                                render={({ field }) => 
                                    <Grid container>
                                        <Grid item sm={1} xs={12}>
                                            <FullWidthTextField disabled={true} label="https://" style={{backgroundColor: '#d6d6d6'}} variant="outlined" margin="normal" />
                                        </Grid>
                                        <Grid item sm={3} xs={12}>
                                            <FullWidthTextField label="Site Name*" variant="outlined" margin="normal" {...field} error={errors.companyDomain ? true : undefined}/>
                                        </Grid>
                                        <Grid item sm={2} xs={12}>
                                            <FullWidthTextField disabled={true} label=".azzier.com" style={{backgroundColor: '#d6d6d6'}} variant="outlined" margin="normal" />
                                        </Grid>
                                    </Grid>
                                }
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.companyDomain?.message === 'PendingEmailConfirmation' && (
                                    <>
                                        Sorry a trial has already been requested for this domain and is waiting for email confirmation.  
                                        <a href="#" onClick={resendActivationEmail}>Resend Activation Email.</a>

                                    </>
                                )}
                                {errors.companyDomain?.message && errors.companyDomain?.message !== 'PendingEmailConfirmation' && (
                                    <>
                                         {errors.companyDomain?.message}
                                    </>
                                )}
                                
                            </Typography>
                            
                        </Grid>
                </Grid>
                <Grid style={{diplay: 'flex'}}>
                    <Controller
                        name="checkBox"
                        control={control}
                        inputRef={register()}
                        defaultValue={false}
                        render={({ field }) => 
                            <Checkbox {...field} error={errors.checkBox ? true : undefined}/>
                        }
                    />
                    I agree to the <a href="https://azzier.com/terms-conditions/" target="_blank" style={{ textDecoration: 'none' }}>Terms of Services</a> and <a href="https://azzier.com/privacy-policy/" target="_blank" style={{ textDecoration: 'none' }}>Privacy Policy</a>
                    <div>
                    <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                        {errors.checkBox?.message}
                    </Typography>
                    </div>
                </Grid>
                <div style={{marginTop:20}}>
                    {!registering &&             
                        <SaveButton
                            type="submit"
                            variant="contained"
                            color="primary"
                        > 
                            Save 
                        </SaveButton>
                    }
                    {registering && <CircularProgress />}
                    <Link to="/login" style={{ textDecoration: 'none' }}>
                        <CancelButton
                                color="secondary"
                                type="button"
                                variant="contained"                                
                            > 
                                Cancel
                        </CancelButton>
                    </Link>
                </div>
            </form>
            <br /><br />
        </Container>
    </PublicLayout>
    );
  }
  
export { TrialSignUpPage };