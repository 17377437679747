import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { useState } from "react";
import { validators } from "../../../_helpers/validation";

const ModalContent = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    padding:50,
    transform: 'translate(-50%, -50%)',
    width: "70%",
    bgcolor: 'background.paper',
    backgroundColor:"#FFFFFF",
    margin:40,
    boxShadow: 24,
    maxWidth:750,
    pt: 2,
    px: 4,
    pb: 3,
})


export default function InviteUserModal({open, onSubmitHandler, onCloseHandler}){

    const [email, setEmail] = useState(null);
    const [group, setGroup] = useState("MANAGER")
    const [errors, setErrors] = useState({});
    const addClicked = () => {
        let newErrors = {}
        if (email === null) newErrors.email = "Email is required";
        if (email !== null){
            if (!validators.validateEmail(email)) newErrors.email = "Email address is not valid";
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0){
            onCloseHandler();
            setEmail(null);
            setGroup("MANAGER");
            setErrors({});
            onSubmitHandler({email, group});
        }
    }

    const close = () => {
        onCloseHandler();
        setEmail(null);
        setGroup("MANAGER");
        setErrors({});
    }
    return (
        <Modal open={open} onClose={close}>
            <ModalContent>
                <Typography variant='h4' sx={{mb:2}}>Invite User</Typography>
                <p>Enter the user's email address and the security group you would like them to be in.  They will receive an email that will allow them to 
                    set up a password and their personal info.
                </p>
                <Grid container >
                    <Grid item sm={6}>
                        <FormControl fullWidth>
                            <TextField label="Email Address*" style={{padding:5, width:"100%"}} value={email} onChange={(e)=> {setEmail(e.target.value)}}
                                error={errors?.email ? true: false} helperText={errors?.email}
                            />
                        </FormControl>
                        
                    </Grid>
                    <Grid item sm={6}>
                        <FormControl fullWidth>
                            <InputLabel id="securityGroupLabel">Security Group</InputLabel>

                            <Select defaultValue={"MANAGER"} label="Security Group" labelId="securityGroupLabel" id="group" style={{width:'100%'}} value={group} 
                                onChange={(e) => {setGroup(e.target.value)}} error={errors?.group ? true : undefined}>
                                <MenuItem key={0} value="MANAGER">Manager</MenuItem>,
                                <MenuItem key={1} value="MANAGER_ADMIN">Manager Admin</MenuItem>,                  
                            </Select>
                        </FormControl>
                        
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" style={{marginTop:20}}>
                    <Grid item>
                        <Button onClick={addClicked}>Add</Button>
                        <Button onClick={close}>Cancel</Button>
                    </Grid>
                </Grid>
            </ModalContent>
        </Modal>
    );
}

