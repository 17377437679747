import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clientActions } from '../../../_actions';
import CssBaseline from '@mui/material/CssBaseline';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
//import { CustomAlert } from '../../components';
import { Button, Typography } from '@mui/material';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { getHostList } from '../../../_helpers';
//import { alertActions } from '../../_actions';
import { PrivateLayout } from "../../../components/PrivateLayout";
import { FormatAlignRight } from '@mui/icons-material';


const TableCellHeader = styled(TableCell)({
    fontWeight: 'bold',
    padding: 15,
    backgroundColor: '#e8e4e4'
});

const NewButton = styled(Button)({
    marginTop: 15,
    marginRight: 15
  });


function HostListPage() {
    const dispatch = useDispatch();
    const [hostSearch, setHostSearch] = useState('');
    const [originalHostList, setOriginalHostList] = useState();
    const [searchedHostList, setSearchedHostList] = useState();
    const [loadingTemp, setLoadingTemp] = useState(true);

    useEffect(() => { 
        getAndSetHostLists();
    }, []);

    const getAndSetHostLists = async () => {
        const hostList = await getHostList();
        console.log('Host list front end: ' + JSON.stringify(hostList))
        setOriginalHostList(hostList);
        setSearchedHostList(hostList);
        setLoadingTemp(false);
    };

    const handleSearch = (searchedVal) => {
        console.log('Search value: ' + searchedVal);
        var filteredHosts = originalHostList.filter((host) => {
            return host.hostName.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setSearchedHostList(filteredHosts);
    };

    const formatDate = (dateString) => {
        var inputDate = new Date(dateString);
        return inputDate.toLocaleDateString(undefined, { year: "numeric", month: "long", day: "numeric", hour:"numeric", minute:"numeric" });
    };
    

    return (
      <PrivateLayout>
        <Container maxWidth='xl'>
        <CssBaseline />
            <Typography variant="h3">Tero Provision Host List</Typography>
            <Grid container>
                <Grid item xs={6}>
                    <TextField id="outlined-search" label="Search by host name" type="search" style={{width:"100%", maxWidth:200}} value={hostSearch}
                               onChange={(event) => {handleSearch(event.target.value); setHostSearch(event.target.value)}}
                    />
                </Grid>
                <Grid item xs={6} style={{textAlign:'right'}}>
                    <Link to={"/hosts/new"} >
                        <NewButton color="primary" style={{align:"right"}}
                                   type="submit"
                                   variant="contained">
                            New Host
                        </NewButton>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer>
                        <Table aria-label="Client table">
                            <TableHead>
                                <TableRow key={'TableHeader'}>
                                    <TableCellHeader align="center" >Host Name</TableCellHeader>
                                    <TableCellHeader align="center" >Base URL</TableCellHeader>
                                    <TableCellHeader align="center" >Region</TableCellHeader>
                                    <TableCellHeader align="center">Actions</TableCellHeader>
                                </TableRow>
                            </TableHead>
                            {!loadingTemp && searchedHostList ?
                                <TableBody>
                                    {searchedHostList.map((host) => (
                                        <TableRow key={host.Id}>
                                            <TableCell align="center">{host.hostName}</TableCell>
                                            <TableCell align="center">{host.baseUrl}</TableCell>
                                            <TableCell align="center">{host.hostCountry}</TableCell>
                                            <TableCell align="center"><Link to={"/hosts/" + host.id} style={{ textDecoration: 'none' }}>Details</Link></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                : null}
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
    </Container>
  </PrivateLayout>
    );
  }
  
export { HostListPage };