import axios from '../_helpers/axiosInstance';
import { authHeader } from '../_helpers/authHeader';

export const deploymentService = {
    deleteDeployment, createTestDeployment, clearDeploymentDb, setDefaultSeed, getNonStandardDeployments, getStandardDeployments
};

async function deleteDeployment(deploymentId){
    var response = await axios.delete(`/deployment/${deploymentId}`, {headers: authHeader()});
    return response.data.result;
}

async function createTestDeployment(liveDeploymentId){
    var response = await axios.post(`/deployment/${liveDeploymentId}/test`,{} ,{headers: authHeader()});
    return response.data.result;
}

async function clearDeploymentDb(deploymentId){
    var response = await axios.put(`/deployment/${deploymentId}/clear`,{} ,{headers: authHeader()});
    return response.data.result;
}

async function setDefaultSeed(deploymentId){
    await axios.put(`/deployment/${deploymentId}/setDefaultSeed`,{} ,{headers: authHeader()});
}

async function getNonStandardDeployments(){
    var response = await axios.get(`/deployment/nonstandard`,{headers: authHeader()});
    return response.data.result;
}

async function getStandardDeployments(){
    var response = await axios.get(`deployment/standard`,{headers: authHeader()});
    return response.data.result;
}
