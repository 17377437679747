import { activateTrialConstants } from '../_constants';
import { activateTrialService } from '../_services';
import { alertActions } from './alert.actions';

export const activateTrialActions = {
    activateTrial,
    trialSignUp
};

function trialSignUp(formData) {
    return async dispatch => {
        dispatch(request());
        activateTrialService.trialSignUp(formData)
            .then(response => {
                dispatch(success());
                document.location.href = '/trialconfirm';
            })
            .catch(e => {
                console.error(e);
                dispatch(failure(e));
            });
    };

    function request() { return { type: activateTrialConstants.TRIAL_SIGN_UP_REQUEST } }
    function success() { return { type: activateTrialConstants.TRIAL_SIGN_UP_SUCCESS } }
    function failure(error) { return { type: activateTrialConstants.TRIAL_SIGN_UP_FAILURE, error } }
}

function activateTrial(activationKey) {
    return dispatch => {
        dispatch(request());
        return activateTrialService.activateTrial(activationKey)
            .then(
                async response => {
                    if(response.ok){
                        dispatch(success());
                        //dispatch(alertActions.success('Trial has been activated'));
                    }else{
                        dispatch(failure());
                        //dispatch(alertActions.error('Unable to activate trial'));
                    }
                }
            );
    }

    function request() { return { type: activateTrialConstants.ACTIVATE_TRIAL_REQUEST } }
    function success() { return { type: activateTrialConstants.ACTIVATE_TRIAL_SUCCESS } }
    function failure(error) { return { type: activateTrialConstants.ACTIVATE_TRIAL_FAILURE, error } }
}