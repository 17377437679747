import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CopyIcon from '@mui/icons-material/ContentCopy';
import { Link } from 'react-router-dom';


export default function LicenseTemplateList({licenseTemplates, onDuplicateLicense}) {
    
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell>Product Name</TableCell>
                    <TableCell >License Name</TableCell>
                    <TableCell ></TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {licenseTemplates && licenseTemplates.map((licenseTemplate) => (
                    <TableRow
                        key={licenseTemplate.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell>
                            {licenseTemplate.productName}
                        </TableCell>
                        <TableCell >{licenseTemplate.licenseName}</TableCell>
                        <TableCell >
                            <Link to={`/licenses/${licenseTemplate.id}`}><EditIcon color='action'/></Link>
                            <CopyIcon onClick={() => onDuplicateLicense(licenseTemplate.id)} color="action"/>
                        </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}