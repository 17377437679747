import { industryManagementConstants } from '../_constants';
import { industryManagementService } from '../_services';
import { alertActions } from './alert.actions';

export const industryManagementActions = {
    getIndustryList,
    saveIndustryDetails,
    saveIndustries,
    addIndustry,
    deleteIndustry
};

function getIndustryList() {
    return async dispatch => {
        try {
            dispatch(request());
            let industryList = await industryManagementService.getIndustryList();
            dispatch(success(industryList));
        } catch(e){
            console.error(e);
            dispatch(alertActions.error("Could not get industry list"))
            dispatch(error(e));
        }
        
    }

    function request() { return { type: industryManagementConstants.GET_INDUSTRY_LIST_REQUEST } }
    function success(industryList) { return { type: industryManagementConstants.GET_INDUSTRY_LIST_SUCCESS, industryList } }
    function error(error) { return { type: industryManagementConstants.GET_INDUSTRY_LIST_FAILURE, error } }
}

function saveIndustryDetails(industry) {
    return async dispatch => {
        try {
            dispatch(request());
            await industryManagementService.saveIndustryDetails(industry);
            dispatch(success())
        } catch(e){
            console.error(e);
            dispatch(alertActions.error("Could not save industry"))
            dispatch(error(e));
        }
        
    }

    function request() { return { type: industryManagementConstants.SAVE_INDUSTRY_DETAILS_REQUEST } }
    function success() { return { type: industryManagementConstants.SAVE_INDUSTRY_DETAILS_SUCCESS } }
    function error(error) { return { type: industryManagementConstants.SAVE_INDUSTRY_DETAILS_FAILURE, error } }
}

    function saveIndustries(industries) {
        return async dispatch => {
            try {
                dispatch(request());
                await industryManagementService.saveIndustries(industries);
                dispatch(success())
            } catch(e){
                console.error(e);
                dispatch(alertActions.error("Could not save industry"))
                dispatch(error(e));
            }
    }

    function request() { return { type: industryManagementConstants.SAVE_INDUSTRIES_REQUEST } }
    function success() { return { type: industryManagementConstants.SAVE_INDUSTRIES_SUCCESS } }
    function error(error) { return { type: industryManagementConstants.SAVE_INDUSTRIES_FAILURE, error } }
}

function addIndustry(industry) {
    return async dispatch => {
        try {
            dispatch(request());
            await industryManagementService.addIndustry(industry);
            dispatch(success())
        } catch(e){
            console.error(e);
            dispatch(alertActions.error("Could not add a new industry"))
            dispatch(error(e));
        }
    }

    function request() { return { type: industryManagementConstants.ADD_INDUSTRY_REQUEST } }
    function success() { return { type: industryManagementConstants.ADD_INDUSTRY_SUCCESS } }
    function error(error) { return { type: industryManagementConstants.ADD_INDUSTRY_FAILURE, error } }
}

function deleteIndustry(industryId) {
    return async dispatch => {
        try {
            dispatch(request());
            await industryManagementService.deleteIndustry(industryId);
            dispatch(success(industryId))
        } catch(e){
            dispatch(alertActions.error("Could not delete the industry"))
            dispatch(error(e));
        }
    }

    function request() { return { type: industryManagementConstants.DELETE_INDUSTRY_REQUEST } }
    function success(industryId) { return { type: industryManagementConstants.DELETE_INDUSTRY_SUCCESS, industryId } }
    function error(error) { return { type: industryManagementConstants.DELETE_INDUSTRY_FAILURE, error } }
}

