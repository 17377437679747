import axios from '../_helpers/axiosInstance';
import { authHeader } from '../_helpers/authHeader';

export const customerSubscriptionService = {
    getLicenses,
    getLicense,
    updateSubscription,
    applyChange,
    clearDeploymentDatabase
};



async function getLicenses() {
    console.log('About to get company licenses for current user');

    var response = await axios.get(`/customerSubscription`, { headers: authHeader() });

    return response.data.result;
}

async function getLicense(companyLicenseId) {
    console.log('About to get company license detailes for id ' + companyLicenseId);
    var response = await axios.get(`/customerSubscription/${companyLicenseId}`, { headers: authHeader() });

    return response.data.result;
}

async function updateSubscription(companyLicenseId, priceId, totalUsers) {
    var response = await axios.put(`/customerSubscription`,{companyLicenseId, priceId, quantity: totalUsers}, {headers: authHeader()});
    return response.data.result;
}

async function applyChange(companyLicenseId, planId, totalUsers, quoteId) {
    console.log('About to apply changes to subscription');
    await axios.post(`/customerSubscription/applychange`,{companyLicenseId, planId, totalUsers, quoteId}, {headers: authHeader()});
}

async function clearDeploymentDatabase(deploymentId){
    console.log("clearing deployment db");
    await axios.put(`/ClearDeploymentDb/${deploymentId}`,null,{headers:authHeader()});
}


