import { userManagementConstants } from '../_constants';

let user = JSON.parse(localStorage.getItem('user'));

const initialState = { 
    userList: [],
    loadingUserList: false,
    userInfo: null,
    loadingUserInfo: false,
    savingUser: false,
    sendingInvite: false,
    creatingUser: false,
    error: null,
    inviteList: [], 
    loadingInvites: false
};

export function userManagement(state = initialState, action) {
    switch (action.type) {
        case userManagementConstants.LIST_USERS_REQUEST:
            return {
                ...state,
                loadingUserList:true
            };
        case userManagementConstants.LIST_USERS_SUCCESS:
            return {
                ...state,
                loadingUserList:false,
                userList: action.userList
            };
        case userManagementConstants.LIST_USERS_FAILURE:
            return {
                ...state,
                loadingUserList:false,
                error: action.error
            };
        case userManagementConstants.GET_USER_INFO_REQUEST:
            return {
                ...state,
                loadingUserInfo:true
            };

        case userManagementConstants.GET_USER_INFO_SUCCESS:
            return {
                ...state,
                loadingUserInfo:false,
                userInfo: action.userInfo
            };
        case userManagementConstants.GET_USER_INFO_FAILURE:
            return {
                ...state,
                loadingUserInfo:false,
                error: action.error
            };
        case userManagementConstants.INVITE_USER_REQUEST:
            return {
                ...state,
                sendingInvite:true
            };

        case userManagementConstants.INVITE_USER_SUCCESS:
            return {
                ...state,
                sendingInvite:false
            };
        case userManagementConstants.INVITE_USER_FAILURE:
            return {
                ...state,
                sendingInvite:false,
                error: action.error
            };
        case userManagementConstants.UPDATE_USER_INFO_REQUEST:
            return {
                ...state,
                savingUser:true
            };

        case userManagementConstants.UPDATE_USER_INFO_SUCCESS:
            return {
                ...state,
                savingUser:false
            };
        case userManagementConstants.UPDATE_USER_INFO_FAILURE:
            return {
                ...state,
                savingUser:false,
                error: action.error
            };
        case userManagementConstants.ADD_USER_FROM_INVITE_REQUEST:
            return {
                ...state,
                creatingUser:true
            };

        case userManagementConstants.ADD_USER_FROM_INVITE_SUCCESS:
            return {
                ...state,
                creatingUser:false
            };
        case userManagementConstants.ADD_USER_FROM_INVITE_FAILURE:
            return {
                ...state,
                creatingUser:false,
                error: action.error
            };
        case userManagementConstants.LIST_INVITES_REQUEST:
            return {
                ...state,
                loadingInvites:true
            };

        case userManagementConstants.LIST_INVITES_SUCCESS:
            return {
                ...state,
                loadingInvites:false,
                inviteList: action.inviteList
            };
        case userManagementConstants.LIST_INVITES_FAILURE:
            return {
                ...state,
                loadingInvites:false,
                error: action.error
            };
        
        
        default:
            return state;
    }
}