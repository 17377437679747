import { userConstants } from '../_constants';

let user = JSON.parse(localStorage.getItem('user'));

const initialState = { 
    loggedIn: user !== null, 
    error: null,
    user, 
};

export function authentication(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                ...state,
                loggingIn: true,
                user: action.user
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                ...state,
                loggedIn: true,
                user: action.user
            };
        case userConstants.LOGIN_FAILURE:
            return {
                ...state,
                loggedIn:false,
                loggingIn:false,
                error: "Username / password combination is invalid"
            };
        case userConstants.LOGOUT:
            return {
                ...state,
                loggedIn: false,
                user: null
            };

        case userConstants.REFRESH_TOKEN_REQUEST:
            return {
                ...state,
                loggingIn: true,
                user: action.user
            };
        case userConstants.REFRESH_TOKEN_SUCCESS:
            return {
                ...state,
                loggedIn: true,
                user: action.user
            };
        case userConstants.REFRESH_TOKEN_FAILURE:
            return {
                ...state,
                loggedIn:false
            };
        default:
            return state
    }
}