export const releaseConstants = {
    GET_RELEASE_LIST_REQUEST: 'GET_RELEASE_LIST_REQUEST',
    GET_RELEASE_LIST_SUCCESS: 'GET_RELEASE_LIST_SUCCESS',
    GET_RELEASE_LIST_FAILURE: 'GET_RELEASE_LIST_FAILURE',

    SET_NON_STANDARD_DEPLOYMENT_REQUEST: 'SET_NON_STANDARD_DEPLOYMENT_REQUEST',
    SET_NON_STANDARD_DEPLOYMENT_SUCCESS: 'SET_NON_STANDARD_DEPLOYMENT_SUCCESS',
    SET_NON_STANDARD_DEPLOYMENT_FAILURE: 'SET_NON_STANDARD_DEPLOYMENT_FAILURE',

    GET_RELEASE_DETAILS_REQUEST: 'GET_RELEASE_DETAILS_REQUEST',
    GET_RELEASE_DETAILS_SUCCESS: 'GET_RELEASE_DETAILS_SUCCESS',
    GET_RELEASE_DETAILS_FAILURE: 'GET_RELEASE_DETAILS_FAILURE',

    UPDATE_RELEASE_DETAILS_REQUEST: 'UPDATE_RELEASE_DETAILS_REQUEST',
    UPDATE_RELEASE_DETAILS_SUCCESS: 'UPDATE_RELEASE_DETAILS_SUCCESS',
    UPDATE_RELEASE_DETAILS_FAILURE: 'UPDATE_RELEASE_DETAILS_FAILURE',

    NEW_RELEASE_REQUEST: 'NEW_RELEASE_REQUEST',
    NEW_RELEASE_SUCCESS: 'NEW_RELEASE_SUCCESS',
    NEW_RELEASE_FAILURE: 'NEW_RELEASE_FAILURE',

    DELETE_RELEASE_REQUEST: 'DELETE_RELEASE_REQUEST',
    DELETE_RELEASE_SUCCESS: 'DELETE_RELEASE_SUCCESS',
    DELETE_RELEASE_FAILURE: 'DELETE_RELEASE_FAILURE',

    
    UPDATE_RELEASE_MIGRATION_DETAILS_REQUEST: 'UPDATE_RELEASE_MIGRATION_DETAILS_REQUEST',
    UPDATE_RELEASE_MIGRATION_DETAILS_SUCCESS: 'UPDATE_RELEASE_MIGRATION_DETAILS_SUCCESS',
    UPDATE_RELEASE_MIGRATION_DETAILS_FAILURE: 'UPDATE_RELEASE_MIGRATION_DETAILS_FAILURE',

    UPDATE_RELEASE_SCREEN_DETAILS_REQUEST: 'UPDATE_RELEASE_SCREEN_DETAILS_REQUEST',
    UPDATE_RELEASE_SCREEN_DETAILS_SUCCESS: 'UPDATE_RELEASE_SCREEN_DETAILS_SUCCESS',
    UPDATE_RELEASE_SCREEN_DETAILS_FAILURE: 'UPDATE_RELEASE_SCREEN_DETAILS_FAILURE',

    DELETE_RELEASE_MIGRATION_REQUEST: 'DELETE_RELEASE_MIGRATION_REQUEST',
    DELETE_RELEASE_MIGRATION_SUCCESS: 'DELETE_RELEASE_MIGRATION_SUCCESS',
    DELETE_RELEASE_MIGRATION_FAILURE: 'DELETE_RELEASE_MIGRATION_FAILURE',

    DELETE_RELEASE_SCREEN_REQUEST: 'DELETE_RELEASE_SCREEN_REQUEST',
    DELETE_RELEASE_SCREEN_SUCCESS: 'DELETE_RELEASE_SCREEN_SUCCESS',
    DELETE_RELEASE_SCREEN_FAILURE: 'DELETE_RELEASE_SCREEN_FAILURE',

    GET_RELEASE_MIGRATION_DETAILS_REQUEST: 'GET_RELEASE_MIGRATION_DETAILS_REQUEST',
    GET_RELEASE_MIGRATION_DETAILS_SUCCESS: 'GET_RELEASE_MIGRATION_DETAILS_SUCCESS',
    GET_RELEASE_MIGRATION_DETAILS_FAILURE: 'GET_RELEASE_MIGRATION_DETAILS_FAILURE',

    GET_RELEASE_SCREEN_DETAILS_REQUEST: 'GET_RELEASE_SCREEN_DETAILS_REQUEST',
    GET_RELEASE_SCREEN_DETAILS_SUCCESS: 'GET_RELEASE_SCREEN_DETAILS_SUCCESS',
    GET_RELEASE_SCREEN_DETAILS_FAILURE: 'GET_RELEASE_SCREEN_DETAILS_FAILURE',

    NEW_RELEASE_MIGRATION_REQUEST: 'NEW_RELEASE_MIGRATION_REQUEST',
    NEW_RELEASE_MIGRATION_SUCCESS: 'NEW_RELEASE_MIGRATION_SUCCESS',
    NEW_RELEASE_MIGRATION_FAILURE: 'NEW_RELEASE_MIGRATION_FAILURE',

    NEW_RELEASE_SCREEN_REQUEST: 'NEW_RELEASE_SCREEN_REQUEST',
    NEW_RELEASE_SCREEN_SUCCESS: 'NEW_RELEASE_SCREEN_SUCCESS',
    NEW_RELEASE_SCREEN_FAILURE: 'NEW_RELEASE_SCREEN_FAILURE',

    REORDER_RELEASE_MIGRATIONS_REQUEST: 'REORDER_RELEASE_MIGRATIONS_REQUEST',
    REORDER_RELEASE_MIGRATIONS_SUCCESS: 'REORDER_RELEASE_MIGRATIONS_SUCCESS',
    REORDER_RELEASE_MIGRATIONS_FAILURE: 'REORDER_RELEASE_MIGRATIONS_FAILURE',

    REORDER_RELEASE_SCREENS_REQUEST: 'REORDER_RELEASE_SCREENS_REQUEST',
    REORDER_RELEASE_SCREENS_SUCCESS: 'REORDER_RELEASE_SCREENS_SUCCESS',
    REORDER_RELEASE_SCREENS_FAILURE: 'REORDER_RELEASE_SCREENS_FAILURE',

    ROLLBACK_RELEASE_REQUEST: 'ROLLBACK_RELEASE_REQUEST',
    ROLLBACK_RELEASE_SUCCESS: 'ROLLBACK_RELEASE_SUCCESS',
    ROLLBACK_RELEASE_FAILURE: 'ROLLBACK_RELEASE_FAILURE',
};