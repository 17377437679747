import React from 'react';
import { useSelector } from 'react-redux';
import AdminSidebar from './AdminSidebar';
import CustomerSideBar from './CustomerSidebar';



function SideBar(){

    console.log("creating sidebar");
    let user = useSelector(state => state.authentication.user);
    if (user.SecurityGroup === 'CUSTOMER'){
        return (<CustomerSideBar/>);
    } else {
        return (<AdminSidebar/>);
    }
}

export default SideBar;