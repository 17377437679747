import { companyLicenseConstants } from '../_constants';
import { companyLicenseService } from '../_services';
import { alertActions } from './alert.actions';
import { licenseTemplateActions } from './licenseTemplate.actions';
export const companyLicenseActions = {
    getCompanyLicenses,
    getCompanyLicenseDetails,
    saveCompanyLicense,
    deactivateCompanyLicense,
    activateCompanyLicense
};

function getCompanyLicenses(companyId) {
    return dispatch => {
        dispatch(request());
            companyLicenseService.getCompanyLicenses(companyId).then((companyLicenses) => {
                dispatch(success(companyLicenses));
            });
    };

    function request() { return { type: companyLicenseConstants.GET_COMPANY_LICENSES_REQUEST } }
    function success(companyLicenses) { return { type: companyLicenseConstants.GET_COMPANY_LICENSES_SUCCESS, companyLicenses } }
    function failure(error) { return { type: companyLicenseConstants.GET_COMPANY_LICENSES_FAILURE, error } }
}

function getCompanyLicenseDetails(companyLicenseId) {
    return async (dispatch, getState) => {
        const state = getState();
        try {
            dispatch(request());
            var licenseTemplates = state.licenseTemplates.templateList;
            //if the license template list hasn't been populated then populate it.
            if (licenseTemplates === null || licenseTemplates.length === 0){
                await dispatch(licenseTemplateActions.getList());
            }
            var companyLicenseDetails = await companyLicenseService.getCompanyLicenseDetails(companyLicenseId);

            dispatch(success(companyLicenseDetails));    
        } catch (e){
            dispatch(failure(e.message));
        }
    };
    function request() { return { type: companyLicenseConstants.GET_COMPANY_LICENSE_DETAILS_REQUEST } }
    function success(companyLicenseDetails) { return { type: companyLicenseConstants.GET_COMPANY_LICENSE_DETAILS_SUCCESS, companyLicenseDetails} }
    function failure(error) { return { type: companyLicenseConstants.GET_COMPANY_LICENSE_DETAILS_FAILURE, error } }
}

function saveCompanyLicense(companyLicense) {
    return async dispatch => {
        try {
            dispatch(request());
            await companyLicenseService.saveCompanyLicense(companyLicense);
            dispatch(success());    
            dispatch(alertActions.success("Company license saved"));
        } catch (e){
            dispatch(alertActions.error("An error occurred while saving the license"));
            dispatch(failure(e.message));
        }
    };

    function request() { return { type: companyLicenseConstants.SAVE_COMPANY_LICENSE_DETAILS_REQUEST } }
    function success() { return { type: companyLicenseConstants.SAVE_COMPANY_LICENSE_DETAILS_SUCCESS} }
    function failure(error) { return { type: companyLicenseConstants.SAVE_COMPANY_LICENSE_DETAILS_FAILURE, error } }
}

function deactivateCompanyLicense(companyLicenseId) {
    return async dispatch => {
        try {
            dispatch(request());
            await companyLicenseService.deactivateCompanyLicense(companyLicenseId);
            dispatch(success());  
            dispatch(alertActions.success("Company license has been deactivated"));  
        } catch (e){
            dispatch(alertActions.error("Could not dectivate the license"));
            dispatch(failure(e.message));
        }
        
    };

    function request() { return { type: companyLicenseConstants.DEACTIVATE_COMPANY_LICENSE_REQUEST } }
    function success() { return { type: companyLicenseConstants.DEACTIVATE_COMPANY_LICENSE_SUCCESS} }
    function failure(error) { return { type: companyLicenseConstants.DEACTIVATE_COMPANY_LICENSE_FAILURE, error } }
}

function activateCompanyLicense(companyLicenseId) {
    return async dispatch => {
        try {
            dispatch(request());
            await companyLicenseService.activateCompanyLicense(companyLicenseId);
            dispatch(success());    
            dispatch(alertActions.success("Company license has been activated"));
        } catch (e){
            dispatch(alertActions.error("Company license could not be activated"));
            dispatch(failure(e.message));
        }
        
    };

    function request() { return { type: companyLicenseConstants.ACTIVATE_COMPANY_LICENSE_REQUEST } }
    function success() { return { type: companyLicenseConstants.ACTIVATE_COMPANY_LICENSE_SUCCESS} }
    function failure(error) { return { type: companyLicenseConstants.ACTIVATE_COMPANY_LICENSE_FAILURE, error } }
}