import axios from '../_helpers/axiosInstance';
import { authHeader } from '../_helpers/authHeader';

const API_URL = process.env.REACT_APP_API_ENDPOINT;

export const getCountryList = async () => {
    const countryList = await fetch(`${API_URL}/Country`);
    const json = await countryList.json();
    console.log(JSON.stringify(json));
    return json;
}

export const getStateList = async (countryCode) => {
    const stateList = await fetch(`${API_URL}/State/` + countryCode);
    const json = await stateList.json();
    console.log(JSON.stringify(json));
    return json;
}

export const getClientList = async () => {
    console.log('About to get list of clients');
  
    var response = await axios.get('/Client/ClientList', { headers: authHeader() });

    return response.data.result;
}

export const getHostList = async () => {
    console.log('About to get list of hosts');
  
    var response = await axios.get('/ProvisionHost', { headers: authHeader() });

    return response.data.result;
}

export const getSeedList = async () => {
    console.log('About to get list of seeds');
  
    var response = await axios.get('/Seed/SeedList', { headers: authHeader() });

    return response.data.result;
}