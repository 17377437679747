import axios from '../_helpers/axiosInstance';
import { authHeader } from '../_helpers/authHeader';

export const snapshotService = {
    getSnapshots,
    getSnapshotDetails,
    restoreSnapshot,
    deleteSnapshot, 
    createSnapshot

};

async function getSnapshots(deploymentId){
    console.log("about to get snapshots");
    var response = await axios.get(`/snapshot/deployment/${deploymentId}`, {headers: authHeader()});
    return response.data.result;
}

async function getSnapshotDetails(snapshotId){
    console.log('About to get snapshot details');
    var response = await axios.get(`/snapshot/${snapshotId}`, {headers: authHeader()});
    return response.data.result;
}

async function deleteSnapshot(snapshotId){
    console.log('About to delete snapshot');
    await axios.delete(`/snapshot/${snapshotId}`, {headers: authHeader()});
}

async function restoreSnapshot(snapshotId, deploymentId){
    console.log('About to restore snapshot');
    await axios.put(`/snapshot/${snapshotId}/restore/deployment/${deploymentId}`,null, {headers: authHeader()});
}

async function createSnapshot(deploymentId, description){
    console.log('About to create snapshot');
    let snapshotData = {deploymentId,description};
    await axios.post(`/snapshot`,snapshotData, {headers: authHeader()});
}