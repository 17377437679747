import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clientActions } from '../../../_actions';
import CssBaseline from '@mui/material/CssBaseline';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
//import { CustomAlert } from '../../components';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel, FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from '@mui/material';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { getClientList, getCountryList, getStateList } from '../../../_helpers';
//import { alertActions } from '../../_actions';
import { PrivateLayout } from "../../../components/PrivateLayout";
import FormBox from '../../../components/formBox';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getValue } from '@testing-library/user-event/dist/utils';
import {hostService} from "../../../_services/host.service";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import moment from "moment/moment";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import '@inovua/reactdatagrid-community/index.css'
import ConfirmDialog from "../../../components/ConfirmDialog";
const TableCellHeader = styled(TableCell)({
    fontWeight: 'bold',
    padding: 15,
    backgroundColor: '#e8e4e4'
});
const validationSchema = Yup.object().shape({
    status: Yup.string()
        .required("Status is required"),
    hostName: Yup.string()
        .required("Host Name is required"),
    maxDeployments: Yup.number()
        .required('Max Deployments is required'),
    pauseNewDeployments: Yup.bool(),
    name: Yup.string()
        .required('Name is required'),
    baseUrl: Yup.string()
        .required('Base Url is required'),
    cnameTarget: Yup.string()
        .required("CNAME target is required"),
    dbTimezone: Yup.string()
        .required('DB Timezone is required'),
    hostCountry: Yup.string()
        .required('Host Country is required'),
    testHost: Yup.bool(),
    azzierSiteName: Yup.string()
        .required('Azzier IIS Site Name is required'),
    useHttps: Yup.bool(),
    httpPort: Yup.number()
        .required('HTTP Port is required'),
    httpsPort: Yup.number()
        .required('HTTPS Port is required'),
    httpsCertAlias: Yup.string()
        .required('HTTPS Cert Alias is required'),
    azzierSiteFolder: Yup.string()
        .required('Azzier Site Folder is required'),
    releaseBackupFolder: Yup.string()
        .required('Release Backup Folder is required'),
    dbBackupDir: Yup.string()
        .required('DB Backup folder is required'),
    sqlServerDataDir: Yup.string()
        .required('SQL Server Data Dir is required'),
    sqlServerConnectionString: Yup.string()
        .required('Connection String is required'),
    apiKey: Yup.string()
        .required('Web serivce API Key is required'),

});

const gridStyle = { minHeight: 600, width: '100%' }

const renderTimezoneList = () => {
    return usCanadaTimezones.map((timzone, keyIndex) => {
        return (<MenuItem key={keyIndex} value={ timzone.tz }>{ timzone.tz }</MenuItem>);
    });
};

const FullWidthTextField = styled(TextField)({
  width: '100%'
})

const SaveButton = styled(Button)({
  marginTop: 15,
  marginRight: 15
});

const CancelButton = styled(Button)({
  marginTop: 15,
  marginRight: 15
});

const DeleteButton = styled(Button)({
    marginTop: 15,
    marginRight: 15
});

const SelectFormControl = styled(FormControl)({
  marginTop: 16,
  width:"100%"
});

function EditProvisionHostPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [provisionHost, setProvisionHost] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const {id} = useParams();
  const { control, handleSubmit, register, formState: { errors, submitCount }, getValues, setValue, trigger, reset} = useForm({
    resolver: yupResolver(validationSchema)
  });

  const onDeleteClicked = () =>{
      setConfirmDeleteOpen(true);
  }


  const deploymentColumns = [
        { name: 'companyName', header: 'Company', defaultFlex: 1},
        { name: 'baseUrl', header: 'Site' , defaultFlex: 1},
        { name: 'deploymentStatus', header: 'Deploy Status' , defaultFlex: 1},
        { name: 'deploymentId', header: '',
            render: ({data: {companyId, deploymentId, companyLicenseId}}) => {
                return <span><Button variant="contained" color="primary" onClick={() => navigate(`/clients/${companyId}/licenses/${companyLicenseId}`)}>Details</Button></span>
            }}
  ];
    const filterValue = [
        { name: 'companyName', operator: 'contains', type: 'string', value: '' },
        { name: 'baseUrl', operator: 'contains', type: 'string', value: '' },
        { name: 'deploymentStatus', operator: 'after', type: 'date', value: '' },
    ];
  useEffect(() => {
      const getHost = async (id) =>{
          if (id){
              const details = await hostService.getDetails(id);
              setProvisionHost(details);
              reset(details);
          }
      }
      getHost(id);
  }, [id]);


  const onSubmit = async (data) => {
    await hostService.saveDetails({id, ...data});
    navigate(`/hosts`);
  }

  const cancelDelete = () =>{
      setConfirmDeleteOpen(false);
  }

  const deleteConfirmed = async () => {
      try {
          await hostService.deleteHost(id);
          navigate('/hosts');
      } catch (e) {
          console.log(e);
      } finally {
          setConfirmDeleteOpen(false);
      }
  }
  return (
      <PrivateLayout>
          <ConfirmDialog
              description={"Are you sure you want to delete this provision host?"}
              title={"Confirm Delete"}
              cancelButtonText={"No, Don't Delete"}
              cancelCallback={cancelDelete}
              open={confirmDeleteOpen}
              confirmButtonText={"Yes, Delete this Host"}
              confirmCallback={deleteConfirmed}
          />

        <Container maxWidth='xl'>
        <CssBaseline />
            <form onSubmit={handleSubmit(onSubmit)}>
            <FormBox title={"Edit Provision Host"}  >
                <Grid container spacing={1}>
                    <Grid item sm={4} >
                        <SelectFormControl style={{marginTop:16}}  variant="outlined">
                            <InputLabel id='statusLabel'>Status*</InputLabel>
                            <Controller
                                name="status"
                                control={control}
                                inputRef={register()}
                                defaultValue=""
                                render={({ field }) =>
                                    <Select label="DB Timezone*" labelId="dbTimezoneLabel" id="select" style={{width:'100%'}} {...field} error={errors.dbTimezone ? true : undefined}>
                                        <MenuItem key={1} value='online'>Online</MenuItem>
                                        <MenuItem key={2} value='offline' >Offline</MenuItem>
                                        <MenuItem key={3} value='setting_up'>Setting Up</MenuItem>
                                        <MenuItem key={4} value='deploying'>Deploying</MenuItem>
                                    </Select>
                                }
                            />
                            <FormHelperText error={errors.dbTimezone ? true : undefined}>{errors.dbTimezone?.message}</FormHelperText>
                        </SelectFormControl>
                    </Grid>

                    <Grid item sm={4} >
                        <Controller
                            name="hostName"
                            control={control}
                            inputRef={register()}
                            defaultValue=""
                            render={({ field }) =>
                                <FullWidthTextField label="Host Name*" variant="outlined" margin="normal" {...field} error={errors.hostName ? true : undefined}
                                                    helperText={errors.hostName?.message}/>
                            }
                        />
                    </Grid>
                    <Grid item sm={4} >
                        <Controller
                            name="name"
                            control={control}
                            inputRef={register()}
                            defaultValue=""
                            render={({ field }) =>
                                <FullWidthTextField label="Name*" variant="outlined" margin="normal" {...field} error={errors.name ? true : undefined}
                                                    helperText={errors.name?.message}/>
                            }
                        />
                    </Grid>
                    <Grid item sm={2} >
                        <Controller
                            name="maxDeployments"
                            control={control}
                            inputRef={register()}
                            defaultValue=""
                            render={({ field }) =>
                                <FullWidthTextField type={"number"} label="Max Deployments*" variant="outlined" margin="normal" {...field} error={errors.maxDeployments ? true : undefined}
                                                    helperText={errors.maxDeployments?.message}/>
                            }
                        />
                    </Grid>
                    <Grid item sm={2} >
                        <Controller
                            name="pauseNewDeployments"
                            control={control}
                            inputRef={register()}
                            defaultValue=""
                            render={({ field }) =>
                                <FormControlLabel control={<Checkbox checked={field.value} onChange={field.onChange} onBlur={field.onBlur} />} style={{marginTop:20}} label={`Pause Deployments`} />
                            }
                        />
                    </Grid>
                    <Grid item sm={8} >
                        <Controller
                            name="baseUrl"
                            control={control}
                            inputRef={register()}
                            defaultValue=""
                            render={({ field }) =>
                                <FullWidthTextField label="Provision Api URL*" variant="outlined" margin="normal" {...field} error={errors.baseUrl ? true : undefined}
                                                    helperText={errors.baseUrl?.message}/>
                            }
                        />
                    </Grid>
                    <Grid item sm={4} >
                        <Controller
                            name="cnameTarget"
                            control={control}
                            inputRef={register()}
                            defaultValue=""
                            render={({ field }) =>
                                <FullWidthTextField label="CNAME Target*" variant="outlined" margin="normal" {...field} error={errors.cnameTarget ? true : undefined}
                                                    helperText={errors.cnameTarget?.message}/>
                            }
                        />
                    </Grid>
                    <Grid item sm={4} >
                        <SelectFormControl style={{marginTop:16}}  variant="outlined">
                            <InputLabel id='dbTimezoneLabel'>DB Timezone*</InputLabel>
                            <Controller
                                name="dbTimezone"
                                control={control}
                                inputRef={register()}
                                defaultValue=""
                                render={({ field }) =>
                                    <Select label="DB Timezone*" labelId="dbTimezoneLabel" id="select" style={{width:'100%'}} {...field} error={errors.dbTimezone ? true : undefined}>
                                        {renderTimezoneList()}
                                    </Select>
                                }
                            />
                            <FormHelperText error={errors.dbTimezone ? true : undefined}>{errors.dbTimezone?.message}</FormHelperText>
                        </SelectFormControl>
                    </Grid>
                    <Grid item sm={4} >
                        <SelectFormControl style={{marginTop:16}}  variant="outlined">
                            <InputLabel id='hostCountryLabel'>Host Country*</InputLabel>
                            <Controller
                                name="hostCountry"
                                control={control}
                                inputRef={register()}
                                defaultValue=""
                                render={({ field }) =>
                                    <Select label="Host Country*" labelId="hostCountryLabel" id="select" style={{width:'100%'}} {...field} error={errors.hostCountry ? true : undefined}>
                                        <MenuItem key={1} value={'CA'}>{'Canada'}</MenuItem>
                                        <MenuItem key={2} value={'US'}>{'USA'}</MenuItem>
                                    </Select>
                                }
                            />
                            <FormHelperText error={errors.hostCountry ? true : undefined}>{errors.hostCountry?.message}</FormHelperText>
                        </SelectFormControl>
                    </Grid>
                    <Grid item sm={4} >
                        <Controller
                            name="testHost"
                            control={control}
                            inputRef={register()}
                            defaultValue=""
                            render={({ field }) =>
                                <FormControlLabel control={<Checkbox checked={field.value} onChange={field.onChange} onBlur={field.onBlur} />} style={{marginTop:20}} label={`Is Test Host`} />
                            }
                        />
                    </Grid>
                    <Grid item sm={4} >
                        <Controller
                            name="azzierSiteName"
                            control={control}
                            inputRef={register()}
                            defaultValue=""
                            render={({ field }) =>
                                <FullWidthTextField label="Azzier IIS Site Name*" variant="outlined" margin="normal" {...field} error={errors.azzierSiteName ? true : undefined}
                                                    helperText={errors.azzierSiteName?.message}/>
                            }
                        />
                    </Grid>
                    <Grid item sm={4} >
                        <Controller
                            name="httpsCertAlias"
                            control={control}
                            inputRef={register()}
                            defaultValue=""
                            render={({ field }) =>
                                <FullWidthTextField label="Default Cert Alias*" variant="outlined" margin="normal" {...field} error={errors.httpsCertAlias ? true : undefined}
                                                    helperText={errors.httpsCertAlias?.message}/>
                            }
                        />
                    </Grid>
                    <Grid item sm={4} >
                        <Controller
                            name="useHttps"
                            control={control}
                            inputRef={register()}
                            defaultValue=""
                            render={({ field }) =>
                                <FormControlLabel control={<Checkbox checked={field.value} onChange={field.onChange} onBlur={field.onBlur} />} style={{marginTop:20}} label={`Use HTTPS`} />
                            }
                        />
                    </Grid>
                    <Grid item sm={2} >
                        <Controller
                            name="httpPort"
                            control={control}
                            inputRef={register()}
                            defaultValue=""
                            render={({ field }) =>
                                <FullWidthTextField type={"number"} label="HTTP Port*" variant="outlined" margin="normal" {...field} error={errors.httpPort ? true : undefined}
                                                    helperText={errors.httpPort?.message}/>
                            }
                        />
                    </Grid>
                    <Grid item sm={2} >
                        <Controller
                            name="httpsPort"
                            control={control}
                            inputRef={register()}
                            defaultValue=""
                            render={({ field }) =>
                                <FullWidthTextField type={"number"} label="HTTPS Port*" variant="outlined" margin="normal" {...field} error={errors.httpsPort ? true : undefined}
                                                    helperText={errors.httpsPort?.message}/>
                            }
                        />
                    </Grid>
                    <Grid item sm={6} >
                        <Controller
                            name="azzierSiteFolder"
                            control={control}
                            inputRef={register()}
                            defaultValue=""
                            render={({ field }) =>
                                <FullWidthTextField label="Azzier Site Path*" variant="outlined" margin="normal" {...field} error={errors.azzierSiteFolder ? true : undefined}
                                                    helperText={errors.azzierSiteFolder?.message}/>
                            }
                        />
                    </Grid>
                    <Grid item sm={6} >
                        <Controller
                            name="releaseBackupFolder"
                            control={control}
                            inputRef={register()}
                            defaultValue=""
                            render={({ field }) =>
                                <FullWidthTextField label="Release Backup Path*" variant="outlined" margin="normal" {...field} error={errors.releaseBackupFolder ? true : undefined}
                                                    helperText={errors.releaseBackupFolder?.message}/>
                            }
                        />
                    </Grid>
                    <Grid item sm={6} >
                        <Controller
                            name="sqlServerDataDir"
                            control={control}
                            inputRef={register()}
                            defaultValue=""
                            render={({ field }) =>
                                <FullWidthTextField label="SQL Server Data Dir*" variant="outlined" margin="normal" {...field} error={errors.sqlServerDataDir ? true : undefined}
                                                    helperText={errors.sqlServerDataDir?.message}/>
                            }
                        />
                    </Grid>
                    <Grid item sm={6} >
                        <Controller
                            name="dbBackupDir"
                            control={control}
                            inputRef={register()}
                            defaultValue=""
                            render={({ field }) =>
                                <FullWidthTextField label="DB Backup Dir*" variant="outlined" margin="normal" {...field} error={errors.dbBackupDir ? true : undefined}
                                                    helperText={errors.dbBackupDir?.message}/>
                            }
                        />
                    </Grid>
                    <Grid item sm={12} >
                        <Controller
                            name="sqlServerConnectionString"
                            control={control}
                            inputRef={register()}
                            defaultValue=""
                            render={({ field }) =>
                                <FullWidthTextField label="SQL Server Connection String*" variant="outlined" margin="normal" {...field} error={errors.sqlServerConnectionString ? true : undefined}
                                                    helperText={errors.sqlServerConnectionString?.message}/>
                            }
                        />
                    </Grid>
                    <Grid item sm={12} >
                        <Controller
                            name="apiKey"
                            control={control}
                            inputRef={register()}
                            defaultValue=""
                            render={({ field }) =>
                                <FullWidthTextField label="Provision API Key*" variant="outlined" margin="normal" {...field} error={errors.apiKey ? true : undefined}
                                                    helperText={errors.apiKey?.message}/>
                            }
                        />
                    </Grid>


                    <Grid item sm={6}>
                        <Box>
                            <SaveButton
                                color="primary"
                                type="submit"
                                variant="contained">Save</SaveButton>
                            <CancelButton
                                onClick={() => {navigate(`/hosts`)}}
                                color="secondary"
                                type="button"
                                variant="contained">Cancel</CancelButton>
                        </Box>
                    </Grid>
                    <Grid item sm={6} style={{textAlign:'right'}}>
                        <Box>
                            <DeleteButton onClick={onDeleteClicked} color="error" variant="contained">Delete</DeleteButton>
                        </Box>
                    </Grid>
                    <Grid xs={12}>
                        <hr/>
                    </Grid>
                    <Grid xs={12}>
                        <h3>Deployments on this Host</h3>
                    </Grid>
                    <Grid xs={12}>
                        <ReactDataGrid
                            enableFiltering={true}
                            defaultFilterValue={filterValue}
                            idProperty="companyId"
                            columns={deploymentColumns}
                            dataSource={provisionHost?.deployments || []}
                            style={gridStyle}
                        />
                    </Grid>
                </Grid>
            </FormBox>
            </form>

            
       
    </Container>
  </PrivateLayout>
    );
  }

const usCanadaTimezones = [
    { description: 'Halifax', tz: 'America/Halifax' },
    { description: 'Glace_Bay', tz: 'America/Glace_Bay' },
    { description: 'Moncton', tz: 'America/Moncton' },
    { description: 'Goose_Bay', tz: 'America/Goose_Bay' },
    { description: 'Blanc-Sablon', tz: 'America/Blanc-Sablon' },
    { description: 'Toronto', tz: 'America/Toronto' },
    { description: 'Nipigon', tz: 'America/Nipigon' },
    { description: 'Thunder_Bay', tz: 'America/Thunder_Bay' },
    { description: 'Iqaluit', tz: 'America/Iqaluit' },
    { description: 'Pangnirtung', tz: 'America/Pangnirtung' },
    { description: 'Atikokan', tz: 'America/Atikokan' },
    { description: 'Winnipeg', tz: 'America/Winnipeg' },
    { description: 'Rainy_River', tz: 'America/Rainy_River' },
    { description: 'Resolute', tz: 'America/Resolute' },
    { description: 'Rankin_Inlet', tz: 'America/Rankin_Inlet' },
    { description: 'Regina', tz: 'America/Regina' },
    { description: 'Swift_Current', tz: 'America/Swift_Current' },
    { description: 'Edmonton', tz: 'America/Edmonton' },
    { description: 'Cambridge_Bay', tz: 'America/Cambridge_Bay' },
    { description: 'Yellowknife', tz: 'America/Yellowknife' },
    { description: 'Inuvik', tz: 'America/Inuvik' },
    { description: 'Creston', tz: 'America/Creston' },
    { description: 'Dawson_Creek', tz: 'America/Dawson_Creek' },
    { description: 'Fort_Nelson', tz: 'America/Fort_Nelson' },
    { description: 'Vancouver', tz: 'America/Vancouver' },
    { description: 'Whitehorse', tz: 'America/Whitehorse' },
    { description: 'Dawson', tz: 'America/Dawson' },
    { description: 'St_Johns', tz: 'America/St_Johns' },
    { description: 'New_York', tz: 'America/New_York' },
    { description: 'Detroit', tz: 'America/Detroit' },
    { description: 'Louisville', tz: 'America/Kentucky/Louisville' },
    { description: 'Monticello', tz: 'America/Kentucky/Monticello' },
    { description: 'Indianapolis', tz: 'America/Indiana/Indianapolis' },
    { description: 'Vincennes', tz: 'America/Indiana/Vincennes' },
    { description: 'Winamac', tz: 'America/Indiana/Winamac' },
    { description: 'Marengo', tz: 'America/Indiana/Marengo' },
    { description: 'Petersburg', tz: 'America/Indiana/Petersburg' },
    { description: 'Vevay', tz: 'America/Indiana/Vevay' },
    { description: 'Tell_City', tz: 'America/Indiana/Tell_City' },
    { description: 'Knox', tz: 'America/Indiana/Knox' },
    { description: 'Chicago', tz: 'America/Chicago' },
    { description: 'Menominee', tz: 'America/Menominee' },
    { description: 'Denver', tz: 'America/Denver' },
    { description: 'Boise', tz: 'America/Boise' },
    { description: 'Phoenix', tz: 'America/Phoenix' },
    { description: 'Los_Angeles', tz: 'America/Los_Angeles' },
    { description: 'Anchorage', tz: 'America/Anchorage' },
    { description: 'Juneau', tz: 'America/Juneau' },
    { description: 'Sitka', tz: 'America/Sitka' },
    { description: 'Metlakatla', tz: 'America/Metlakatla' },
    { description: 'Yakutat', tz: 'America/Yakutat' },
    { description: 'Nome', tz: 'America/Nome' },
    { description: 'Adak', tz: 'America/Adak' }
];
export { EditProvisionHostPage };