import {Button, CircularProgress, Modal, TextareaAutosize, TextField} from "@mui/material";
import { borderColor, Box, Container, styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FormBox from "../../../components/formBox";
import { PrivateLayout } from "../../../components/PrivateLayout";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch } from "react-redux";
import { releaseActions } from "../../../_actions";
import DraggableList from "../../../components/DraggableList";
import Moment from 'react-moment';
import ConfirmDialog from "../../../components/ConfirmDialog";

const BoldLabel = styled("span")({
    fontWeight: "bold"
});

const SaveButton = styled(Button)({
    marginTop: 15,
    marginRight: 15
});

const CancelButton = styled(Button)({
    marginTop: 15,
    marginRight: 15
});
const DeleteButton = styled(Button)({
    marginTop: 15,
    marginRight: 15
});


const ResizingTextArea = styled(TextField)({
    resize: "both"
});

const validationSchema = Yup.object().shape({
    scriptName: Yup.string()
      .required('Script Name is required'),
    sqlScript: Yup.string()
      .required('Sql Script is required')
  });


const ModalContent = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    padding: 50,
    transform: 'translate(-50%, -50%)',
    width: '85%',   // Set width to 80%
    height: '85%',  // Set height to 80%
    bgcolor: 'background.paper',
    backgroundColor: "#FFFFFF",
    margin: 40,  // Consider removing or adjusting margin
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    overflowY: "auto" // Add the overflow for scrolling if needed
});



function ReleaseMigrationDetails({open, onClose, dbMigrationId, releaseId}){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const migrationDetails = useSelector(state => state.releases.currentReleaseMigration);
    const loading = useSelector(state => state.releases.gettingReleaseMigrationDetails);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [readOnly, setReadOnly] = useState(false);

    const { control, setValue, handleSubmit, register, reset, formState: { errors }} = useForm({
        resolver: yupResolver(validationSchema),
        
    });
    useEffect(()=> {
        if (dbMigrationId)
            dispatch(releaseActions.loadReleaseMigrationDetails(dbMigrationId));
    },[dbMigrationId]);

    
    const deleteRelaseMigration = () => {
        setDeleteConfirmOpen(true);
    }
    const cancelDelete = () => {
        setDeleteConfirmOpen(false);
    }
    const deleteConfirmed = async () => {
        setDeleteConfirmOpen(false);
        await dispatch(releaseActions.deleteReleaseMigration(migrationDetails.id));
        await dispatch(releaseActions.loadReleaseDetails(releaseId));

        onClose();
    }


    useEffect(() => {
        if (migrationDetails !== null){
            reset({...migrationDetails});  
            setReadOnly(migrationDetails.dateApplied !== null);          
        }
    },[migrationDetails]);

    const onSubmit = async (data) => {
        await dispatch(releaseActions.saveReleaseMigraion(migrationDetails.id, releaseId, data.scriptName, data.sqlScript, data.rollbackScript ));
        await dispatch(releaseActions.loadReleaseDetails(releaseId));
        dispatch(releaseActions.loadReleaseMigrationDetails(dbMigrationId));
        close();
    }
    const close = () => {
        reset();
        onClose();
    }
    return (
        <Modal open={open} onClose={close}>
            <ModalContent>
                <ConfirmDialog open={deleteConfirmOpen} cancelCallback={cancelDelete} confirmCallback={deleteConfirmed}
                               title={"Confirm Delete"}
                               description={"Are you sure that you want to delete this release migration?"}/>
                <h2>Edit Release Migration</h2>
                <form onSubmit={handleSubmit(onSubmit)} style={{width: '100%'}}>
                    {loading || migrationDetails === null || migrationDetails === undefined ? <CircularProgress/> :
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(2, 1fr)',
                                gap: "15px",
                                gridTemplateRows: 'auto',
                                p: 2
                            }}
                        >
                            <Box>
                                <Controller
                                    name="scriptName"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue={migrationDetails.scriptName}
                                    render={({field}) =>
                                        <TextField disabled={readOnly} variant="outlined" fullWidth label="Script Name*"
                                                   margin="normal" {...field} error={errors.scriptName ? true : false}
                                                   helperText={errors.scriptName?.message}
                                                   inputProps={{maxLength: 100}}/>

                                    }
                                />
                            </Box>
                            <Box>

                            </Box>
                            <Box sx={{gridColumnStart: 1, gridColumnEnd: -1}}>
                                <Controller
                                    name="sqlScript"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue={migrationDetails.sqlScript}
                                    render={({field}) =>
                                        <ResizingTextArea disabled={readOnly} variant="outlined" fullWidth
                                                          label="SQL Script*" multiline minRows={10} maxRows={20}
                                                          margin="normal" {...field}
                                                          error={errors.sqlScript ? true : false}
                                                          helperText={errors.version?.sqlScript}/>

                                    }
                                />
                            </Box>
                            <Box sx={{gridColumnStart: 1, gridColumnEnd: -1}}>
                                <Controller
                                    name="rollbackScript"
                                    control={control}
                                    inputRef={register()}
                                    defaultValue={migrationDetails.rollbackScript}
                                    render={({field}) =>
                                        <ResizingTextArea disabled={readOnly} variant="outlined" fullWidth
                                                          label="SQL Rollback Script*" multiline minRows={8}
                                                          maxRows={20} margin="normal" {...field}
                                                          error={errors.rollbackScript ? true : false}
                                                          helperText={errors.version?.rollbackScript}/>

                                    }
                                />
                            </Box>
                            {migrationDetails.dateApplied !== null ?
                                <>
                                    <Box>
                                        <BoldLabel>Date Applied:</BoldLabel>
                                    </Box>
                                    <Box>
                                        <Moment>{migrationDetails.dateApplied}</Moment>
                                    </Box>
                                </>
                                : null}

                            {readOnly ?
                                <Box sx={{gridColumnStart: 1, gridColumnEnd: -1}}>
                                    <CancelButton variant="contained" color='primary' type="button"
                                                  onClick={() => onClose()}>Close</CancelButton>
                                </Box>
                                :
                                <Box sx={{gridColumnStart: 1, gridColumnEnd: -1}}>
                                    <SaveButton variant="contained" color='primary' type="submit">Save</SaveButton>
                                    <CancelButton variant="contained" color="secondary" type='button'
                                                  onClick={() => onClose()}>Cancel</CancelButton>
                                    <DeleteButton variant="contained" color="error" type='button'
                                                  onClick={deleteRelaseMigration}>Delete</DeleteButton>
                                </Box>
                            }


                        </Box>
                    }
                </form>
            </ModalContent>
        </Modal>
    );
}

export {ReleaseMigrationDetails};