import { clientConstants } from '../_constants';
import { clientService } from '../_services';
import { alertActions } from './alert.actions';

export const clientActions = {
    getClientList,
    getClientDetails,
    saveClientDetails
};

function getClientList() {
    return dispatch => {
        dispatch(request());
        clientService.getClientList()
            .then(
                async response => {
                    if(response.ok){
                        dispatch(success(response.body));
                        //dispatch(alertActions.success('Registration successful'));
                    }else{
                        let body = await response.json();
                        dispatch(failure(body));
                        //dispatch(alertActions.error(JSON.stringify(body.Error.Message)));
                    }
                }
            );
    };

    function request() { return { type: clientConstants.GET_CLIENT_LIST_REQUEST } }
    function success(clientList) { return { type: clientConstants.GET_CLIENT_LIST_SUCCESS, clientList } }
    function failure(error) { return { type: clientConstants.GET_CLIENT_LIST_FAILURE, error } }
}

function getClientDetails( companyId) {
    return async dispatch => {
        dispatch(request());
        try {
            const clientDetails = await clientService.getDetails(companyId);
            dispatch(success(clientDetails));
            
        } catch (e){
            dispatch(failure(e))
            return Promise.reject();
        }
        
        
    };

    function request() { return { type: clientConstants.GET_CLIENT_DETAILS_REQUEST } }
    function success(clientDetails) { return { type: clientConstants.GET_CLIENT_DETAILS_SUCCESS, clientDetails } }
    function failure(error) { return { type: clientConstants.GET_CLIENT_DETAILS_FAILURE, error } }
}

function saveClientDetails( clientDetails ) {
    return async dispatch => {
        dispatch(request());
        try {
            await clientService.saveDetails(clientDetails);
            dispatch(success());
            dispatch(alertActions.success("Client details saved"));
        } catch (e){
            dispatch(failure(e));
            dispatch(alertActions.error("An error occurred while trying to save client details."));
            return Promise.reject();
        }
        
        
    };

    function request() { return { type: clientConstants.SAVE_CLIENT_DETAILS_REQUEST } }
    function success() { return { type: clientConstants.SAVE_CLIENT_DETAILS_SUCCESS } }
    function failure(error) { return { type: clientConstants.SAVE_CLIENT_DETAILS_FAILURE, error } }
}

