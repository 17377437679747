export const industryManagementConstants = {
    GET_INDUSTRY_LIST_REQUEST: 'GET_INDUSTRY_LIST_REQUEST',
    GET_INDUSTRY_LIST_SUCCESS: 'GET_INDUSTRY_LIST_SUCCESS',
    GET_INDUSTRY_LIST_FAILURE: 'GET_INDUSTRY_LIST_FAILURE',

    GET_INDUSTRY_DETAILS_REQUEST: 'GET_INDUSTRY_DETAILS_REQUEST',
    GET_INDUSTRY_DETAILS_SUCCESS: 'GET_INDUSTRY_DETAILS_SUCCESS',
    GET_INDUSTRY_DETAILS_FAILURE: 'GET_INDUSTRY_DETAILS_FAILURE',

    SAVE_INDUSTRY_DETAILS_REQUEST: 'SAVE_INDUSTRY_DETAILS_REQUEST',
    SAVE_INDUSTRY_DETAILS_SUCCESS: 'SAVE_INDUSTRY_DETAILS_SUCCESS',
    SAVE_INDUSTRY_DETAILS_FAILURE: 'SAVE_INDUSTRY_DETAILS_FAILURE',

    SAVE_INDUSTRIES_REQUEST: 'SAVE_INDUSTRIES_REQUEST',
    SAVE_INDUSTRIES_SUCCESS: 'SAVE_INDUSTRIES_SUCCESS',
    SAVE_INDUSTRIES_FAILURE: 'SAVE_INDUSTRIES_FAILURE',

    ADD_INDUSTRY_REQUEST: 'ADD_INDUSTRY_REQUEST',
    ADD_INDUSTRY_SUCCESS: 'ADD_INDUSTRY_SUCCESS',
    ADD_INDUSTRY_FAILURE: 'ADD_INDUSTRY_FAILURE',

    DELETE_INDUSTRY_REQUEST: 'DELETE_INDUSTRY_REQUEST',
    DELETE_INDUSTRY_SUCCESS: 'DELETE_INDUSTRY_SUCCESS',
    DELETE_INDUSTRY_FAILURE: 'DELETE_INDUSTRY_FAILURE',
};