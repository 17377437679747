export const clientConstants = {
    GET_CLIENT_LIST_REQUEST: 'GET_CLIENT_LIST_REQUEST',
    GET_CLIENT_LIST_SUCCESS: 'GET_CLIENT_LIST_SUCCESS',
    GET_CLIENT_LIST_FAILURE: 'GET_CLIENT_LIST_FAILURE',

    GET_CLIENT_DETAILS_REQUEST: 'GET_CLIENT_DETAILS_REQUEST',
    GET_CLIENT_DETAILS_SUCCESS: 'GET_CLIENT_DETAILS_SUCCESS',
    GET_CLIENT_DETAILS_FAILURE: 'GET_CLIENT_DETAILS_FAILURE',

    SAVE_CLIENT_DETAILS_REQUEST: 'SAVE_CLIENT_DETAILS_REQUEST',
    SAVE_CLIENT_DETAILS_SUCCESS: 'SAVE_CLIENT_DETAILS_SUCCESS',
    SAVE_CLIENT_DETAILS_FAILURE: 'SAVE_CLIENT_DETAILS_FAILURE'
};