export const resetPasswordConstants = {
    
    VALIDATE_RESET_KEY_REQUEST: 'VALIDATE_RESET_KEY_REQUEST',
    VALIDATE_RESET_KEY_SUCCESS: 'VALIDATE_RESET_KEY_SUCCESS',
    VALIDATE_RESET_KEY_FAILURE: 'VALIDATE_RESET_KEY_FAILURE',
    
    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE'
};
