import axios from '../_helpers/axiosInstance';
import { authHeader } from '../_helpers/authHeader';

export const chargebeeService = {
    getSession
};


async function getSession(){
    console.log('About to get session');
    var response = await axios.get('/chargebee/session', {headers: authHeader()});
    return response.data.result;
}
