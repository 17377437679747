import { Box, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Modal, Select, styled, TextField, Typography } from "@mui/material";
import React, {useEffect} from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { releaseActions } from "../../../_actions";

const ModalContent = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    padding:50,
    transform: 'translate(-50%, -50%)',
    width: "70%",
    bgcolor: 'background.paper',
    backgroundColor:"#FFFFFF",
    margin:40,
    boxShadow: 24,
    maxWidth:750,
    pt: 2,
    px: 4,
    pb: 3,
  });

function RestoreCustSnapshotDialog ({open, onClose, onConfirm, liveDeploymentId, testDeploymentId, defaultDeploymentId}){

    
    const [restoreConfirm, setRestoreConfirm] = useState(null);
    const [deploymentId, setDeploymentId] = useState(null);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setDeploymentId(defaultDeploymentId);
    }, [open]);
    const onRestoreConfirmChanged = (e) => {
        setRestoreConfirm(e.target.value);
    }
    const onInstanceChanged = (e) =>{
        console.log("new instance value", e.target.value)
        setDeploymentId(e.target.value);
    }
   
    const isFormValid = () => {
        let newErrors = {};
        if (restoreConfirm === null || restoreConfirm !== 'restore') newErrors.restoreConfirm = 'You must type the word "restore"'
        
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }
    const submit = () => {
        if (isFormValid()){
            onConfirm(deploymentId);
        }
    }
    return (
        <Modal open={open} onClose={onClose}>
        <ModalContent>
            <Typography variant='h5'>Restore Snapshot</Typography><br/>
            <Grid container rowSpacing={2} >
                {testDeploymentId && liveDeploymentId && deploymentId && (
                    <Grid item sm={12}>
                        <FormControl fullWidth>
                            <InputLabel id='instanceLabel'>Choose Instance to restore to:*</InputLabel>
                            <Select id={"instanceSelect"} value={deploymentId || null} onChange={onInstanceChanged} labelId={"instanceLabel"} label={"Choose Instance to restore to:*"}>
                                <MenuItem key={liveDeploymentId} value={liveDeploymentId}>Live Instance</MenuItem>
                                <MenuItem key={testDeploymentId} value={testDeploymentId}>Test Instance</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                )}

                <Grid item sm={12}>
                    <FormControl fullWidth >
                        <TextField error={errors.restoreConfirm ? true : undefined} id="restoreConfirm" label='Type "restore" to confirm restore' onChange={onRestoreConfirmChanged}  variant="outlined" />
                    </FormControl>

                </Grid>
                <Grid item xs={12}>
                    <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                        {errors.restoreConfirm}
                    </Typography>
                </Grid>
                
            </Grid>
            <Grid container justifyContent="center" style={{marginTop:20}}>
                <Grid item >
                    <Button sx={{mr:2}} variant="contained" color="primary" onClick={submit} >Restore Snapshot</Button>
                    <Button variant="contained" color="error" onClick={onClose} >Cancel</Button>


                </Grid>
            </Grid>
        </ModalContent>
          
      </Modal>
    )
}

export {RestoreCustSnapshotDialog};