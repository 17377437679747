import { Box } from "@mui/system";
import React from "react";

export default function FormBox({children, title}){
    return (
        <Box>
            <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(1, 1fr)',
                gap: 0,
                gridTemplateRows: 'auto',
                gridTemplateAreas: `"header"
            "main"`,
            }}
            >
            <Box sx={{ gridArea: 'header', backgroundColor: "#E0E0E0", p:1.5, border: 1}}>
                <span style={{fontWeight:'bold', fontSize:20}}>{title}</span>
            </Box>
            <Box sx={{ gridArea: 'main', border:1, borderTop:0, p:1.5 }}>{children}</Box>

            </Box>
        </Box>
    );
}