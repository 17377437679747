import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';
import { PublicLayout } from '../../../components/PublicLayout';

function TrialConfirmPage() {
    return (
    <PublicLayout>
        <Container maxWidth='md'>
            <CssBaseline />
            <Grid 
                container
                spacing={2}
                alignItems="center"
                justifyContent="center"
                style={{ marginTop: 30 }}
            >
                
                <Grid container>
                    <Typography variant="h3" gutterBottom>
                        Azzier Trial - Confirmation
                    </Typography>
                </Grid>
                <Grid container>
                    <Typography mt={5}>
                        Thank you for signing up for an Azzier Trial!
                    </Typography>
                    <Typography mt={3}>
                        You will shortly receive an email with a <span style={{fontWeight: 'bold', fontStyle: 'italic'}}>verification link</span> to confirm the email address you provided. Once you confirm your email address by clicking on the verification link our system will start to prepare your Azzier trial.
                    </Typography>
                    <Typography mt={3} style={{fontWeight: 'bold'}}>
                        Once your Azzier trial is ready an email will be sent to you and you can log in with the username and password you provided.
                    </Typography>
                    <Typography mt={3}>
                        If you have any questions please feel free to contact via our contact us page at <a href="https://azzier.com/contact/">https://azzier.com/contact/</a> and one of our sales specialists will be happy to set up a time to walk through the product with you.
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    </PublicLayout>
      
    );
  }
  
export { TrialConfirmPage };