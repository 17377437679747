import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clientActions, industryManagementActions } from '../../../_actions';
import CssBaseline from '@mui/material/CssBaseline';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
//import { CustomAlert } from '../../components';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { getClientList, getCountryList, getStateList } from '../../../_helpers';
//import { alertActions } from '../../_actions';
import { PrivateLayout } from "../../../components/PrivateLayout";
import FormBox from '../../../components/formBox';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getValue } from '@testing-library/user-event/dist/utils';

const TableCellHeader = styled(TableCell)({
    fontWeight: 'bold',
    padding: 15,
    backgroundColor: '#e8e4e4'
});
const validationSchema = Yup.object().shape({
  companyPhoneContact: Yup.string()
    .required('Contact phone number is required'),
  companyEmailContact: Yup.string()
    .required('Contact email is required')
    .email("Email address is invalid"),
  companyName: Yup.string()
      .required('Company name is required'),
  companyAddress: Yup.string()
      .required('Company address is required'),
  companyCountry: Yup.string()
      .required('Country is required'),
  companyStateProv: Yup.string()
      .test("reqForCAorUS", "Please choose state/prov",
        (value, {createError, parent}) => {
            var country = parent.companyCountry;
            console.log("Country: " + parent.companyCountry)
            if ((country == 'CA' || country === 'US' ) && value == ''){
                return createError( {message: 'Please choose state / prov', type: 'companyStateProv'});
            } 
            return true;
        }
      ),
  companyCity: Yup.string()
      .required('City is required'),
  companyPostalCode: Yup.string()
      .required('Postal Code is required'),
  contactFirstName: Yup.string()
      .required('First name is required'),
  contactLastName: Yup.string()
      .required('Last name is required')
      
});

const FullWidthTextField = styled(TextField)({
  width: '100%'
})

const SaveButton = styled(Button)({
  marginTop: 15,
  marginRight: 15
});

const CancelButton = styled(Button)({
  marginTop: 15,
  marginRight: 15
});

const SelectFormControl = styled(FormControl)({
  marginTop: 16,
  width:"100%"
});

function ClientDetailsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {companyId} = useParams();
  const [countryList, setCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [stateLookup, setStateLookup] = useState(null);
  const [stateList, setStateList] = useState([]);
  const clientInfo = useSelector(state => state.client.selectedClientDetails);
  const loading = useSelector(state => state.client.loadingClientDetails);
  const industryList = useSelector(state => state.industryManagement.industryList);
  const { control, handleSubmit, register, formState: { errors, submitCount }, getValues, setValue, trigger, reset} = useForm({
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => { 
    if (companyId){
      dispatch(clientActions.getClientDetails(companyId))
      dispatch(industryManagementActions.getIndustryList())
    }
    
  }, []);

  useEffect(() => {
      if(selectedCountry){
          updateStateList();
      }
  }, [selectedCountry]);

  useEffect(() => {
    initializeCountryAndStateList();
    initializeStateLookup();
    if (clientInfo != null){
      reset(clientInfo);
    }
    
  }, [clientInfo])

  const initializeCountryAndStateList = () => {
    if (clientInfo !== null){
      setCountryList(clientInfo.countries);
      const states = clientInfo.stateLookup[clientInfo.companyCountry.toLowerCase()]
      if (states)
        setStateList(states)
    }
      
  };

  const renderCountryList = () => {
      return countryList.map((country, keyIndex) => {
          return (<MenuItem key={keyIndex} value={ country.countryCode }>{ country.countryName }</MenuItem>);
      });
  };

  const renderStateList = () => {
      console.log("statelist:",stateList);
      if (stateList === null) return null;
      return stateList.map((state, keyIndex) => {
          return (<MenuItem key={keyIndex} value={ state.stateCode }>{ state.stateName }</MenuItem>);
      });
  }

  const renderIndustryList = () => {
    console.log("industryList:",industryList);
    if (industryList === null) return null;
    return industryList.map((industry, keyIndex) => {
        return (<MenuItem key={keyIndex} value={ industry.id }>{ industry.industryName }</MenuItem>);
    });
  }

  const initializeStateLookup =  (countryCode) => {
    if (clientInfo !== null){
      setStateLookup(clientInfo.stateLookup);
    }
  };

  const updateStateList = () => {
    if (clientInfo !== null && stateLookup !== null){
      setStateList(stateLookup[selectedCountry.toLowerCase()]);
    }
  }

  const onSubmit = (data) => {
    data.id = clientInfo.id;
    dispatch(clientActions.saveClientDetails(data));
    navigate(`/clients/${companyId}`)
  }

  return (
      <PrivateLayout>
        <Container maxWidth="xl">
        <CssBaseline />
        {clientInfo === null || loading ? <CircularProgress/> :
        <form onSubmit={handleSubmit(onSubmit)}>
        <FormBox title={"Company Info"}  >
          <Box>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: "15px",
                gridTemplateRows: 'auto',
                p:2
              }}
              >
              <Box>
                <Controller
                    name="contactFirstName"
                    control={control}
                    inputRef={register()}
                    defaultValue={clientInfo?.contactFirstName}
                    render={({ field }) =>
                        <FullWidthTextField label="First Name*" variant="outlined" margin="normal" {...field} error={errors.contactFirstName ? true : undefined}
                          helperText={errors.contactFirstName?.message}/>
                    }
                />
              </Box>
              <Box >
              <Controller
                    name="contactLastName"
                    control={control}
                    inputRef={register()}
                    defaultValue={clientInfo?.contactLastName}
                    render={({ field }) =>
                        <FullWidthTextField label="Last Name*" variant="outlined" margin="normal" {...field} error={errors.contactLastName ? true : undefined}
                          helperText={errors.contactLastName?.message}/>
                    }
                />
              </Box>
              <Box >
                <Controller
                    name="companyEmailContact"
                    control={control}
                    inputRef={register()}
                    defaultValue={clientInfo?.companyEmailContact}
                    render={({ field }) =>
                        <FullWidthTextField label="Contact Email*" variant="outlined" margin="normal" {...field} error={errors.companyEmailContact ? true : undefined}
                          helperText={errors.companyEmailContact?.message}/>
                    }
                />
              </Box>
              <Box >
                <Controller
                    name="companyPhoneContact"
                    control={control}
                    inputRef={register()}
                    defaultValue={clientInfo?.companyPhoneContact}
                    render={({ field }) =>
                        <FullWidthTextField label="Contact Phone Number*" variant="outlined" margin="normal" {...field} error={errors.companyPhoneContact ? true : undefined}
                          helperText={errors.companyPhoneContact?.message}/>
                    }
                />
              </Box>
              <Box >
                <Controller
                    name="companyName"
                    control={control}
                    inputRef={register()}
                    defaultValue={clientInfo?.companyName}
                    render={({ field }) =>
                        <FullWidthTextField label="Company Name*" variant="outlined" margin="normal" {...field} error={errors.companyName ? true : undefined}
                          helperText={errors.companyName?.message}/>
                    }
                />
              </Box>

              <Box >
                <Controller
                    name="companyAddress"
                    control={control}
                    inputRef={register()}
                    defaultValue={clientInfo?.companyAddress}
                    render={({ field }) =>
                        <FullWidthTextField label="Company Address*" variant="outlined" margin="normal" {...field} error={errors.companyAddress ? true : undefined}
                          helperText={errors.companyAddress?.message}/>
                    }
                />
              </Box>
              <Box >
                <Controller
                    name="companyCity"
                    control={control}
                    inputRef={register()}
                    defaultValue={clientInfo?.companyCity}
                    render={({ field }) =>
                        <FullWidthTextField label="Company City*" variant="outlined" margin="normal" {...field} error={errors.companyCity ? true : undefined}
                          helperText={errors.companyCity?.message}/>
                    }
                />
              </Box>
              <Box>
                <SelectFormControl style={{marginTop:16}}  variant="outlined">
                  <InputLabel id='companyCountryLabel'>Country*</InputLabel>
                  <Controller
                      name="companyCountry"
                      control={control}
                      inputRef={register()}
                      defaultValue={clientInfo?.companyCountry}
                      render={({ field }) =>
                          <Select  labelId="companyCountryLabel" id="select" style={{width:'100%'}} {...field}

                                  onChange={(e) => {
                                      field.onChange(e);
                                      setSelectedCountry(e.target.value);
                                      setValue('companyStateProv', '');
                                      if (submitCount > 0)
                                          trigger('companyStateProv');
                                  }}
                                  error={errors.companyCountry ? true : undefined} label="Country*">
                              {renderCountryList()}
                          </Select>
                      }
                  />
                </SelectFormControl>
              </Box>
              <Box>
                {(getValues("companyCountry") === 'CA' || getValues("companyCountry") === 'US') ?
                  <SelectFormControl disabled={!getValues("companyCountry")} style={{marginTop:16}}  variant="outlined">
                      <InputLabel id='companyStateProv'>State / Prov*</InputLabel>

                      <Controller
                          name="companyStateProv"
                          control={control}
                          inputRef={register()}
                          defaultValue=""
                          render={({ field }) =>

                          <Select label="State / Prov*" labelId="companyStateProv" id="select" style={{width:'100%'}} {...field} error={errors.companyStateProv ? true : undefined}>
                              {renderStateList()}
                          </Select>

                          }
                      />
                  </SelectFormControl>
                  :
                  <Controller
                      name="companyStateProv"
                      control={control}
                      inputRef={register()}
                      defaultValue=""
                      render={({ field }) =>
                          <TextField label="State / Prov" variant="outlined" margin="normal" style = {{width: '100%'}} {...field} error={errors.companyStateProv ? true : undefined}/>
                      }
                  />
                }
              </Box>
              <Box >
                <Controller
                    name="companyPostalCode"
                    control={control}
                    inputRef={register()}
                    defaultValue={clientInfo?.companyPostalCode}
                    render={({ field }) =>
                        <FullWidthTextField label="Postal Code / Zip*" variant="outlined" margin="normal" {...field} error={errors.companyPostalCode ? true : undefined}
                          helperText={errors.companyPostalCode?.message}/>
                    }
                />
              </Box>

              <Box >
                <SelectFormControl style={{marginTop:16}}  variant="outlined">
                        <InputLabel id='companyIndustry'>Company Industry</InputLabel>
                        <Controller
                            name="industryId"
                            control={control}
                            inputRef={register()}
                            defaultValue=""
                            render={({ field }) =>
                            <Select label="Company Industry" labelId="companyIndustryLbl" id="select" style={{width:'100%'}} {...field}>
                                {renderIndustryList()}
                            </Select>

                            }
                        />
                </SelectFormControl>
              </Box>

              <Box sx={{gridColumnStart: 1}}>
                <SaveButton
                            color="primary"
                            type="submit"
                            variant="contained">Save</SaveButton>
                <CancelButton
                            onClick={() => {navigate(`/clients/${companyId}`)}}
                            color="secondary"
                            type="button"
                            variant="contained">Cancel</CancelButton>
              </Box>
            </Box>
          </Box>
        </FormBox>
        </form>
        }

        </Container>
      </PrivateLayout>
    );
  }
  
export { ClientDetailsPage };