import { licenseTemplateConstants } from '../_constants';


const initialState = { 
    gettingLicenseTemplates: false,
    loadingLicenseTemplate: false,
    savingLicenseTemplate: false,
    templateList: [],
    currentLicenseTemplate: null, 
    errorMsg: null
};

export function licenseTemplates(state = initialState, action) {
    switch (action.type) {
        case licenseTemplateConstants.GET_LICENSE_TEMPLATE_LIST_REQUEST:
            return {
                ...state,
                gettingLicenseTemplates: true
            };
        case licenseTemplateConstants.GET_LICENSE_TEMPLATE_LIST_SUCCESS:
            return {
                ...state,
                gettingLicenseTemplates: false,
                templateList: action.list
            };
        case licenseTemplateConstants.GET_LICENSE_TEMPLATE_LIST_FAILURE:
            return {
                ...state,
                gettingLicenseTemplates: false, 
                errorMsg: action.error
            };
        case licenseTemplateConstants.GET_LICENSE_TEMPLATE_REQUEST:
            return {
                ...state,
                gettingLicenseTemplates: false,
                errorMsg: null,
                currentLicenseTemplate: null,
                loadingLicenseTemplate: true
            };

        case licenseTemplateConstants.GET_LICENSE_TEMPLATE_SUCCESS:
            return {
                ...state,
                loadingLicenseTemplate: false,
                currentLicenseTemplate: action.licenseTemplate
            };
        case licenseTemplateConstants.GET_LICENSE_TEMPLATE_FAILURE:
            return {
                ...state,
                loadingLicenseTemplate: false,
                currentLicenseTemplate: null,
                errorMsg: action.error
            };
        case licenseTemplateConstants.SAVE_LICENSE_TEMPLATE_REQUEST:
            return {
                ...state,
                savingLicenseTemplate:true
            };
        case licenseTemplateConstants.SAVE_LICENSE_TEMPLATE_SUCCESS:
            return {
                ...state,
                savingLicenseTemplate:false
            };
        case licenseTemplateConstants.SAVE_LICENSE_TEMPLATE_FAILURE:
            return {
                ...state,
                savingLicenseTemplate:false,
                errorMsg: action.error
            };
        case licenseTemplateConstants.DUPLICATE_LICENSE_TEMPLATE_REQUEST:
            return {
                ...state,
                gettingLicenseTemplates:true
            };
        case licenseTemplateConstants.DUPLICATE_LICENSE_TEMPLATE_SUCCESS:
            return {
                ...state,
                gettingLicenseTemplates:false,
                templateList: action.newLicenseList
            };
        case licenseTemplateConstants.DUPLICATE_LICENSE_TEMPLATE_FAILURE:
            return {
                ...state,
                gettingLicenseTemplates:false,
                errorMsg: action.error
            };
        case licenseTemplateConstants.ADD_LICENSE_TEMPLATE_REQUEST:
            return {
                ...state,
                savingLicenseTemplate:true
            };
        case licenseTemplateConstants.ADD_LICENSE_TEMPLATE_SUCCESS:
            return {
                ...state,
                savingLicenseTemplate:false
            };
        case licenseTemplateConstants.ADD_LICENSE_TEMPLATE_FAILURE:
            return {
                ...state,
                savingLicenseTemplate:false,
                errorMsg: action.error
            };
        case licenseTemplateConstants.DELETE_LICENSE_TEMPLATE_REQUEST:
            return {
                ...state
            };
        case licenseTemplateConstants.DELETE_LICENSE_TEMPLATE_SUCCESS:
            return {
                ...state
            };
        case licenseTemplateConstants.DELETE_LICENSE_TEMPLATE_FAILURE:
            return {
                ...state
            };
        default:
            return state
    }
}