import { Box, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Modal, Select, styled, TextField, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { releaseActions } from "../../../_actions";

const ModalContent = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    padding:50,
    transform: 'translate(-50%, -50%)',
    width: "70%",
    bgcolor: 'background.paper',
    backgroundColor:"#FFFFFF",
    margin:40,
    boxShadow: 24,
    maxWidth:750,
    pt: 2,
    px: 4,
    pb: 3,
  });

function RollbackReleaseModal ({open, onClose}){
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const release = useSelector(state => state.releases.currentRelease);
    const releases = useSelector(state => state.releases.releaseList);
    const [rollingBack, setRollingBack] = useState(false);
    const [rollbackType, setRollbackType] = useState(null);
    const [rollbackConfirm, setRollbackConfirm] = useState("");
    const [errors, setErrors] = useState({});
    let rollbackToVersion = "1.0.0";
    if (release && releases && releases.length){
        for (let i = 0; i < releases.length; i++){
            if (releases[i].dateReleased && release.dateReleased && releases[i]
                && releases[i].dateReleased < release.dateReleased && releases[i].releaseStatus !== "Rolled-Back"
                ){
                rollbackToVersion = releases[i].version;
                break;
            }
        }
    }

    const close = () => {
        if (!rollingBack){
            onClose();
        }
    }

    const rollbackTypeChanged = (e) =>{
        setRollbackType(e.target.value);
    }
    const rollbackConfirmChanged = (e) => {
        setRollbackConfirm(e.target.value);
    }

    const isFormValid = () => {
        console.log("Rollback Type:",rollbackType);
        let newErrors = {};
        if (rollbackType === null) newErrors.rollbackType = "Please select a rollback type";
        if (rollbackConfirm !== 'rollback') newErrors.rollbackConfirm = 'Please type "rollback" to confirm that you wish to rollback.'
        console.log(newErrors);
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }
    const submit = async () => {
        if (isFormValid()){
            try {
                setRollingBack(true);
                await dispatch(releaseActions.rollbackRelease(release.id, rollbackType));
                setRollingBack(false);
                navigate("/releases");
            } catch(e) {
                setRollingBack(false);
            }
        }
    }
    return (
        <Modal open={open} onClose={close}>
        <ModalContent>
            <Typography variant='h5'>Rollback Release</Typography><br/>
            <Grid container >
                <Grid item sm={12} mb={2}>
                    <Typography variant='h6'>Rolling back to release {rollbackToVersion}</Typography><br/>
                    <p>Rolling back a release will impact every client. Please be sure that this is what you want to do before starting the release.</p>
                    <FormControl fullWidth error={errors.rollbackType ? true : undefined}>
                        <InputLabel id="rollback-type-label">Rollback Type</InputLabel>
                        <Select
                            labelId="rollback-type-label"
                            id="rollbackType"
                            
                            label="Rollback Type"
                            onChange={rollbackTypeChanged}
                        >
                            <MenuItem value={"CodeOnly"}>Code Only</MenuItem>
                            <MenuItem value={"CodeAndRollbackScripts"}>Code and use Rollback Scripts</MenuItem>
                            <MenuItem value={"CodeAndSnapshot"}>Code Rollback and use DB Snapshot</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={12}>
                    <FormControl fullWidth >

                        <TextField error={errors.rollbackConfirm ? true : undefined} id="rollbackConfirm" label='Please type "rollback" to confirm you want to rollback.' labelId="rollback-type-label" onChange={rollbackConfirmChanged}  variant="outlined" />
                    </FormControl>
                </Grid>
                
            </Grid>
            <Grid container justifyContent="center" style={{marginTop:20}}>
                <Grid item >
                    {rollingBack ? 
                    <>
                        <CircularProgress/> Rolling back release ... please wait.
                    </>
                    :
                    <>
                        <Button sx={{mr:2}} variant="contained" color="primary" onClick={submit} >Rollback</Button>
                        <Button variant="contained" color="error" onClick={onClose} >Cancel</Button>
                   </>
                    }
                   
                </Grid>
            </Grid>
        </ModalContent>
          
      </Modal>
    )
}

export {RollbackReleaseModal};