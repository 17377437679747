import { resetPasswordConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './alert.actions';


export const resetPasswordActions = {
    validateResetKey,
    resetPassword
};

function validateResetKey(resetKey) {
    return dispatch => {
        dispatch(request());
        userService.validateResetKey(resetKey)
            .then(
                async response => {
                    if(response.ok){
                        dispatch(success());                        
                    }else{
                        userService.logout();
                        dispatch(failure("invalid key"));
                    }
                }
            );
    }

    function request() { return { type: resetPasswordConstants.VALIDATE_RESET_KEY_REQUEST } }
    function success() { return { type: resetPasswordConstants.VALIDATE_RESET_KEY_SUCCESS } }
    function failure() { return { type: resetPasswordConstants.VALIDATE_RESET_KEY_FAILURE } }
}

function resetPassword(newPassword, newPasswordConfirm, resetKey) {
    return dispatch => {
        dispatch(request());
        userService.resetPassword(newPassword, newPasswordConfirm, resetKey)
            .then(
                async response => {
                    if(response.ok){
                        dispatch(success())
                        dispatch(alertActions.success("Password was reset successfully"));
                    }else{
                        userService.logout();
                        dispatch(failure("invalid password reset key"));
                    }
                }
            );
    }

    function request() { return { type: resetPasswordConstants.RESET_PASSWORD_REQUEST } }
    function success() { return { type: resetPasswordConstants.RESET_PASSWORD_SUCCESS } }
    function failure() { return { type: resetPasswordConstants.RESET_PASSWORD_FAILURE } }
}