import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';

import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';

import { CustomAlert } from '.';
import { Height } from '@mui/icons-material';

import AzzierLogo from '../_resources/Azzier_FullColour_Logo.png';
import { styled, useTheme } from '@mui/material/styles';


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  })
}));


function PublicLayout({children,title}) {

    const theme = useTheme();
   
    const user = useSelector(state => state.authentication.user);
    const masquerading = useSelector(state => state.authentication.masquerading);
    const dispatch = useDispatch();

    
    useEffect(() => {

    }, []);

    return (
      <Box sx={{ display: 'flex' }}>
            
            <CssBaseline />
              <AppBar style={{ background: '#ECECEC'}} position="fixed">
                    <Toolbar>
                   
                        <img src={AzzierLogo} alt="logo" height={80} style={{margin: 10}} />
                    </Toolbar>
                </AppBar>
                
                <div style={{
                  flex:1,
                  display:"flex",
                  flexGrow: 1,
                  padding: theme.spacing(3),
                  paddingTop:100,
                  height:"100vh"
                }}>
                    {children}
                </div>
      </Box>
    );
}

export { PublicLayout };