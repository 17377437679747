import { RowingSharp } from "@mui/icons-material";
import { Button, CircularProgress, Container, CssBaseline, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BlockingProgressModal from "../../../components/BlockingProgressModal";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { PrivateLayout } from "../../../components/PrivateLayout";
import { snapshotService } from "../../../_services/snapshot.service";
import { NewSnapshotDialog } from "./NewSnapshotDialog";
import {RestoreSnapshotDialog} from "./RestoreSnapshotDialog";

function SnapshotPage (){
    const {deploymentId} = useParams();
    const [pageState, setPageState] = 
        useState(
            {
                loading:true,
                liveDeploymentId: null,
                testDeploymentId: null,
                snapshots:[], 
                snapshotIdToRestore:null,
                deploymentIdToRestore: null,
                snapshotIdToDelete:null,
                confirmRestoreOpen: false, 
                confirmCreateOpen: false,
                confirmDeleteOpen: false,
                creating: false,
                restoring: false,
                deleting: false
            });
    
    let currentCompany = useSelector(state => state.companyLicenses.selectedCompany);        
    const getData = async () => {
        setPageState(prevState => {
            return {...prevState, loading:true}
        });
        try {
            let snapshotPageData = await snapshotService.getSnapshots(deploymentId);
            console.log("GOT SNAPSHOTS:",snapshotPageData.snapshots);
            setPageState(prevState => {
                return {...prevState, liveDeploymentId: snapshotPageData.liveDeploymentId,
                    testDeploymentId: snapshotPageData.testDeploymentId, snapshots: snapshotPageData.snapshots, loading:false}
            })
        } catch (e){
            console.error(e);
            setPageState(prevState => {
                return {...prevState, loading:false}
            });
        }
        
        
    }
    useEffect( () => {        
        getData();
    },[])

    const restoreSnapshot = (snapshotId, deploymentId) => {
        setPageState({...pageState, snapshotIdToRestore: snapshotId, deploymentIdToRestore: deploymentId, confirmRestoreOpen: true});
    }

    const cancelRestore = () => {
        setPageState({...pageState, confirmRestoreOpen: false});
    }

    const confirmRestore = async (deploymentId) =>{
        setPageState(prevState => {
            return {...prevState, confirmRestoreOpen: false, restoring: true}
        });
        try {
            await snapshotService.restoreSnapshot(pageState.snapshotIdToRestore, deploymentId);
            setPageState(prevState => {
                return {...prevState, restoring: false}
            });
            setTimeout(() => getData(), 1000);
            
        } catch (e){
            console.error(e);
            setPageState(prevState => {
                return {...prevState, restoring: false}
            });
        }
    }

    const deleteSnapshot = (snapshotId) => {
        setPageState({...pageState, snapshotIdToDelete: snapshotId, confirmDeleteOpen: true});
    }

    const cancelDelete = () => {
        setPageState({...pageState, confirmDeleteOpen: false});
    }

    const confirmDelete = async () =>{
        setPageState(prevState => {
            return {...prevState, confirmDeleteOpen: false, deleting: true}
        });
        try {
            await snapshotService.deleteSnapshot(pageState.snapshotIdToDelete)
            setPageState(prevState => {
                return {...prevState, deleting: false}
            });
            setTimeout(() => getData(), 1000);
            
        } catch (e){
            console.error(e);
            setPageState(prevState => {
                return {...prevState, deleteing: false}
            });
        }
    }

    const createSnapshot = (snapshotId) => {
        setPageState({...pageState, confirmCreateOpen: true});
    }

    const cancelCreate = () => {
        setPageState({...pageState, confirmCreateOpen: false});
    }

    const confirmCreate = async (chosenDeploymentId, description) =>{
        setPageState(prevState => {
            return {...prevState, confirmCreateOpen: false, creating: true}
        });
        try {
            await snapshotService.createSnapshot(chosenDeploymentId, description);
            setPageState(prevState => {
                return {...prevState, creating: false}
            });
            setTimeout(() => getData(), 1000);
        } catch (e){
            console.error(e);
            setPageState(prevState => {
                return {...prevState, creating: false}
            });
        }
    }

    return (
        <PrivateLayout>
            <BlockingProgressModal open={pageState.restoring} title="Restoring snapshot" 
                description={"Please wait while the snapshot is restored. This may take a few minutes based on the size of the snapshot"} />
            <ConfirmDialog open={pageState.confirmRestoreOpen}  cancelCallback={cancelRestore}
                confirmCallback={confirmRestore}
                confirmButtonText="Yes, overwrite database with this snapshot"
                description={`Are you sure you want to restore this snapshot?  
                This will overwrite the current snapshot and will result in data loss.  Only do this if you are sure what you are doing.`} title="Confirm snapshot restore"/>
            <RestoreSnapshotDialog
                open={pageState.confirmRestoreOpen}
                onClose={cancelRestore}
                onConfirm={confirmRestore}
                testDeploymentId={pageState.testDeploymentId}
                liveDeploymentId={pageState.liveDeploymentId}
                defaultDeploymentId={pageState.deploymentIdToRestore}
            />
            <BlockingProgressModal open={pageState.deleting} title="Deleting snapshot" 
                description={"Please wait while the snapshot is deleted."} />
            <ConfirmDialog open={pageState.confirmDeleteOpen}  cancelCallback={cancelDelete} 
                confirmCallback={confirmDelete}
                confirmButtonText="Yes, delete this snapshot"
                description={`Are you sure you want to delete this snapshot?  
                This will permanently delete this snapshot and it will not be recoverable.`} title="Confirm snapshot delete"/>

            <BlockingProgressModal open={pageState.creating} title="Creating snapshot" 
                description={"Please wait while the snapshot is created. This may take a few minutes based on the size of the snapshot"} />
            <NewSnapshotDialog
                open={pageState.confirmCreateOpen}
                onClose={cancelCreate}
                onConfirm={confirmCreate}
                liveDeploymentId={pageState.liveDeploymentId}
                testDeploymentId={pageState.testDeploymentId}/>
            <Container maxWidth='xl'>
                <CssBaseline />
                <Typography variant="h3" style={{ marginBottom: 30 }}>{currentCompany} Azzier Snapshots</Typography>
                <Button variant="contained" onClick={createSnapshot}>Create New Snapshot</Button>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Snapshot Date</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Instance</TableCell>
                                <TableCell>Azzier Version</TableCell>
                                <TableCell>Restores</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                pageState.loading ? <CircularProgress/> : 
                                <>
                                 {
                                    pageState.snapshots.map((snapshot) => (
                                    <TableRow>
                                        <TableCell><Moment format="YYYY-MM-DD HH:mm">{snapshot.dateBackedUp}</Moment></TableCell>
                                        <TableCell>{snapshot.description}</TableCell>
                                        <TableCell>{snapshot.liveOrTest}</TableCell>
                                        <TableCell>{snapshot.version}</TableCell>
                                        <TableCell>
                                            {snapshot.snapshotRestores.map(s => (
                                                <div style={{fontSize:10}}>
                                                    {s.target} - <Moment format="YYYY-MM-DD HH:mm">{s.restoreDate}</Moment>
                                                </div>
                                            ))}
                                        </TableCell>
                                        <TableCell><Button variant="outlined" onClick={() => restoreSnapshot(snapshot.snapshotId, snapshot.deploymentId)}>Restore</Button>
                                        <Button color="error" variant="outlined" onClick={() => deleteSnapshot(snapshot.snapshotId)}>Delete</Button></TableCell>
                                    </TableRow>))}
                                </>     
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </PrivateLayout>
    )

}

export {SnapshotPage}