import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../../_actions';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import logo from '../../../_resources/Azzier_ops_logo_no_bg.png';
import Container from '@mui/material/Container';
import { PublicLayout } from '../../../components/PublicLayout';
import { Alert, Typography } from '@mui/material';

function LoginPage() {
    const [inputs, setInputs] = useState({
        username: '',
        password: ''
    });
    const [submitted, setSubmitted] = useState(false);
    const { username, password } = inputs;
    const loggingIn = useSelector(state => state.authentication.loggingIn);
    const error = useSelector(state => state.authentication.error);
    const alert = useSelector(state => state.alert);
    const dispatch = useDispatch();
    const location = useLocation();

    // reset login status
    useEffect(() => { 
        dispatch(userActions.logout()); 
    }, []);

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function handleSubmit(e) {
        e.preventDefault();

        setSubmitted(true);
        if (username && password) {
            // get return url from location state or default to home page
            const { from } = location.state || { from: { pathname: "/" } };
            dispatch(userActions.login(username, password, from));
        }
    }


    return (
        <PublicLayout>
            <Container maxWidth='md'>
                <CssBaseline />
                {error && <Alert severity="error">{error}</Alert>}
                <Grid 
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"

                >
                    
                    <Grid item xs={12} sm={6}>
                        
                        <Grid item style={{marginTop:20}}>
                        <Typography variant='h4'>Azzier Account Sign-In</Typography>
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="username"
                                    label="User Name"
                                    name="username"
                                    autoComplete="username"
                                    autoFocus
                                    error={submitted && !username }
                                    value={username}
                                    onChange={handleChange}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    error={submitted && !password }
                                    value={password}
                                    onChange={handleChange}
                                />
                                {!loggingIn &&             
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        style={{width: '40%'}}
                                    > 
                                        Sign In 
                                    </Button>
                                }
                                {loggingIn && <CircularProgress />}
                            </form>
                            <ul style={{listStyleType: 'none', padding: 0, margin: 0, marginTop: 15}}>
                                <li>
                                    <Link to={{pathname: '/forgotpassword'}} variant="body2" style={{ textDecoration: 'none' }}>
                                        Forgot password?
                                    </Link>
                                </li>
                                <li>
                                    <Link to={{pathname: '/trialsignup'}} variant="body2" style={{ textDecoration: 'none' }}>
                                        Sign Up
                                    </Link>
                                </li>
                            </ul>
                        </Grid>
                        
                    </Grid>
                    
                </Grid>
            </Container>
        </PublicLayout>
    );
  }
  
export { LoginPage };