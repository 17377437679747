import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { Modal, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PrivateLayout } from "../../../components/PrivateLayout";
import { Box, Container, Stack } from "@mui/system";
import { alertActions, clientActions, companyLicenseActions } from '../../../_actions';
import LicenseBox from '../../../components/licenseBox';
import { clientService } from '../../../_services';
import {deploymentService} from "../../../_services/deployment.service";
import ConfirmDialog from "../../../components/ConfirmDialog";



const ModifyButton = styled(Button)({
    width: '10%'
})
const DeleteButton = styled(Button)({

})

const ModalContent = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    padding:50,
    transform: 'translate(-50%, -50%)',
    width: "70%",
    bgcolor: 'background.paper',
    backgroundColor:"#FFFFFF",
    margin:40,
    boxShadow: 24,
    maxWidth:750,
    pt: 2,
    px: 4,
    pb: 3,
});


function LicenseDetailsPage() {
    const navigate = useNavigate();
    let { companyId } = useParams();
    console.log('company Id: ' + companyId);
    const dispatch = useDispatch();
    const loadingCompanyLicenses = useSelector(state => state.companyLicenses.loadingCompanyLicenses);
    const companyLicenses = useSelector(state => state.companyLicenses.companyLicenses);
    let currentCompany = useSelector(state => state.companyLicenses.selectedCompany);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [showConfirmClearDb, setShowConfirmClearDb] = useState(false);
    const [deploymentIdToClear, setDeploymentIdToClear] = useState(null);
    const [clearingDb, setClearingDb] = useState(false);
    const [showConfDelTestDeploy, setShowConfDelTestDeploy] = useState(false);
    const [showConfirmCreateTestSite, setShowConfirmCreateTestSite] = useState(false);
    const [liveDeploymentId, setLiveDeploymentId] = useState(null);
    const [testDeploymentId, setTestDeploymentId] = useState(null);
    const loggedInUserSecurityGroup = useSelector(state => state.authentication.user.SecurityGroup);


    useEffect(() => { 
        dispatch(companyLicenseActions.getCompanyLicenses(companyId));
    }, []);

    const formatDate = (dateString) => {
        var inputDate = new Date(dateString);
        return inputDate.toLocaleDateString(undefined, { year: "numeric", month: "long", day: "numeric" });
    };

    const showLicenseDetails = (companyLicenseId) => {
        navigate(`/clients/${companyId}/licenses/${companyLicenseId}`);
    }

    const deleteClient = () => {
        setShowDeleteConfirm(true);
    }

    const closeDeleteConfirm = () => {
        if (!deleting)
        setShowDeleteConfirm(false);
    }

    const confirmDeletion = async () => {
        setDeleting(true);
        try {
            await clientService.deleteClient(companyId);
            navigate('/clients');
        } finally{
            setDeleting(false);
            setShowDeleteConfirm(false);
        }


    }

    const handleDeleteTestIntance = (testDeploymentId) => {
        setTestDeploymentId(testDeploymentId);
        setShowConfDelTestDeploy(true);
    }
    const cancelDeleteTestInstance = () => {
        setShowConfDelTestDeploy(false);
    }
    const handleDelTestInstanceConfirm = async () => {
        setDeleting(true);
        try {
            await deploymentService.deleteDeployment(testDeploymentId);
        } finally {
            setDeleting(false);
            setShowConfDelTestDeploy(false);
            dispatch(companyLicenseActions.getCompanyLicenses(companyId));
        }
    }

    const handleClearDb = (deploymentId) => {
        setDeploymentIdToClear(deploymentId);
        setShowConfirmClearDb(true);
    }

    const cancelClearDb = () => {
        setShowConfirmClearDb(false);
    }
    const handleConfirmClearDb = async () => {
        setClearingDb(true);
        try {
            await deploymentService.clearDeploymentDb(deploymentIdToClear)
        } finally {
            setClearingDb(false);
            setShowConfirmClearDb(false);
            dispatch(companyLicenseActions.getCompanyLicenses(companyId));
        }
    }

    const handleCreateTestSite = (liveDeploymentId) => {
        setLiveDeploymentId(liveDeploymentId);
        setShowConfirmCreateTestSite(true);
    }
    const cancelCreateTestSite = () => {
        setShowConfirmCreateTestSite(false);
    }
    const handleConfirmCreateTestSite = async () => {
        try {
            await deploymentService.createTestDeployment(liveDeploymentId);
        } finally {
            setShowConfirmCreateTestSite(false);
            dispatch(companyLicenseActions.getCompanyLicenses(companyId));
        }
    }
  
    return (
        <PrivateLayout>
            <ConfirmDialog
                open={showConfirmCreateTestSite}
                cancelCallback={cancelCreateTestSite}
                confirmCallback={handleConfirmCreateTestSite}
                confirmButtonText={"Yes, create test site"}
                cancelButtonText={"No, don't create it"}
                description={"Do you really want to create a test site for this client?"}
                title={"Confirm create test site"}
            />
            <Modal open={showDeleteConfirm} onClose={closeDeleteConfirm}>
                <ModalContent>
                    <Typography variant='h4'>Confirm Delete Client</Typography>
                    <p>Are you sure you want to delete this client?  This is not reversable.  Their database will be immediatly deleted and access to their azzier site will be ended immediately.</p>
                    <Grid container justifyContent="center" style={{marginTop:20}}>
                        {deleting?
                        <span><CircularProgress/> Deleting... please wait.</span>
                        :
                        <Grid item>
                            <Button variant='contained' color='error' onClick={confirmDeletion} sx={{mr:2}}>Yes I want to delete this client </Button>
                            <Button variant='contained' onClick={closeDeleteConfirm}>Cancel</Button>
                        </Grid> }

                    </Grid>
                </ModalContent>

            </Modal>
            <Modal open={showConfDelTestDeploy} onClose={cancelDeleteTestInstance}>
                <ModalContent>
                    <Typography variant='h4'>Confirm Delete Test Instance</Typography>
                    <p>Are you sure you want to delete this test instance?  The test database will be immediatly deleted and access to the customer test site will be ended immediately.</p>
                    <Grid container justifyContent="center" style={{marginTop:20}}>
                        {deleting?
                            <span><CircularProgress/> Deleting... please wait.</span>
                            :
                            <Grid item>
                                <Button variant='contained' color='error' onClick={handleDelTestInstanceConfirm} sx={{mr:2}}>Yes, Delete it </Button>
                                <Button variant='contained' onClick={cancelDeleteTestInstance}>No, Don't delete it.</Button>
                            </Grid> }

                    </Grid>
                </ModalContent>
            </Modal>

            <Modal open={showConfirmClearDb} onClose={cancelClearDb}>
                <ModalContent>
                    <Typography variant='h4'>Confirm Clear Database</Typography>
                    <p>Are you sure you want to clear the database for this deployment?  This will delete ALL of the customer's data in this database.</p>
                    <Grid container justifyContent="center" style={{marginTop:20}}>
                        {clearingDb?
                            <span><CircularProgress/> Clearing DB... please wait.</span>
                            :
                            <Grid item>
                                <Button variant='contained' color='error' onClick={handleConfirmClearDb} sx={{mr:2}}>Yes, Clear the DB </Button>
                                <Button variant='contained' onClick={cancelClearDb}>No, Don't clear it.</Button>
                            </Grid> }

                    </Grid>
                </ModalContent>

            </Modal>
            <Container maxWidth="xl">
                <Typography variant="h3" style={{ marginBottom: 30 }}>{currentCompany} Licenses</Typography>
                {!loadingCompanyLicenses && companyLicenses ?
                    companyLicenses.map((license) => (
                    <LicenseBox productName={license.productName} productCode={license.productCode}>
                        <Grid container spacing={2} >
                            <Grid item sm={4}>
                                <Typography style={{ fontWeight:"bold" }}>License Name:</Typography>
                                <Typography>{license.licenseName} {license.trial? "(Trial)": ""}</Typography>
                            </Grid>
                            <Grid item sm={4}>
                                <Typography style={{ fontWeight:"bold" }}>Status:</Typography>
                                <Typography>{license.status ? "Active" : "Not active"}</Typography>
                            </Grid>
                            <Grid item sm={4}>
                                <Typography style={{ fontWeight:"bold" }}>Subscription Id:</Typography>
                                <Typography>{license.subscriptionId}</Typography>
                            </Grid>
                            {license.seed && <Grid item sm={4}>
                                <Typography style={{ fontWeight:"bold" }}>Seed:</Typography>
                                <Typography>{license.seed}</Typography>
                            </Grid>}
                            {license.trial ?
                                <>
                                    <Grid item sm={4}>
                                        <Typography style={{ fontWeight:"bold" }}>Trial Start:</Typography>
                                        <Typography>{formatDate(license.startDate)}</Typography>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Typography style={{ fontWeight:"bold" }}>Trial End:</Typography>
                                        <Typography>{formatDate(license.endDate)}</Typography>
                                    </Grid>
                                </>
                                : null}
                        </Grid>
                        {
                            license.liveDeploymentId && (
                                <Grid container spacing={2} style={{marginTop: 20}}>
                                    <Grid item xs={12} >
                                        <span style={{fontWeight: 'bold', fontSize: 18}}>Live Deployment</span>
                                        <hr/>
                                    </Grid>
                                    <Grid item md={4}>
                                        <Typography style={{ fontWeight:"bold" }}>URL:</Typography>
                                        <Typography>{license.liveUrl}</Typography>
                                    </Grid>
                                    <Grid item md={4}>
                                        <Typography style={{ fontWeight:"bold" }}>Host:</Typography>
                                        <Typography>{license.liveHostName}</Typography>
                                    </Grid>
                                    <Grid item md={4}>
                                        <Typography style={{ fontWeight:"bold" }}>Deployment Status:</Typography>
                                        <Typography>{license.liveDeploymentState}</Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        {["MANAGER_ADMIN","SUPER_USER"].includes(loggedInUserSecurityGroup) && license.liveDeploymentState === 'Completed' && (
                                            <Button variant='outlined' onClick={() => handleClearDb(license.liveDeploymentId)}>Clear Database</Button>
                                        )}
                                      
                                        {!license.testDeploymentId && !license.nonStandardAzzier && (
                                            <Button variant="contained" sx={{ml:2}} color={"primary"} onClick={() => handleCreateTestSite(license.liveDeploymentId)}>Create Test Site</Button>
                                        )}
                                    </Grid>

                                </Grid>
                            )
                        }
                        {
                            license.testDeploymentId && (
                                <Grid container spacing={2} style={{marginTop: 20}}>
                                    <Grid item xs={12} >
                                        <span style={{fontWeight: 'bold', fontSize: 18}}>Test Deployment</span>
                                        <hr/>
                                    </Grid>
                                    <Grid item md={4}>
                                        <Typography style={{ fontWeight:"bold" }}>URL:</Typography>
                                        <Typography>{license.testUrl}</Typography>
                                    </Grid>
                                    <Grid item md={4}>
                                        <Typography style={{ fontWeight:"bold" }}>Host:</Typography>
                                        <Typography>{license.testHostName}</Typography>
                                    </Grid>
                                    <Grid item md={4}>
                                        <Typography style={{ fontWeight:"bold" }}>Deployment Status:</Typography>
                                        <Typography>{license.testDeploymentState}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {!license.CanDeleteTestDb && license.testDeploymentState === 'Completed' && (
                                            <Button variant='outlined' onClick={() => handleClearDb(license.testDeploymentId)} >Clear Database</Button>
                                        )}
                                        <Button variant='outlined' sx={{ml:2}} onClick={() => handleDeleteTestIntance(license.testDeploymentId)} >Delete Test Site</Button>
                                    </Grid>

                                </Grid>
                            )
                        }
                        <hr/>
                        <ModifyButton
                                    type="button"
                                    variant="contained"
                                    onClick={()=>{showLicenseDetails(license.id)}}
                                    style={{ backgroundColor: '#f0ecec', color: '#000000', marginTop: 20, marginBottom: 20 }}
                                >
                                    Modify
                        </ModifyButton>
                        {license.liveDeploymentState === 'Completed' && <Button variant='outlined' sx={{ml:2}} onClick={() => navigate(`/clients/${companyId}/deployments/${license.liveDeploymentId}/snapshots`)}>Manage Snapshots</Button> }
                    </LicenseBox>
                    ))
                : null}
                <DeleteButton
                            type="button"
                            variant="contained"
                            onClick={deleteClient}
                            style={{ backgroundColor: 'red', color: '#FFFFFF', marginTop: 20, marginBottom: 20 }}
                        >
                            Delete Client
                </DeleteButton>


            </Container>
    </PrivateLayout>
    );
  }

export { LicenseDetailsPage };
