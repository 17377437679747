export const licenseTemplateConstants = {
    GET_LICENSE_TEMPLATE_LIST_REQUEST: 'GET_LICENSE_TEMPLATE_LIST_REQUEST',
    GET_LICENSE_TEMPLATE_LIST_SUCCESS: 'GET_LICENSE_TEMPLATE_LIST_SUCCESS',
    GET_LICENSE_TEMPLATE_LIST_FAILURE: 'GET_LICENSE_TEMPLATE_LIST_FAILURE',

    GET_LICENSE_TEMPLATE_REQUEST: 'GET_LICENSE_TEMPLATE_REQUEST',
    GET_LICENSE_TEMPLATE_SUCCESS: 'GET_LICENSE_TEMPLATE_SUCCESS',
    GET_LICENSE_TEMPLATE_FAILURE: 'GET_LICENSE_TEMPLATE_FAILURE',
    
    SAVE_LICENSE_TEMPLATE_REQUEST: 'SAVE_LICENSE_TEMPLATE_REQUEST',
    SAVE_LICENSE_TEMPLATE_SUCCESS: 'SAVE_LICENSE_TEMPLATE_SUCCESS',
    SAVE_LICENSE_TEMPLATE_FAILURE: 'SAVE_LICENSE_TEMPLATE_FAILURE',

    DUPLICATE_LICENSE_TEMPLATE_REQUEST: 'DUPLICATE_LICENSE_TEMPLATE_REQUEST',
    DUPLICATE_LICENSE_TEMPLATE_SUCCESS: 'DUPLICATE_LICENSE_TEMPLATE_SUCCESS',
    DUPLICATE_LICENSE_TEMPLATE_FAILURE: 'DUPLICATE_LICENSE_TEMPLATE_FAILURE',

    ADD_LICENSE_TEMPLATE_REQUEST: 'ADD_LICENSE_TEMPLATE_REQUEST',
    ADD_LICENSE_TEMPLATE_SUCCESS: 'ADD_LICENSE_TEMPLATE_SUCCESS',
    ADD_LICENSE_TEMPLATE_FAILURE: 'ADD_LICENSE_TEMPLATE_FAILURE',

    DELETE_LICENSE_TEMPLATE_REQUEST: 'DELETE_LICENSE_TEMPLATE_REQUEST',
    DELETE_LICENSE_TEMPLATE_SUCCESS: 'DELETE_LICENSE_TEMPLATE_SUCCESS',
    DELETE_LICENSE_TEMPLATE_FAILURE: 'DELETE_LICENSE_TEMPLATE_FAILURE',

};
