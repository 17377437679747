import { seedManagementConstants } from '../_constants';
import { seedManagementService } from '../_services';
import { deploymentService } from '../_services';
import { alertActions } from './alert.actions';

export const seedManagementActions = {
    getSeedList,
    storeDefaultFirstSeeds,
    saveSeedDetails,
    addSeed,
    deleteSeed,
    getStandardDeploymentList
};

function getSeedList() {
    return async dispatch => {
        try {
            dispatch(request());
            let seedList = await seedManagementService.getSeedList();
            dispatch(success(seedList));
        } catch(e){
            console.error(e);
            dispatch(alertActions.error("Could not get deployment seed list"))
            dispatch(error(e));
        }
        
    }

    function request() { return { type: seedManagementConstants.GET_SEED_LIST_REQUEST } }
    function success(seedList) { return { type: seedManagementConstants.GET_SEED_LIST_SUCCESS, seedList } }
    function error(error) { return { type: seedManagementConstants.GET_SEED_LIST_FAILURE, error } }
}

function storeDefaultFirstSeeds(defaultFirstSeeds){
    return {type: seedManagementConstants.STORE_DEFAULT_FIRST_SEEDS, defaultFirstSeeds}
}

function saveSeedDetails(seed) {
    return async dispatch => {
        try {
            dispatch(request());
            await seedManagementService.saveSeedDetails(seed);
            dispatch(success())
        } catch(e){
            console.error(e);
            dispatch(alertActions.error("Could not save seed"))
            dispatch(error(e));
        }
        
    }

    function request() { return { type: seedManagementConstants.SAVE_SEED_DETAILS_REQUEST } }
    function success() { return { type: seedManagementConstants.SAVE_SEED_DETAILS_SUCCESS } }
    function error(error) { return { type: seedManagementConstants.SAVE_SEED_DETAILS_FAILURE, error } }
}

function addSeed(seed) {
    return async dispatch => {
        try {
            dispatch(request());
            await seedManagementService.addSeed(seed);
            dispatch(success())
        } catch(e){
            console.error(e);
            dispatch(alertActions.error("Could not add a new seed"))
            dispatch(error(e));
        }
    }

    function request() { return { type: seedManagementConstants.ADD_SEED_REQUEST } }
    function success() { return { type: seedManagementConstants.ADD_SEED_SUCCESS } }
    function error(error) { return { type: seedManagementConstants.ADD_SEED_FAILURE, error } }
}

function deleteSeed(seedId) {
    return async dispatch => {
        try {
            dispatch(request());
            await seedManagementService.deleteSeed(seedId);
            dispatch(success(seedId))
        } catch(e){
            dispatch(alertActions.error("Could not delete the seed"))
            dispatch(error(e));
        }
    }

    function request() { return { type: seedManagementConstants.DELETE_SEED_REQUEST } }
    function success(seedId) { return { type: seedManagementConstants.DELETE_SEED_SUCCESS, seedId } }
    function error(error) { return { type: seedManagementConstants.DELETE_SEED_FAILURE, error } }
}

function getStandardDeploymentList() {
    return async dispatch => {
        try {
            dispatch(request());
            let standardDeploymentList = await deploymentService.getStandardDeployments();
            dispatch(success(standardDeploymentList));
        } catch(e){
            console.error(e);
            dispatch(alertActions.error("Could not get standard deployment list"))
            dispatch(error(e));
        }
        
    }

    function request() { return { type: seedManagementConstants.GET_STANDARD_DEPLOYMENTS_REQUEST } }
    function success(standardDeployments) { return { type: seedManagementConstants.GET_STANDARD_DEPLOYMENTS_SUCCESS, standardDeployments } }
    function error(error) { return { type: seedManagementConstants.GET_STANDARD_DEPLOYMENTS_FAILURE, error } }
}


