import { Alert, Snackbar } from "@mui/material";
import React from "react";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from "react";
import { useSelector } from 'react-redux';

export default function SystemAlert(){

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpen(false);
    };
    var alertState = useSelector((state) => state.alert);

    useEffect(()=>{
        console.log("UPDATED!!!!")
        setOpen(true);
    },[alertState])

    if (alertState.message) {
        return (
            <Snackbar
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
                }}
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                message={alertState.message}
                action={
                <React.Fragment>
                    
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                    <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
                }
            >
                <Alert severity={alertState.type} onClose={handleClose}  >
                    { alertState.message}
                </Alert>
            </Snackbar>);
    }
    return null;
}