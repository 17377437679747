import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { licenseTemplateActions } from "../../../_actions/licenseTemplate.actions";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Checkbox, CircularProgress, Container, CssBaseline, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Modal, Paper, Select, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { licenseTemplateService } from '../../../_services/licenseTemplate.service';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import ConfirmDialog from "../../../components/ConfirmDialog";
import { PrivateLayout } from "../../../components/PrivateLayout";
import ModuleGrid from "../../../components/ModuleGrid";
import LicenseModulesEditor from "../../../components/LicenseModulesEditor";

const gridStyle = { minHeight: 550 }


const ModalContent = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    padding:50,
    transform: 'translate(-50%, -50%)',
    width: "70%",
    bgcolor: 'background.paper',
    backgroundColor:"#FFFFFF",
    margin:40,
    boxShadow: 24,
    maxWidth:750,
    pt: 2,
    px: 4,
    pb: 3,
})

const SaveButton = styled(Button)({
    marginTop: 15,
    marginRight: 15
});

const CancelButton = styled(Button)({
    marginTop: 15,
    marginRight: 15
});

const DeleteButton = styled(Button)({
    marginTop: 15,
    marginRight: 15
});

const SelectFormControl = styled(FormControl)({
    width:"100%"
})

let inEdit;


function LicenseTemplateDetailsPage(){
    let dispatch = useDispatch();
    let { licenseId } = useParams();
    let licenseTemplate = useSelector((state) => state.licenseTemplates.currentLicenseTemplate);
    let licenseOptions = null;
    if (licenseTemplate != null){
        licenseOptions = licenseTemplate.licenseOptions;
    }
    let loading = useSelector(state => state.licenseTemplates.loadingLicenseTemplate);
    let saving = useSelector((state) => state.licenseTemplates.savingLicenseTemplate);
    const [updatedLicenseOptions, setUpdatedLicenseOptions] = useState([]);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

    let navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        licenseName: Yup.string()
            .required('You must enter a license name')
            .max(100, 'License name must not exceed 100 characters'),
        timeLimited: Yup.bool(),
        trial: Yup.bool(),        
        defaultDurationInDays: Yup.number()
            .min(0).max(999999).nullable(true)
            .transform((_, val) => isNaN(parseInt(val))? null: parseInt(val) ) 
            .when('timeLimited', {
                is: true,
                then: Yup.number()
                .nullable(true)
                .transform((_, val) => isNaN(parseInt(val))? null: parseInt(val) )
                .required("Must enter default duration if time limited")
            })
            
            
    });

    useEffect(() => {
        dispatch(licenseTemplateActions.getById(licenseId));
    },[licenseId]);

    //We have to do this since default form values have already been set and we need to update them with the 
    //values that were just fetched
    useEffect(() => {
        if (licenseTemplate !== null)
            reset({...licenseTemplate});
    },[licenseTemplate])
   


    const { control, setValue, handleSubmit, register, reset, formState: { errors }} = useForm({
        resolver: yupResolver(validationSchema),
        
    });

    const onSubmit = async data => {
        let updatedOptions = [...updatedLicenseOptions];
        updatedOptions = updatedOptions.map((o) => {
            //get rid of the #NEW id since the back end won't need it.
            if (o.id != null && typeof o.id !== 'number'){
                o.id = null;
            }
            return o;            
        });
        let updatedLicenseTemplate = {
            id: licenseTemplate.id,
            licenseName: data.licenseName,
            timeLimited: data.timeLimited, 
            trial: data.trial, 
            defaultDurationInDays: data.defaultDurationInDays,
            licenseOptions: updatedOptions,
            subscriptionPlanId: data.subscriptionPlanId
        }
        try {
            await dispatch(licenseTemplateActions.save(updatedLicenseTemplate));
            navigate("/licenses");
        } catch (e){
            console.error(e);
        }
       
    };


    const onDelete = () => {
        setDeleteConfirmOpen(true);
    }
    const cancelDelete = () => {
        setDeleteConfirmOpen(false);
    }

    const deleteConfirmed = async () => {
        setDeleteConfirmOpen(false);
        await dispatch(licenseTemplateActions.deleteLicenseTemplate(licenseTemplate.id));
        navigate("/licenses");
    }


    return (
        <PrivateLayout>
            <Container style={{paddingBottom:20}}>

                <ConfirmDialog open={deleteConfirmOpen} cancelCallback={cancelDelete} confirmCallback={deleteConfirmed} title={"Confirm Delete"}
                    description={"Are you sure that you want to delete this license?"} />
                <CssBaseline />
                
                {licenseTemplate && !loading ? 
                    <>
                        <Typography variant='h3'>Edit - {licenseTemplate.productName} - {licenseTemplate.licenseName}</Typography>
                        <form onSubmit={handleSubmit(onSubmit)} style={{width: '100%'}}>
                        <Grid container style={{marginTop: 20}} spacing={2}>
                            <Grid item xs={12} sm={6}>
                                    <Controller
                                        name="licenseName"
                                        control={control}
                                        inputRef={register()}
                                        defaultValue={licenseTemplate.licenseName}
                                        render={({ field }) => 
                                            <TextField variant="outlined" fullWidth label="License Name*" margin="normal" {...field} error={errors.licenseName ? true : false}
                                                helperText={errors.licenseName?.message} inputProps={{maxLength: 100}}/>
                                            
                                        }
                                    />
                                    
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                    <Controller
                                        name="timeLimited"
                                        control={control}
                                        inputRef={register()}
                                        defaultValue={licenseTemplate.timeLimited}
                                        render={({ field }) => 
                                            <FormControlLabel control={<Checkbox checked={field.value} onChange={field.onChange} onBlur={field.onBlur} />} style={{marginTop:20}} label={`Time Limited?`} />
                                        }
                                    />
                                    
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Controller
                                        name="trial"
                                        control={control}
                                        inputRef={register()}
                                        defaultValue={licenseTemplate.trial}
                                        render={({ field }) => 
                                            <FormControlLabel control={<Checkbox checked={field.value} onChange={field.onChange} onBlur={field.onBlur} />} style={{marginTop:20}} label={`Trial?`} />
                                        }
                                    />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            
                            <Grid item xs={12} sm={6}>
                                <Controller
                                        name="defaultDurationInDays"
                                        control={control}
                                        inputRef={register()}
                                        defaultValue={licenseTemplate.defaultDurationInDays}
                                        render={({ field }) => 
                                            <TextField type="number" variant="outlined" fullWidth label="Default Duration in Days*" margin="normal" {...field} error={errors.defaultDurationInDays ? true : false}
                                                helperText={errors.defaultDurationInDays?.message} inputProps={{maxLength: 100}}/>
                                            
                                        }
                                    />
                                
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                        name="subscriptionPlanId"
                                        control={control}
                                        inputRef={register()}
                                        defaultValue={licenseTemplate.subscriptionPlanId}
                                        render={({ field }) => 
                                            <TextField variant="outlined" fullWidth label="Subscription Plan Id*" margin="normal" {...field} error={errors.defaultDurationInDays ? true : false}
                                                helperText={errors.defaultDurationInDays?.message} inputProps={{maxLength: 100}}/>
                                            
                                        }
                                    />
                                
                            </Grid>
                            
                        </Grid>
                        <Grid>
                            <Grid item>
                                <Typography variant='h4'>License Options</Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12}>
                                <LicenseModulesEditor editable={true} licenseOptions={licenseOptions} onChange={(licOptions) => {setUpdatedLicenseOptions(licOptions)}}/>
                            </Grid>
                        </Grid>
                        <br /><br />
                        {!saving &&   
                        <>
                            <SaveButton
                                color="primary"
                                type="submit"
                                variant="contained"
                            > 
                                Save Changes
                            </SaveButton>
                            <DeleteButton
                                color="error"
                                type="button"
                                variant="contained"
                                onClick={onDelete}
                            > 
                                Delete License
                            </DeleteButton>
                        </>
                        }
                        {saving && <CircularProgress />}
                        <Link to="/licenses" style={{ textDecoration: 'none' }}>
                            <CancelButton
                                color="secondary"
                                type="button"
                                variant="contained"
                            > 
                                Cancel
                            </CancelButton>
                        </Link>
                        
                    </form> 
                </>
                :       

                <CircularProgress/>
                    
                }
                
            </Container>
        </PrivateLayout>
    )
}

export {LicenseTemplateDetailsPage};

