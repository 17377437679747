import { companyLicenseConstants } from '../_constants';

const initialState = { 
    companyLicenses: null,
    loadingCompanyLicenses: false,
    loadingCompanyLicenseDetails: false,
    selectedCompany: null, 
    selectedCompanyLicense: null, 
    errorMessage: null
};

export function companyLicenses(state = initialState, action) {
    switch (action.type) {
        case companyLicenseConstants.GET_COMPANY_LICENSES_REQUEST:
            return {
                ...state,
                loadingCompanyLicenses: true
            };
        case companyLicenseConstants.GET_COMPANY_LICENSES_SUCCESS:
            return {
                ...state,
                loadingCompanyLicenses: false,
                companyLicenses: action.companyLicenses,
                selectedCompany: action.companyLicenses[0].companyName
            };
        case companyLicenseConstants.GET_COMPANY_LICENSES_FAILURE:
            return {
                ...state,
                loadingCompanyLicenses: false,
                errorMessage: action.error
            };
        case companyLicenseConstants.GET_COMPANY_LICENSE_DETAILS_REQUEST:
            return {
                ...state,
                selectedCompanyLicense: null,
                loadingCompanyLicenseDetails: true
            }
        case companyLicenseConstants.GET_COMPANY_LICENSE_DETAILS_SUCCESS: 
            return {
                ...state,
                selectedCompanyLicense: action.companyLicenseDetails,
                loadingCompanyLicenseDetails: false
            }
        case companyLicenseConstants.GET_COMPANY_LICENSE_DETAILS_FAILURE:
            return {
                ...state,
                selectedCompanyLicense: null,
                loadingCompanyLicenseDetails: false,
                errorMessage: action.error
            };

        case companyLicenseConstants.DEACTIVATE_COMPANY_LICENSE_REQUEST:
            return {
                ...state
            }
        case companyLicenseConstants.DEACTIVATE_COMPANY_LICENSE_SUCCESS: 
            return {
                ...state,
                selectedCompanyLicense: null
            }
        case companyLicenseConstants.DEACTIVATE_COMPANY_LICENSE_FAILURE:
            return {
                ...state,
                errorMessage: action.error
            };

        case companyLicenseConstants.ACTIVATE_COMPANY_LICENSE_REQUEST:
            return {
                ...state
            }
        case companyLicenseConstants.ACTIVATE_COMPANY_LICENSE_SUCCESS: 
            return {
                ...state,
                selectedCompanyLicense: null
            }
        case companyLicenseConstants.ACTIVATE_COMPANY_LICENSE_FAILURE:
            return {
                ...state,
                errorMessage: action.error
            };
        default:
            return state
    }
}