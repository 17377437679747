import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';

import { CustomAlert } from '.';
import { Height } from '@mui/icons-material';

import AzzierLogo from '../_resources/Azzier_FullColour_Logo.png';
import { styled, useTheme } from '@mui/material/styles';
import { Container } from '@mui/material';
import SideBar from './SideBar';


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  })
}));

function PrivateLayout({children,title}) {
  console.log("creating private layout");
    const user = useSelector(state => state.authentication.user);
    const masquerading = useSelector(state => state.authentication.masquerading);
    const dispatch = useDispatch();

    
    useEffect(() => {
        console.log("calling private layout use effect");
    }, []);

    return (
      <div>
            
              <CssBaseline />
              <AppBar style={{ background: '#ECECEC'}} position="fixed">
                    <Toolbar>
                   
                        <img src={AzzierLogo} alt="logo" height={80} style={{margin: 10}} />
                    </Toolbar>
                </AppBar>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(4, 1fr)',
                    gap: 1,
                    gridTemplateRows: 'auto',
                    gridTemplateAreas: `"sidebar main main main"`,
                    paddingTop:15,
                    height:'100vh',
                    width:'100%'
                  }}
                >
                  <Box sx={{ gridArea: 'sidebar'}}>
                    <SideBar/>
                  </Box>
                  <Box sx={{ gridArea: 'main' }}>
                  {children}
                  </Box>
                </Box>
                
      </div>
    );
}

export { PrivateLayout };